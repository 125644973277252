<template>
    <div class="wrap">
        <div class="page_title"><b>报价统计</b></div>
        <div class="search_box public_box">
            <!--检索部分-->
            <el-form :inline="true" :model="navForm" class="demo-form-inline">
                <el-form-item label="查询时间：" class="dateBox">
                    <el-row style="width: 330px">
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" v-model="navForm.startTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                        <el-col class="line" style="text-align: center" :span="2">至</el-col>
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" v-model="navForm.endTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="报价人手机号：">
                    <el-input class="width_150" v-model="navForm.phoneNum" type="tel" maxlength="11" placeholder="请输入手机号" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="searchTable" style="margin-left: 0.2rem">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main">
            <ul class="workorder_statistics">
                <li class="public_box">
                    <div class="workorder_icon">
                        <img src="../../../public/images/gongdanzongshu.png" />
                    </div>
                    <div class="workorder_data">
                        <div class="workorder_num">
                            <b>{{ countOrderNum.totalCount }}</b>
                            <span>单</span>
                        </div>
                        <p>报价工单总单数</p>
                        <div class="average">日均报价单数：{{ countOrderNum.avgCount }}单</div>
                    </div>
                </li>
                <li class="public_box">
                    <div class="workorder_icon">
                        <img src="../../../public/images/yichanggongdan.png" />
                    </div>
                    <div class="workorder_data">
                        <div class="workorder_num">
                            <b>{{ countOrderNum.abnormalCount }}</b>
                            <span>单</span>
                        </div>
                        <p>报价异常工单总单数</p>
                    </div>
                </li>
                <li class="public_box">
                    <div class="workorder_icon">
                        <img src="../../../public/images/shichang.png" />
                    </div>
                    <div class="workorder_data">
                        <div class="workorder_num">
                            <b>{{ countOrderNum.moreMarketPriceCount }}</b>
                            <span>单</span>
                        </div>
                        <p>零件报价高于市场价总单数</p>
                    </div>
                </li>
                <li class="public_box">
                    <div class="workorder_icon">
                        <img src="../../../public/images/4sdian.png" />
                    </div>
                    <div class="workorder_data">
                        <div class="workorder_num">
                            <b>{{ countOrderNum.more4SPriceCount }}</b>
                            <span>单</span>
                        </div>
                        <p>零件报价高于4S店价总单数</p>
                    </div>
                </li>
                <li class="public_box">
                    <div class="workorder_icon">
                        <img src="../../../public/images/gongshi.png" />
                    </div>
                    <div class="workorder_data">
                        <div class="workorder_num">
                            <b>{{ countOrderNum.moreManHourPriceCount }}</b>
                            <span>单</span>
                        </div>
                        <p>工时费报价异常总单数</p>
                    </div>
                </li>
            </ul>
            <div class="charts_box">
                <div class="charts_top">
                    <div class="charts_bar1 public_box">
                        <div class="detail_title">异常报价品牌TOP5&异常次数统计</div>
                        <div id="charts_bar1" v-if="!isShowBar1" class="charts_bar"></div>
                        <div class="charts_null" v-show="isShowBar1">暂无数据</div>
                    </div>
                    <div class="charts_bar2 public_box">
                        <div class="detail_title">异常报价报价人TOP5&异常次数统计</div>
                        <div id="charts_bar2" v-if="!isShowBar2" class="charts_bar"></div>
                        <div class="charts_null" v-show="isShowBar2">暂无数据</div>
                    </div>
                    <div class="charts_bar3 public_box">
                        <div class="detail_title">零件异常报价频次TOP5&异常次数统计</div>
                        <div id="charts_bar3" v-if="!isShowBar3" class="charts_bar"></div>
                        <div class="charts_null" v-show="isShowBar3">暂无数据</div>
                    </div>
                </div>
                <div class="charts_bottom public_box">
                    <div class="detail_title">10个大件零件每周更换次数统计</div>
                    <div id="charts_line" v-show="!isShowTime"></div>
                    <div class="null_data" v-show="isShowTime">
                        <div><img src="../../../public/images/time.png" /></div>
                        <p>查询时间太短，请查询大于7天的时间</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import * as echarts from 'echarts';
export default {
    name: 'offerStatistics',
    components: {},
    data() {
        return {
            chartsBar1: null,
            chartsBar2: null,
            chartsBar3: null,
            chartsLine: null,
            isShowTime: false,
            isShowBar1: false,
            isShowBar2: false,
            isShowBar3: false,
            countOrderNum: {}, //工单数量统计
            navForm: {
                //储存搜索框的检索条件信息
                startTime: '',
                endTime: '',
                phoneNum: ''
            }
        };
    },
    methods: {
        //点击查询
        searchTable() {
            if (!this.navForm.startTime) {
                this.$message.warning('抱歉，请选择查询开始时间！');
                return false;
            }
            if (!this.navForm.endTime) {
                this.$message.warning('抱歉，请选择查询结束时间！');
                return false;
            }
            this.getCountOrderNum();
            this.getAbnormalPriceBrand();
            this.getAbnormalPriceParts();
            this.getAbnormalPricePerson();
            this.getCountTenPartsChange();
        },
        //工单数量统计接口
        getCountOrderNum: function () {
            this.instance({
                method: 'post',
                url: '/backstage/countOrderNum',
                data: this.navForm
                //  headers:{
                // 'Content-Type':"application/x-www-form-urlencoded",
                //  },
            }).then((res) => {
                // this.loading=false;
                if (res.data.code == 1) {
                    this.countOrderNum = res.data.data;
                }
            });
        },
        //异常报价品牌TOP5&异常次数统计
        getAbnormalPriceBrand: function () {
            this.chartsBar1 && this.chartsBar1.clear();
            this.instance({
                method: 'post',
                url: '/backstage/abnormalPriceBrand',
                data: this.navForm
                //  headers:{
                // 'Content-Type':"application/x-www-form-urlencoded",
                //  },
            }).then((res) => {
                if (res.data.code == 1) {
                    if (res.data.data.length > 0) {
                        this.isShowBar1 = false;
                        setTimeout(() => {
                            this.loadChartsBar1(res.data.data);
                        }, 200);
                    }
                } else {
                    this.isShowBar1 = true;
                }
            });
        },
        //零件异常报价频次TOP5&异常次数统计
        getAbnormalPriceParts: function () {
            this.chartsBar3 && this.chartsBar3.clear();
            this.instance({
                method: 'post',
                url: '/backstage/abnormalPriceParts',
                data: this.navForm
                //  headers:{
                // 'Content-Type':"application/x-www-form-urlencoded",
                //  },
            }).then((res) => {
                // this.loading=false;
                if (res.data.code == 1) {
                    if (res.data.data.length > 0) {
                        this.isShowBar3 = false;
                        setTimeout(() => {
                            this.loadChartsBar3(res.data.data);
                        }, 200);
                    }
                } else {
                    this.isShowBar3 = true;
                }
            });
        },
        //异常报价人TOP5&异常次数统计
        getAbnormalPricePerson: function () {
            this.chartsBar2 && this.chartsBar2.clear();
            this.instance({
                method: 'post',
                url: '/backstage/abnormalPricePerson',
                data: this.navForm
                //  headers:{
                // 'Content-Type':"application/x-www-form-urlencoded",
                //  },
            }).then((res) => {
                // this.loading=false;
                if (res.data.code == 1) {
                    if (res.data.data.length > 0) {
                        this.isShowBar2 = false;
                        setTimeout(() => {
                            this.loadChartsBar2(res.data.data);
                        }, 200);
                    }
                } else {
                    this.isShowBar2 = true;
                }
            });
        },
        //10大件更换次数统计
        getCountTenPartsChange: function () {
            this.chartsLine && this.chartsLine.clear();
            this.instance({
                method: 'post',
                url: '/backstage/countTenPartsChange',
                data: this.navForm
                //  headers:{
                // 'Content-Type':"application/x-www-form-urlencoded",
                //  },
            }).then((res) => {
                if (res.data.code == 1) {
                    if (res.data.data.length > 0) {
                        this.isShowTime = false;
                        this.loadChartsLine(res.data.data);
                    }
                } else {
                    this.isShowTime = true;
                }
            });
        },
        //加载异常报价品牌TOP5&异常次数统计图表
        loadChartsBar1(response) {
            let xAxisData = [],
                seriesData = [];
            response.forEach((val, key) => {
                xAxisData.push(val.abnormalName);
                seriesData.push(val.nums);
            });
            var chartDom = document.getElementById('charts_bar1');
            this.chartsBar1 = echarts.init(chartDom, null, { renderer: 'svg' });
            var option = {
                xAxis: {
                    type: 'category',
                    axisLabel: {
                        interval: 0,
                        // rotate: 20,
                        color: '#666', //坐标值得具体的颜色
                        fontSize: '0.14rem'
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    data: xAxisData
                },
                yAxis: {
                    type: 'value',
                    axisLabel: { show: false },
                    splitLine: {
                        show: false
                    }
                },
                grid: {
                    top: '30',
                    bottom: '30',
                    left: '0',
                    right: '0'
                },
                series: [
                    {
                        barWidth: '50%',
                        label: {
                            show: true,
                            position: 'top',
                            formatter: '{@score}次',
                            color: '#1989FA',
                            fontSize: '0.16rem'
                        },
                        itemStyle: {
                            color: '#63B8FF',
                            borderRadius: [5, 5, 0, 0]
                        },
                        data: seriesData,
                        type: 'bar'
                    }
                ]
            };
            this.chartsBar1.setOption(option);
        },
        //异常报价报价人TOP5&报价金额统计
        loadChartsBar2(response) {
            let xAxisData = [],
                seriesData = [];
            response.forEach((val, key) => {
                xAxisData.push(val.abnormalName);
                seriesData.push(val.nums);
            });
            var chartDom = document.getElementById('charts_bar2');
            this.chartsBar2 = echarts.init(chartDom, null, { renderer: 'svg' });
            var option = {
                tooltip: {
                    trigger: 'axis',
                    formatter(params) {
                        return params[0].name + '\n' + response[params[0].dataIndex].avgOffer + '元';
                    }
                },
                xAxis: {
                    type: 'category',
                    axisLabel: {
                        interval: 0,
                        // rotate: 20,
                        color: '#666', //坐标值得具体的颜色
                        fontSize: '0.14rem'
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    data: xAxisData
                },
                yAxis: {
                    type: 'value',
                    axisLabel: { show: false },
                    splitLine: {
                        show: false
                    }
                },
                grid: {
                    top: '30',
                    bottom: '10',
                    left: '10',
                    right: '10',
                    containLabel: true
                },
                series: [
                    {
                        barWidth: '50%',
                        label: {
                            show: true,
                            position: 'top',
                            formatter: '{@score}次',
                            color: '#4C71F0',
                            fontSize: '0.16rem'
                        },
                        itemStyle: {
                            color: '#4C71F0',
                            borderRadius: [5, 5, 0, 0]
                        },
                        data: seriesData,
                        type: 'bar'
                    }
                ]
            };
            this.chartsBar2.setOption(option);
        },
        //零件异常报价频次TOP5&异常次数统计
        loadChartsBar3(response) {
            let xAxisData = [],
                seriesData1 = [],
                seriesData2 = [];
            response.forEach((val, key) => {
                xAxisData.push(val.abnormalName);
                seriesData1.push(val.nums);
                seriesData2.push(val.avgOffer);
            });
            var chartDom = document.getElementById('charts_bar3');
            this.chartsBar3 = echarts.init(chartDom, null, { renderer: 'svg' });
            var option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                grid: {
                    top: '30',
                    bottom: '10',
                    left: '10',
                    right: '30',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: xAxisData,
                        axisLine: {
                            show: false
                        },
                        axisLabel: {
                            interval: 0,
                            rotate: 20,
                            color: '#666', //坐标值得具体的颜色
                            fontSize: '0.14rem'
                        },
                        axisTick: {
                            show: false
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '(元)',
                        // axisLabel:{show:false},
                        splitLine: {
                            // show: false
                        }
                    },
                    {
                        type: 'value',
                        name: '(次)',
                        splitLine: {
                            show: false
                        }
                    }
                ],
                series: [
                    {
                        // name: 'Evaporation',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + ' 元';
                            }
                        },
                        barWidth: '50%',
                        label: {
                            show: true,
                            position: 'top',
                            formatter: '{@score}',
                            color: '#00D0C2',
                            fontSize: '0.16rem'
                        },
                        itemStyle: {
                            color: '#00D0C2',
                            borderRadius: [5, 5, 0, 0]
                        },
                        data: seriesData2
                    },
                    {
                        // name: 'Temperature',
                        type: 'line',
                        yAxisIndex: 1,
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + ' 次';
                            }
                        },
                        symbol: 'circle',
                        itemStyle: {
                            color: '#FFBF3A'
                        },
                        lineStyle: {
                            color: '#FFBF3A'
                        },
                        data: seriesData1
                    }
                ]
            };
            this.chartsBar3.setOption(option);
        },
        //10个大件零件每周更换次数统计
        loadChartsLine(response) {
            let xAxisData = [],
                seriesData = [],
                legendData = [];
            response[0].abnormalOfferVos.forEach((value, index) => {
                legendData.push(value.abnormalName);
                let obj = {
                    name: value.abnormalName,
                    type: 'line',
                    symbol: 'circle',
                    data: []
                };
                seriesData.push(obj);
            });
            response.forEach((val, key) => {
                xAxisData.push(val.period);
                val.abnormalOfferVos.forEach((value, index) => {
                    seriesData[index].data.push(val.abnormalOfferVos[index].nums);
                });
            });
            var chartDom = document.getElementById('charts_line');
            this.chartsLine = echarts.init(chartDom, null, { renderer: 'svg' });
            var option = {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: legendData
                },
                grid: {
                    top: 70,
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    // boundaryGap: false,
                    axisLine: {
                        show: false
                    },
                    axisLabel: {
                        interval: 0,
                        rotate: 10,
                        color: '#666', //坐标值得具体的颜色
                        fontSize: '0.12rem'
                    },
                    axisTick: {
                        show: false
                    },
                    data: xAxisData
                },
                yAxis: {
                    type: 'value',
                    minInterval: 1,
                    name: '(次)'
                },
                series: seriesData
            };
            this.chartsLine.setOption(option);
        }
    },
    mounted() {
        const date = new Date();
        let startDate = new Date(date.getTime() - 3600 * 1000 * 24 * 30);
        this.navForm.startTime = this.dateFormatting(startDate, 'yyyy-MM-dd');
        this.navForm.endTime = this.dateFormatting(date, 'yyyy-MM-dd');
        this.searchTable();
        window.addEventListener('resize', () => {
            if (this.chartsBar1) {
                setTimeout(() => {
                    this.chartsBar1.resize();
                }, 300);
            }
            if (this.chartsBar2) {
                setTimeout(() => {
                    this.chartsBar2.resize();
                }, 300);
            }
            if (this.chartsBar3) {
                setTimeout(() => {
                    this.chartsBar3.resize();
                }, 300);
            }
            if (this.chartsLine) {
                setTimeout(() => {
                    this.chartsLine.resize();
                }, 300);
            }
        });
    }
};
</script>
<style scoped>
@import '../../assets/css/table_public.css';
.charts_null {
    text-align: center;
    font-size: 0.2rem;
    color: #666;
    line-height: 3.3rem;
}
.null_data {
    width: 100%;
    text-align: center;
    font-size: 0.16rem;
    color: #333;
    padding-top: 0.44rem;
}
.null_data div {
    width: 1.93rem;
    margin: 0 auto 0.24rem;
}
.charts_bar {
    width: 100%;
    height: 3.3rem;
    margin-top: 0.1rem;
}
.charts_top {
    width: 100%;
    margin: 0.3rem 0;
    display: flex;
    justify-content: space-between;
}
.charts_bottom {
    width: 100%;
    min-height: 4.3rem;
}
#charts_line {
    width: 100%;
    height: 3.5rem;
}
.charts_top .public_box {
    width: 30.6%;
    min-height: 4.6rem;
}
.charts_top .charts_bar3 {
    width: 35%;
}
.wrap {
    width: 100%;
    /* height: 100%; */
    position: relative;
}
.workorder_statistics {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0.3rem 0;
}
.workorder_statistics .public_box {
    padding-left: 0.1rem;
    display: flex;
    margin-left: 1.25%;
    width: 19%;
}
.workorder_statistics .public_box:first-child {
    margin-left: 0;
}
.workorder_icon {
    width: 0.7rem;
}
.workorder_data {
    /* width: auto; */
    width: 1.95rem;
}
.workorder_num {
    line-height: 1.6;
    font-size: 0.36rem;
    font-weight: 600;
}
.workorder_num span {
    font-size: 0.2rem;
    font-weight: 400;
    color: #b4bccc;
}
.workorder_data p {
    margin: 0.1rem 0 0.26rem;
    font-size: 0.22rem;
    color: #333333;
}
.average {
    font-size: 0.18rem;
    color: #1989fa;
}
</style>
