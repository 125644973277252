<template>
    <div ref="networkContainer" style="width: 600px; height: 400px"></div>
</template>  
  
<script>
// import { Network } from 'vis-network';

export default {
    name: 'VisNetworkComponent',
    data() {
        return {
            network: null,
            nodes: [
                { id: 1, label: 'Node 1', x: 150, y: 200, shape: 'customTextShape' }
                // 你可以在这里添加更多节点
            ],
            edges: [] // 你可以在这里定义边
        };
    },
    mounted() {
        this.initNetwork();
    },
    methods: {
        initNetwork() {
            const container = this.$refs.networkContainer;
            const options = {
                // 你可以在这里设置更多的网络图选项
                nodes: {
                    shapeProperties: {
                        // 自定义形状属性（如果需要）
                    }
                }
            };

            // 创建网络实例
            this.network = new Network(container, { nodes: this.nodes, edges: this.edges }, options);

            // 注册自定义节点形状
            this.network.registerShape('customTextShape', {
                draw: function (ctx, nodeParameters) {
                    // 绘制节点背景
                    ctx.beginPath();
                    ctx.arc(nodeParameters.x, nodeParameters.y, 40, 0, 2 * Math.PI, false);
                    ctx.fillStyle = 'lightblue';
                    ctx.fill();

                    // 绘制节点文本
                    ctx.fillStyle = 'black';
                    ctx.font = '14px Arial';
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    ctx.fillText(nodeParameters.label, nodeParameters.x, nodeParameters.y);

                    // 如果需要，可以绘制更多文本或图形

                    // 结束路径
                    ctx.closePath();
                },
                // 如果需要，可以定义 hitArea 形状用于交互
                hitArea: {
                    shape: 'circle',
                    coordinates: function (nodeParameters, zoomLevel) {
                        return {
                            x: nodeParameters.x,
                            y: nodeParameters.y,
                            radius: 50 // 确保这个半径足够大，以便能够覆盖整个自定义节点
                        };
                    }
                }
            });

            // 注意：如果你打算在组件的生命周期内更新网络图（例如添加/删除节点或边），
            // 你需要调用 this.network 的相应方法来更新数据，例如 this.network.setData({ nodes: newNodes, edges: newEdges })
        }
    }
    // 如果你需要响应外部数据变化来更新网络图，你可以添加 watcher 或其他 Vue 响应式逻辑
};
</script>  
  
<style scoped>
/* 你的样式 */
</style>