<template>
    <div class="wrap">
        <div class="page_title">
            <b>小额案件在线质检</b>
            <span>（案件列表显示满足筛选规则的案件）</span>
        </div>
        <div class="search_box public_box">
            <!--检索部分-->
            <el-form :inline="true" :model="navForm" class="demo-form-inline">
                <div class="flex">
                    <div>
                        <el-form-item style="margin-right: 0.8rem">
                            <el-upload class="upload_box" accept=".xls,.xlsx" :action="baseURL + '/case/importCaseExcel'" :show-file-list="true" :headers="unloaderHeader" :limit="1" :on-remove="handleRemove" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                                <template>
                                    <el-button type="primary"><i class="el-icon-upload el-icon--left"></i>上传案件</el-button>
                                </template>
                            </el-upload>
                        </el-form-item>
                        <el-form-item>
                            <span class="hint_text">（若无批量案件上传，可按条件查询）</span>
                        </el-form-item>
                        <br />
                        <el-form-item label="案件类型：">
                            <el-select v-model="navForm.caseType" clearable multiple class="width_150" placeholder="请选择">
                                <!-- <el-option  label="全部"  value="0"></el-option> -->
                                <el-option label="骑手人伤" value="骑手人伤"></el-option>
                                <el-option label="三者人伤" value="三者人伤"></el-option>
                                <el-option label="三者物损" value="三者物损"></el-option>
                                <el-option label="三者车损" value="三者车损"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="结案状态：">
                            <el-select v-model="caseClosedStatus" @change="caseClosedStatusChange" clearable class="width_150" placeholder="请选择">
                                <el-option label="未决" value="0"></el-option>
                                <el-option label="已决" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="案件状态：">
                            <el-select v-model="navForm.caseStatus" multiple class="width_150" clearable placeholder="请选择">
                                <el-option v-for="(item, index) in statusOptions" :key="index" :label="item.status" :value="item.status"> </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="事故省：">
                            <el-select v-model="navForm.accidentProvince" clearable class="width_150" placeholder="请选择">
                                <el-option v-for="item in provinceOptions" :key="item" :label="item" :value="item"> </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="公估公司：">
                            <el-input class="width_150" v-model="navForm.lossAdjusterCompany" placeholder="请填写公估公司" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="被保险人：">
                            <el-input class="width_150" v-model="navForm.nameOfInsured" placeholder="请填写分包商" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="案件号/保单号：">
                            <el-input class="width_180" v-model="navForm.caseNo" placeholder="请填写案件号/保单号" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="初审是否通过：">
                            <el-select v-model="navForm.firstReviewPass" class="width_150" placeholder="请选择" clearable>
                                <el-option v-for="item in peviewPassOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="初次立案金额：">
                            <el-input class="width_180" v-model="navForm.startFirstAmount" min="0" type="number" placeholder="请填写案件号/保单号" clearable @input="numberChange(arguments[0], 999999999999999999)"></el-input>
                            到
                            <el-input class="width_180" v-model="navForm.endFirstAmount" min="0" type="number" placeholder="请填写案件号/保单号" clearable @input="numberChange1(arguments[0], 999999999999999999)"></el-input>
                        </el-form-item>
                        <el-form-item label="报案时间：" class="dateBox">
                            <el-row style="width: 350px">
                                <el-col :span="11">
                                    <el-date-picker placeholder="请选择日期" clearable v-model="navForm.reportStartTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                </el-col>
                                <el-col class="line" style="text-align: center" :span="2">至</el-col>
                                <el-col :span="11">
                                    <el-date-picker placeholder="请选择日期" clearable v-model="navForm.reportEndTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item label="结案时间：" class="dateBox">
                            <el-row style="width: 350px">
                                <el-col :span="11">
                                    <el-date-picker placeholder="请选择日期" clearable v-model="navForm.closeStartTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                </el-col>
                                <el-col class="line" style="text-align: center" :span="2">至</el-col>
                                <el-col :span="11">
                                    <el-date-picker placeholder="请选择日期" clearable v-model="navForm.closeEndTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item label="出险时间：" class="dateBox">
                            <el-row style="width: 350px">
                                <el-col :span="11">
                                    <el-date-picker placeholder="请选择日期" clearable v-model="navForm.emergenceStartTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                </el-col>
                                <el-col class="line" style="text-align: center" :span="2">至</el-col>
                                <el-col :span="11">
                                    <el-date-picker placeholder="请选择日期" clearable v-model="navForm.emergenceEndTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item label="操作时间：" class="dateBox">
                            <el-row style="width: 350px">
                                <el-col :span="11">
                                    <el-date-picker placeholder="请选择日期" clearable v-model="navForm.startTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                </el-col>
                                <el-col class="line" style="text-align: center" :span="2">至</el-col>
                                <el-col :span="11">
                                    <el-date-picker placeholder="请选择日期" clearable v-model="navForm.endTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                </el-col>
                            </el-row>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" @click="searchTable">风险查询</el-button>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
        </div>
        <div class="main">
            <div class="table_box public_box">
                <div class="table_title">
                    <b
                        >案件详情<el-button @click="handleDownload" v-if="tableData.length > 0" class="table_btn download" type="primary"><i class="el-icon-download el-icon--left"></i>下载清单</el-button></b
                    >

                    <div class="case_statistics">
                        <div>初审案件{{ tagCount.oneselfVerifyCount || 0 }}个，其中红灯案件{{ tagCount.oneselfVerifyRedCount || 0 }}个，绿灯案件{{ tagCount.oneselfVerifyGreenCount || 0 }}个；</div>
                        <div>复审案件{{ tagCount.modelVerifyCount || 0 }}个，其中红灯案件{{ tagCount.modelVerifyRedCount || 0 }}个，绿灯案件{{ tagCount.modelVerifyGreenCount || 0 }}个。</div>
                    </div>
                </div>
                <!--table表格部分-->
                <el-table ref="multipleTable" :data="tableData" v-loading="loading" style="width: 100%" @sort-change="sortChange" :empty-text="caseExcelData.length > 0 || navForm.endTime ? '暂无案件' : '暂无案件,请上传案件清单或选择查询日期'">
                    <!-- @selection-change="handleSelectionChange"  -->
                    <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                    <!-- <el-table-column prop="meituanCaseNo" label="美团案件号" width="170px">
						<template slot-scope="scope">
							<div>{{scope.row.meituanCaseNo}}</div>
							<div :class="{flex:scope.row.case5dayNotClosed}">
								<div class="risk_hint" :class="{red:scope.row.caseLevel=='D'}">{{scope.row.caseLevel}}级风险</div>
								<div v-if="scope.row.case5dayNotClosed" class="risk_hint purple">超5日未结案</div>
							</div>
						</template>
					</el-table-column> -->
                    <el-table-column prop="caseNo" label="案件号" width="210"></el-table-column>
                    <el-table-column prop="currentStatusDesc" label="案件状态"></el-table-column>
                    <el-table-column prop="accidentProperty" label="案件类型" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column prop="accidentTime" label="出险时间"></el-table-column>
                    <el-table-column prop="caseClosedTime" label="结案时间"></el-table-column>
                    <!-- <el-table-column prop="totalPayAmount" label="结案金额" sortable='custom'></el-table-column> -->
                    <!-- <el-table-column prop="valueAtRisk" label="风险值" sortable="custom"></el-table-column> -->
                    <el-table-column prop="oneselfVerify" label="初审结果" width="100">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.oneselfVerify" size="mini" :type="scope.row.oneselfVerify == '红灯案件' ? 'danger' : 'success'" effect="dark" style="width: 30px"></el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="modelVerify" label="复审结果" width="100">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.modelVerify" size="mini" :type="scope.row.modelVerify == '红灯案件' ? 'danger' : 'success'" effect="dark" style="width: 30px"></el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="handleTime" label="操作时间" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column label="操作" width="180px">
                        <template slot-scope="scope">
                            <div class="flex">
                                <span class="a_color" @click="lookDetails(scope.row)">查看详情</span>
                                <span class="a_color" @click="hanleVerify(1, '初审结果', scope.row)">初审结果</span>
                                <span class="a_color" @click="hanleVerify(2, '复审结果', scope.row)">复审结果</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="10" :total="totalPage"> </el-pagination>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisibleVerify" :title="verifyTitle" :modal-append-to-body="false" :append-to-body="true" center width="50%">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="验证信息：">
                    <el-radio-group v-model="verifyRadio">
                        <el-radio-button v-if="(checkedRow.firstVerdictType && verifyValue == 1) || verifyValue == 2" class="bg_red" label="红灯案件"></el-radio-button>
                        <el-radio-button v-if="(checkedRow.oneselfVerify == '绿灯案件' && verifyValue == 1) || verifyValue == 2" class="bg_green" label="绿灯案件"></el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <div v-if="(checkedRow.firstVerdictType && verifyValue == 1) || (verifyValue == 2 && verifyRadio == '红灯案件')">
                    <el-form-item v-if="verifyValue == 2" label="验证依据：" class="form-items">
                        <el-cascader ref="cascader" v-model="tmpDatas" :options="tmpOptions" :props="props" collapse-tags clearable @change="getselKnowledgeList"></el-cascader>
                    </el-form-item>
                    <br />
                    <el-form-item label="验证结论：" class="form-items">
                        <template v-if="verifyValue == 2">
                            <el-tag v-for="tag in conclusionTags" :key="tag.value" closable @close="conclusionClose(tag)">
                                {{ tag.label }}
                            </el-tag>
                        </template>
                        <template v-else>
                            <template v-if="conclusionTags.length > 0">
                                <el-tag v-for="tag in conclusionTags" :key="tag.value">
                                    {{ tag.label }}
                                </el-tag>
                            </template>
                            <p v-else>--</p>
                        </template>
                    </el-form-item>
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button v-if="verifyValue == 2" @click="cancelCaseDialog">取 消</el-button>
                <el-button type="primary" @click="updateCaseTag">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog class="case_detail" :visible.sync="dialogVisible" :modal-append-to-body="false" :append-to-body="true" width="70%">
            <div class="dialog_box">
                <div class="dialog_header">
                    <div>案件号：{{ checkedRowDetail.caseNo }}</div>
                    <div>是否快赔：{{ checkedRowDetail.quickPayOrNot }}</div>
                </div>
                <div class="dialog_main">
                    <el-steps :active="stepsActive" align-center process-status="finish">
                        <el-step v-for="(item, index) in checkedRowDetail.stageOfCaseVoList" :key="index" :title="item.stageName">
                            <template slot="description" v-if="item.arrivalOrNot">
                                <!-- #F56C6C -->
                                <div class="status_icon">
                                    <img v-if="item.yesOrNo && item.caseRiskPoints.length > 0" src="../../../../public/images/wraning_icon.png" alt="" />
                                    <img v-else src="../../../../public/images/success_icon.png" alt="" />
                                </div>
                                <div style="color: #333">
                                    <div v-if="item.yesOrNo && item.caseRiskPoints.length > 0">
                                        <p>此阶段存在{{ item.caseRiskPoints.length }}个风险点</p>
                                        <ul class="risk_point">
                                            <li v-for="(risk, key) in item.caseRiskPoints" :key="key">
                                                <p>{{ key + 1 + '、' + risk.riskTip }}</p>
                                                <div class="status_box">
                                                    <template v-if="risk.riskType == 1">
                                                        <span v-if="risk.status == 0" class="a_color" @click="handleAttention(item, risk)">待关注</span>
                                                        <span v-else-if="risk.status == 1" style="color: #e6a23c">已关注</span>
                                                    </template>
                                                    <template v-else-if="risk.riskType == 2">
                                                        <span v-if="risk.status == 0" class="a_color" @click="handleAttention(item, risk)">待处理</span>
                                                    </template>
                                                    <span v-if="risk.status == 2" style="color: #67c23a">已处理</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <p v-else>此阶段无风险</p>
                                </div>
                            </template>
                        </el-step>
                    </el-steps>
                    <div
                        class="relevancy_case"
                        v-if="
                            checkedRowDetail.riderAsThirdCases &&
                            (checkedRowDetail.riderAsThirdCases.length > 0 || checkedRowDetail.thirdVehicleInCases.length > 0 || checkedRowDetail.riderAccumulatedCases.length > 0 || checkedRowDetail.riderMultiCases.length > 0 || checkedRowDetail.thirdInjuredCases.length > 0)
                        "
                    >
                        <p>风险关联案件：</p>
                        <template v-if="checkedRowDetail.riderAsThirdCases.length > 0">
                            <div class="small_title">该骑手在以下案件中为三者：</div>
                            <div>
                                <span v-for="(item, index) in checkedRowDetail.riderAsThirdCases" :key="index">{{ item + '；' }}</span>
                            </div>
                        </template>
                        <template v-if="checkedRowDetail.thirdVehicleInCases.length > 0">
                            <div class="small_title">该三者车辆在以下案件中出现：</div>
                            <div>
                                <span v-for="(item, index) in checkedRowDetail.thirdVehicleInCases" :key="index">{{ item + '；' }}</span>
                            </div>
                        </template>
                        <template v-if="checkedRowDetail.thirdInjuredCases.length > 0">
                            <div class="small_title">同一三者在以下案件中出现：</div>
                            <div>
                                <span v-for="(item, index) in checkedRowDetail.thirdInjuredCases" :key="index">{{ item + '；' }}</span>
                            </div>
                        </template>
                        <template v-if="checkedRowDetail.riderMultiCases.length > 0">
                            <div class="small_title">骑手近期在以下案件中出险：</div>
                            <div>
                                <span v-for="(item, index) in checkedRowDetail.riderMultiCases" :key="index">{{ item + '；' }}</span>
                            </div>
                        </template>
                        <template v-if="checkedRowDetail.riderAccumulatedCases.length > 0">
                            <div class="small_title">该骑手在以下案件中累计赔付：</div>
                            <div>
                                <span v-for="(item, index) in checkedRowDetail.riderAccumulatedCases" :key="index">{{ item + '；' }}</span>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src
// import MyHeader from '@/components/MyHeader.vue'
import { getToken } from '../../../utils/auth.js'
export default {
    name: 'onlineTesting',
    components: {},
    data() {
        return {
            caseExcelData: [],
            verifyTitle: '自留验证',
            checkedRow: '',
            dialogVisible: false,
            relevancyVisible: false,
            dialogVisibleVerify: false,
            verifyRadio: '',
            verifyValue: '',
            baseURL: '',
            unloaderHeader: { token: '' }, //上传的请求头
            fullscreenLoading: false,
            uploadFile: {}, //上传成功后的值
            fileName: '',
            activeName: 'first',
            loading: false,
            nowFilter: '',
            statusOptions: [],
            baseStatusOptions: [],
            provinceOptions: [
                '辽宁省',
                '江苏省',
                '广西壮族自治区',
                '福建省',
                '四川省',
                '黑龙江省',
                '山东省',
                '江西省',
                '陕西省',
                '吉林省',
                '北京市',
                '安徽省',
                '河北省',
                '河南省',
                '重庆市',
                '天津市',
                '湖北省',
                '甘肃省',
                '上海市',
                '云南省',
                '贵州省',
                '内蒙古自治区',
                '山西省',
                '海南省',
                '浙江省',
                '宁夏回族自治区',
                '青海省',
                '广东省',
                '新疆维吾尔自治区',
                '湖南省',
                '西藏自治区',
                '香港特别行政区',
                '澳门特别行政区',
                '台湾省'
            ],
            navForm: {
                //储存搜索框的检索条件信息
                startTime: '',
                endTime: '',
                reportStartTime: '',
                reportEndTime: '',
                closeStartTime: '',
                closeEndTime: '',
                emergenceStartTime: '',
                emergenceEndTime: '',
                caseType: [],
                value: '',
                nameOfInsured: '',
                lossAdjusterCompany: '',
                accidentProvince: '',
                caseStatus: [],
                meituanCaseInfoList: [],
                firstReviewPass: '',
                startFirstAmount: '0',
                endFirstAmount: '2000'
            },
            tagCount: {},
            caseClosedStatus: '',
            stepsActive: 0,
            checkedRowDetail: {}, //点击行的详情
            riskOptions: [], //我的规则 风险点组合名称
            customFilterOptionList: [], //自定义全局筛选  风险筛选项
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            totalPage: 0, //总页数tableData:[],
            tableData: [],
            props: { multiple: true },
            tmpOptions: [],
            parentLevelOption: [],
            childLevelOption: [],
            levelOptionObj: {},
            parentLevel: '',
            childLevel: '',
            conclusionParent: [],
            conclusionTags: [],
            tmpDatas: [],
            peviewPassOptions: [
                { label: '全部', value: null },
                { label: '是', value: '是' },
                { label: '否', value: '否' }
            ]
        }
    },
    methods: {
        numberChange(val, maxNum) {
            //转换数字类型
            this.navForm.startFirstAmount = Number(val)
            //重新渲染
            this.$nextTick(() => {
                //比较输入的值和最大值，返回小的
                let num = Math.min(Number(val), maxNum)
                //输入负值的情况下， = 0（可根据实际需求更该）
                if (num < 0) {
                    this.navForm.startFirstAmount = 0
                } else {
                    //反之
                    this.navForm.startFirstAmount = num
                }
            })
        },
        numberChange1(val, maxNum) {
            //转换数字类型
            this.navForm.endFirstAmount = Number(val)
            //重新渲染
            this.$nextTick(() => {
                //比较输入的值和最大值，返回小的
                let num = Math.min(Number(val), maxNum)
                //输入负值的情况下， = 0（可根据实际需求更该）
                if (num < 0) {
                    this.navForm.endFirstAmount = 0
                } else {
                    //反之
                    this.navForm.endFirstAmount = num
                }
            })
        },
        getselKnowledgeList() {
            let nodesObj = this.$refs['cascader'].getCheckedNodes()
            if (nodesObj.length > 0) {
                this.conclusionTags = []
                this.conclusionParent = []
                nodesObj.forEach((item) => {
                    if (item.parent) {
                        this.conclusionParent.push({ value: item.parent.value, name: item.parent.label })
                        this.conclusionTags.push({ value: item.data.value, label: item.data.label, parentValue: item.parent.value })
                    }
                })
            } else {
                this.conclusionTags = []
            }
        },
        conclusionClose(closeItem) {
            var tmpDatas1 = JSON.parse(JSON.stringify(this.tmpDatas))
            let tmpIndex = this.conclusionTags.findIndex((item) => {
                return item.value == closeItem.value
            })
            this.conclusionTags.splice(tmpIndex, 1)
            let tmpParentIndex = tmpDatas1.findIndex((item) => {
                return item[0] == closeItem.parentValue && item[1] == closeItem.value
            })
            tmpDatas1.splice(tmpParentIndex, 1)
            this.tmpDatas = tmpDatas1
        },
        unique(Arr) {
            var hash = {}
            Arr = Arr.reduce(function (arr, current) {
                hash[current.value] ? '' : (hash[current.value] = true && arr.push(current))
                return arr
            }, [])
            return Arr
        },
        // 获取验证依据
        getRiskCharacteristics() {
            this.instance
                .get('/meituanCaseTag/riskCharacteristics', {
                    params: {}
                })
                .then((res) => {
                    let data = res.data
                    if (data.code == 1) {
                        this.parentLevelOption = []
                        this.childLevelOption = []
                        this.levelOptionObj = {}
                        let tmpData = []
                        data.data.length > 0 &&
                            data.data.forEach((item, index) => {
                                item.label = item.key
                                item.value = item.key + index
                                if (item.val.length > 0) {
                                    item.children = item.val
                                    item.val.forEach((items, index) => {
                                        items.value = items['key'] + index
                                        items.label = items.key
                                    })
                                }
                                this.levelOptionObj[item['key']] = item['val']
                            })

                        this.tmpOptions = data.data
                    }
                })
        },
        //点击自留验证或者或者模型验证
        hanleVerify(value, title, row) {
            this.verifyRadio = row.oneselfVerify
            this.dialogVisibleVerify = true
            this.verifyTitle = title
            this.verifyValue = value
            this.checkedRow = row
            this.conclusionTags = []
            this.tmpDatas = []

            if (row.firstVerdictType) {
                // 回显验证结论
                let tmpOptionArr = this.levelOptionObj[row.firstVerdictType]
                row.firstVerdictList.forEach((item) => {
                    tmpOptionArr.forEach((items) => {
                        if (items.key == item) {
                            this.conclusionTags.push(items)
                            // 回显验证依据
                            this.tmpOptions.forEach((res) => {
                                if (res.label == row.firstVerdictType) {
                                    this.tmpDatas.push([res.value, items.value])
                                }
                            })
                        }
                    })
                })
            } else if (row.verdictType) {
                // 回显验证结论
                let tmpOptionArr = this.levelOptionObj[row.verdictType]
                row.verdictList.forEach((item) => {
                    tmpOptionArr.forEach((items) => {
                        if (items.key == item) {
                            this.conclusionTags.push(items)
                            // 回显验证依据
                            this.tmpOptions.forEach((res) => {
                                if (res.label == row.verdictType) {
                                    this.tmpDatas.push([res.value, items.value])
                                }
                            })
                        }
                    })
                })
            }
        },
        //更新案件信息
        updateCaseTag() {
            if (this.verifyValue == 1) {
                this.cancelCaseDialog()
            } else {
                let json = JSON.parse(JSON.stringify(this.checkedRow))
                let tmpTag = []
                let submitFlag = false
                this.conclusionTags.length > 0 &&
                    this.conclusionTags.forEach((item) => {
                        tmpTag.push(item.label)
                    })
                if (this.verifyValue == 1) {
                    //自留验证
                    json.modelVerify = ''
                    json.oneselfVerify = this.verifyRadio
                    json.firstVerdictList = tmpTag
                    json.firstVerdictType = this.conclusionParent.length > 0 && this.unique(this.conclusionParent)[0].name
                } else if (this.verifyValue == 2) {
                    //模型验证
                    json.oneselfVerify = ''
                    json.modelVerify = this.verifyRadio
                    json.verdictList = tmpTag
                    json.verdictType = this.conclusionParent.length > 0 && this.unique(this.conclusionParent)[0].name
                }

                if (this.tmpDatas.length == 0 && this.verifyValue == 2 && this.verifyRadio == '红灯案件') {
                    this.$message.error('请选择验证依据。')
                } else {
                    submitFlag = true
                }

                submitFlag &&
                    this.instance.post('/meituanCaseTag/updateCaseTag', json).then((res) => {
                        if (res.data.code == 1) {
                            this.getTbodyData()
                            this.cancelCaseDialog()
                            this.$message.success('恭喜你，保存成功！')
                        }
                    })
            }
        },
        cancelCaseDialog() {
            this.conclusionTags = []
            this.verifyRadio = ''
            this.tmpDatas = []
            this.dialogVisibleVerify = false
        },
        //结案状态change事件
        caseClosedStatusChange(status) {
            let baseStatusOptions = JSON.parse(JSON.stringify(this.baseStatusOptions))
            if (status == '') {
                this.statusOptions = baseStatusOptions
            } else {
                let arr = []
                baseStatusOptions.forEach((val, key) => {
                    if (status == val.type) {
                        arr.push(val)
                    }
                })
                this.statusOptions = arr
            }
        },
        //获取案件状态
        getCaseStatus() {
            this.restaurants = []
            this.instance
                .get('/case/getCaseStatus', {
                    params: {}
                })
                .then((res) => {
                    if (res.data.code == 1) {
                        this.statusOptions = res.data.data
                        this.baseStatusOptions = res.data.data
                    }
                })
        },
        sortChange(column) {
            // column.ascending==上升  column.descending==下降  column.prop
            this.navForm.sortField = column.prop
            this.navForm.sortOrder = column.order == 'ascending' ? 'asc' : 'desc'
            this.getTbodyData()
        },
        //点击未关注按钮，关注案件
        handleAttention(row, risk) {
            var hintText = '此操作表示已关注该风险，将修改案件信息, 是否继续?'
            if (risk.riskType == 2) {
                hintText = '此操作表示已核实该提示内容符合实情, 无风险，是否继续?'
            }
            this.$confirm(hintText, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    var json = {
                        id: risk.id,
                        status: risk.riskType == 1 ? 1 : 2,
                        stage: risk.stage,
                        meituanCaseNo: this.checkedRowDetail.meituanCaseNo
                    }
                    this.instance.post('/case/operationMark', json).then((res) => {
                        if (res.data.code == 1) {
                            risk.status = risk.riskType == 1 ? 1 : 2
                            risk.status == 2 && this.checkedRowDetail.numberOfRiskPoints--
                            this.$message({
                                type: 'success',
                                message: res.data.message
                            })
                        }
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    })
                })
        },
        //删除文件
        handleRemove(file, fileList) {
            this.caseExcelData = []
        },
        //获取已定义筛选规则
        getCommonFilterOptionList() {
            this.instance.post('/case/getCommonFilterOptionList', {}).then((res) => {
                if (res.data.code == 1) {
                    this.riskOptions = res.data.data
                }
            })
        },
        //点击查看详情
        lookDetails(row) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.instance.post('/case/getCaseDetail', { caseNo: row.caseNo }).then((res) => {
                loading.close()
                if (res.data.code == 1) {
                    this.dialogVisible = true
                    this.stepsActive = 0
                    this.checkedRowDetail = res.data.data
                    res.data.data.stageOfCaseVoList.forEach((val, key) => {
                        if (val.arrivalOrNot) {
                            this.stepsActive = key
                        }
                    })
                }
            })
        },
        //文件上传成功时的钩子
        handleAvatarSuccess(res, file) {
            this.fullscreenLoading.close()
            this.fileName = file.name
            this.uploadFile = res.data
            if (res.code == 1) {
                this.$message.success(res.message)
                this.caseExcelData = res.data
            } else {
                this.$message.error(res.message)
            }
        },
        //上传文件之前的钩子
        beforeAvatarUpload(file) {
            if (file.size / 1024 > 5000) {
                this.$message.error('抱歉，上传文件不得大于5M！')
                return false
            }
            this.unloaderHeader.Authorization = 'Bearer ' + getToken()
            this.fullscreenLoading = this.$loading({
                lock: true,
                text: '上传中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
        },
        //切换table选中事件
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        //点击查询
        searchTable() {
            this.currentPage = 1
            this.getTbodyData()
            // if (this.caseExcelData.length > 0 || this.navForm.caseNo || (this.navForm.startTime && this.navForm.endTime) || (this.navForm.reportStartTime && this.navForm.reportEndTime)) {
            //     this.currentPage = 1
            //     this.getTbodyData()
            // } else {
            //     this.$message.error('请上传文件或者选择结案时间、报案时间之后再查询！')
            // }
        },
        //搜索标签数量
        searchTagCount(json) {
            this.instance.post('/meituanCaseTag/searchTagCount', json).then((res) => {
                if (res.data.code == 1) {
                    this.tagCount = res.data.data || {}
                }
            })
        },
        //获取tbody里的数据
        getTbodyData() {
            this.loading = true
            this.tableData = [] //  清空table的值
            var json = JSON.parse(JSON.stringify(this.navForm))
            json.meituanCaseInfoList = this.caseExcelData
            //储存当前的检索条件，下载用
            this.nowFilter = JSON.parse(JSON.stringify(json))
            json.pageNum = this.currentPage
            json.pageSize = this.pageSize
            this.searchTagCount(json)
            this.instance.post('/meituanCaseTag/searchPage', json).then((res) => {
                this.loading = false
                if (res.data.code == 1) {
                    this.tableData = res.data.data.list
                    this.totalPage = parseInt(res.data.data.total)
                } else {
                    this.totalPage = 0
                }
            })
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val
            this.getTbodyData()
        },
        //分页：当前页改变之后的函数
        handleCurrentChange(val) {
            this.currentPage = Number(val)
            this.getTbodyData()
        },
        //下载工单
        handleDownload() {
            var json = JSON.parse(JSON.stringify(this.nowFilter))
            this.fullscreenLoading = this.$loading({
                lock: true,
                text: '下载中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.instance({
                method: 'post',
                // url: '/case/exportCaseExcel',
                url: '/meituanCaseTag/downloadCaseTag',
                data: json,
                responseType: 'blob',
                headers: {
                    // 'Content-Type':"application/x-www-form-urlencoded",
                }
            })
                .then((res) => {
                    // this.loading=false;
                    this.fullscreenLoading.close()
                    if (res.data.size < 200) {
                        this.$message.error('单次下载案件不能超过10000条,请分段下载!')
                        return false
                    }
                    let url = window.URL.createObjectURL(new Blob([res.data]))
                    let link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url
                    link.setAttribute('download', '案件下载清单.csv') //指定下载后的文件名，防跳转
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    mounted() {
        //
        this.getCaseStatus()
        // this.getCommonFilterOptionList();
        this.baseURL = this.$store.getters.baseURL
        const date = new Date()
        let startDate = new Date(date.getTime() - 3600 * 1000 * 24 * 7)
        this.navForm.reportStartTime = this.dateFormatting(startDate, 'yyyy-MM-dd')
        this.navForm.reportEndTime = this.dateFormatting(date, 'yyyy-MM-dd')
        // this.navForm.startTime = '2023-01-01'
        // this.navForm.endTime = '2023-04-17'
        this.getTbodyData()
        this.getRiskCharacteristics()
    }
}
</script>
<style scoped>
@import '../../../assets/css/table_public.css';
.risk_hint {
    padding: 0 8px;
    border-radius: 3px;
    background-color: #fd8042;
    color: #fff;
    display: inline-block;
    font-size: 12px;
}
.bg_red /deep/.el-radio-button__inner {
    background-color: #f56c6c;
    color: #fff;
}
.bg_red /deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner,
.bg_green /deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background-color: #409eff;
    border-color: #409eff;
    box-shadow: -1px 0 0 0 #409eff;
}
.bg_green /deep/.el-radio-button__inner {
    background-color: #67c23a;
    color: #fff;
}
.risk_hint.red {
    background-color: red;
}
.risk_hint.purple {
    background-color: #c7317b;
}
.relevancy_case {
    font-size: 0.14rem;
}
.relevancy_case p {
    margin-top: 0.5rem;
    font-size: 0.22rem;
    color: #409eff;
}
.relevancy_case div {
    padding: 0.05rem 0;
}
.small_title {
    margin-top: 0.15rem;
    font-size: 0.18rem;
}
.risk_point {
    text-align: left;
}
.risk_point li {
    line-height: 1.4;
    margin-top: 0.15rem;
}
.status_box {
    text-align: right;
}
.status_box .a_color {
    text-decoration: underline;
}
.status_icon {
    width: 26px;
    margin: 0 auto;
}
/deep/.color_a {
    color: #e4393c;
}
/deep/.el-step.is-center .el-step__description {
    padding: 0 10%;
}
.dialog_main {
    padding-top: 0.4rem;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}
::v-deep input[type='‘number’'] {
    -moz-appearance: textfield !important;
}
.condition_form /deep/.el-input__inner,
.condition_form /deep/.el-select-dropdown__item {
    font-size: 0.14rem;
}
.condition_form {
    margin-top: 0.15rem;
}
.condition_form .el-form-item--mini.el-form-item,
.condition_form .el-form-item--small.el-form-item {
    margin-bottom: 0;
}
.condition_form .el-select,
.condition_form .el-input {
    margin-right: 0.2rem;
}
.condition_form /deep/.el-form-item__label {
    padding: 0;
}
.condition_lebal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.18rem;
}
.condition_lebal b {
    width: 0.2rem;
    height: 0.2rem;
    background: url(../../../../public/images/close_icon.png) no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
}
.condition_item {
    width: 17.6rem;
    min-height: 1.2rem;
    background: #f2f8fc;
    border-radius: 0.04rem;
    padding: 0.2rem 0.3rem;
}
.condition_box li {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    min-height: 1.2rem;
    margin-bottom: 0.3rem;
}
.condition_box li:nth-child(2) .condition_item {
    width: 17.3rem;
}
.condition_box li:nth-child(3) .and_or {
    left: 0.3rem;
}
.condition_box li:nth-child(3) .condition_item {
    width: 17rem;
}
.condition_box li:nth-child(4) .and_or {
    left: 0.6rem;
}
.condition_box li:nth-child(4) .condition_item {
    width: 16.7rem;
}
.condition_box li:nth-child(5) .and_or {
    left: 0.9rem;
}
.condition_box li:nth-child(5) .condition_item {
    width: 16.4rem;
}
.condition_box li:nth-child(6) .and_or {
    left: 1.2rem;
}
.condition_box li:nth-child(6) .condition_item {
    width: 16.1rem;
}
.condition_box li:nth-child(7) .and_or {
    left: 1.5rem;
}
.condition_box li:nth-child(7) .condition_item {
    width: 15.8rem;
}
.condition_box li:nth-child(8) .and_or {
    left: 1.8rem;
}
.condition_box li:nth-child(8) .condition_item {
    width: 15.5rem;
}
.condition_box li:nth-child(9) .and_or {
    left: 2.1rem;
}
.condition_box li:nth-child(9) .condition_item {
    width: 15.2rem;
}
.condition_box li:nth-child(10) .and_or {
    left: 2.4rem;
}
.condition_box li:nth-child(10) .condition_item {
    width: 14.9rem;
}
.condition_box li:nth-child(11) .and_or {
    left: 2.7rem;
}
.condition_box li:nth-child(11) .condition_item {
    width: 14.6rem;
}
.condition_box li:nth-child(12) .and_or {
    left: 3rem;
}
.condition_box li:nth-child(12) .condition_item {
    width: 14.3rem;
}
.condition_box li:nth-child(13) .and_or {
    left: 3.3rem;
}
.condition_box li:nth-child(13) .condition_item {
    width: 14rem;
}
.condition_box li:nth-child(14) .and_or {
    left: 3.6rem;
}
.condition_box li:nth-child(14) .condition_item {
    width: 13.7rem;
}
.condition_box li:nth-child(15) .and_or {
    left: 3.9rem;
}
.condition_box li:nth-child(15) .condition_item {
    width: 13.4rem;
}
.condition_box li:nth-child(16) .and_or {
    left: 4.2rem;
}
.condition_box li:nth-child(16) .condition_item {
    width: 13.1rem;
}
.condition_box li:nth-child(17) .and_or {
    left: 4.5rem;
}
.condition_box li:nth-child(17) .condition_item {
    width: 12.8rem;
}
.condition_box li:nth-child(18) .and_or {
    left: 4.8rem;
}
.condition_box li:nth-child(18) .condition_item {
    width: 12.5rem;
}
.and_or {
    position: absolute;
    bottom: 0.4rem;
    left: 0;
    z-index: 20;
    width: 0.26rem;
    height: 1.7rem;
    padding-top: 0.5rem;
}
.and_or span {
    width: 1px;
    height: 1.7rem;
    background-color: #c1e0ff;
    position: absolute;
    top: 0;
    left: 0.12rem;
    z-index: -1;
}
.and_or b {
    display: inline-block;
    background: #ffffff;
    border: 1px solid #1989fa;
    font-size: 0.15rem;
    width: 0.26rem;
    height: 0.26rem;
    line-height: 0.24rem;
    text-align: center;
    color: #1989fa;
    margin-bottom: 0.1rem;
    cursor: pointer;
}
.and_or .active {
    color: #fff;
    background: #1989fa;
}
.custom_filtering {
    padding: 0 0 0.3rem;
    border-bottom: 1px solid #ebeef5;
}
.add_condition {
    display: flex;
    align-items: center;
    font-size: 0.18rem;
}
.add_condition div {
    width: 0.24rem;
    margin-right: 0.1rem;
    cursor: pointer;
}
.add_condition span {
    cursor: pointer;
}
.adjustment_detail {
    width: 100%;
    display: flex;
}
.dialog_col li,
.adjustment_detail > div {
    font-size: 0.18rem;
    color: #606266;
    margin-top: 0.2rem;
}
.adjustment_detail li span:first-child {
    display: inline-block;
    min-width: 3rem;
}
.dialog_row {
    margin-top: 0.4rem;
    display: flex;
}
.dialog_row > div {
    width: 50%;
}
.dialog_header {
    display: flex;
    height: 0.68rem;
    border-bottom: 1px solid #ebeef5;
    font-size: 0.22rem;
    font-weight: 600;
}
.dialog_header div:first-child {
    margin-right: 1.2rem;
}
.case_detail /deep/.el-dialog__header {
    padding: 0;
}
/deep/.el-dialog__body {
    padding: 0.5rem;
    color: #333;
}
.table_title {
    display: flex;
    margin-bottom: 0.3rem;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.table_title b {
    font-size: 0.26rem;
    font-weight: 600;
}
.table_title b .table_btn {
    margin-left: 20px;
}
.case_statistics {
    color: #666;
    font-size: 12px;
}
.hint_text {
    color: #909399;
    font-size: 0.16rem;
}
/deep/.el-tabs__item {
    height: 0.4rem;
    font-size: 0.22rem;
    line-height: 1;
}
/deep/.el-tabs__header {
    margin-bottom: 0.38rem;
}
.warning_icon {
    width: 0.14rem;
    margin-right: 0.18rem;
}
.download {
    margin-left: 0.5rem;
    font-size: 0.18rem;
}
.demo-form-inline .form-items .el-tag {
    margin-left: 10px;
    margin-bottom: 10px;
}
.demo-form-inline .form-items .el-tag:nth-child(1) {
    margin-left: 0px !important;
}
.demo-form-inline .form-items .form-select-child {
    margin-left: 10px;
}
</style>
