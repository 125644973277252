<template>
    <div class="wrap">
        <div class="task_page" v-show="!caseInfo">
            <div id="pageTitle" class="page_title"><b>质检工作管理</b></div>
            <!--检索部分  -->
            <div class="search_box public_box">
                <el-form :inline="true" :model="navForm" class="demo-form-inline">
                    <el-form-item label="开始时间：" class="dateBox">
                        <el-date-picker v-model="navForm.startTime" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
                    </el-form-item>
                    <el-form-item label="结束时间：" class="dateBox">
                        <el-date-picker v-model="navForm.endTime" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
                    </el-form-item>
                    <el-form-item label="质检员：">
                        <el-select v-model="navForm.username" placeholder="请选择" @change="selectChange">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="getWorkList(true)" style="margin-left: 0.2rem">查询</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button icon="el-icon-s-tools" circle @click="dialogVisible = true"></el-button>
                    </el-form-item>
                </el-form>
            </div>
            <!-- 统计 -->
            <div class="public_box">
                <div v-show="navForm.username == 'all' && statisticsFlag && tableData.length > 0">
                    <el-row>
                        <el-col :span="6"><div id="chartDom1"></div></el-col>
                        <el-col :span="5"><div id="chartDom2"></div></el-col>
                        <el-col :span="5" :offset="3" class="show_txt">
                            <p>减损总金额：{{ caseJobAggInfoVo && caseJobAggInfoVo.amount ? caseJobAggInfoVo.amount : '--' }} 元</p>
                            <p>案均减损：{{ caseJobAggInfoVo && caseJobAggInfoVo.avgAmount ? caseJobAggInfoVo.avgAmount : '--' }} 元</p>
                            <p>案件总时长：{{ caseJobAggInfoVo && caseJobAggInfoVo.sumTimeStr ? caseJobAggInfoVo.sumTimeStr : '--' }}</p>
                            <p>案均时长：{{ caseJobAggInfoVo && caseJobAggInfoVo.avgTimeStr ? caseJobAggInfoVo.avgTimeStr : '--' }}</p>
                        </el-col>
                        <el-col :span="7" :offset="5"><div id="chartDom3"></div></el-col>
                    </el-row>
                </div>
                <div class="task_cards" v-show="navForm.username != 'all' && statisticsFlag">
                    <div class="task_card card1">
                        <p>
                            任务数量 <span class="card_hot">{{ aggUserCaseJobVosOutside[navForm.username] && aggUserCaseJobVosOutside[navForm.username].allCount ? aggUserCaseJobVosOutside[navForm.username].allCount : '--' }}</span> 个
                        </p>
                    </div>
                    <div class="task_card card2">
                        <p class="card_txt card_font">
                            已完成 <span class="card_hot">{{ aggUserCaseJobVosOutside[navForm.username] && aggUserCaseJobVosOutside[navForm.username].submitCount ? aggUserCaseJobVosOutside[navForm.username].submitCount : '--' }}</span> 个
                        </p>
                        <p class="card_txt">减损总金额：{{ caseJobAggInfoVo && caseJobAggInfoVo.amount ? caseJobAggInfoVo.amount : '--' }} 元 案均减损：{{ caseJobAggInfoVo && caseJobAggInfoVo.avgAmount ? caseJobAggInfoVo.avgAmount : '--' }} 元</p>
                        <p class="card_txt">案件总时长：{{ caseJobAggInfoVo && caseJobAggInfoVo.avgTimeStr ? caseJobAggInfoVo.avgTimeStr : '--' }} 案均时长：{{ caseJobAggInfoVo && caseJobAggInfoVo.sumTimeStr ? caseJobAggInfoVo.sumTimeStr : '--' }}</p>
                    </div>
                    <div class="task_card card3">
                        <p>
                            未完成 <span class="card_hot">{{ aggUserCaseJobVosOutside[navForm.username] && aggUserCaseJobVosOutside[navForm.username].incompleteCount ? aggUserCaseJobVosOutside[navForm.username].incompleteCount : '--' }}</span> 个
                        </p>
                    </div>
                </div>
            </div>
            <!-- 表格 -->
            <div class="main">
                <div class="table_box public_box">
                    <el-table ref="multipleTable" :data="tableData" v-loading="loading" style="width: 100%" height="640">
                        <el-table-column prop="caseNo" label="案件号"></el-table-column>
                        <el-table-column prop="policyNo" label="保单号"></el-table-column>
                        <el-table-column prop="insureCompany" label="保险公司"></el-table-column>
                        <el-table-column prop="accidentAddress" label="事故地点"></el-table-column>
                        <el-table-column prop="insureType" label="险种"></el-table-column>
                        <el-table-column prop="reportTime" label="报案时间"></el-table-column>
                        <el-table-column prop="closeTime" label="结案时间"></el-table-column>
                        <el-table-column prop="caseStatus" label="案件状态"></el-table-column>
                        <el-table-column prop="caseTimeStr" label="案件总用时"></el-table-column>
                        <el-table-column prop="payAmount" label="实际赔付金额"></el-table-column>
                        <el-table-column prop="adjustAmount" label="人工理算金额"></el-table-column>
                        <el-table-column prop="diffAmount" label="差价"></el-table-column>
                        <el-table-column prop="username" label="质检员"></el-table-column>
                        <el-table-column prop="consumeTimeStr" label="质检时长"></el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <span class="a_color" @click="lookDetails(scope.row, true, 'show')">查看</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页部分-->
                    <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="navForm.page" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="navForm.size" :total="navForm.total"> </el-pagination>
                </div>
            </div>
        </div>
        <!-- 设置分配数量弹窗 -->
        <el-dialog title="分配任务数量" :visible.sync="dialogVisible" :modal-append-to-body="false" width="30%">
            <el-input v-model="allocationCount" placeholder="请输入内容"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitCount">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 案件详情 -->
        <div class="case_page" v-if="caseInfo">
            <div class="page_title cursor" @click="lookDetails(null, false)"><b>< 返回</b></div>
            <case-info :case-data="caseRowData"></case-info>
        </div>
    </div>
</template>
<script>
import CaseInfo from '@/components/staging/index'
import * as echarts from 'echarts'
export default {
    components: {
        CaseInfo
    },
    data() {
        return {
            navForm: {
                startTime: '',
                endTime: '',
                username: 'all',
                size: 10,
                page: 1,
                total: 0
            },
            totalPage: 0,
            currentPage: 10,
            dialogVisible: false,
            loading: false,
            caseInfo: false,
            allocationCount: 1,
            tableData: [],
            options: [],
            caseRowData: {},
            caseJobAggInfoVo: {},
            aggUserCaseJobVos: {},
            aggUserCaseJobVosOutside: {},
            myChart1: null,
            myChart2: null,
            myChart3: null,
            statisticsFlag: false
        }
    },
    mounted() {
        // 统计饼图
        this.setChartDom()
        // 获取质检人列表
        this.getResponsibleExaminer()
        // 获取案件分配数量
        this.getCount()
        // 获取案件列表
        this.getWorkList()
        // 获取统计数据
        this.getStatisticsData()
    },
    methods: {
        selectChange(val) {
            this.getStatisticsData(val)
            this.getWorkList(true)
        },
        getStatisticsData() {
            let queryForm = JSON.parse(JSON.stringify(this.navForm))
            queryForm.username = queryForm.username != 'all' ? queryForm.username : ''
            this.instance.post('/caseJobCheck/statisticalCaseJob', queryForm).then((res) => {
                let data = res.data
                if (data.code == 1) {
                    this.caseJobAggInfoVo = data.data.caseJobAggInfoVo
                    this.aggUserCaseJobVos = data.data.aggUserCaseJobVos

                    let tmpAllCount = []
                    let tmpSubmitCount = []
                    let tmpIncompleteCount = []
                    let tmpAllCountNum = 0
                    let tmpSubmitCountNum = 0
                    let tmpIncompleteCountNum = 0
                    data.data.aggUserCaseJobVos.forEach((item) => {
                        // object 查询表
                        this.aggUserCaseJobVosOutside[item.username] = item

                        if (this.navForm.username == 'all') {
                            // 统计总数
                            tmpAllCountNum += item.allCount
                            tmpSubmitCountNum += item.submitCount
                            tmpIncompleteCountNum += item.incompleteCount

                            // 饼图统计数据
                            tmpAllCount.push({ value: item.allCount, name: item.nikename })
                            tmpSubmitCount.push({ value: item.submitCount, name: item.nikename })
                            tmpIncompleteCount.push({ value: item.incompleteCount, name: item.nikename })
                        }
                    })

                    this.statisticsFlag = true

                    if (this.navForm.username == 'all') {
                        // 绘制饼图数据
                        let tmpAllCountObj = {
                            title: {
                                text: `任务数据：${tmpAllCountNum} 个`,
                                top: '80%',
                                left: '35%'
                            },
                            tooltip: {
                                trigger: 'item'
                            },
                            series: [
                                {
                                    center: ['50%', '40%'],
                                    // name: 'Access From',
                                    type: 'pie',
                                    radius: '50%',
                                    data: tmpAllCount
                                }
                            ]
                        }
                        let tmpSubmitCountObj = {
                            title: {
                                text: `已完成数：${tmpSubmitCountNum} 个`,
                                top: '80%',
                                left: '35%'
                            },
                            tooltip: {
                                trigger: 'item'
                            },
                            series: [
                                {
                                    center: ['50%', '40%'],
                                    // name: 'Access From',
                                    type: 'pie',
                                    radius: '50%',
                                    data: tmpSubmitCount
                                }
                            ]
                        }
                        let tmpIncompleteCountObj = {
                            title: {
                                text: `未完成数：${tmpIncompleteCountNum} 个`,
                                top: '80%',
                                left: '35%'
                            },
                            tooltip: {
                                trigger: 'item'
                            },
                            series: [
                                {
                                    center: ['50%', '40%'],
                                    // name: 'Access From',
                                    type: 'pie',
                                    radius: '50%',
                                    data: tmpIncompleteCount
                                }
                            ]
                        }

                        // 渲染饼图
                        tmpAllCount.length > 0 && this.myChart1.setOption(tmpAllCountObj)
                        tmpSubmitCount.length > 0 && this.myChart2.setOption(tmpSubmitCountObj)
                        tmpIncompleteCount.length > 0 && this.myChart3.setOption(tmpIncompleteCountObj)
                    }
                }
            })
        },
        getWorkList(btnFlag) {
            let queryForm = JSON.parse(JSON.stringify(this.navForm))
            queryForm.username = queryForm.username != 'all' ? queryForm.username : ''
            queryForm.page = btnFlag ? 1 : queryForm.page
            queryForm.size = btnFlag ? 10 : queryForm.size

            this.instance.post('/caseJobCheck/pageJob', queryForm).then((res) => {
                let data = res.data
                if (data.code == 1) {
                    this.tableData = data.data.list
                    this.navForm.size = data.data.size
                    this.navForm.page = data.data.current
                    this.navForm.total = data.data.total
                }
            })
            btnFlag && this.getStatisticsData()
        },
        getResponsibleExaminer() {
            this.instance.post('/caseJobCheck/searchCommitJobUser', {}).then((res) => {
                let data = res.data
                if (data.code == 1) {
                    data.data.forEach((item) => {
                        item.value = item.username
                        item.label = item.nikeName
                    })
                    this.options = data.data
                    this.options.unshift({ value: 'all', label: '全部' })
                }
            })
        },
        //点击查看详情
        lookDetails(row, showFlag, ajaxFlag) {
            ajaxFlag != 'show' &&
                showFlag &&
                this.instance.post(`/caseJobCheck/handleCaseJob/${row.caseNo}`, {}).then((res) => {
                    let data = res.data
                    if (data.code == 1) {
                        this.caseRowData = data.data
                        this.caseRowData.submit = false
                    }
                })
            this.caseRowData = row
            this.caseInfo = showFlag
        },
        handleSizeChange(val) {
            this.navForm.size = val
            this.getWorkList()
        },
        handleCurrentChange(val) {
            this.navForm.page = val
            this.getWorkList()
        },
        setChartDom1() {
            let chartDom = document.getElementById('chartDom1')
            let myChart = echarts.init(chartDom)
            let option

            option = {
                title: {
                    text: '任务数据：100个',
                    top: '80%',
                    left: '35%'
                },
                tooltip: {
                    trigger: 'item'
                },
                series: [
                    {
                        center: ['50%', '40%'],
                        name: 'Access From',
                        type: 'pie',
                        radius: '50%',
                        data: [
                            { value: 1048, name: 'Search Engine' },
                            { value: 735, name: 'Direct' },
                            { value: 580, name: 'Email' },
                            { value: 484, name: 'Union Ads' },
                            { value: 300, name: 'Video Ads' }
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }

            option && myChart.setOption(option)
        },
        setChartDom2() {
            let chartDom = document.getElementById('chartDom2')
            let myChart = echarts.init(chartDom)
            let option

            option = {
                title: {
                    text: '已完成数：80个',
                    top: '80%',
                    left: '35%'
                },
                tooltip: {
                    trigger: 'item'
                },
                series: [
                    {
                        center: ['50%', '40%'],
                        name: 'Access From',
                        type: 'pie',
                        radius: '50%',
                        data: [
                            { value: 1048, name: 'Search Engine' },
                            { value: 735, name: 'Direct' },
                            { value: 580, name: 'Email' },
                            { value: 484, name: 'Union Ads' },
                            { value: 300, name: 'Video Ads' }
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }

            option && myChart.setOption(option)
        },
        setChartDom3() {
            let chartDom = document.getElementById('chartDom3')
            let myChart = echarts.init(chartDom)
            let option

            option = {
                title: {
                    text: '未完成数：20个',
                    top: '80%',
                    left: '35%'
                },
                tooltip: {
                    trigger: 'item'
                },
                series: [
                    {
                        center: ['50%', '40%'],
                        name: 'Access From',
                        type: 'pie',
                        radius: '50%',
                        data: [
                            { value: 1048, name: 'Search Engine' },
                            { value: 735, name: 'Direct' },
                            { value: 580, name: 'Email' },
                            { value: 484, name: 'Union Ads' },
                            { value: 300, name: 'Video Ads' }
                        ]
                        // emphasis: {
                        //     itemStyle: {
                        //         shadowBlur: 10,
                        //         shadowOffsetX: 0,
                        //         shadowColor: 'rgba(0, 0, 0, 0.5)'
                        //     }
                        // }
                    }
                ]
            }

            option && myChart.setOption(option)
        },
        setChartDom() {
            let chartDom1 = document.getElementById('chartDom1')
            this.myChart1 = echarts.init(chartDom1)
            let chartDom2 = document.getElementById('chartDom2')
            this.myChart2 = echarts.init(chartDom2)
            let chartDom3 = document.getElementById('chartDom3')
            this.myChart3 = echarts.init(chartDom3)
        },
        getCount() {
            this.instance.get('/caseJobCheck/getCaseJobCount', { params: {} }).then((res) => {
                let data = res.data
                if (data.code == 1) {
                    this.allocationCount = data.data
                }
            })
        },
        submitCount() {
            this.instance.post(`/caseJobCheck/updateCaseJobCount/${this.allocationCount}`, {}).then((res) => {
                let data = res.data
                if (data.code == 1) {
                    this.$message.success(res.data.message)
                    this.dialogVisible = false
                } else {
                    this.$message.error(res.data.message)
                }
            })
        }
    }
}
</script>
<style>
@import '../../assets/css/table_public.css';

#chartDom1,
#chartDom2,
#chartDom3 {
    width: 400px;
    height: 200px;
}
.show_txt {
    padding-top: 17px;
    font-size: 14px;
    font-weight: 800;
    line-height: 23px;
    margin-left: 10%;
    position: absolute;
    left: 46%;
}
.task_cards {
    display: flex;
    justify-content: space-around;
}
.task_card {
    width: 340px;
    height: 85px;
    line-height: 85px;
    background: rgb(0, 192, 239);
    border-radius: 5px;
}
.card2 {
    background: rgb(0, 160, 87);
}
.card3 {
    background: rgb(241, 155, 18);
}
.task_card p {
    color: white;
    font-size: 20px;
    text-align: center;
}
.task_card .card_txt {
    line-height: normal;
    font-size: 12px;
    color: black;
}
.task_card .card_font {
    color: white;
    font-size: 20px;
}
.task_card .card_hot {
    color: blue;
    font-weight: 700;
    font-size: 36px;
}
</style>