<template>
    <div class="wrap">
        <div class="page_title">
            <b>风险案件推送</b>
        </div>
        <div class="search_box public_box">
            <el-form :inline="true" :model="searchFrom" class="demo-form-inline">
                <div class="flex">
                    <div>
                        <el-form-item label="案件号/保单号：">
                            <el-input class="width_150" v-model="searchFrom.keyword" placeholder="请输入" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="报案时间：" class="dateBox">
                            <el-row style="width: 350px">
                                <el-col :span="11">
                                    <el-date-picker placeholder="请选择日期" clearable v-model="searchFrom.startReportTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"></el-date-picker>
                                </el-col>
                                <el-col class="line" style="text-align: center" :span="2">至</el-col>
                                <el-col :span="11">
                                    <el-date-picker placeholder="请选择日期" clearable v-model="searchFrom.endReportTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"></el-date-picker>
                                </el-col>
                            </el-row>
                        </el-form-item>

                        <el-form-item label="结案时间：" class="dateBox">
                            <el-row style="width: 350px">
                                <el-col :span="11">
                                    <el-date-picker placeholder="请选择日期" clearable v-model="searchFrom.startCloseTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"></el-date-picker>
                                </el-col>
                                <el-col class="line" style="text-align: center" :span="2">至</el-col>
                                <el-col :span="11">
                                    <el-date-picker placeholder="请选择日期" clearable v-model="searchFrom.endCloseTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"></el-date-picker>
                                </el-col>
                            </el-row>
                        </el-form-item>

                        <el-form-item label="案件金额(元)大于：">
                            <el-input class="width_150" v-model="searchFrom.amountGt" placeholder="请输入" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="案件金额(元)小于：">
                            <el-input class="width_150" v-model="searchFrom.amountLt" placeholder="请输入" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="案件状态：">
                            <el-select v-model="searchFrom.caseStatus" class="width_150" clearable placeholder="请选择">
                                <el-option v-for="(item, index) in statusOptions" :key="index" :label="item.status" :value="item.status"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="结案状态：">
                            <el-select v-model="searchFrom.caseStatusIdx" clearable class="width_150" placeholder="请选择">
                                <el-option label="全部" value="0"></el-option>
                                <el-option label="已决状态" value="1"></el-option>
                                <el-option label="未决状态" value="2"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" @click="searchTable">搜索</el-button>
                        </el-form-item>
                    </div>
                </div>
            </el-form>
        </div>

        <div class="main">
            <div class="table_box public_box">
                <el-table ref="multipleTable" :data="tableData" v-loading="loading" style="width: 100%" @sort-change="sortChange">
                    <el-table-column prop="caseNo" label="案件号" width="170px">
                        <template slot-scope="scope">
                            <span class="a_color" @click="linkHrefClick(scope.row.caseNo)">{{ scope.row.caseNo }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="policyNo" label="保单号"></el-table-column>
                    <el-table-column prop="accidentProvincesAndCities" label="事故地点"></el-table-column>
                    <el-table-column prop="reportTime" label="报案时间" width="140"></el-table-column>
                    <el-table-column prop="currentStatusDesc" label="案件状态"></el-table-column>
                    <el-table-column prop="predictPayAmount" label="理算金额"></el-table-column>
                    <el-table-column prop="currentProcessor" label="当前处理人"></el-table-column>
                    <el-table-column prop="riskNum" label="风险数量"></el-table-column>
                    <el-table-column label="案件风险">
                        <template slot-scope="scope">
                            <span class="a_color" @click="lookDetails(scope.row)">查看</span>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="searchFrom.page" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="10" :total="totalPage"></el-pagination>
            </div>
        </div>
        <!-- 风险弹窗 -->
        <el-dialog class="dialog_info" :visible.sync="dialogVisible" :modal-append-to-body="false" :append-to-body="true" width="70%">
            <div class="content_header">
                <p>
                    案件号：<span class="href_link" @click="linkHrefClick(currentCase.caseNo)">{{ currentCase.caseNo ? currentCase.caseNo : '' }}</span>
                </p>
                <p>
                    保单号：<span>{{ currentCase.policyNo ? currentCase.policyNo : '' }}</span>
                </p>
            </div>
            <div class="dialog_content">
                <el-row>
                    <el-col class="col_left" :span="10">
                        <div class="content_info content_left">
                            <p class="content_title">减损建议</p>
                            <template v-if="adviceData">
                                <el-row>
                                    <el-col class="col_title" :span="7">可减损金额：</el-col>
                                    <el-col :span="13">{{ adviceData.reduceAmount ? adviceData.reduceAmount + ' 元' : '' }}</el-col>
                                </el-row>
                                <el-row>
                                    <el-col class="col_title" :span="7">减损金额：</el-col>
                                    <el-col :span="13">{{ adviceData.estimateAmount ? adviceData.estimateAmount + ' 元' : '' }}</el-col>
                                </el-row>
                                <el-row>
                                    <el-col class="col_title" :span="7">减损建议：</el-col>
                                    <el-col :span="17">
                                        <div class="col_txt">{{ adviceData.remark ? adviceData.remark : '' }}</div>
                                    </el-col>
                                </el-row>
                            </template>
                            <p class="content_no" v-else>暂未查询到建议</p>
                        </div>
                    </el-col>
                    <el-col class="col_right" :span="12" :offset="2">
                        <div class="content_info content_right">
                            <p class="content_title case_risk">风险提示</p>
                            <ul class="risk_ul" v-if="caseRiskList.length > 0">
                                <li v-for="(item, index) in caseRiskList" :key="index">
                                    <div class="li_prompt">
                                        <el-button type="danger" plain size="mini">{{ item.title }}</el-button>
                                    </div>
                                    <template v-if="item.caseNoList">
                                        <p class="prompt_txt">关联案件号：<span></span></p>
                                        <div class="case_content">
                                            <template v-for="(items, indexs) in item.caseNoList">
                                                <p class="prompt_txt prompt_no" :key="indexs" @click="linkHrefClick(items)">
                                                    <span>{{ items.length > 35 ? items.slice(0, 35) + '...' : items }}</span>
                                                </p>
                                            </template>
                                        </div>
                                    </template>
                                    <template v-else-if="item.relatedCaseNos && item.relatedCaseNos.length == 0">
                                        <p class="prompt_txt">{{ item.message }}<span></span></p>
                                    </template>
                                    <template v-else-if="item.relatedCaseNos && item.relatedCaseNos.length > 0">
                                        <p class="prompt_txt" style="margin-bottom: 10px" v-if="item.message">{{ item.message }}：<span></span></p>
                                        <p class="prompt_txt">关联案件号：<span></span></p>
                                        <div class="case_content">
                                            <template v-for="(items, indexs) in item.relatedCaseNos">
                                                <p class="prompt_txt prompt_no" :key="indexs" @click="linkHrefClick(items)">
                                                    <span>{{ items.length > 35 ? items.slice(0, 35) + '...' : items }}</span>
                                                </p>
                                            </template>
                                        </div>
                                    </template>
                                </li>
                            </ul>
                            <p class="content_no" v-else>暂未查询到风险</p>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: 'PushRiskCase',
    components: {},
    data() {
        return {
            loading: false,
            dialogVisible: false,
            searchFrom: {
                keyword: null,
                startReportTime: null,
                endReportTime: null,
                startCloseTime: null,
                endCloseTime: null,
                amountGt: null,
                amountLt: null,
                caseStatusIdx: null,
                page: 1,
                size: 10
            },
            navForm: {},
            totalPage: 0,
            statusOptions: [],
            tableData: [],
            caseExcelData: [],
            caseRiskList: [],
            currentCase: {},
            adviceData: null
        };
    },
    methods: {
        lookDetails(rowItem) {
            this.currentCase = rowItem;
            this.dialogVisible = true;
            this.searchQualiity(rowItem.caseNo);
            this.getCaseRiskList(rowItem.caseNo);
        },
        linkHrefClick(caseNo) {
            this.instance.get('/case/getMeituanCaseNo?caseNo=' + caseNo, { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    window.open('https://www.jchunuo.com/fe/insurance-claim-service-platform/index.html#/detail/caseDetail/' + data.data);
                }
            });
        },
        getCaseRiskList(caseNo) {
            this.caseRiskList = [];
            let params = { caseNo: caseNo };
            this.instance
                .get('/risk/fetchCaseRiskPoints', {
                    params: params
                })
                .then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        data.data.forEach((item) => {
                            if (item.message && item.message.indexOf('案件') != -1 && item.title != '该包商有拒赔案件') {
                                let tmpSplitArr = item.message.split('疑似与');
                                tmpSplitArr = tmpSplitArr[tmpSplitArr.length - 1].split('案件')[0];
                                tmpSplitArr = tmpSplitArr.split(',');
                                item.caseNoList = tmpSplitArr;
                            }
                        });
                        this.caseRiskList = data.data;
                    } else {
                        this.caseRiskList = [];
                    }
                });
        },
        searchQualiity(caseNo) {
            this.adviceData = null;
            this.instance.post('/caseQuality/searchQualityRisk', { caseNo: caseNo }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.adviceData = data.data;
                }
            });
        },
        sortChange() {},
        getCaseStatus() {
            this.restaurants = [];
            this.instance
                .get('/case/getCaseStatus', {
                    params: {}
                })
                .then((res) => {
                    if (res.data.code == 1) {
                        this.statusOptions = res.data.data;
                        this.baseStatusOptions = res.data.data;
                    }
                });
        },
        //获取tbody里的数据
        getTbodyData() {
            this.loading = true;
            this.tableData = []; //  清空table的值
            var json = JSON.parse(JSON.stringify(this.searchFrom));
            this.instance.post('/pushRiskCase/searchCase', json).then((res) => {
                this.loading = false;
                if (res.data.code == 1) {
                    this.tableData = res.data.data.list;
                    this.totalPage = parseInt(res.data.data.total);
                } else {
                    this.totalPage = 0;
                }
            });
        },
        searchTable() {
            this.searchFrom.page = 1;
            this.getTbodyData();
        },
        handleSizeChange(val) {
            this.searchFrom.size = val;
            this.getTbodyData();
        },
        handleCurrentChange(val) {
            this.searchFrom.page = Number(val);
            this.getTbodyData();
        }
    },
    mounted() {
        //
        this.getCaseStatus();
        // this.getCommonFilterOptionList();
        this.baseURL = this.$store.getters.baseURL;
        this.getTbodyData();
    }
};
</script>

<style lang="less">
.dialog_info {
    .el-dialog__body {
        padding-bottom: 15px;
    }
    .content_header {
        margin-bottom: 20px;
        margin-top: -20px;
        p {
            margin-bottom: 10px;
            .href_link {
                color: #409eff;
                display: inline-block;
                border-bottom: 1px solid #409eff;
                cursor: pointer;
            }
        }
    }
    .dialog_content {
        .col_left,
        .col_right {
            // border: 1px solid blue;
        }
        .content_left {
            .el-row {
                margin-bottom: 10px;
                .col_title {
                    text-align: right;
                    padding-right: 10px;
                }
                .col_txt {
                    border: 1px solid #ccc;
                    padding: 5px;
                    border-radius: 5px;
                    line-height: 20px;
                }
            }
        }
        .content_right {
            .risk_ul {
                height: 350px;
                overflow: scroll;
                // border: 1px solid red;
                li {
                    width: 99%;
                    padding: 0.2rem 0.1rem;
                    margin: 0 auto;
                    border-bottom: 1px solid #e3e3e3;
                    .li_prompt {
                        margin-bottom: 0.15rem;
                    }
                }
                li:last-child {
                    margin-bottom: 0;
                }
                .prompt_txt {
                    word-wrap: break-word;
                }
                .case_content {
                    margin-top: -15px;
                    margin-left: 100px;
                }
                .prompt_no {
                    margin-bottom: 8px;
                    span {
                        display: inline-block;
                        color: #0d84ff;
                        border-bottom: 1px solid #0d84ff;
                        cursor: pointer;
                    }
                }
            }
        }
        .content_info {
            .content_title {
                margin-bottom: 20px;
                padding: 10px 0;
                text-align: center;
                box-shadow: 0 2px 12px 0 rgba(58, 142, 230, 0.2);
                border: 1px solid rgba(58, 142, 230, 0.2);
                color: rgba(58, 142, 230, 0.7);
            }
            .case_risk {
                box-shadow: 0 2px 12px 0 rgba(225, 13, 13, 0.12);
                border: 1px solid rgba(225, 13, 13, 0.12);
                color: rgba(225, 13, 13, 0.7);
            }
            .content_no {
                text-align: center;
                margin-top: 50px;
                margin-bottom: 50px;
            }
        }
    }
}
</style>
