<template>
    <div class="wrap">
        <div class="page_title"><b>案件分析</b></div>
        <div class="public_box">
            <div class="charts autoC">
                <div class="card">
                    <p class="chart_title">一、诉讼案件区域及类型</p>
                    <div id="chart1" class="chart_box"></div>
                    <p class="chart_txt">口径：所有类型案件（6月-至今）</p>
                </div>
                <div class="card" v-if="0">
                    <p class="chart_title">二、区域胜诉情况</p>
                    <div id="chart2" class="chart_box"></div>
                    <p class="chart_txt">口径：所有系统结案案件（6月-至今）、胜诉案件：我司受理费/法院受理费 < 50%</p>
                </div>
                <div class="card">
                    <p class="chart_title">二、案件结案情况</p>
                    <!-- show-summary -->
                    <el-table class="chart_table bgc1" :data="closeCaseUserData" :span-method="(e) => objectSpanMethod(e, 'closeCaseUserData')" border style="width: 100%; margin-top: 20px">
                        <el-table-column prop="handler" label="处理方式" width="170"> </el-table-column>
                        <el-table-column prop="name" label="处理人" :resizable="false"> </el-table-column>
                        <el-table-column prop="closeNum" label="结案件数（累计）" :resizable="false"> </el-table-column>
                        <el-table-column prop="closeMonthNum" label="结案件数（月）" :resizable="false"> </el-table-column>
                        <el-table-column prop="closeMonthWeek" label="结案件数（周）" :resizable="false"></el-table-column>
                        <el-table-column prop="lawsuitAmount" label="诉讼金额" :resizable="false"> </el-table-column>
                        <el-table-column prop="compensationAmount" label="结案金额" :resizable="false"> </el-table-column>
                        <el-table-column prop="impairmentAmount" label="减损金额" :resizable="false"> </el-table-column>
                        <!-- <el-table-column prop="winningRate" label="胜诉率"> </el-table-column> -->
                        <el-table-column prop="mediationRate" label="调解率" :resizable="false"> </el-table-column>
                    </el-table>
                    <el-table class="chart_table bgc1 hideTableHeader" :data="closeCaseHe1" v-show="closeCaseUserData.length > 0" border style="width: 100%; margin-top: 20px">
                        <el-table-column prop="handler" label="处理方式" width="170"> </el-table-column>
                        <el-table-column prop="name" label="处理人" :resizable="false"> </el-table-column>
                        <el-table-column prop="closeNum" label="结案件数（累计）" :resizable="false"> </el-table-column>
                        <el-table-column prop="closeMonthNum" label="结案件数（月）" :resizable="false"> </el-table-column>
                        <el-table-column prop="closeMonthWeek" label="结案件数（周）" :resizable="false"></el-table-column>
                        <el-table-column prop="lawsuitAmount" label="诉讼金额" :resizable="false"> </el-table-column>
                        <el-table-column prop="compensationAmount" label="结案金额" :resizable="false"> </el-table-column>
                        <el-table-column prop="impairmentAmount" label="减损金额" :resizable="false"> </el-table-column>
                        <!-- <el-table-column prop="winningRate" label="胜诉率"> </el-table-column> -->
                        <el-table-column prop="mediationRate" label="调解率" :resizable="false"> </el-table-column>
                    </el-table>
                    <el-table class="chart_table bgc2 hideTableHeader" :data="closeCaseLawFirmData" v-show="closeCaseLawFirmData.length > 0" :span-method="(e) => objectSpanMethod(e, 'closeCaseLawFirmData')" border style="width: 100%; margin-top: 20px">
                        <el-table-column prop="handler" label="处理方式" width="170"> </el-table-column>
                        <el-table-column prop="name" label="处理人"> </el-table-column>
                        <el-table-column prop="closeNum" label="结案件数（累计）"> </el-table-column>
                        <el-table-column prop="closeMonthNum" label="结案件数（月）"> </el-table-column>
                        <el-table-column prop="closeMonthWeek" label="结案件数（周）"> </el-table-column>
                        <el-table-column prop="lawsuitAmount" label="诉讼金额"> </el-table-column>
                        <el-table-column prop="compensationAmount" label="结案金额"> </el-table-column>
                        <el-table-column prop="impairmentAmount" label="减损金额"> </el-table-column>
                        <!-- <el-table-column prop="winningRate" label="胜诉率"> </el-table-column> -->
                        <el-table-column prop="mediationRate" label="调解率"> </el-table-column>
                    </el-table>
                    <el-table class="chart_table bgc2 hideTableHeader" :data="closeCaseHe1_1" v-show="closeCaseLawFirmData.length > 0" border style="width: 100%; margin-top: 20px">
                        <el-table-column prop="handler" label="处理方式" width="170"> </el-table-column>
                        <el-table-column prop="name" label="处理人"> </el-table-column>
                        <el-table-column prop="closeNum" label="结案件数（累计）"> </el-table-column>
                        <el-table-column prop="closeMonthNum" label="结案件数（月）"> </el-table-column>
                        <el-table-column prop="closeMonthWeek" label="结案件数（周）"> </el-table-column>
                        <el-table-column prop="lawsuitAmount" label="诉讼金额"> </el-table-column>
                        <el-table-column prop="compensationAmount" label="结案金额"> </el-table-column>
                        <el-table-column prop="impairmentAmount" label="减损金额"> </el-table-column>
                        <!-- <el-table-column prop="winningRate" label="胜诉率"> </el-table-column> -->
                        <el-table-column prop="mediationRate" label="调解率"> </el-table-column>
                    </el-table>

                    <!-- <p class="chart_txt">口径：减损金额=诉讼金额-结案金额；胜诉率=胜诉案件数/判决案件件数；调解率：调解案件数/结案案件件数</p> -->
                    <p class="chart_txt">口径：减损金额=诉讼金额-结案金额；调解率：调解案件数/结案案件件数</p>
                </div>
                <div class="card">
                    <p class="chart_title">三、处理中案件情况（及时更新）</p>
                    <el-table class="chart_table bgc1 bgcHeaderFooter" :data="caseUserData" :span-method="(e) => objectSpanMethod(e, 'caseUserData')" border style="width: 100%; margin-top: 20px">
                        <el-table-column prop="handler" label="委托方式" width="170"> </el-table-column>
                        <el-table-column prop="name" label="处理人" :resizable="false"> </el-table-column>
                        <el-table-column prop="activeNum" label="活跃案件数" :resizable="false"> </el-table-column>
                        <el-table-column prop="hangUpNum" label="挂起案件数" :resizable="false"> </el-table-column>
                        <el-table-column prop="inputNum" label="录入处理中案件数" :resizable="false"> </el-table-column>
                        <el-table-column prop="firstNum" label="一审中案件数" :resizable="false"> </el-table-column>
                        <el-table-column prop="secondNum" label="二审中案件数" :resizable="false"> </el-table-column>
                        <el-table-column prop="reviewNum" label="再审中案件数" :resizable="false"> </el-table-column>
                    </el-table>
                    <el-table class="chart_table bgc1 hideTableHeader bgcHeaderFooter" :data="closeCaseHe2" v-show="caseUserData.length > 0" border style="width: 100%; margin-top: 20px">
                        <el-table-column prop="handler" label="委托方式" width="170"> </el-table-column>
                        <el-table-column prop="name" label="处理人" :resizable="false"> </el-table-column>
                        <el-table-column prop="activeNum" label="活跃案件数" :resizable="false"> </el-table-column>
                        <el-table-column prop="hangUpNum" label="挂起案件数" :resizable="false"> </el-table-column>
                        <el-table-column prop="inputNum" label="录入处理中案件数" :resizable="false"> </el-table-column>
                        <el-table-column prop="firstNum" label="一审中案件数" :resizable="false"> </el-table-column>
                        <el-table-column prop="secondNum" label="二审中案件数" :resizable="false"> </el-table-column>
                        <el-table-column prop="reviewNum" label="再审中案件数" :resizable="false"> </el-table-column>
                    </el-table>

                    <el-table class="chart_table bgc2 hideTableHeader bgcHeaderFooter" :data="caseLawFirmData" v-show="caseLawFirmData.length > 0" :span-method="(e) => objectSpanMethod(e, 'caseLawFirmData')" border style="width: 100%; margin-top: 20px">
                        <el-table-column prop="handler" label="委托方式" width="170"> </el-table-column>
                        <el-table-column prop="name" label="处理人"> </el-table-column>
                        <el-table-column prop="activeNum" label="活跃案件数"> </el-table-column>
                        <el-table-column prop="hangUpNum" label="挂起案件数"> </el-table-column>
                        <el-table-column prop="inputNum" label="录入处理中案件数"> </el-table-column>
                        <el-table-column prop="firstNum" label="一审中案件数"> </el-table-column>
                        <el-table-column prop="secondNum" label="二审中案件数"> </el-table-column>
                        <el-table-column prop="reviewNum" label="再审中案件数"> </el-table-column>
                    </el-table>
                    <el-table class="chart_table bgc2 hideTableHeader bgcHeaderFooter" :data="closeCaseHe2_1" v-show="caseLawFirmData.length > 0" border style="width: 100%; margin-top: 20px">
                        <el-table-column prop="handler" label="委托方式" width="170"> </el-table-column>
                        <el-table-column prop="name" label="处理人"> </el-table-column>
                        <el-table-column prop="activeNum" label="活跃案件数"> </el-table-column>
                        <el-table-column prop="hangUpNum" label="挂起案件数"> </el-table-column>
                        <el-table-column prop="inputNum" label="录入处理中案件数"> </el-table-column>
                        <el-table-column prop="firstNum" label="一审中案件数"> </el-table-column>
                        <el-table-column prop="secondNum" label="二审中案件数"> </el-table-column>
                        <el-table-column prop="reviewNum" label="再审中案件数"> </el-table-column>
                    </el-table>
                    <p class="chart_txt"></p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
    data() {
        return {
            queryForm: {
                monthTime: '',
                weekTime: ''
            },
            closeCaseLawFirmData: [],
            closeCaseUserData: [],
            caseLawFirmData: [],
            caseUserData: [],
            regionType: [],
            closeCaseHe1: [],
            closeCaseHe1_1: [],
            closeCaseHe2: [],
            closeCaseHe2_1: [],
            chart1: null
        };
    },
    mounted() {
        window.addEventListener('resize', function () {
            this.chart1 && this.chart1.resize();
        });

        this.queryRegionType();
        this.queryCloseCaseUser();
        this.queryCloseCaseLawFirm();
        this.queryCaseLawuser();
        this.queryCaseLawFirm();
    },
    methods: {
        // 查询区域类型统计
        queryRegionType() {
            this.instance.post('/lawsuitCase/statistics/regionType', this.queryForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    if (data.data) {
                        this.regionType = data.data;

                        let xAxisData = [];
                        let seriesData = [];
                        let legendArr = ['新商保重复索赔', '索赔超保单项目金额', '保险责任争议', '其他'];
                        legendArr.forEach((res) => {
                            seriesData.push({
                                name: res,
                                type: 'bar',
                                stack: 'bar',
                                // barWidth: 50,
                                data: []
                            });
                        });

                        this.regionType.forEach((item, index) => {
                            xAxisData.push(item.itemName);
                            seriesData.forEach((resItem, resIndex) => {
                                resItem.data.push(item['itemNum' + (resIndex + 1)]);
                            });
                        });

                        this.caseRegionTypeChart(legendArr, xAxisData, seriesData, 'chart1');
                    }
                }
            });
        },
        // 查询案件结案情况（用户）
        queryCloseCaseUser() {
            this.instance.post('/lawsuitCase/statistics/closeCaseUser', this.queryForm).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data.itemList) {
                    this.closeCaseUserData = data.data.itemList;
                    this.closeCaseUserData[0].handler = '员工出庭';
                    if (data.data.total) {
                        this.closeCaseHe1 = [data.data.total];
                        this.closeCaseHe1[0].handler = '合计';
                        this.closeCaseHe1[0].name = '';
                    } else {
                        this.closeCaseHe1 = [];
                    }
                }
            });
        },
        // 查询案件结案情况（律所）
        queryCloseCaseLawFirm() {
            this.instance.post('/lawsuitCase/statistics/closeCaseLawFirm', this.queryForm).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data.itemList) {
                    this.closeCaseLawFirmData = data.data.itemList;
                    this.closeCaseLawFirmData[0].handler = '律师出庭';
                    if (data.data.total) {
                        this.closeCaseHe1_1 = [data.data.total];
                        this.closeCaseHe1_1[0].handler = '合计';
                        this.closeCaseHe1_1[0].name = '';
                    } else {
                        this.closeCaseHe1_1 = [];
                    }
                }
            });
        },
        // 查询处理案件情况（用户）
        queryCaseLawuser() {
            this.instance.post('/lawsuitCase/statistics/handlerCaseUser', this.queryForm).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data.itemList) {
                    this.caseUserData = data.data.itemList || [];
                    this.caseUserData[0].handler = '员工出庭';
                    if (data.data.total) {
                        this.closeCaseHe2 = [data.data.total];
                        this.closeCaseHe2[0].handler = '合计';
                        this.closeCaseHe2[0].name = '';
                    } else {
                        this.closeCaseHe2 = [];
                    }
                }
            });
        },
        // 查询处理案件情况（律所）
        queryCaseLawFirm() {
            this.instance.post('/lawsuitCase/statistics/handlerCaseLawFirm', this.queryForm).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data.itemList) {
                    this.caseLawFirmData = data.data.itemList || [];
                    this.caseLawFirmData[0].handler = '律师出庭';
                    if (data.data.total) {
                        this.closeCaseHe2_1 = [data.data.total];
                        this.closeCaseHe2_1[0].handler = '合计';
                        this.closeCaseHe2_1[0].name = '';
                    } else {
                        this.closeCaseHe2_1 = [];
                    }
                }
            });
        },
        // 案件总量情况 - 柱状图
        caseRegionTypeChart(legendArr, xAxisData, seriesData, elIdStr) {
            const grid = {
                left: 100,
                right: 100,
                top: 50,
                bottom: 80
            };

            var option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    // selectedMode: false
                    data: legendArr
                },
                grid,
                yAxis: {
                    type: 'value'
                },
                xAxis: {
                    type: 'category',
                    axisLabel: {
                        rotate: 45, // 设置X轴标签倾斜45度
                        interval: 0 // 如果需要全部显示标签，则设置interval为0
                    },
                    data: xAxisData
                },
                series: seriesData,
                devicePixelRatio: window.devicePixelRatio // 使用设备的像素比
            };

            let tmpDom = document.getElementById(elIdStr);
            if (tmpDom) {
                this.chart1 = echarts.init(tmpDom);
                this.chart1.setOption(option);
                // setTimeout(() => {
                //     this.chart1.resize();
                // }, 30);
            }
        },
        objectSpanMethod(event, dataStr) {
            if (event.columnIndex === 0) {
                const length = this[dataStr].length;
                if (event.rowIndex < length) {
                    if (event.rowIndex === 0) {
                        return {
                            rowspan: this[dataStr].length,
                            colspan: 1
                        };
                    } else {
                        // 对于其他行，首列不渲染
                        return {
                            rowspan: 0,
                            colspan: 0
                        };
                    }
                }
            }
        }
    }
};
</script>
<style lang="less" scoped>
.public_box {
    margin-top: 20px;
    padding: 20px;
    min-width: 900px;
    .charts {
        width: 900px;
        .card {
            width: 900px;
            margin-bottom: 20px;
            .chart_title {
                font-size: 16px;
                font-weight: bold;
                margin-top: 40px;
            }
            .chart_box {
                width: 100%;
                height: 300px;
                border: 1px solid #a3a1a1;
                margin: 20px 0;
                padding-top: 10px;
            }
            .chart_table {
                margin: 20px 0;
            }
            // el-table__empty-text
            .bgc1,
            .bgc2 {
                border-color: black;
                border-right: 1px solid black;
                color: black;
                /deep/.cell {
                    border-right-color: black;
                    border-bottom-color: black;
                }

                /deep/.el-table__body-wrapper {
                    tr {
                        td,
                        th {
                            border-right: 1px solid black;
                            border-bottom: 1px solid black;
                        }
                        td:last-child,
                        th:last-child {
                            border-right: none !important;
                        }
                    }
                }

                /deep/.el-table__footer-wrapper * {
                    border-color: black;
                    color: black;
                }

                // header/footer 最后列
                /deep/.el-table__header-wrapper,
                /deep/.el-table__footer-wrapper {
                    .has-gutter {
                        tr {
                            th {
                                color: black;
                                border-color: black;
                                border-right: 1px solid black;
                                background: #fff;
                            }
                            .is-leaf:nth-child(9) {
                                border-right: 0;
                            }
                        }
                    }
                }
            }

            /deep/ .el-table--border::after,
            /deep/ .el-table--group::after,
            /deep/ .el-table::before {
                display: none;
            }

            .bgc1 {
                /deep/ .el-table__body-wrapper,
                /deep/ .el-table__footer-wrapper {
                    * {
                        background-color: #ffe699;
                    }
                }
            }
            .bgc2 {
                // border-bottom: 1px solid black;
                /deep/ .el-table__body-wrapper,
                /deep/ .el-table__footer-wrapper {
                    * {
                        background-color: #bdd7ee;
                    }
                }
            }
            .bgcHeaderFooter {
                // header/footer 最后列
                /deep/.el-table__header-wrapper,
                /deep/.el-table__footer-wrapper {
                    .has-gutter {
                        tr {
                            .is-leaf:nth-child(8) {
                                border-right: 0;
                            }
                        }
                    }
                }
            }
            .hideTableHeader {
                margin-top: -21px !important;
                /deep/.el-table__header {
                    display: none;
                }
            }
        }
        .card:last-child {
            margin-bottom: 0;
        }
    }
}
</style>