<template>
    <div class="wrap">
        <div class="page_title"><b>案件详情</b></div>
        <div class="page_content clearfix">
            <div :class="{ cards_content: 1, cards_left: 1, fl: 1, 'cards_left-width': !hideCaseLog }">
                <div class="btn_groups">
                    <el-button type="primary" size="mini" v-if="btnMenuData && btnMenuData[999]" @click="closeCaseDialog">结 案</el-button>
                    <el-button type="primary" size="mini" v-if="btnMenuData && btnMenuData[700]" @click="approvalFlag = true">审 批</el-button>
                    <el-button type="primary" size="mini" v-if="btnMenuData && btnMenuData[200]" @click="allocateFlag = true">分 配</el-button>
                    <el-button type="primary" size="mini" v-if="btnMenuData && btnMenuData[600]" @click="reviewFlag = true">提 审</el-button>
                    <el-button type="primary" size="mini" v-if="btnMenuData && (btnMenuData[302] || btnMenuData[303])" @click="pendingFn(btnMenuData[302])">{{ btnMenuData[302] ? '挂 起' : '取消挂起' }}</el-button>
                    <el-button type="primary" size="mini" v-if="btnMenuData && btnMenuData[304]" @click="transmitFlag = true">转 交</el-button>
                    <el-button type="primary" size="mini" v-if="btnMenuData && btnMenuData[400]" @click="commissionFlag = true">委 托</el-button>
                    <el-button type="primary" size="mini" v-if="btnMenuData && btnMenuData[499]" @click="withdrawFlag = true">撤回委派</el-button>
                    <el-button type="primary" size="mini" v-if="btnMenuData && btnMenuData[800]" @click="getReturnedStep">退 回</el-button>
                </div>
                <!-- 诉讼案件详情 -->
                <case-info :caseDetailData="caseDetailData"></case-info>
                <!-- 保险案件信息 -->
                <insurance-info :caseDetailData="caseDetailData" :btnMenuData="btnMenuData" @getCaseDetail="getCaseDetail" @editInfo="editInfo"></insurance-info>
                <!-- 诉讼案件信息 -->
                <litigation-info v-if="litigationInfoShow" :caseDetailData="caseDetailData" :btnMenuData="btnMenuData" @getCaseDetail="getCaseDetail"></litigation-info>
            </div>
            <!-- 案件日志 -->
            <div :class="{ cards_right: 1, fr: 1, 'cards_right-width': !hideCaseLog }" v-if="!hideCaseLog">
                <case-log ref="caseLogRef" :caseDetailData="caseDetailData" @hiddenCaseLog="hiddenCaseLog"></case-log>
            </div>
        </div>
        <!-- 结案弹窗 -->
        <el-dialog class="process_popup" title="结案信息" width="450px" :visible.sync="closeCaseFlag" center :modal-append-to-body="false" :close-on-click-modal="false">
            <div class="dialog_content">
                <el-form :model="closeCaseForm" :rules="closeCaseRules" ref="closeCaseRef" label-width="160px">
                    <el-form-item label="判决时间：" prop="judgmentTime">
                        <el-date-picker class="form_date" v-model="closeCaseForm.judgmentTime" type="date" value-format="yyyy-MM-dd"> </el-date-picker>
                    </el-form-item>
                    <el-form-item label="诉讼原因：" prop="lawsuitCause">
                        <el-select v-model="closeCaseForm.lawsuitCause" placeholder="请选择">
                            <el-option v-for="item in causeOptions" :key="item.id" :label="item.dictName" :value="item.dictName" @change="selectChange"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="判决/调解：" prop="judgmentMediation">
                        <el-select v-model="closeCaseForm.judgmentMediation" placeholder="请选择">
                            <el-option v-for="item in judgmentMediationOptions" :key="item.id" :label="item.dictName" :value="item.dictName" @change="selectChange"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="我司承担受理费(元)：" prop="bearAcceptanceFee">
                        <el-input
                            v-model="closeCaseForm.bearAcceptanceFee"
                            maxlength="11"
                            oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '').replace(/^0+(\d)/, '$1')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="法院受理费(元)：" prop="courtAcceptanceFee">
                        <el-input
                            v-model="closeCaseForm.courtAcceptanceFee"
                            maxlength="11"
                            oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '').replace(/^0+(\d)/, '$1')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="我司赔付金额(元)：" prop="compensationAmount">
                        <el-input
                            v-model="closeCaseForm.compensationAmount"
                            maxlength="11"
                            oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '').replace(/^0+(\d)/, '$1')"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="判决情况：" prop="compensationAmount">
                        <el-input v-model="closeCaseForm.situation" type="textarea" :rows="4" maxlength="200" show-word-limit></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="closeCaseFn(true)">结 案</el-button>
                <el-button @click="closeCaseFn(false)">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 审批弹窗 -->
        <el-dialog class="process_popup" title="审批" :visible.sync="approvalFlag" center :modal-append-to-body="false" :close-on-click-modal="false" width="30%">
            <div class="dialog_content">
                <el-form :model="approvalForm" :rules="approvalRules" ref="approvalRef" label-width="120px">
                    <el-form-item label="审批：" prop="agree">
                        <el-select v-model="approvalForm.agree" placeholder="请选择">
                            <el-option v-for="item in agreeOptions" :key="item.label" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="审批意见：" prop="situation">
                        <el-input v-model="approvalForm.situation" type="textarea" :rows="4" maxlength="200" show-word-limit></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="approvalFn(true)">提 交</el-button>
                <el-button @click="approvalFn(false)">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 分配弹窗 -->
        <el-dialog class="process_popup" title="分配案件" :visible.sync="allocateFlag" center :modal-append-to-body="false" :close-on-click-modal="false" width="30%">
            <div class="dialog_content">
                <el-form :model="allocateForm" :rules="allocateRules" ref="allocateRef" label-width="120px">
                    <el-form-item label="案件分配至：" prop="distributeHandlerUser">
                        <el-select v-model="allocateForm.distributeHandlerUser" filterable placeholder="请选择">
                            <el-option v-for="item in allocationUserOptions" :key="item.userId" :label="item.nickName" :value="item.userName"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="备注：" prop="situation">
                        <el-input v-model="allocateForm.situation" type="textarea" :rows="4" maxlength="200" show-word-limit></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="allocateFn(true)">提 交</el-button>
                <el-button @click="allocateFn(false)">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 提审弹窗 -->
        <el-dialog title="提交审核" :visible.sync="reviewFlag" center :modal-append-to-body="false" :close-on-click-modal="false" width="30%">
            <div class="dialog_content">
                <el-form :model="reviewForm" :rules="reviewRules" ref="reviewRef" label-width="120px">
                    <el-form-item label="提交领导审批：" prop="distributeHandlerUser">
                        <el-select v-model="reviewForm.distributeHandlerUser" filterable placeholder="请选择">
                            <el-option v-for="item in leaderOptions" :key="item.userId" :label="item.nickName" :value="item.userName"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="备注：" prop="situation">
                        <el-input v-model="reviewForm.situation" type="textarea" :rows="4" maxlength="200" show-word-limit></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="reviewFn(true)">提 交</el-button>
                <el-button @click="reviewFn(false)">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 委托弹窗 -->
        <el-dialog class="process_popup" title="委托律师" :visible.sync="commissionFlag" center :modal-append-to-body="false" :close-on-click-modal="false" width="400px">
            <div class="dialog_content">
                <el-form :model="commissionForm" :rules="commissionRules" ref="commissionRef" label-width="120px">
                    <el-form-item label="律师平台：">
                        <el-select v-model="commissionForm.lawPlatform" clearable filterable placeholder="请选择" @change="(e) => commissionFormChange(e, 'lawPlatform')">
                            <el-option v-for="item in platOptions" :key="item.id" :label="item.firmName" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="律所：" prop="lawFirm">
                        <el-select v-model="commissionForm.lawFirm" clearable filterable placeholder="请选择" @change="(e) => commissionFormChange(e, 'lawFirm')">
                            <el-option v-for="item in firmOptions" :key="item.id" :label="item.firmName" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="律师：" prop="distributeHandler">
                        <el-select v-model="commissionForm.distributeHandlerUser" filterable placeholder="请选择">
                            <el-option v-for="item in lawyerOptions" :key="item.id" :label="item.lawyerName" :value="item.userName"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="备注：" prop="situation">
                        <el-input v-model="commissionForm.situation" type="textarea" :rows="4" maxlength="200" show-word-limit></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="commissionFn(true)">提 交</el-button>
                <el-button @click="commissionFn(false)">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 转交弹窗 -->
        <el-dialog class="process_popup" title="案件转交" :visible.sync="transmitFlag" center :modal-append-to-body="false" :close-on-click-modal="false" width="30%">
            <div class="dialog_content">
                <el-form :model="transmitForm" :rules="transmitRules" ref="transmitRef" label-width="120px">
                    <el-form-item label="案件转交至：" prop="distributeHandlerUser">
                        <el-select v-model="transmitForm.distributeHandlerUser" filterable placeholder="请选择">
                            <el-option v-for="item in transmitUserOptions" :key="item.userId" :label="item.nickName" :value="item.userName"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="备注：" prop="situation">
                        <el-input v-model="transmitForm.situation" type="textarea" :rows="4" maxlength="200" show-word-limit></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="transmitFn(true)">提 交</el-button>
                <el-button @click="transmitFn(false)">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 退回弹窗 -->
        <el-dialog title="案件退回" :visible.sync="returnFlag" width="30%" center :modal-append-to-body="false">
            <p style="margin-bottom: 20px; padding-left: 5px">
                确定要将案件退回给<span style="color: #409eff; margin-left: 10px">{{ returnData && returnData.preHandler ? returnData.preHandler : '' }}（{{ returnData && returnData.nextCaseStep && returnData.nextCaseStep.desc ? returnData.nextCaseStep.desc : '' }}）</span>
            </p>
            <div class="dialog_content">
                <el-form :model="returnForm" ref="returnRef" label-width="50px">
                    <el-form-item label="备注:" prop="situation" style="margin-bottom: 0">
                        <el-input v-model="returnForm.situation" type="textarea" :rows="4" maxlength="200" show-word-limit></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="returnFn(true)">确 认</el-button>
                <el-button @click="returnFn(false)">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 撤回委派弹窗 -->
        <el-dialog title="撤回委派" :visible.sync="withdrawFlag" width="400px" center :modal-append-to-body="false">
            <p style="padding-left: 5px; text-align: center">确定要将案件撤回委派？</p>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="withdrawFn(true)">确 认</el-button>
                <el-button @click="withdrawFn(false)">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import CaseInfo from '@/components/caseDetail/caseInfo.vue';
import InsuranceInfo from '@/components/caseDetail/insuranceInfo.vue';
import LitigationInfo from '@/components/caseDetail/litigationInfo.vue';
import CaseLog from '@/components/caseDetail/caseLog.vue';
export default {
    components: {
        CaseInfo,
        InsuranceInfo,
        LitigationInfo,
        CaseLog
    },
    data() {
        return {
            withdrawFlag: false,
            transmitFlag: false,
            closeCaseFlag: false,
            approvalFlag: false,
            allocateFlag: false,
            reviewFlag: false,
            returnFlag: false,
            commissionFlag: false,
            loading: false,
            hideCaseLog: false,
            hiddenCaseLog: true,
            litigationInfoShow: true,
            navForm: {
                caseNo: '',
                value1: [],
                value2: [],
                value3: '',
                value4: '',
                value5: '',
                page: 1,
                size: 10,
                total: 0
            },
            btnMenuData: null,
            agreeOptions: [
                {
                    value: true,
                    label: '同意'
                },
                {
                    value: false,
                    label: '不同意'
                }
            ],
            judgmentMediationOptions: [],
            causeOptions: [],
            transmitUserOptions: [],
            allocationUserOptions: [],
            allocationUserObj: {},
            firmOptions: [],
            platOptions: [],
            lawyerOptions: [],
            leaderOptions: [],
            leaderObj: {},
            value: '',
            textarea: '',
            caseId: '',
            returnData: {},
            caseDetailData: null,
            closeCaseForm: {
                judgmentTime: '',
                bearAcceptanceFee: '',
                courtAcceptanceFee: '',
                compensationAmount: '',
                situation: ''
            },
            closeCaseRules: {
                lawsuitCause: [{ required: true, message: '请选择诉讼原因', trigger: ['blur', 'change'] }],
                judgmentMediation: [{ required: true, message: '请选择是否判决/调解', trigger: ['blur', 'change'] }],
                bearAcceptanceFee: [{ required: true, message: '请填写我司承担受理费', trigger: 'blur' }],
                courtAcceptanceFee: [{ required: true, message: '请填写法院受理费', trigger: 'blur' }],
                compensationAmount: [{ required: true, message: '请填写我司赔付金额', trigger: 'blur' }],
                situation: [{ required: true, message: '请填写判决情况', trigger: 'blur' }]
            },
            approvalForm: {
                agree: '',
                situation: ''
            },
            approvalRules: {
                agree: [{ required: true, message: '请选择', trigger: 'blur' }]
            },
            allocateForm: {
                distributeHandler: '',
                situation: ''
            },
            allocateRules: {
                distributeHandlerUser: [{ required: true, message: '请选择分配用户', trigger: 'blur' }]
            },
            transmitRules: {
                distributeHandlerUser: [{ required: true, message: '请选择转交用户', trigger: 'blur' }]
            },
            reviewForm: {
                distributeHandlerUser: '',
                situation: ''
            },
            transmitForm: {
                distributeHandler: '',
                situation: ''
            },
            reviewRules: {
                distributeHandlerUser: [{ required: true, message: '请选择', trigger: 'blur' }]
            },
            commissionForm: {
                lawPlatform: '',
                lawFirm: '',
                distributeHandler: '',
                situation: ''
            },
            returnForm: {
                situation: ''
            },
            commissionRules: {
                // lawFirm: [{ required: true, message: '请选择', trigger: 'blur' }]
            },
            findLawyerData: {
                lawFirmId: '',
                lawPlatformId: ''
            }
        };
    },
    mounted() {
        // 获取诉讼原因
        this.getCause();
        // 判决/调解
        this.getJudgmentMediation();
        // 获取领导
        this.findLeader();
        // 律所
        this.getFindFirm();
        // 平台
        this.getFindPlatform();
        if (this.$route.query && this.$route.query.caseId) {
            this.caseId = this.$route.query.caseId;
            this.updatePageInfo();
            // 查询分配用户
            this.getAllocationUser();
            // 查询转交用户
            this.findHandoverUser();
        }
    },
    methods: {
        updatePageInfo() {
            this.caseDetailBtnTree();
            this.getCaseDetail();
        },
        getCause() {
            this.instance.get('/lawsuitCase/getCause', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.causeOptions = data.data;
                }
            });
        },
        getJudgmentMediation() {
            this.instance.get('/lawsuitCase/getJudgmentMediation', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.judgmentMediationOptions = data.data;
                }
            });
        },
        getFindFirm() {
            this.instance.post('/lawyerInfo/findFirm', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.firmOptions = data.data;
                }
            });
        },
        getFindPlatform() {
            this.instance.post('/lawyerInfo/findPlatform', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.platOptions = data.data;
                }
            });
        },
        getAllocationUser() {
            this.instance.post('/lawsuitCase/editDistributeCaseUser', { caseId: this.caseId }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.allocationUserOptions = data.data;
                    this.allocationUserOptions.forEach((item) => {
                        this.allocationUserObj[item.name] = item.username;
                    });
                }
            });
        },
        findHandoverUser() {
            this.instance.post('/lawsuitCase/findHandoverUser', { caseId: this.caseId }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.transmitUserOptions = data.data;
                }
            });
        },
        getReturnedStep() {
            this.instance.post('/lawsuitCase/getReturnedStep', { caseId: this.caseId }).then((res) => {
                let data = res.data;
                if (data.code == 1 && data.data) {
                    this.returnFlag = true;
                    this.returnData = data.data;
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        findLeader() {
            this.instance.post('/lawsuitCase/findLeader', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.leaderOptions = data.data;
                    this.leaderOptions.forEach((item) => {
                        this.leaderObj[item.nickName] = item.username;
                    });
                }
            });
        },
        getCaseDetail(btnMenuFlag) {
            this.caseDetailData = null;
            this.instance
                .post('/lawsuitCase/caseDetail', {
                    caseId: this.caseId
                })
                .then((res) => {
                    let data = res.data;
                    if (btnMenuFlag) {
                        this.caseDetailBtnTree();
                    }
                    if (data.code == 1) {
                        this.caseDetailData = data.data;
                    } else {
                        this.$message.error(data.message);
                    }
                });
        },
        commissionFormChange(val, selectType) {
            this.$set(this.commissionForm, 'distributeHandlerUser', '');
            if (selectType == 'lawPlatform') {
                this.findLawyerData.lawPlatformId = val;
            } else {
                this.findLawyerData.lawFirmId = val;
            }
            // this.commissionForm.distributeHandlerUser = null;
            this.getPageLawyer();
        },
        getPageLawyer() {
            this.instance.post('/lawyerInfo/findLawyerInfo', this.findLawyerData).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.lawyerOptions = data.data;
                }
            });
        },
        caseDetailBtnTree() {
            this.instance
                .post('/lawsuitCase/getMenu', {
                    caseId: this.caseId
                })
                .then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        // INPUT_CASE(100, "录入案件"),
                        // DISTRIBUTE_CASE(200, "分配"),
                        // EDIT_CASE(300, "编辑"),
                        // EDIT_CASE_LAWYER(301, "编辑案件律师"),
                        // commission_CASE(400, "委托"),
                        // COMMIT_CASE(500, "提交"),
                        // MENTION_APPROVE_CASE(700, "提审"),
                        // APPROVE_CASE(600, "审批"),
                        // END_CASE(999, "结案");
                        this.btnMenuData = {};
                        data.data.forEach((item) => {
                            this.btnMenuData[item.code] = item;
                        });
                    }
                });
        },
        closeCaseFn(submitFlag) {
            if (submitFlag) {
                this.$refs.closeCaseRef.validate((valid) => {
                    if (valid) {
                        this.closeCaseInterface();
                    }
                });
            } else {
                this.closeCaseFlag = false;
                // this.$refs.closeCaseRef.resetFields();
            }
        },
        selectChange() {
            this.$forceUpdate();
        },
        closeCaseDialog() {
            this.closeCaseForm = {
                judgmentTime: this.caseDetailData.judgmentTime || '',
                lawsuitCause: this.caseDetailData.lawsuitCause || '',
                judgmentMediation: this.caseDetailData.judgmentMediation || '',
                bearAcceptanceFee: '',
                courtAcceptanceFee: '',
                compensationAmount: '',
                situation: ''
            };
            // this.closeCaseForm.judgmentTime = !this.closeCaseForm.judgmentTime ? this.caseDetailData.judgmentTime : this.closeCaseForm.judgmentTime ? this.closeCaseForm.judgmentTime : '';
            // this.closeCaseForm.lawsuitCause = !this.closeCaseForm.lawsuitCause ? this.caseDetailData.lawsuitCause : this.closeCaseForm.lawsuitCause ? this.closeCaseForm.lawsuitCause : '';
            // this.closeCaseForm.judgmentMediation = !this.closeCaseForm.judgmentMediation ? this.caseDetailData.judgmentMediation : this.closeCaseForm.judgmentMediation ? this.closeCaseForm.judgmentMediation : '';
            this.closeCaseFlag = true;
        },
        closeCaseInterface() {
            this.closeCaseForm.caseId = this.caseDetailData.id;
            this.instance.post('/lawsuitCase/closeCase', this.closeCaseForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('操作成功！');
                    this.closeCaseFlag = false;
                } else {
                    this.$message.error(data.message);
                }
                this.updatePageInfo();
            });
        },
        approvalFn(submitFlag) {
            if (submitFlag) {
                this.$refs.approvalRef.validate((valid) => {
                    if (valid) {
                        this.approvalInterface();
                    }
                });
            } else {
                this.approvalFlag = false;
                this.$refs.approvalRef.resetFields();
            }
        },
        approvalInterface() {
            this.approvalForm.caseId = this.caseDetailData.id;
            this.instance.post('/lawsuitCase/approve', this.approvalForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('操作成功！');
                    this.approvalFlag = false;
                } else {
                    this.$message.error(data.message);
                }
                this.updatePageInfo();
            });
        },
        allocateFn(submitFlag) {
            if (submitFlag) {
                this.$refs.allocateRef.validate((valid) => {
                    if (valid) {
                        this.distributeCase();
                    }
                });
            } else {
                this.allocateFlag = false;
                this.$refs.allocateRef.resetFields();
            }
        },
        distributeCase() {
            this.allocateForm.caseId = this.caseDetailData.id;
            this.instance.post('/lawsuitCase/distributeCase', this.allocateForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('分配成功！');
                    this.allocateFlag = false;
                } else {
                    this.$message.error(data.message);
                }
                this.updatePageInfo();
            });
        },
        reviewFn(submitFlag) {
            if (submitFlag) {
                this.$refs.reviewRef.validate((valid) => {
                    if (valid) {
                        this.reviewInterface();
                    }
                });
            } else {
                this.reviewFlag = false;
                this.$refs.reviewRef.resetFields();
            }
        },
        reviewInterface() {
            this.reviewForm.caseId = this.caseDetailData.id;
            this.instance.post('/lawsuitCase/mentionApprove', this.reviewForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('操作成功！');
                    this.reviewFlag = false;
                } else {
                    this.$message.error(data.message);
                }
                this.updatePageInfo();
            });
        },
        commissionFn(submitFlag) {
            if (submitFlag) {
                if (!this.commissionForm.lawPlatform && !this.commissionForm.lawFirm) {
                    this.$message.error('律所或者律师委托平台二选一');
                    return;
                }
                if (!this.commissionForm.distributeHandlerUser) {
                    this.$message.error('必须选择委托律师');
                    return;
                }
                this.commissionInterface();
            } else {
                this.commissionFlag = false;
                this.$refs.commissionRef.resetFields();
            }
        },
        returnFn(submitFlag) {
            if (submitFlag) {
                this.returnForm.caseId = this.caseDetailData.id;
                this.instance.post('/lawsuitCase/returnedCase', this.returnForm).then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        this.$message.success('退回成功！');
                        this.returnFlag = false;
                        this.getAllocationUser();
                        this.updatePageInfo();
                    } else {
                        this.$message.error(data.message);
                    }
                });
            } else {
                this.returnFlag = false;
            }
        },
        withdrawFn(submitFlag) {
            if (submitFlag) {
                this.instance.post('/lawsuitCase/cancelEntrust', { caseId: this.caseId }).then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        this.$message.success('操作成功！');
                        this.withdrawFlag = false;
                    } else {
                        this.$message.error(data.message);
                    }
                    this.updatePageInfo();
                });
            } else {
                this.withdrawFlag = false;
            }
        },
        commissionInterface() {
            this.commissionForm.caseId = this.caseDetailData.id;
            this.instance.post('/lawsuitCase/entrustCase', this.commissionForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('委托成功！');
                    this.commissionFlag = false;
                } else {
                    this.$message.error(data.message);
                }
                this.updatePageInfo();
            });
        },
        editInfo(data) {
            this.litigationInfoShow = data;
        },
        pendingFn(flag) {
            if (flag) {
                this.instance.post('/lawsuitCase/suspended', { caseId: this.caseDetailData.id }).then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        this.$message.success('挂起成功！');
                    } else {
                        this.$message.error(data.message);
                    }
                    this.updatePageInfo();
                });
            } else {
                this.instance.post('/lawsuitCase/cancelSuspended', { caseId: this.caseDetailData.id }).then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        this.$message.success('取消挂起成功！');
                    } else {
                        this.$message.error(data.message);
                    }
                    this.updatePageInfo();
                });
            }
        },
        transmitFn(submitFlag) {
            if (submitFlag) {
                this.$refs.transmitRef.validate((valid) => {
                    if (valid) {
                        this.handoverCase();
                    }
                });
            } else {
                this.transmitFlag = false;
                this.$refs.transmitRef.resetFields();
            }
        },
        handoverCase() {
            this.transmitForm.caseId = this.caseDetailData.id;
            this.instance.post('/lawsuitCase/handoverCase', this.transmitForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('转交成功！');
                    this.transmitFlag = false;
                } else {
                    this.$message.error(data.message);
                }
                this.updatePageInfo();
            });
        }
    }
};
</script>
<style>
@import '../../assets/css/table_public.css';
@import '../../assets/css/caseCard.css';
</style>
<style lang="less" scoped>
.page_title {
    margin-bottom: 10px;
}
.page_content {
    ::-webkit-scrollbar {
        width: 0px;
        height: 10px;
        background-color: red;
    }
    .cards_left {
        position: relative;
        .btn_groups {
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 5;
        }
    }
}
// 弹窗
.dialog_content {
    .el-row {
        margin-bottom: 30px;
        .title_col {
            text-align: right;
        }
    }
    .el-row:last-child {
        margin-bottom: 0;
    }
}
.process_popup {
    .el-form-item__content {
        .el-select {
            width: 100%;
        }
    }
}
</style>