<template>
    <div class="wrap">
        <div class="page_title"><b>到期案件提醒</b></div>
        <!--检索部分  -->
        <div class="search_box public_box">
            <el-form :inline="true" :model="navForm">
                <el-form-item label="案件号：">
                    <el-input class="width_250" v-model="navForm.caseNo" type="text" placeholder="请输入案件号" clearable></el-input>
                </el-form-item>
                <el-form-item label="案件类型：">
                    <el-select class="width_110" v-model="navForm.types" multiple clearable placeholder="请选择">
                        <el-option v-for="item in caseReminderTypeOption" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="下次提醒日期：">
                    <el-row style="width: 350px">
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" clearable v-model="navForm.startExpirationDate" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                        <el-col class="line" style="text-align: center" :span="2">至</el-col>
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" clearable v-model="navForm.endExpirationDate" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="当前提醒状态：">
                    <el-select class="width_110" v-model="navForm.reminderStatus" multiple clearable placeholder="请选择">
                        <el-option v-for="item in caseReminderStatusOption" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="当前标签状态：">
                    <el-select class="width_110" v-model="navForm.labelStatus" multiple clearable placeholder="请选择">
                        <el-option v-for="item in caseReminderLabelOption" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="当前作业人：">
                    <el-select class="width_110" v-model="navForm.currentProcessor" multiple clearable placeholder="请选择">
                        <el-option v-for="(item, index) in caseReminderProcessorOption" :key="index" :label="item" :value="item"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" style="margin-left: 10px" @click="searchCasePage(true)">查询</el-button>
                    <el-button type="warning" style="margin-left: 20px" @click="resetForm(true)">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 表格 -->
        <div class="main">
            <div class="table_box public_box">
                <el-table :data="tableData" :height="tableHeight" v-loading="loading">
                    <el-table-column prop="id" label="id" width="50"></el-table-column>
                    <el-table-column prop="caseNo" label="案件号" width="180">
                        <template slot-scope="scope">
                            <overflow-txt :txt="scope.row.caseNo"></overflow-txt>
                        </template>
                    </el-table-column>
                    <el-table-column prop="typeName" label="案件类型" width="140"></el-table-column>
                    <el-table-column prop="expirationTime" label="下次提醒日期">
                        <template slot-scope="scope">
                            <span>{{ scope.row.expirationTime.slice(0, 10) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="reminderStatusName" label="当前提醒状态" width="200">
                        <template slot-scope="scope">
                            <span :class="{ red_txt: scope.row.reminderStatus == 3 || scope.row.reminderStatus == 4 }">{{ scope.row.reminderStatusName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="labelStatusName" label="当前标签状态"> </el-table-column>
                    <el-table-column prop="currentProcessor" label="当前作业人"></el-table-column>
                    <el-table-column prop="sendStatus" label="通知发送状态"></el-table-column>
                    <el-table-column prop="preSendTime" label="上一次提醒" width="160"></el-table-column>
                    <el-table-column label="操作" width="140">
                        <template slot-scope="scope">
                            <el-button :disabled="scope.row.reminderStatus == 3 || scope.row.reminderStatus == 4" @click="clickRemind(scope.row, false)" type="text" size="small">停止提醒</el-button>
                            <el-button :disabled="scope.row.reminderStatus != 3 || scope.row.reminderStatus == 4" @click="clickRemind(scope.row, true)" type="text" size="small">启动提醒</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="navForm.page" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="navForm.size" :total="navForm.total"> </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
let queryCaseRuleFlag = false;
const validatePass = (rule, value, callback) => {
    if (value.length > 0 && queryCaseRuleFlag) {
        callback(new Error('未查询到案件号，请输入正确的理赔报案号重新查询'));
    } else {
        callback();
    }
};
export default {
    data() {
        return {
            tableHeight: null,
            dialogShow: false,
            queryCaseFlag: false,
            enterFlag: false,
            loading: false,
            queryInputCase: false,
            navForm: {
                caseNo: '',
                types: [],
                startExpirationDate: '',
                endExpirationDate: '',
                reminderStatus: [],
                labelStatus: [],
                currentProcessor: [],
                page: 1,
                size: 10,
                total: 0
            },
            caseReminderStatusOption: [],
            caseReminderTypeOption: [],
            caseReminderLabelOption: [],
            caseReminderProcessorOption: [],
            sessionOptions: [
                {
                    code: 3,
                    label: '3日内'
                },
                {
                    code: 7,
                    label: '7日内'
                },
                {
                    code: 15,
                    label: '15日内'
                },
                {
                    code: 30,
                    label: '30日内'
                },
                {
                    code: 0,
                    label: '所有待开庭案件'
                }
            ],
            appealOptions: [
                {
                    code: 3,
                    label: '3日内'
                },
                {
                    code: 7,
                    label: '7日内'
                },
                {
                    code: 15,
                    label: '15日内'
                },
                {
                    code: 30,
                    label: '30日内'
                },
                {
                    code: 0,
                    label: '所有可上诉案件'
                }
            ],
            payOptions: [
                {
                    code: 3,
                    label: '3日内'
                },
                {
                    code: 7,
                    label: '7日内'
                },
                {
                    code: 15,
                    label: '15日内'
                },
                {
                    code: 30,
                    label: '30日内'
                },
                {
                    code: 0,
                    label: '所有待支付案件'
                }
            ],
            tableData: [
                {
                    value1: '812020080703202401261000022'
                }
            ],
            allocationUserOptions: [],
            allocationUserObj: {},
            causeOptions: [],
            btnMenuData: {}
        };
    },
    mounted() {
        // 动态计算table高度
        window.onresize = () => {
            this.calcHeight();
        };
        this.calcHeight();

        this.searchCasePage();
        this.getTypeEnum();
        this.getStatusEnum();
        this.getLabelEnum();
        this.getProcessor();
    },
    methods: {
        clickTab() {
            this.navForm.suspendedFlag = this.editableTabsValue != '0';
            this.searchCasePage();
        },
        calcHeight() {
            // 顶部tabs
            let registerTabsHeight = document.querySelector('.register_tabs') ? document.querySelector('.register_tabs').clientHeight : 0;
            // 搜索筛选项
            let publicBoxHeight = document.querySelector('.public_box') ? document.querySelector('.public_box').clientHeight : 0;
            // 分页
            let elPaginationHeight = document.querySelector('.el-pagination') ? document.querySelector('.el-pagination').clientHeight : 0;
            let tableHeaderHeight = 0;
            let guHeight = 175;

            this.tableHeight = window.innerHeight - registerTabsHeight - publicBoxHeight - elPaginationHeight - tableHeaderHeight - guHeight;

            this.tableHeight = this.tableHeight < 200 ? 200 : this.tableHeight;
            this.$forceUpdate();
        },
        getTypeEnum() {
            this.instance.get('/caseReminder/getTypeEnum', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.caseReminderTypeOption = data.data;
                }
            });
        },
        getStatusEnum() {
            this.instance.get('/caseReminder/getStatusEnum', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.caseReminderStatusOption = data.data;
                }
            });
        },
        getLabelEnum() {
            this.instance.get('/caseReminder/getLabelEnum', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.caseReminderLabelOption = data.data;
                }
            });
        },
        getProcessor() {
            this.instance.get('/caseReminder/searchProcessor', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.caseReminderProcessorOption = data.data;
                }
            });
        },
        searchCasePage(queryFlag) {
            if (queryFlag) {
                this.navForm.page = 1;
            }
            this.tableData = [];
            this.instance.post('/caseReminder/searchPage', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.tableData = data.data.list;
                    this.navForm.total = data.data.total;
                }
            });
        },
        openNewTab(caseNo) {
            let a = document.createElement('a');
            a.href = `${location.origin}/litigationManage/caseDetail?caseId=${caseNo}`;
            a.target = '_blank';
            a.click();
        },
        handleCurrentChange(val) {
            this.navForm.page = val;
            this.searchCasePage();
        },
        handleSizeChange(val) {
            this.navForm.size = val;
            this.searchCasePage();
        },
        resetForm() {
            let tmpNavForm = JSON.parse(JSON.stringify(this.navForm));
            this.navForm = {
                caseNo: '',
                types: [],
                startExpirationDate: '',
                endExpirationDate: '',
                reminderStatus: [],
                labelStatus: [],
                currentProcessor: [],
                page: tmpNavForm.page,
                size: tmpNavForm.size,
                total: tmpNavForm.total
            };
        },
        clickRemind(rowItem, remindFlag) {
            if (remindFlag) {
                this.enableReminder(rowItem);
            } else {
                this.stopReminder(rowItem);
            }
        },
        enableReminder(rowItem) {
            this.instance.post('/caseReminder/enableReminder/' + rowItem.id, {}).then((res) => {
                this.searchCasePage();
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('操作成功！');
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        stopReminder(rowItem) {
            this.instance.post('/caseReminder/stopReminder/' + rowItem.id, {}).then((res) => {
                this.searchCasePage();
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('操作成功！');
                } else {
                    this.$message.error(data.message);
                }
            });
        }
    }
};
</script>
<style>
@import '../../assets/css/table_public.css';
</style>
<style lang="less" scoped>
.red_txt {
    color: red;
}
.search_box {
    .el-form {
        .datePickerWidth {
            width: 250px;
        }
    }
}
.table_box {
    .tooltip_text {
        cursor: pointer;
        color: #409eff;
    }
    .winRate {
        display: inline-block;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        margin-right: 5px;
    }
    .greenColor {
        background: #67c23a;
    }
    .redColor {
        background: red;
    }
}
.dialog_content {
    .title_col {
        margin-bottom: 10px;
        .txt_box {
            display: inline-block;
        }
        .info_title {
            width: 100px;
            text-align: right;
            // border: 1px solid red;
        }
        .query_title {
            width: 128px;
        }
    }
}
</style>