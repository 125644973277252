<template>
    <div class="wrap">
        <div class="page_title"><b>人伤理算自动化</b></div>
        <div class="public_box public_box1" ref="scrollview">
            <el-backtop target=".public_box"></el-backtop>
            <div class="invoice_box">
                <div class="search_box">
                    <div class="search_input">
                        <span>案件号：</span>
                        <el-input v-model="navForm.caseNo" placeholder="请输入案件号" clearable />
                        <el-button type="primary" @click="getCaseData">搜索</el-button>
                        <span style="margin-left: 50px">统筹支付金额：</span>
                        <el-input class="payment_amount" v-model="navForm.overallAmount" placeholder="请输入" />
                        <span>治疗扣除比例：</span>
                        <el-input class="deduction_ratio" v-model="navForm.payRatio" oninput="value=value.replace(/[^\d]/g,'')" :maxlength="3" @change="payRatioChange" />%
                        <el-button type="primary" @click="getAdjustMedicalFile" style="margin-left: 20px">获取理赔结果</el-button>
                    </div>
                    <div class="demo_num">
                        <i class="el-icon-info" style="color: #409eff; font-size: 13px; margin-right: 5px"></i>
                        <span>示例案件号：602020080703202301201000048</span>
                    </div>
                </div>
                <div class="small_title">
                    <span>查询结果</span>
                    <!-- <el-button style="margin-left: 20px" @click="getAdjustMedicalFile" type="primary" size="mini">获取理算结果</el-button> -->
                </div>
            </div>
            <div class="main">
                <div class="main_left" v-show="showHiddenFlag">
                    <ul class="invoice_list" v-if="invoicePageList.length > 0">
                        <li v-for="items in invoicePageList" :key="items.type" @click="handleInvoiceList(items)" :class="{ active: checkedInvoice.type == items.type }">
                            <p>{{ items.type }}</p>
                            <div class="invoice_thumbnail" :class="{ active: items.voList.length < 3 }">
                                <template v-for="(item, index) in items.voList">
                                    <div v-if="index < 4" :key="index">
                                        <el-image style="width: 100%; height: 100%" :src="item.url" fit="cover">
                                            <div slot="error" class="image-slot">
                                                <i class="el-icon-picture-outline"></i>
                                            </div>
                                        </el-image>
                                    </div>
                                </template>
                            </div>
                        </li>
                    </ul>
                    <el-skeleton v-else style="width: 95%">
                        <template slot="template">
                            <div style="padding-bottom: 8px">
                                <el-skeleton-item variant="p" style="width: 100%" />
                            </div>
                            <el-skeleton-item variant="image" style="width: 100%; height: 140px" />
                        </template>
                    </el-skeleton>
                </div>
                <div :class="showHiddenFlag ? 'main_right' : 'main_right hiddenLeft'">
                    <p>
                        {{ checkedInvoice.voList && checkedInvoice.voList.length > 0 ? titleText + '（' + (imgCurrentIndex + 1) + ' / ' + checkedInvoice.voList.length + '）' : titleText }}
                        <span class="fold_btn"><i :class="showHiddenFlag ? 'el-icon-s-fold' : 'el-icon-s-unfold'" @click="showHiddenFlag = !showHiddenFlag" v-if="invoicePageList.length > 0"></i></span>
                    </p>
                    <template v-if="checkedInvoice.voList && checkedInvoice.voList.length > 0">
                        <div class="invoice_info">
                            <div :class="showHiddenFlag ? 'invoice_carousel' : 'invoice_carousel showWidth'">
                                <el-carousel v-loading="queryFileLoading" trigger="click" @change="carouselChange" :autoplay="false" indicator-position="none" style="width: 100%; height: 100%">
                                    <el-carousel-item v-for="(item, index) in checkedInvoice.voList" :key="index">
                                        <img-deal ref="imgDeal" :url="item.url"></img-deal>
                                    </el-carousel-item>
                                </el-carousel>
                            </div>
                            <el-form class="invoice_form" label-position="top" size="mini" :model="invoiceForm">
                                <!-- <el-form-item label="发票基本信息">
                                    <el-input class="width_140" style="margin-right: 15px" v-model="invoiceForm.filetypedesc" placeholder="发票号码" clearable></el-input>
                                    <el-input class="width_140" v-model="invoiceForm.invoicecode" placeholder="发票代码" clearable></el-input>
                                </el-form-item> -->
                                <el-form-item>
                                    <ul class="sum_label">
                                        <template v-if="checkedInvoice.parentType == '医疗发票'">
                                            <li>项目名称</li>
                                            <li :class="checkedInvoice.parentType == '医疗发票' ? 'medical_invoice1' : 'width_80'">项目金额</li>
                                            <li :class="checkedInvoice.parentType == '医疗发票' ? 'medical_invoice2' : 'width_55'">比例</li>
                                            <li :class="checkedInvoice.parentType == '医疗发票' ? 'medical_invoice3' : ''">理算金额</li>
                                        </template>
                                        <template v-else>
                                            <li>药费项目名称</li>
                                            <li class="width_80">药品费用金额</li>
                                            <li class="width_55 margin_left">比例</li>
                                            <li class="gauge_outfit">扣除后金额</li>
                                            <li>分类</li>
                                        </template>
                                        <li :class="checkedInvoice.parentType == '医疗发票' ? 'medical_invoice4' : 'add_btn'">
                                            <el-button @click="addItem" type="primary" icon="el-icon-plus" circle></el-button>
                                        </li>
                                    </ul>
                                    <div class="expenses_medicine">
                                        <template v-for="(item, index) in invoiceForm.listData">
                                            <div class="sum_box" :key="index">
                                                <el-input class="width_140" v-model="item.name" placeholder="药费项目名称"></el-input>
                                                <el-button icon="el-icon-search" v-if="checkedInvoice.parentType == '费用清单'" circle @click="searchClick(item, index)"></el-button>
                                                <el-input class="width_70 width_80" v-model="item.amount"></el-input>
                                                <el-input class="width_70 width_55" v-model.number="item.ratio" oninput="value=value.replace(/[^\d]/g,'')" @blur="ratioChange(item.ratio, index)"></el-input>%
                                                <el-input class="width_70 deductible" v-model="item.adjustAmount" disabled></el-input>
                                                <div class="width_70 sum_type deductible" v-if="checkedInvoice.parentType == '费用清单'">{{ item.type }}</div>
                                                <el-button @click="deleteItem(item, index)" type="danger" icon="el-icon-delete" style="margin-left: 20px" circle></el-button>
                                            </div>
                                        </template>
                                    </div>
                                </el-form-item>
                                <el-form-item>
                                    <ul class="sum_label">
                                        <template v-if="checkedInvoice.type == '骑手门诊发票'">
                                            <li>发票金额合计</li>
                                            <li style="width: 140px">理算金额合计</li>
                                        </template>
                                        <template v-else>
                                            <li>药品费用总金额</li>
                                            <li style="width: 140px">扣除后总金额</li>
                                        </template>
                                    </ul>
                                    <el-input class="width_140" style="margin-right: 10px" v-model="invoiceForm.invoiceAmount" disabled></el-input>
                                    <el-input class="width_140" v-model="invoiceForm.adjustAmount" disabled></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="submit_btn">
                            <el-button type="primary" @click="handleSubmit">保 存</el-button>
                        </div>
                    </template>
                    <template v-else>
                        <div class="invoice_info">
                            <el-skeleton class="invoice_carousel">
                                <template slot="template">
                                    <el-skeleton-item variant="image" style="width: 100%; height: 100%" />
                                </template>
                            </el-skeleton>
                            <el-skeleton :rows="8" class="invoice_form" />
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisibleBigImg" :modal-append-to-body="false">
            <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
        <!-- <el-dialog :visible.sync="dialogVisibleSum" :modal-append-to-body="false" width="600px">
            <div class="case_num">案件号：{{ search }}</div>
            <el-card class="box-card">
                <ul class="case_sum">
                    <li>发票理算金额：{{ adjustMedicalFile.invoiceDesc }} 元</li>
                    <li>清单理算金额：{{ adjustMedicalFile.medicalDesc }} 元</li>
                    <li>合计金额：{{ adjustMedicalFile.sumAmount }} 元</li>
                </ul>
            </el-card>
        </el-dialog> -->
        <!-- 药品分类查询 -->
        <el-dialog title="药品分类查询" :visible.sync="centerDialogVisible" :modal-append-to-body="false" width="20%" center style="right: -55%" :close-on-click-modal="false">
            <div class="classification_inline">
                <span>药品名称：</span>
                <el-autocomplete v-model="searchDialogData.name" :fetch-suggestions="querySearchAsync" placeholder="请输入内容" @select="handleSelect"></el-autocomplete>
                <p class="inline_txt" v-if="inlineErrorFlag">查不到该药品名称</p>
            </div>
            <div class="classification_inline">
                <span>所属分类：</span>
                <span v-if="!inlineErrorFlag">{{ searchDialogData.drugType }}</span>
            </div>
            <div class="btn_group">
                <el-button type="primary" :disabled="!searchDialogData.drugType" @click="replaceTxt">替 换</el-button>
            </div>
        </el-dialog>
        <!-- 理赔结果 弹窗 -->
        <el-dialog class="dialog_adjustMent" title="人伤理算结果" :visible.sync="dialogVisibleSum" :modal-append-to-body="false" width="70%" center>
            <div class="dialog_top">
                <el-row>
                    <el-col :span="12"> 案件号：{{ navForm.caseNo }} </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        发票金额：{{ adjustMedicalFile.invoiceDescStr ? adjustMedicalFile.invoiceDescStr : '' }} <span class="moneySuccess">{{ adjustMedicalFile.invoiceDescStr ? adjustMedicalFile.invoiceMoneyStr : '0' }} </span>元
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        清单金额：{{ adjustMedicalFile.medicalDescStr ? adjustMedicalFile.medicalDescStr : '' }}<span class="moneySuccess">{{ adjustMedicalFile.invoiceDescStr ? adjustMedicalFile.medicalMoneyStr : '0' }} </span>元
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        统筹支付金额：
                        <span class="moneySuccess">{{ adjustMedicalFile.overallAmount ? adjustMedicalFile.overallAmount : '0' }}</span>
                        元
                    </el-col>
                    <el-col :span="8">
                        治疗扣除比例：
                        <span class="moneySuccess">{{ adjustMedicalFile.payRatio ? adjustMedicalFile.payRatio : '0' }}%</span>
                    </el-col>
                    <el-col :span="8" v-if="userInfo.company == '16'">
                        实际赔付金额：
                        <span class="moneySuccess">{{ adjustMedicalFile.caseAmount ? adjustMedicalFile.caseAmount : '0' }}</span> 元
                    </el-col>
                </el-row>
            </div>
            <div class="dialog_bottom">
                <el-row>
                    <el-col :span="8">
                        甲类：
                        <span class="moneySuccess">{{ adjustMedicalFile.amount1 ? adjustMedicalFile.amount1 : '0' }}</span>
                        元
                    </el-col>
                    <el-col :span="8" :offset="8">
                        乙类：
                        <span class="moneySuccess">{{ adjustMedicalFile.amount2 ? adjustMedicalFile.amount2 : '0' }}</span>
                        元 <span v-if="adjustMedicalFile.reduceAmount2">（{{ adjustMedicalFile.reduceAmount2 ? adjustMedicalFile.reduceAmount2 : '' }} ）</span>
                    </el-col>
                    <el-col :span="8" v-if="0">
                        保障外用药：
                        <span class="moneySuccess">{{ adjustMedicalFile.amount3 ? adjustMedicalFile.amount3 : '' }}</span>
                        元
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24"> 理算合计金额=发票金额X治疗扣除比例+清单金额-统筹支付金额 </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <span class="fontBold">理算金额：</span>
                        <span class="moneySuccess">{{ adjustMedicalFile.sumAmount ? adjustMedicalFile.sumAmount : '' }}</span>
                        元
                    </el-col>
                    <el-col :span="12" v-if="0">
                        <span class="fontBold">减损：</span>
                        <span class="reduceMoney moneyError">{{ adjustMedicalFile.reduceAmount ? adjustMedicalFile.reduceAmount : '' }}</span>
                        元
                        <span class="reduceIcon moneyError">⬇</span>
                    </el-col>
                </el-row>
            </div>
            <div class="risk_warning" v-if="adjustMedicalFile.riskVos && adjustMedicalFile.riskVos.length > 0">
                <div class="risk_tips">风险提示</div>
                <el-row v-for="(item, index) in adjustMedicalFile.riskVos" :key="index">
                    <el-col :span="12">{{ item.riskName ? item.riskName : '' }}</el-col>
                    <el-col :span="5" :offset="1" v-if="item.sumAmount"> 总额：{{ item.sumAmount }}{{ item.riskName.indexOf('万') != -1 ? '万' : '元' }} </el-col>
                    <el-col :span="5" :offset="1" v-if="item.diffAmount"> 差额：{{ item.diffAmount }}{{ item.riskName.indexOf('万') != -1 ? '万' : '元' }} </el-col>
                </el-row>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src
import ImgDeal from '@/components/ImgDeal.vue';
export default {
    name: 'myTask',
    components: {
        ImgDeal
    },
    data() {
        return {
            queryFileLoading: false,
            baseURL: '',
            qualityTestingVisible: false,
            dialogVisible: false,
            dialogVisibleSum: false,
            isUnfold: false,
            dialogImageUrl: '',
            queryInvoiceNo: '',
            search: '602020080703202301201000048',
            dialogVisibleBigImg: false,
            checkedInvoice: {
                voList: []
            },
            adjustMedicalFile: {},
            invoiceForm: {
                filetypedesc: '',
                invoicecode: '',
                listData: [
                    {
                        name: '',
                        amount: ''
                    }
                ],
                invoiceamount: ''
            },
            invoicePageList: [],
            centerDialogVisible: false,
            inlineErrorFlag: false,
            searchDialogData: {
                name: '',
                drugType: ''
            },
            showHiddenFlag: true,
            imgCurrentIndex: 0,
            adjustMentFlag: false,
            navForm: {
                caseNo: '602020080703202301201000048',
                overallAmount: '',
                payRatio: '',
                internal: true
            },
            userInfo: {},
            titleText: '图片展示'
        };
    },
    watch: {
        invoiceForm: {
            handler(newValue, oldValue) {
                if (newValue.listData && newValue.listData.length > 0) {
                    let adjustAmount = 0,
                        invoiceAmount = 0;
                    newValue.listData.forEach((val) => {
                        val.adjustAmount = this.unifyNumber(((val.amount || 0) * (val.ratio || 0)) / 100);
                        adjustAmount += parseFloat(((val.amount || 0) * (val.ratio || 0)) / 100);
                        invoiceAmount += parseFloat(val.amount || 0);
                    });
                    newValue.adjustAmount = this.unifyNumber(adjustAmount);
                    newValue.invoiceAmount = this.unifyNumber(invoiceAmount);
                } else {
                    newValue.adjustAmount = 0;
                    newValue.invoiceAmount = 0;
                }
            },
            deep: true
        }
    },
    methods: {
        ratioChange(val, index) {
            // 当比例为0时自动匹配分类保障外用药；
            // 当比例为100时自动匹配分类甲；
            // 当比例不为0或100时自动匹配分类乙；
            let tmpType = '';
            val = String(val).length > 0 ? (val >= 100 ? String(100) : String(val)) : '0';
            switch (val) {
                case '0':
                    tmpType = '保障外用药';
                    break;
                case '100':
                    tmpType = '甲';
                    break;
                default:
                    tmpType = '乙';
                    break;
            }

            this.invoiceForm.listData[index].type = tmpType;
            this.invoiceForm.listData[index].ratio = val;
            this.$set(this.invoiceForm.listData, index, this.invoiceForm.listData[index]);
        },
        replaceTxt() {
            let tmpNameStr = this.searchDialogData.name;
            let tmpIndex = tmpNameStr.indexOf(' -');
            tmpNameStr = tmpIndex != -1 ? tmpNameStr.substr(0, tmpIndex) : tmpNameStr;
            this.centerDialogVisible = false;
            let tmpItemData = this.invoiceForm.listData[this.searchDialogData.index];
            tmpItemData.name = tmpNameStr;

            // 替换时自动替换比例和分类
            if (this.searchDialogData.drugType) {
                let proportionNum = '';
                switch (this.searchDialogData.drugType) {
                    case '保障外用药':
                        proportionNum = '0';
                        break;
                    case '甲':
                        proportionNum = '100';
                        break;
                    case '乙':
                        proportionNum = '80';
                        break;
                    default:
                        proportionNum = '';
                        break;
                }
                tmpItemData.ratio = proportionNum;
                tmpItemData.type = this.searchDialogData.drugType;
            }

            this.$set(this.invoiceForm.listData, this.searchDialogData.index, tmpItemData);
        },
        //点击报存按钮
        handleSubmit() {
            this.instance.post('/human/injury/updateMedicalFile', this.invoiceForm).then((res) => {
                if (res.data.code == 1) {
                    this.$message.success(res.data.message);
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        // 获取理算结果
        getAdjustMedicalFile() {
            let userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'));
            this.navForm.internal = userInfo && userInfo.company == 16 ? true : false;
            this.instance.post('/human/injury/adjustMedicalFile', this.navForm).then((res) => {
                if (res.data.code == 1) {
                    this.dataCollection({ eventId: 312, remark: '人伤理算自动化进行清单理算', caseNo: this.navForm.caseNo });
                    this.dialogVisibleSum = true;
                    let response = res.data.data;
                    let invoiceIndex = response.invoiceDesc ? response.invoiceDesc.lastIndexOf('=') : -1;
                    let medicalIndex = response.medicalDesc ? response.medicalDesc.lastIndexOf('=') : -1;
                    // 发票公式拆分
                    response.invoiceDescStr = invoiceIndex != -1 ? response.invoiceDesc.slice(0, invoiceIndex + 2) : null;
                    response.invoiceMoneyStr = invoiceIndex != -1 ? response.invoiceDesc.slice(invoiceIndex + 2, response.invoiceDesc.length) : null;
                    // 清单公式拆分
                    response.medicalDescStr = medicalIndex != -1 ? response.medicalDesc.slice(0, medicalIndex + 2) : null;
                    response.medicalMoneyStr = medicalIndex != -1 ? response.medicalDesc.slice(medicalIndex + 2, response.medicalDesc.length) : null;
                    this.adjustMedicalFile = response;
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        //添加药费项目
        addItem() {
            if (!this.invoiceForm.listData) {
                this.invoiceForm.listData = [];
            }
            this.invoiceForm.listData.push({
                name: '',
                amount: ''
            });
        },
        //删除药费项目
        deleteItem(item, index) {
            this.invoiceForm.listData.splice(index, 1);
        },
        //发票确认处的走马灯切换
        carouselChange(val) {
            let tmpVoList = this.checkedInvoice.voList;
            this.imgCurrentIndex = val;
            if (!tmpVoList[val].ocrFlag) {
                this.queryFileLoading = true;
                this.instance.post('/human/injury/ocrCaseMedicalFile', { medicalFileName: this.checkedInvoice.voList[val].fileName }).then((res) => {
                    this.queryFileLoading = false;
                    if (res.data.code == 1) {
                        let tmpData = res.data.data;
                        tmpData.ocrFlag = true;
                        let tmpIndex = tmpVoList.findIndex((item) => {
                            return item.id == tmpVoList[val].id;
                        });
                        tmpVoList != -1 && tmpVoList.splice(tmpIndex, 1, tmpData);
                        this.invoiceForm = tmpVoList[val];
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
            } else {
                this.invoiceForm = this.checkedInvoice.voList[val];
            }
            this.isUnfold = false;
        },
        //操作选中案件列表某一案件
        handleInvoiceList(item) {
            this.checkedInvoice = item;
            setTimeout(() => {
                item.voList.length > 0 &&
                    item.voList.forEach((val, index) => {
                        this.$refs.imgDeal[index].restImg();
                    });
            }, 300);
            this.carouselChange(0);
        },
        //点击搜索按钮
        getCaseData() {
            this.invoicePageList = [];
            this.initInvoiceData();
            this.instance
                .post('/human/injury/caseMedicalFileList', {
                    caseNo: this.navForm.caseNo
                })
                .then((res) => {
                    if (res.data.code == 1) {
                        this.dataCollection({ eventId: 311, remark: '人伤理算自动化页面，且成功通过案件号搜索到案件', caseNo: this.navForm.caseNo });
                        let response = res.data.data;
                        response &&
                            response.forEach((val) => {
                                val.voList.forEach((value) => {
                                    value.listData = value.listData || [
                                        {
                                            name: '',
                                            amount: ''
                                        }
                                    ];
                                });
                            });
                        this.invoicePageList = response;
                    }
                });
        },
        //初始化发票确认数据
        initInvoiceData() {
            this.checkedInvoice = {
                voList: []
            };
            this.invoiceForm.filetypedesc = '';
            this.invoiceForm.invoicecode = '';
            this.invoiceForm.listData = [
                {
                    name: '',
                    amount: ''
                }
            ];
            this.invoiceForm.invoiceamount = '';
        },
        closeDialog() {
            this.qualityTestingVisible = false;
        },
        searchClick(row, index) {
            this.centerDialogVisible = true;
            this.searchDialogData.name = row.name;
            this.searchDialogData.index = index;
            this.searchDialogData.drugType = '';
            this.instance
                .get('/human/injury/searchDrugList', {
                    params: { drugName: row.name }
                })
                .then((res) => {
                    let data = res.data;
                    if (data.code == 1 && data.data && data.data.length > 0) {
                        this.inlineErrorFlag = false;
                        data.data.forEach((item) => {
                            item.value = item.drugName + ' - ' + item.dosageForm;
                        });
                        cb(data.data);
                    } else {
                        this.inlineErrorFlag = true;
                    }
                });
        },
        querySearchAsync(queryString, cb) {
            // 布洛芬 - 口服液体剂
            let tmpIndex = queryString.indexOf(' -');
            queryString = tmpIndex != -1 ? queryString.substr(0, tmpIndex) : queryString;
            queryString.length > 0 &&
                this.instance
                    .get('/human/injury/searchDrugList', {
                        params: { drugName: queryString }
                    })
                    .then((res) => {
                        let data = res.data;
                        if (data.code == 1 && data.data && data.data.length > 0) {
                            this.inlineErrorFlag = false;
                            data.data.forEach((item) => {
                                item.value = item.drugName + ' - ' + item.dosageForm;
                            });
                            cb(data.data);
                        } else {
                            this.inlineErrorFlag = true;
                        }
                    });
        },
        handleSelect(item) {
            this.searchDialogData.drugType = item.drugType;
        },
        payRatioChange(val) {
            val = Number(val) > 100 ? '100' : val;
            this.navForm.payRatio = val;
        }
    },
    mounted() {
        this.baseURL = this.$store.getters.baseURL;
        this.userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'));
    }
};
</script>
<style scoped>
@import '../../assets/css/table_public.css';
.wrap {
    font-size: 14px;
}
.case_num {
    font-size: 16px;
    color: #000000;
    margin-bottom: 12px;
}
.case_sum li {
    line-height: 1.8;
}
.invoice_big {
    width: 100%;
}
.submit_btn {
    position: absolute;
    right: 0%;
    bottom: 10%;
}
.hiddenLeft {
    padding-left: 0 !important;
}
.invoice_info {
    width: 100%;
    display: flex;
    position: relative;
}
/deep/.el-form--label-top .el-form-item__label {
    padding: 0;
}
.el-form-item--small.el-form-item {
    margin-bottom: 12px;
}
.invoice_form {
    width: 600px;
    padding-top: 10px;
}
.deductible {
    margin-left: 10px !important;
}
.width_70 {
    margin-left: 10px;
}
.width_80 {
    width: 80px;
    margin-left: 10px;
}
.medical_invoice1 {
    margin-left: 10px !important;
}
.medical_invoice2 {
    margin-left: 20px !important;
}
.medical_invoice3 {
    margin-left: 10px !important;
}
.medical_invoice4 {
    margin-left: 22px !important;
}
.invoice_form_fixed {
    margin-left: 6.8rem;
}
/deep/.el-carousel__container {
    height: 100%;
}
.invoice_carousel {
    position: relative;
    z-index: 100;
    width: 44%;
    height: 8rem;
    margin: 15px 20px 0 0;
    overflow: hidden;
    min-width: 260px;
    min-height: 200px;
    outline: 1px solid #ccc;
    border-radius: 6px;
    user-select: none;
}
.showWidth {
    width: 65%;
}
.invoice_carousel_fixed {
    position: fixed;
}
/deep/.el-skeleton {
    height: 100%;
}
.invoice_list li {
    width: 100%;
    padding: 0 8px 0 2px;
    font-size: 13px;
    margin-bottom: 16px;
}
.invoice_thumbnail {
    width: 100%;
    height: 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 6px;
    margin-top: 8px;
    padding: 10px;
    align-items: center;
    user-select: none;
}
.sum_label {
    display: flex;
    font-size: 13px;
    color: #606266;
    margin-bottom: 6px;
}
.sum_label li {
    width: 70px;
    margin-left: 34px;
}
.sum_label .width_80 {
    width: 80px;
}
.sum_label .width_55 {
    width: 55px;
}
.sum_label li:first-child {
    width: 140px;
    margin: 0;
}
.sum_label li:nth-child(4) {
    margin-left: 30px;
}
.sum_label .add_btn {
    width: 30px;
    margin: 0;
}
.margin_left {
    margin-left: 14px !important;
}
.width_55 {
    width: 55px;
}
.invoice_thumbnail.active {
    height: 88px;
}
.sum_box {
    margin-bottom: 10px;
}
.expenses_medicine {
    max-height: 260px;
    overflow-y: auto;
}
.invoice_form .sum_box:last-child {
    margin: 0;
}
.invoice_thumbnail:hover {
    background-color: #f2f8fc;
    cursor: pointer;
}
.invoice_list .active .invoice_thumbnail {
    background-color: #f2f8fc;
}
.invoice_form /deep/.el-form-item__label {
    font-size: 13px;
}
.sum_type /deep/.el-input__inner {
    padding: 0 8px;
}
.sum_type {
    display: inline-block;
    width: 70px;
    text-align: center;
}
.invoice_thumbnail div {
    width: 48%;
    height: 60px;
}
.card_img {
    height: 150px;
}
.image {
    max-height: 100%;
}
.unfold {
    text-align: right;
    font-size: 13px;
    color: #409eff;
    cursor: pointer;
    line-height: 1;
}
.mini_title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;
}
.card_ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1040px;
}
.card_ul li {
    width: 160px;
    margin-left: 20px;
}
.center {
    text-align: center;
    margin: 0 0 10px;
}
.main {
    display: flex;
    width: 100%;
    min-height: calc(100% - 120px);
}
.public_box1 {
    position: absolute;
    top: 0.2rem;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    overflow: auto;
}
.main_left {
    width: 220px;
    min-width: 150px;
    padding: 0 5px 5px 0;
    position: relative;
    border-right: 1px solid #ccc;
}
.main_right {
    flex: 1;
    padding: 0 0 5px 18px;
    position: relative;
}
.main_right > p {
    user-select: none;
}
.public_box {
    margin-top: 0.3rem;
    border-radius: 0.1rem;
}
.dialog_title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #000000;
}
.dialog_title div {
    width: 50%;
    text-align: center;
}

.search_input .el-input {
    width: 300px;
    margin: 0 20px 0 10px;
}
.search_input .el-input .el-input__inner {
    padding-right: 25px;
}
.search_input {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0.19rem;
}
.demo_num {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #666;
}
.search_box {
    margin: 0;
}
.search_box .search_input .deduction_ratio {
    width: 60px;
    margin-right: 0;
}
.search_box .search_input .payment_amount {
    width: 180px;
}
.classification_inline {
    margin-bottom: 30px;
}
.classification_inline .inline_txt {
    color: red;
    font-size: 12px;
    padding-left: 66px;
}
.classification_inline .el-autocomplete {
    width: 80%;
}
.gauge_outfit {
    margin-left: 21px !important;
}
.fold_btn {
    font-size: 22px;
    position: relative;
    top: 3px;
    cursor: pointer;
    margin-left: 3px;
    color: #409eff;
}
.dialog_adjustMent .el-row {
    margin-bottom: 20px;
    font-size: 18px;
    color: #000000;
}
.moneySuccess {
    color: #409eff;
}
.moneyError {
    color: #ff4040;
}
.reduceIcon {
    font-size: 85px;
}
.dialog_bottom {
    border-top: 1px solid #828080;
    padding-top: 20px;
}
.dialog_bottom .el-row:last-child .el-col:first-child {
    padding-top: 8px;
}
.dialog_bottom .el-row:last-child {
    position: relative;
    margin-top: 30px;
    line-height: 50px;
}
.dialog_bottom .el-row .reduceIcon {
    position: absolute;
    top: 1px;
    right: 6%;
}
.dialog_bottom .el-row .reduceMoney {
    font-size: 36px;
    font-weight: 900;
}
.dialog_bottom .el-row .fontBold {
    font-weight: 600;
}
.risk_warning {
    border-top: 1px solid #828080;
    padding-top: 20px;
    padding-left: 140px;
    position: relative;
}
.risk_warning .risk_tips {
    position: absolute;
    left: 3px;
    top: 40%;
    font-size: 28px;
    font-weight: 600;
    color: red;
}
.risk_warning .el-col {
    /* border: 1px solid red; */
    color: red;
}
</style>
