<template>
    <div class="invoice-content">
        <div class="align-title autoC">图像查重平台</div>
        <div class="align-inputs autoC">
            <i class="input-icon el-icon-search"></i>
            <el-input class="align-input" v-model="caseNo" placeholder="请输入案件号" @keyup.enter.native="invoiceSearch"></el-input>
            <span class="input-btn" @click="invoiceSearch">查验</span>
        </div>
        <div class="align-center" v-loading="searchLoading">
            <template v-if="searchData.length > 0">
                <ul v-if="menuShow" :class="searchData.length < 6 ? 'small_amount clearfix' : 'large autoC clearfix'">
                    <li v-for="(item, index) in searchData" :key="index" @click="invoiceDetail(item)">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix">
                                <p style="margin-bottom: 5px">风险案件号：</p>
                                <p style="margin-bottom: 8px">{{ item ? item.caseNo : '' }}</p>
                                <p style="margin-bottom: 5px">保单号：</p>
                                <p style="margin-bottom: 8px">{{ item.targetInvoiceList && item.targetInvoiceList[0] ? item.targetInvoiceList[0].policyNo : '' }}</p>
                                <p style="margin-bottom: 5px">出险时间：</p>
                                <p style="margin-bottom: 8px">{{ item.targetInvoiceList && item.targetInvoiceList[0] ? item.targetInvoiceList[0].accidentTime : '' }}</p>
                            </div>
                            <div class="card_content" @click="invoiceDetail(item)">
                                <div class="caseInvoice">
                                    <p>重复图像数：{{ item.caseInvoiceList ? item.caseInvoiceList.length : '' }}</p>
                                </div>
                            </div>
                        </el-card>
                    </li>
                </ul>
                <ul v-else :class="searchData.length < 6 ? 'small_amount clearfix' : 'menuFalseLarge autoC clearfix'">
                    <li v-for="(item, index) in searchData" :key="index" @click="invoiceDetail(item)">
                        <el-card class="box-card">
                            <div slot="header" class="clearfix">
                                <p style="margin-bottom: 5px">风险案件号：</p>
                                <p style="margin-bottom: 8px">{{ item ? item.caseNo : '' }}</p>
                                <p style="margin-bottom: 5px">保单号：</p>
                                <p style="margin-bottom: 8px">{{ item.targetInvoiceList && item.targetInvoiceList[0] ? item.targetInvoiceList[0].policyNo : '' }}</p>
                                <p style="margin-bottom: 5px">出险时间：</p>
                                <p style="margin-bottom: 8px">{{ item.targetInvoiceList && item.targetInvoiceList[0] ? item.targetInvoiceList[0].accidentTime : '' }}</p>
                            </div>
                            <div class="card_content" @click="invoiceDetail(item)">
                                <div class="caseInvoice">
                                    <p>重复图像数：{{ item.caseInvoiceList ? item.caseInvoiceList.length : '' }}</p>
                                </div>
                            </div>
                        </el-card>
                    </li>
                </ul>
            </template>
            <el-empty :description="caseNo ? descriptionTxt : '暂无数据'" v-if="searchData.length == 0"></el-empty>
        </div>
        <!-- 发票图片查看 弹窗 -->
        <el-dialog class="popup_invoice" center append-to-body :visible.sync="dialogVisible" width="90%" :before-close="closeDIalog">
            <div class="dialog_invoice clearfix">
                <div class="dialog_left fl">
                    <p class="dialog_title" @click="copyTxt(caseNo)">
                        查询案件号：
                        <span v-if="caseNo && caseNo.length > 40">{{ caseNo.slice(0, 40) + '...' }}</span>
                        <span v-else-if="caseNo">{{ caseNo }}</span>
                    </p>
                    <p style="margin-bottom: 10px" @click="copyTxt(imgListData.left.imgUrlList[0].policyNo)">保单号：{{ imgListData.left.imgUrlList && imgListData.left.imgUrlList[0] ? imgListData.left.imgUrlList[0].policyNo : '' }}</p>
                    <p style="margin-bottom: 10px">出险时间：{{ imgListData.left.imgUrlList && imgListData.left.imgUrlList[0] ? imgListData.left.imgUrlList[0].accidentTime : '' }}</p>
                    <div class="invoice_carousel">
                        <p style="margin-bottom: 10px" v-if="0">( {{ imgListData.left.imgActiveIndex + 1 }} / {{ invoiceData.caseInvoiceList ? invoiceData.caseInvoiceList.length : '' }} )</p>
                        <img-deal ref="imgDeal" :url="previewCaseInvoiceImg"></img-deal>
                    </div>
                    <div class="invoice_content">
                        <div class="preview-box" v-if="imgListData.left.imgUrlList.length > 0">
                            <i class="left-btn el-icon-arrow-left" @click="imgLeft('left')"></i>
                            <div class="img_list">
                                <ul class="img_ul">
                                    <template v-for="(item, i) in imgListData.left.imgUrlList">
                                        <li class="Img_li" :style="leftImgStyle" :key="i" @click="switchPreviewImg(item, i, 'left')">
                                            <img :class="i === imgListData.left.imgActiveIndex ? 'img_activeBorder' : ''" :src="item.imageUrl" />
                                        </li>
                                    </template>
                                </ul>
                            </div>
                            <i class="right-btn el-icon-arrow-right" @click="imgRight('left')"></i>
                        </div>
                    </div>
                </div>
                <div class="dialog_right fr">
                    <p class="dialog_title" @click="copyTxt(invoiceData.caseNo)">
                        风险案件号：
                        <span v-if="invoiceData.caseNo && invoiceData.caseNo.length > 40">{{ invoiceData.caseNo.slice(0, 40) + '...' }}</span>
                        <span v-else>{{ invoiceData ? invoiceData.caseNo : '' }}</span>
                    </p>
                    <p style="margin-bottom: 10px" @click="copyTxt(imgListData.right.imgUrlList[0].policyNo)">保单号：{{ imgListData.right.imgUrlList && imgListData.right.imgUrlList[0] ? imgListData.right.imgUrlList[0].policyNo : '' }}</p>
                    <p style="margin-bottom: 10px">出险时间：{{ imgListData.right.imgUrlList && imgListData.right.imgUrlList[0] ? imgListData.right.imgUrlList[0].accidentTime : '' }}</p>

                    <div class="invoice_carousel">
                        <p style="margin-bottom: 10px" v-if="0">( {{ imgListData.right.imgActiveIndex + 1 }} / {{ invoiceData.targetInvoiceList ? invoiceData.targetInvoiceList.length : '' }} )</p>
                        <img-deal ref="imgDeal" :url="previewTargetInvoiceImg"></img-deal>
                    </div>
                    <div class="invoice_content">
                        <div class="preview-box" v-if="imgListData.right.imgUrlList.length > 0">
                            <i class="left-btn el-icon-arrow-left" @click="imgLeft('left')"></i>
                            <div class="img_list">
                                <ul class="img_ul">
                                    <template v-for="(item, i) in imgListData.right.imgUrlList">
                                        <li class="Img_li" :style="leftImgStyle" :key="i + 100" @click="switchPreviewImg(item, i, 'left')">
                                            <img :class="i === imgListData.left.imgActiveIndex ? 'img_activeBorder' : ''" :src="item.imageUrl" />
                                        </li>
                                    </template>
                                </ul>
                            </div>
                            <i class="right-btn el-icon-arrow-right" @click="imgRight('left')"></i>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <div class="footer">Copyright @2023 北京宏瓴科技发展有限公司</div>
        <!-- <img ref="imgsf" @load="imgOload(true)" @error="imgOload(false)" :src="dataImgUrl" style="position: fixed; visibility: hidden; top: 0" /> -->
    </div>
</template>
<script>
import ImgDeal from '@/components/ImgDeal.vue';
export default {
    components: {
        ImgDeal
    },
    data() {
        return {
            searchLoading: false,
            dialogVisible: false,
            // caseNo: '812020080703202304091000114',
            caseNo: '6020200807032022042276254',
            dataImgUrl: '',
            imgLoad: false,
            searchObj: {},
            searchData: [],
            invoiceData: {},
            descriptionTxt: '暂无数据',
            caseInvoiceCurrentIndex: 1,
            targetInvoiceCurrentIndex: 1,
            imgUrlList: [],
            previewCaseInvoiceImg: '',
            previewTargetInvoiceImg: '',
            imgActiveIndex: 0, // 当前移动图片的索引值
            imgDistance: 0, // 移动的距离
            allDistance: 0, // 总移动距离
            imgListData: {
                left: {
                    imgUrlList: [],
                    imgActiveIndex: 0, // 当前移动图片的索引值
                    imgDistance: 0, // 移动的距离
                    allDistance: 0 // 总移动距离
                },
                right: {
                    imgUrlList: [],
                    imgActiveIndex: 0, // 当前移动图片的索引值
                    imgDistance: 0, // 移动的距离
                    allDistance: 0 // 总移动距离
                }
            },
            menuShow: true
        };
    },
    computed: {
        leftImgStyle() {
            return {
                transform: `translate3d(${this.imgListData.left.imgDistance}px, 0, 0)` // 计算移动的距离(x,y,z)
            };
        },
        rightImgStyle() {
            return {
                transform: `translate3d(${this.imgListData.right.imgDistance}px, 0, 0)` // 计算移动的距离(x,y,z)
            };
        }
    },
    mounted() {
        // { token: 'E7IpJc*6(=}<' },
        // this.caseNo && this.invoiceSearch()
        if (localStorage.getItem('AUTH_TOKEN') == 'E7IpJc*6(=}<') {
            this.menuShow = false;
            document.getElementsByClassName('fold_icon')[0].style.display = 'none';
            document.getElementsByClassName('header_right')[0].style.display = 'none';
            document.getElementsByClassName('header')[0].style.position = 'relative';
            document.getElementsByClassName('header')[0].style.zIndex = 9;
        }
    },
    methods: {
        invoiceSearch() {
            this.searchObj = {};
            this.searchData = [];
            this.searchLoading = this.caseNo;
            this.caseNo &&
                this.instance.post('/case/invoiceDuplicateCheck', { caseNo: this.caseNo }).then((res) => {
                    this.searchLoading = false;
                    if (res.data.code == 1) {
                        this.searchData = res.data.data;
                        if (res.data.data.length < 1) {
                            this.descriptionTxt = '暂未发现图像异常案件';
                        }
                        this.$message.success(res.data.message);
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        invoiceDetail(item) {
            this.dialogVisible = true;
            this.invoiceData = item;
            if (this.invoiceData.caseInvoiceList.length > 0) {
                this.imgListData.left.imgUrlList = this.invoiceData.caseInvoiceList;
                this.previewCaseInvoiceImg = this.invoiceData.caseInvoiceList[0].imageUrl;
            }
            if (this.invoiceData.targetInvoiceList.length > 0) {
                this.imgListData.right.imgUrlList = this.invoiceData.targetInvoiceList;
                this.previewTargetInvoiceImg = this.invoiceData.targetInvoiceList[0].imageUrl;
            }
        },
        imgOload(loadFlag) {
            this.imgLoad = loadFlag;
        },
        copyTxt(context) {
            let oInput = document.createElement('input');
            oInput.value = context;
            document.body.appendChild(oInput);
            oInput.select();
            document.execCommand('Copy');
            oInput.remove();
            this.$message.success('复制成功');
        },
        leftCarouselChange(val) {
            this.caseInvoiceCurrentIndex = val + 1;
        },
        rightCarouselChange(val) {
            this.targetInvoiceCurrentIndex = val + 1;
        },
        imgLeft(previewType) {
            if (this.imgListData[previewType].imgActiveIndex > 0) {
                this.imgListData[previewType].imgActiveIndex--;
            } else {
                this.imgListData[previewType].imgActiveIndex = 0;
            }
            if (this.imgListData[previewType].imgActiveIndex >= 6) {
                var index = 0;
                const temp = window.setInterval(() => {
                    // 利用定时器实现图片左右移动的动画效果
                    if (index < 45) {
                        this.imgListData[previewType].imgDistance += 2; // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
                        index++;
                        return;
                    } else {
                        window.clearInterval(temp); // 移动完清除定时器
                    }
                }, 10);
            }

            // 设置预览图片
            // if (previewType == 'left') {
            //     this.previewCaseInvoiceImg = this.imgListData[previewType].imgUrlList[this.imgListData[previewType].imgActiveIndex].imageUrl
            // } else {
            //     this.previewTargetInvoiceImg = this.imgListData[previewType].imgUrlList[this.imgListData[previewType].imgActiveIndex].imageUrl
            // }
            this.previewCaseInvoiceImg = this.imgListData['left'].imgUrlList[this.imgListData['left'].imgActiveIndex].imageUrl;
            this.previewTargetInvoiceImg = this.imgListData['right'].imgUrlList[this.imgListData['left'].imgActiveIndex].imageUrl;
        },
        imgRight(previewType) {
            if (this.imgListData[previewType].imgActiveIndex < this.imgListData[previewType].imgUrlList.length - 1) {
                this.imgListData[previewType].imgActiveIndex++;
                if (this.imgListData[previewType].imgActiveIndex >= 7 && this.imgListData[previewType].imgActiveIndex <= this.imgListData[previewType].imgUrlList.length - 1) {
                    this.imgListData[previewType].allDistance = -90 * (this.imgListData[previewType].imgActiveIndex - 4);
                    var index = 0;
                    const temp = window.setInterval(() => {
                        if (index < 45) {
                            this.imgListData[previewType].imgDistance -= 2; // 每次向右移动的距离
                            index++;
                            return;
                        } else {
                            window.clearInterval(temp);
                        }
                    }, 10);
                }
            } else {
                // 到达最后一张图片，再点击跳转回第一张
                this.imgListData[previewType].imgActiveIndex = 0;
                var index = 0;
                var distanceNum = Math.abs(JSON.parse(JSON.stringify(this.imgListData[previewType].imgDistance))) / 4;
                const temp = window.setInterval(() => {
                    // 利用定时器实现图片左右移动的动画效果
                    if (index < distanceNum) {
                        // 取绝对值再除
                        this.imgListData[previewType].imgDistance += 4; // 每次向左移动的距离 (移动总距离为33*this.imgDistance)
                        index++;
                        return;
                    } else {
                        window.clearInterval(temp); // 移动完清除定时器
                    }
                }, 1);
            }

            // 设置预览图片
            // if (previewType == 'left') {
            //     this.previewCaseInvoiceImg = this.imgListData[previewType].imgUrlList[this.imgListData[previewType].imgActiveIndex].imageUrl
            // } else {
            //     this.previewTargetInvoiceImg = this.imgListData[previewType].imgUrlList[this.imgListData[previewType].imgActiveIndex].imageUrl
            // }
            this.previewCaseInvoiceImg = this.imgListData['left'].imgUrlList[this.imgListData['left'].imgActiveIndex].imageUrl;
            this.previewTargetInvoiceImg = this.imgListData['right'].imgUrlList[this.imgListData['left'].imgActiveIndex].imageUrl;
        },
        switchPreviewImg(item, index, switchType) {
            this.imgListData['left'].imgActiveIndex = index;
            this.imgListData['right'].imgActiveIndex = index;
            // if (switchType == 'left') {
            //     this.previewCaseInvoiceImg = item.imageUrl
            // } else {
            //     this.previewTargetInvoiceImg = item.imageUrl
            // }
            this.previewCaseInvoiceImg = this.imgListData['left'].imgUrlList[this.imgListData['left'].imgActiveIndex].imageUrl;
            this.previewTargetInvoiceImg = this.imgListData['right'].imgUrlList[this.imgListData['right'].imgActiveIndex].imageUrl;
        },
        closeDIalog() {
            this.dialogVisible = false;
            this.imgListData = {
                left: {
                    imgUrlList: [],
                    imgActiveIndex: 0, // 当前移动图片的索引值
                    imgDistance: 0, // 移动的距离
                    allDistance: 0 // 总移动距离
                },
                right: {
                    imgUrlList: [],
                    imgActiveIndex: 0, // 当前移动图片的索引值
                    imgDistance: 0, // 移动的距离
                    allDistance: 0 // 总移动距离
                }
            };
        }
    }
};
</script>
<style scoped>
@import '../../assets/css/table_public.css';
body {
    font-family: 'Trebuchet MS', sans-serif;
    margin: 50px;
    background: #fff;
}
.invoice-content {
    width: 98%;
    height: 98%;
    padding-top: 55px;
    position: absolute;
    top: 0px;
    background: #fff;
}
.logo {
    width: 100px;
    margin-bottom: 20px;
}
.align-title {
    margin-bottom: 20px;
    position: relative;
    width: 160px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}
.align-center {
    margin-top: 20px;
}
.align-center ul {
    padding-top: 5px;
    height: 485px;
    overflow-y: scroll;
}
.align-center .large {
    width: 92%;
}
.align-center .menuFalseLarge {
    width: 88%;
}
.align-center .small_amount {
    display: flex;
    justify-content: center;
}
.align-center ul li {
    width: 250px;
    height: 234px;
    cursor: pointer;
    float: left;
    margin-right: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
}
.align-center ul li:nth-child(5n) {
    margin-right: 0;
}
.align-center ul li .el-card__header {
    padding-bottom: 10px;
}
.align-center ul li:last-child {
    margin-right: 0;
}
.align-center ul li:hover {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
}
.box-card .targetInvoice {
    margin-bottom: 10px;
}
.footer {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    color: #999;
    font-size: 12px;
}
.align-input /deep/.el-input__inner {
    border-radius: 10px !important;
    padding-left: 30px;
    padding-right: 60px;
    border-color: #333;
}
.align-inputs {
    width: 700px;
    position: relative;
}
.input-icon {
    position: absolute;
    left: 9px;
    top: 11px;
    z-index: 9;
    color: #333;
}
.input-btn {
    position: absolute;
    right: 5px;
    top: 0px;
    z-index: 9;
    color: rgb(20, 171, 230);
    cursor: pointer;
    user-select: none;
    padding: 8px 5px;
}
.input-btn::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 15px;
    background: #dcdfe6;
    background: #333;
    position: absolute;
    top: 8px;
    left: -2px;
}
.popup_invoice /deep/ .el-dialog {
    margin-top: 1vh !important;
    margin-bottom: 0 !important;
}
/deep/ .el-dialog__body {
    padding-bottom: 10px !important;
}
.dialog_invoice {
    width: 100%;
    min-height: 300px;
}
.dialog_invoice /deep/ .el-carousel__container {
    height: 450px;
}
.dialog_invoice .dialog_left,
.dialog_invoice .dialog_right {
    width: 47%;
    margin-top: -25px;
}
.dialog_left {
    margin-left: 5px;
}
.dialog_right {
    margin-right: 5px;
}
.invoice_carousel {
    height: 450px;
}
.dialog_title {
    font-weight: bold;
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
}
.invoice_content {
}
/* 轮播图 */
.preview-box {
    position: relative;
    width: 107%;
    left: 50%;
    transform: translateX(-50%);
}
.img_list {
    width: 635px;
    overflow-x: hidden;
    position: relative;
    left: 51%;
    transform: translateX(-50%);
}
.img_ul {
    left: 36px;
    width: 5000%;
    overflow: hidden;
    list-style: none;
}
.Img_li {
    float: left;
    margin: 0 5px;
    cursor: pointer;
    width: 80px;
    height: 80px;
    overflow: hidden;
    border: 1px solid #ccc;
}
.Img_li img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.img_activeBorder {
    border: 3px solid #ee0f0f;
}
.li_activeBorder {
    background: #409eff;
    color: #fff;
}

.left-btn {
    font-size: 30px;
    display: inline-block;
    position: relative;
    top: 60px;
    cursor: pointer;
    z-index: 99;
}
.right-btn {
    font-size: 30px;
    display: inline-block;
    position: absolute;
    right: -8px;
    top: 60px;
    cursor: pointer;
    z-index: 99;
}
</style>