<template>
    <div class="iframe-box">
        <el-tabs class="tabs_menu" v-model="activeName">
            <el-tab-pane label="公估公司数据统计看板" name="1"></el-tab-pane>
            <el-tab-pane label="公估公司未决案件环比" name="2"></el-tab-pane>
        </el-tabs>
        <template v-if="activeName == '1'">
            <div class="zhegai"></div>
            <iframe id="iframe1" :style="'height:' + resizeHeight" v-if="iframeSrc.length >= 0" :src="iframeSrc" frameborder="0"></iframe>
        </template>
        <template v-else>
            <p class="table_title">公估公司未决案件环比</p>
            <div class="table_toolbox">
                <el-button type="primary" size="mini" :loading="downloadBtnFlag" @click="formTimeChange(true)">下 载</el-button>
                <div style="margin: 10px 0">月单位：万元</div>
            </div>
            <div class="table_parent">
                <div class="frame_tables clearfix">
                    <div class="table_card card1 fl">
                        <el-date-picker v-model="navForm.target2Time" :disabledDate="disabledDate" :clearable="false" @change="target2TimeChange" @focus="setTmpFormData" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"> </el-date-picker>
                        <el-table :data="tableData" style="width: 271px">
                            <el-table-column prop="lossadjuster" label="公估公司" width="70"> </el-table-column>
                            <el-table-column label="地址">
                                <el-table-column prop="target2Num" label="件数" width="100"> </el-table-column>
                                <el-table-column prop="target2Amount" label="金额" width="100"> </el-table-column>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="table_card card2 fl">
                        <el-date-picker v-model="navForm.target1Time" :picker-options="pickerOptions1" :clearable="false" @change="target1TimeChange" @focus="setTmpFormData" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"> </el-date-picker>
                        <el-table :data="tableData" style="width: 271px">
                            <el-table-column prop="lossadjuster" label="公估公司" width="70"> </el-table-column>
                            <el-table-column label="地址">
                                <el-table-column prop="target1Num" label="件数" width="100"> </el-table-column>
                                <el-table-column prop="target1Amount" label="金额" width="100"> </el-table-column>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="table_card card3 fl">
                        <!-- :disabled="!navForm.target1Time"/ -->
                        <el-date-picker v-model="navForm.fromTime" :picker-options="pickerOptions2" :clearable="false" @change="formTimeChange(false)" @focus="setTmpFormData" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"> </el-date-picker>
                        <el-table :data="tableData" style="width: 271px">
                            <el-table-column prop="lossadjuster" label="公估公司" width="70"> </el-table-column>
                            <el-table-column label="地址">
                                <el-table-column prop="fromNum" label="件数" width="100"> </el-table-column>
                                <el-table-column prop="fromAmount" label="金额" width="100"> </el-table-column>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="table_card card4 fl">
                        <el-table :data="tableData" style="width: 271px">
                            <el-table-column prop="lossadjuster" label="公估公司" width="70"> </el-table-column>
                            <el-table-column :label="card4Title">
                                <el-table-column prop="ringTarget2Num" label="件数" width="100"> </el-table-column>
                                <el-table-column prop="ringTarget2Amount" label="金额" width="100"> </el-table-column>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="table_card card5 fl">
                        <el-table :data="tableData" style="width: 271px">
                            <el-table-column prop="lossadjuster" label="公估公司" width="70"> </el-table-column>
                            <el-table-column :label="card5Title">
                                <el-table-column prop="ringTarget1Num" label="件数" width="100"> </el-table-column>
                                <el-table-column prop="ringTarget1Amount" label="金额" width="100"> </el-table-column>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    data() {
        return {
            downloadBtnFlag: false,
            testNum: 0,
            iframeSrc: '',
            resizeHeight: '653',
            activeName: '1',
            navForm: {
                target2Time: '',
                target1Time: '',
                fromTime: ''
            },
            tableData: [],
            card4Title: '环比 -- 变化情况',
            card5Title: '环比 -- 变化情况',
            tmpFormData: null,
            pickerOptions1: {},
            pickerOptions2: {},
            yesterday: null,
            beforeYesterday: null
        };
    },
    mounted() {
        this.getDashboard();
        this.resizeHeight = window.innerHeight - 10 + 'px';
        window.onresize = () => {
            this.resizeHeight = window.innerHeight - 10 + 'px';
        };

        // 昨天
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        this.yesterday = yesterday;
        // 前天
        const beforeYesterday = new Date();
        beforeYesterday.setDate(beforeYesterday.getDate() - 2);
        this.beforeYesterday = beforeYesterday;
        // 上个月月末
        let lastDayDate = this.getTimeStamp(this.getLastDayOfPreviousMonth());

        this.navForm.target2Time = this.getLastDayOfPreviousMonth();
        this.navForm.target1Time = this.formatDate(beforeYesterday);
        this.navForm.fromTime = this.formatDate(yesterday);

        // this.navForm.target2Time = '2022-06-30';
        // this.navForm.target1Time = '2022-07-08';
        // this.navForm.fromTime = '2022-07-09';
        this.card4Title = `环比 ${this.navForm.target2Time ? this.navForm.target2Time : '--'} 变化情况`;
        this.card5Title = `环比 ${this.navForm.target1Time ? this.navForm.target1Time : '--'} 变化情况`;

        // 第二列时间可选范围
        this.pickerOptions1.disabledDate = (time) => {
            const one = 24 * 3600 * 1000; //  1天时间间隔（毫秒数）
            return time.getTime() < this.getTimeStamp(this.navForm.target2Time) + one || time.getTime() > this.beforeYesterday;
        };
        // 第三列时间可选范围
        this.pickerOptions2.disabledDate = (time) => {
            const one = 24 * 3600 * 1000; //  1天时间间隔（毫秒数）
            return time.getTime() < this.getTimeStamp(this.navForm.target1Time) + one || time.getTime() > this.yesterday;
        };

        this.formTimeChange();
        this.setTmpFormData();
    },
    methods: {
        getDataList() {
            this.instance.post('/lossadjuster/pendingCaseStatistics', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.tableData = data.data;
                }
            });
        },
        downloadFile() {
            this.downloadBtnFlag = true;
            this.instance.post('/lossadjuster/pendingCaseDownload', this.navForm, { responseType: 'blob' }).then((res) => {
                this.downloadBtnFlag = false;
                let url = window.URL.createObjectURL(new Blob([res.data]));
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', decodeURIComponent(res.headers.filename)); //指定下载后的文件名，防跳转
                document.body.appendChild(link);
                link.click();
            });
        },
        getTimeStamp(timeStr) {
            return new Date(timeStr.split('-')[0], timeStr.split('-')[1] - 1, timeStr.split('-')[2]).getTime();
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        getYearMonth(time) {
            // 创建一个新的Date对象，它表示当前的日期和时间
            var currentDate = time ? time : new Date();

            // 获取年份
            var year = currentDate.getFullYear();

            // 获取月份（注意：月份是从0开始的，所以我们需要加1）
            var month = currentDate.getMonth() + 1;

            // 如果月份或日期小于10，我们可能希望在它前面加0
            month = month < 10 ? '0' + month : month;

            return [year, month];
        },
        getLastDayOfPreviousMonth() {
            // 获取当前日期
            let today = new Date();

            // 设置日期为上个月的第一天
            today.setMonth(today.getMonth() - 1);
            today.setDate(1);

            // 获取上个月的天数
            let daysInPreviousMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();

            // 设置日期为上个月的最后一天
            today.setDate(daysInPreviousMonth);

            // 格式化日期为 YYYY-MM-DD 格式
            let year = today.getFullYear();
            let month = String(today.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以需要+1，并用0填充到2位
            let day = String(today.getDate()).padStart(2, '0'); // 用0填充到2位
            let formattedDate = `${year}-${month}-${day}`;

            return formattedDate;
        },
        disabledDate(time) {
            // 获取当前时间所在月份的最后一天
            const lastDayOfMonth = new Date(time.getFullYear(), time.getMonth() + 1, 0).getDate();

            // 获取当前选中日期的日期部分
            const currentDay = time.getDate();

            // 如果当前日期不是该月的最后一天，则禁用它
            return currentDay !== lastDayOfMonth;
        },
        target2TimeChange(val) {
            if (val) {
                const date = new Date(val);
                date.setDate(28);
                date.setMonth(date.getMonth() + 1);
                // 日期设置为0号, 0表示1号的前一天
                this.navForm.target2Time = this.formatDate(new Date(date.setDate(0)));

                if (this.tmpFormData.target2Time != this.navForm.target2Time) {
                    this.navForm.target1Time = '';
                    this.navForm.fromTime = '';
                }
                this.pickerOptions1.disabledDate = (time) => {
                    // 月末最后一天+1天
                    var timestamp = this.getTimeStamp(this.navForm.target2Time); // 例如：2022-09-20T00:00:00Z 的时间戳
                    var oneDayInMilliseconds = 24 * 60 * 60 * 1000;
                    var newTimestamp = timestamp + oneDayInMilliseconds;
                    return time.getTime() < newTimestamp || time.getTime() > this.beforeYesterday;
                };
                this.formTimeChange();
                this.card4Title = `环比 ${this.navForm.target2Time ? this.navForm.target2Time : '--'} 变化情况`;
            }
        },
        target1TimeChange(val) {
            if (val) {
                if (this.tmpFormData.target1Time != this.navForm.target1Time) {
                    this.navForm.fromTime = '';
                }
                this.pickerOptions2.disabledDate = (time) => {
                    // 月末最后一天+1天
                    var timestamp = this.getTimeStamp(this.navForm.target1Time); // 例如：2022-09-20T00:00:00Z 的时间戳
                    var oneDayInMilliseconds = 24 * 60 * 60 * 1000;
                    var newTimestamp = timestamp + oneDayInMilliseconds;
                    // const one = 24 * 3600 * 1000; //  1天时间间隔（毫秒数）
                    return time.getTime() < newTimestamp || time.getTime() > this.yesterday;
                };
                this.formTimeChange();
            }
        },
        formTimeChange(downloadFlag) {
            if (!this.navForm.target2Time) {
                this.$message.error('请选择第一个日期！');
                return;
            }
            // if (!this.navForm.target1Time) {
            //     this.$message.error('请选择第二个日期！');
            //     return;
            // }
            // if (!this.navForm.fromTime) {
            //     this.$message.error('请选择第三个日期！');
            //     return;
            // }
            if (downloadFlag) {
                this.downloadFile();
            } else {
                this.card5Title = `环比 ${this.navForm.target1Time ? this.navForm.target1Time : '--'} 变化情况`;
                this.getDataList();
            }
        },
        setTmpFormData() {
            this.tmpFormData = JSON.parse(JSON.stringify(this.navForm));
        },
        testIframe() {
            this.testNum++;
            if (this.testNum > 5) {
                this.iframeSrc = 'http://jiaanantest.platform.sigreal.cn/staging/workManage?t=123';
                setTimeout(() => {
                    const iframe = document.getElementById('iframe1');
                    const iframeWindow = iframe.contentWindow;
                    const iframeDocument = iframeWindow.document;
                    const element = iframeDocument.getElementById('pageTitle');
                    element.innerHTML = 'Hello World!';
                    element.style.color = 'red';
                }, 5000);
            }
        },
        getDashboard() {
            this.instance.get('/metabase/getDashboard/5', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    let dataUrl = data.data;
                    // 使用正则表达式来匹配 'titled=true'（包括可能的前导和后继的 '&'）
                    let newUrl = dataUrl.replace(/(?:&|^)titled=true(?=&|$)/, '&titled=false');
                    this.iframeSrc = newUrl;
                }
            });
        }
    }
};
</script>
<style lang="less" scope>
.iframe-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background: #fff;
}
.tabs_menu {
    padding: 0 10px;
}

.table_title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-top: 30px;
}
.table_toolbox {
    width: 1082px;
    text-align: right;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding-right: 10px;
    margin-top: 30px;
}
.table_parent {
    width: 1082px;
    height: 405px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    .frame_tables {
        width: 1152px;
        height: 380px;
        .table_card {
            position: relative;
            .el-date-editor {
                position: absolute;
                left: 14px;
                top: 5px;
                z-index: 5;
                width: 63%;
            }
        }
        .card1 {
            width: 271px;
            overflow: hidden;
            .el-date-editor {
                position: absolute;
                left: 85px;
                top: 5px;
                z-index: 5;
                width: 63%;
            }
        }
        .card2 {
            width: 271px;
            overflow: hidden;
            .el-table {
                margin-left: -70px;
            }
        }

        .card3 {
            width: 271px;
            overflow: hidden;
            position: absolute;
            left: 471px;
            .el-table {
                margin-left: -70px;
            }
        }
        .card4 {
            width: 271px;
            overflow: hidden;
            position: absolute;
            left: 671px;
            .el-table {
                margin-left: -70px;
            }
        }
        .card5 {
            width: 271px;
            overflow: hidden;
            position: absolute;
            left: 871px;
            .el-table {
                margin-left: -70px;
            }
        }
    }
}

#iframe1 {
    width: 100%;
    height: 780px;
}
.height_653 {
    height: 653px;
}
</style>