<template>
    <div class="case_log case_card">
        <div class="card_title clearfix">
            <div class="fl">案件日志</div>
            <div class="title_right fr" v-if="0">
                <i v-show="!hidden" class="el-icon-s-fold" @click="handleFoldHidden(true)"></i>
                <i v-show="hidden" class="el-icon-s-unfold" @click="handleFoldHidden(false)"></i>
            </div>
        </div>
        <!-- card_content -->
        <div class="card_timeline">
            <template v-if="caseDetailData">
                <el-timeline>
                    <el-timeline-item v-for="(activity, index) in caseDetailData.step" :key="index">
                        <div :class="{ active_timeline: 1, errorBgc: !activity.approvalFlag && String(activity.approvalFlag) != 'null' }">{{ activity.currentCaseStepDesc }}</div>
                        <div class="timeline_content">
                            <div v-if="activity.createTime">{{ activity.createTime }}</div>
                            <div>{{ activity.currentCaseStep == 500 ? '主律师：' : '处理人：' }}{{ activity.currentHandler }}</div>
                            <div v-if="!activity.approvalFlag && String(activity.approvalFlag) != 'null'">退回</div>
                            <div class="log_remark" v-if="activity.remark">
                                <div :class="{ hideRemark: activity.remark.length > 150 && !activity.showFlag, showRemark: 1 }">
                                    <span>备注：</span>
                                    <span style="word-wrap: break-word">{{ activity.remark }}</span>
                                    <div class="font_box"></div>
                                    <el-button class="expand_btn" type="text" v-if="activity.remark.length > 150" @click="expandBtn(activity, index)">{{ activity.showFlag ? '收起' : '展开' }}</el-button>
                                </div>
                            </div>
                        </div>
                    </el-timeline-item>
                </el-timeline>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CaseLog',
    props: {
        caseDetailData: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            hidden: true,
            impairmentData: {
                caseLossAmount: null,
                caseLossFormula: null
            },
            activities: [
                {
                    content: '活动按期开始',
                    timestamp: '2018-04-15'
                },
                {
                    content: '通过审核',
                    timestamp: '2018-04-13'
                },
                {
                    content: '创建成功',
                    timestamp: '2018-04-11'
                }
            ]
        };
    },
    mounted() {
        this.$nextTick(() => {
            let logEl = document.querySelector('.card_timeline');
            logEl.style.height = window.innerHeight - 200 + 'px';
        });
    },
    methods: {
        handleFoldHidden(hide) {
            this.$emit('hiddenCaseLog');
        },
        expandBtn(item, index) {
            let newItem = JSON.parse(JSON.stringify(item));
            newItem.showFlag = !newItem.showFlag;
            this.$set(this.caseDetailData.step, index, newItem);
        }
    }
};
</script>
<style scoped lang="less">
/* @import '../../assets/css/public.css'; */
@import '../../assets/css/caseCard.css';
.card_timeline .case_handler {
    margin-right: 15px;
}
.card_timeline .case_status {
    color: #005bab;
    margin-left: 20px;
}
.case_txt {
    margin-top: 7px;
    font-size: 12px;
}
.case_log {
    padding-top: 10px;
    height: 104%;
}
.card_timeline {
    width: 100%;
    // height: 500px;
    overflow-y: scroll;
    padding: 0 10px;
    margin-top: 16px;
    .active_timeline {
        padding: 3px 8px;
        background: #409eff;
        border-radius: 5px;
        color: #fff;
        margin-top: 8px;
        margin-bottom: 10px;
    }
    .errorBgc {
        background: #fd4747 !important;
    }
    .timeline_content {
        background: #fff;
        padding: 5px 10px;
        border-radius: 4px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .log_remark {
        position: relative;
        .expand_btn {
            position: absolute;
            bottom: 3px;
            right: 0;
            padding: 0;
            padding-left: 5px;
        }
        .hideRemark {
            height: 40px;
            overflow: hidden;
            .font_box {
                width: 36px;
                height: 16px;
                position: absolute;
                bottom: 3px;
                right: 0;
                background: #f2f8fc;
            }
        }
    }
}
.card_timeline .el-timeline /deep/.el-timeline-item__tail {
    border-color: #409eff;
}
.card_timeline .el-timeline /deep/ .el-timeline-item__node {
    background: #409eff;
}
/* is-top */
.card_timeline .el-timeline /deep/.el-timeline-item__timestamp {
    /* color: #005bab; */
}
.title_right {
    padding: 3px;
    margin-right: 5px;
    cursor: pointer;
}
.case_card {
    background: none;
}
</style>