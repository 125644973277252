<template>
  <div class="module_wrap box_border">
  	<div class="box_title">案件风险均值TOP5省份统计</div>
	<div class="times_top">
		<template v-if="topCities.length>0">
			<ul>
				<li>
					<b>{{topCities[3].provinceName}}</b>
					<div>
						<span>{{topCities[3].avgRiskScore||0}}</span>
						<!-- <span class="unit">次</span> -->
					</div>
					
				</li>
				<li>
					<b>{{topCities[1].provinceName}}</b>
					<div>
						<span>{{topCities[1].avgRiskScore||0}}</span>
						<!-- <span class="unit">次</span> -->
					</div>
				</li>
				<li>
					<b>{{topCities[0].provinceName}}</b>
					<div>
						<span>{{topCities[0].avgRiskScore||0}}</span>
						<!-- <span class="unit">次</span> -->
					</div>
				</li>
				<li>
					<b>{{topCities[2].provinceName}}</b>
					<div>
						<span>{{topCities[2].avgRiskScore||0}}</span>
						<!-- <span class="unit">次</span> -->
					</div>
				</li>
				<li>
					<b>{{topCities[4].provinceName}}</b>
					<div>
						<span>{{topCities[4].avgRiskScore||0}}</span>
						<!-- <span class="unit">次</span> -->
					</div>
				</li>
			</ul>
		</template>
	</div>
  </div>
</template>

<script>
export default {
  name: 'CaseProvince',
	props: {
		nowMonth: String,
		devicePixelRatio:{
			  type:Number,
			  default:1
		}
	},
	data(){
	  return{
		topCities:[
			
		],
		allAvgDurationOfDay:'',
		newNumber:0,
	  }
	},
	mounted() {
	  this.role=localStorage.getItem("role");
	  this.getData();
	},
	methods:{
		getData(){
			this.instance({
				method:'post',
				url:'/large/screen/avgRiskOfProvinceTopFive',
				data:{},
				headers:{
					'Content-Type':"application/json;charset=UTF-8",
				}
			}).then(res=>{
				if(res.data.code==1){
					let response=res.data.data;
					// response.forEach((val,key)=>{
					// 	val.avgRiskScore=(0-val.avgRiskScore*100)*100/-100
					// })
					this.topCities=response;
				}
			});
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.module_wrap{
		width:100%;
		height: 3.3rem;
		padding: 0.15rem 0.3rem 0;
		margin-top: 0.25rem;
	}
	.score_text{
		font-size:0.18rem;
	}
	.score_text span{
		font-size:0.14rem;
	}
	.small_title{
		font-size: 0.14rem;
		font-weight: 400;
		color: #FFFFFF;
	}
	.small_title b{
		color: #2ADEEA;
	}
	.times_top{
		width: 100%;
		height:2.5rem ;
		display: flex;
		align-items: center;
		/* padding:0 0.3rem; */
		margin-top: 0.2rem;
	}
	.times_top ul{
		width: 100%;
		height: 80%;
		/* height: 1.8rem; */
		background: url(../../../../public/images/province_bg.png) no-repeat;
		background-size: 100% 100%;
		display: flex;
		justify-content: space-between;
	}
	
	.times_top li{
		width: 17%;
		/* height:80%; */
		height:0.96rem ;
		font-size: 12px;
		font-weight: 500;
		color: #30FCFF;
		text-align: center;
	}
	.times_top li:nth-child(1){
		margin-top:0.13rem;
	}
	.times_top li:nth-child(2){
		margin-top:0.04rem;
	}
	.times_top li:nth-child(3){
		margin-top:-0.1rem;
	}
	.times_top li:nth-child(4){
		margin-top:0.04rem;
	}
	.times_top li:nth-child(5){
		margin-top:0.13rem;
	}
	.times_top li b{
		overflow:hidden;
		text-overflow:ellipsis;
		-o-text-overflow:ellipsis;
		white-space:nowrap;
		width:100%;
	}
	.times_top li div{
		font-size: 0.2rem;
		margin-top: 0.6rem;
		display: block;
		font-family: Source Han Sans CN;
	}
	.times_top li .unit{
		font-size:12px;
	}
</style>
