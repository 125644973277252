<template>
    <div class="module_wrap box_border">
        <div class="box_title">案件风险值分布（分值越高，渗漏风险越大）</div>
        <div id="distribution_pie"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'RiskDistribution',
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        isCollapse() {
            setTimeout(() => {
                this.myChart.resize();
            }, 600);
        }
    },
    data() {
        return {
            topDays: [],
            newNumber: 0,
            myChart: ''
        };
    },
    mounted() {
        this.role = localStorage.getItem('role');
        this.getData();
        window.addEventListener('resize', () => {
            if (this.myChart) {
                setTimeout(() => {
                    this.myChart.resize();
                }, 500);
            }
        });
    },
    methods: {
        getData() {
            this.instance({
                method: 'post',
                url: '/large/screen/settledCaseRiskDistribution',
                data: {},
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                if (res.data.code == 1) {
                    let response = res.data.data;
                    this.loadCharts(response);
                }
            });
        },
        loadCharts(response) {
            var chartDom = document.getElementById('distribution_pie');
            if (!chartDom) {
                return;
            }
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' });
            var option = {
                tooltip: {
                    trigger: 'axis',
                    formatter: '{b}分: {c}万件'
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisLabel: {
                        interval: 9,
                        textStyle: {
                            fontSize: '0.12rem',
                            color: '#fff' //坐标值得具体的颜色
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false,
                        interval: 10
                    },
                    axisLine: {
                        show: false
                    },
                    data: response.riskScores
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        showMaxLabel: true,
                        textStyle: {
                            fontSize: '0.12rem',
                            color: '#fff' //坐标值得具体的颜色
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    }
                },
                grid: {
                    top: '30',
                    bottom: '20',
                    left: '20',
                    right: '10',
                    containLabel: true
                },
                series: [
                    {
                        data: response.nums,
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        areaStyle: {}
                    }
                ]
            };
            this.myChart.setOption(option);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    height: 3.05rem;
    padding: 0.15rem 0.3rem 0;
    /* margin-top: 0.25rem; */
}
#distribution_pie {
    width: 100%;
    height: 2.5rem;
}
</style>
