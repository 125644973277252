<template>
    <div class="wrap">
        <div class="page_title"><b>外部机构管理</b></div>
        <!--检索部分  -->
        <div class="search_box public_box">
            <el-tabs v-model="activeName" type="card" @tab-click="tabClick">
                <template v-if="isLawyer">
                    <el-tab-pane label="律师" name="1"></el-tab-pane>
                </template>
                <template v-else>
                    <el-tab-pane label="律师" name="1"></el-tab-pane>
                    <el-tab-pane label="律所" name="2"></el-tab-pane>
                    <el-tab-pane label="律师委托平台" name="3"></el-tab-pane>
                </template>
            </el-tabs>
            <template v-if="activeName == '1'">
                <el-form :inline="true" :model="querySubmitForm">
                    <el-form-item label="关键字：">
                        <el-input class="width_250" v-model="querySubmitForm.keyword" type="text" placeholder="请输入律师姓名/电话号码" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="所属律所：">
                        <el-select class="width_110" v-model="querySubmitForm.lawFirmId" clearable placeholder="请选择">
                            <el-option v-for="item in firmOptions" :key="item.id" :label="item.firmName" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所属律师平台：">
                        <el-select class="width_110" v-model="querySubmitForm.lawPlatformId" filterable clearable placeholder="请选择">
                            <el-option v-for="item in platOptions" :key="item.id" :label="item.firmName" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="状态：">
                        <el-select class="width_110" v-model="querySubmitForm.enableFlag" clearable placeholder="请选择">
                            <el-option v-for="item in enableFlagOptions" :key="item.id" :label="item.firmName" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" style="margin-left: 10px" @click="tabClick(true)">查 询</el-button>
                        <el-button type="primary" style="margin-left: 20px" v-if="lawyerMenuData[200]" @click="submitFlag = true">创 建</el-button>
                    </el-form-item>
                </el-form>
            </template>
            <template v-if="activeName == '2'">
                <el-form :inline="true" :model="queryFirmForm">
                    <el-form-item label="关键字：">
                        <el-input class="width_250" v-model="queryFirmForm.keyword" type="text" placeholder="请输入律所名称/联系人姓名" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" style="margin-left: 10px" @click="tabClick(true)">查 询</el-button>
                        <el-button type="primary" style="margin-left: 20px" v-if="lawyerMenuData[200]" @click="firmFlag = true">创 建</el-button>
                    </el-form-item>
                </el-form>
            </template>
            <template v-if="activeName == '3'">
                <el-form :inline="true" :model="queryLawPlatForm">
                    <el-form-item label="关键字：">
                        <el-input class="width_250" v-model="queryLawPlatForm.keyword" type="text" placeholder="请输入委托平台名称" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" style="margin-left: 10px" @click="tabClick(true)">查 询</el-button>
                        <el-button type="primary" style="margin-left: 20px" v-if="lawyerMenuData[200]" @click="lawPlatFlag = true">创 建</el-button>
                    </el-form-item>
                </el-form>
            </template>
        </div>
        <!-- 表格 -->
        <div class="main">
            <template v-if="activeName == '1'">
                <div class="table_box public_box">
                    <el-table :data="submitTableData" v-loading="loading">
                        <el-table-column prop="lawyerName" label="律师姓名"></el-table-column>
                        <el-table-column prop="phoneNumber" label="电话号码"></el-table-column>
                        <el-table-column prop="lawFirm" label="所属律所"></el-table-column>
                        <el-table-column prop="lawFirmContactPerson" label="律所联系人"></el-table-column>
                        <el-table-column prop="lawPlatform" label="所属律师平台" width="120"></el-table-column>
                        <el-table-column prop="lawPlatformContactPerson" label="律师平台联系人" width="130"></el-table-column>
                        <el-table-column prop="updateBy" label="维护人"></el-table-column>
                        <el-table-column prop="updateTime" label="维护时间"></el-table-column>
                        <el-table-column label="操作" width="180">
                            <template slot-scope="scope">
                                <el-button type="text" v-if="lawyerMenuData && lawyerMenuData[400]" :disabled="!scope.row.enableFlag" @click="handleClick(scope.row, 'edit')">修改</el-button>
                                <el-button type="text" v-if="lawyerMenuData && lawyerMenuData[400]" :disabled="!scope.row.enableFlag" @click="handleClick(scope.row, 'disabled')">停用</el-button>
                                <el-button type="text" v-if="lawyerMenuData && lawyerMenuData[400]" :disabled="scope.row.enableFlag" @click="handleClick(scope.row, 'enable')">启用</el-button>
                                <el-button type="text" @click="handleClick(scope.row, 'record')">修改记录</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页部分-->
                    <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="querySubmitForm.page" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="querySubmitForm.size" :total="querySubmitForm.total"> </el-pagination>
                </div>
            </template>
            <template v-if="activeName == '2'">
                <div class="table_box public_box">
                    <el-table :data="firmTableData" v-loading="loading">
                        <el-table-column prop="firmName" label="律所">
                            <template slot-scope="scope">
                                <span class="tooltip_text" @click="userDialog(scope.row, 'firm')">{{ scope.row.firmName }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="firmCode" label="律所社会信用代码"></el-table-column>
                        <el-table-column prop="contactPerson" label="律所联系人"></el-table-column>
                        <el-table-column prop="contactPhone" label="联系人电话"></el-table-column>
                        <el-table-column prop="updateBy" label="维护人" width="120"></el-table-column>
                        <el-table-column prop="updateTime" label="维护时间" width="130"></el-table-column>
                        <el-table-column label="操作" width="180" v-if="lawyerMenuData && lawyerMenuData[400]">
                            <template slot-scope="scope">
                                <el-button type="text" @click="editFirmFn(scope.row, 'edit')">修改</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页部分-->
                    <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="queryFirmForm.page" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="queryFirmForm.size" :total="queryFirmForm.total"> </el-pagination>
                </div>
            </template>
            <template v-if="activeName == '3'">
                <div class="table_box public_box">
                    <el-table :data="lawPlatTableData" v-loading="loading">
                        <el-table-column prop="firmName" label="委托平台全称">
                            <template slot-scope="scope">
                                <span class="tooltip_text" @click="userDialog(scope.row, 'plat')">{{ scope.row.firmName }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="abbreviation" label="简称"></el-table-column>
                        <el-table-column prop="firmCode" label="网址/APP名称"></el-table-column>
                        <el-table-column prop="contactPerson" label="联系人"></el-table-column>
                        <el-table-column prop="contactPhone" label="联系人电话" width="120"></el-table-column>
                        <el-table-column prop="updateBy" label="维护人"></el-table-column>
                        <el-table-column prop="updateTime" label="维护时间"></el-table-column>
                        <el-table-column label="操作" width="180" v-if="lawyerMenuData && lawyerMenuData[400]">
                            <template slot-scope="scope">
                                <el-button type="text" @click="editLawplatFormFn(scope.row, 'edit')">修改</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页部分-->
                    <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="queryLawPlatForm.page" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="queryLawPlatForm.size" :total="queryLawPlatForm.total"> </el-pagination>
                </div>
            </template>
        </div>
        <!-- 创建律师弹窗 -->
        <el-dialog title="添加律师" :visible.sync="submitFlag" center :modal-append-to-body="false" width="400px">
            <div class="dialog_content">
                <el-form :model="submitForm" :rules="submitRules" ref="submitRef" label-width="140px">
                    <el-form-item label="律师姓名：" prop="lawyerName">
                        <el-input v-model="submitForm.lawyerName" placeholder="请输入内容" maxlength="11"></el-input>
                    </el-form-item>
                    <el-form-item label="电话号码：" prop="phoneNumber">
                        <el-input v-model="submitForm.phoneNumber" placeholder="请输入内容" maxlength="11"></el-input>
                    </el-form-item>
                    <el-form-item label="所属律所：" prop="lawFirmId">
                        <el-select v-model="submitForm.lawFirmId" clearable placeholder="请选择">
                            <el-option v-for="item in firmOptions" :key="item.id" :label="item.firmName" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所属律师委托平台：" prop="lawPlatformId">
                        <el-select v-model="submitForm.lawPlatformId" clearable placeholder="请选择">
                            <el-option v-for="item in platOptions" :key="item.id" :label="item.firmName" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitFn(true)">提 交</el-button>
                <el-button @click="submitFn(false)">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 创建律所弹窗 -->
        <el-dialog title="创建律所" :visible.sync="firmFlag" center :modal-append-to-body="false" width="400px">
            <div class="dialog_content">
                <el-form :model="firmForm" :rules="firmRules" ref="firmFormRef" label-width="140px">
                    <el-form-item label="律所全称：" prop="firmName">
                        <el-input v-model="firmForm.firmName" placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="社会统一信用代码：" prop="firmCode">
                        <el-input v-model="firmForm.firmCode" placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="律所联系人：" prop="contactPerson">
                        <el-input v-model="firmForm.contactPerson" placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人电话：" prop="contactPhone">
                        <el-input v-model="firmForm.contactPhone" maxlength="11" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitFn(true)">提 交</el-button>
                <el-button @click="submitFn(false)">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 创建平台弹窗 -->
        <el-dialog title="创建委托平台" :visible.sync="lawPlatFlag" center :modal-append-to-body="false" width="400px">
            <div class="dialog_content">
                <el-form :model="lawPlatForm" :rules="lawPlatFormRules" ref="lawPlatFormRef" label-width="140px">
                    <el-form-item label="委托平台全称：" prop="firmName">
                        <el-input v-model="lawPlatForm.firmName" placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="简称：" prop="abbreviation">
                        <el-input v-model="lawPlatForm.abbreviation" placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="平台联系人：" prop="contactPerson">
                        <el-input v-model="lawPlatForm.contactPerson" placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人电话：" prop="contactPhone">
                        <el-input v-model="lawPlatForm.contactPhone" maxlength="11" placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="网址/APP名称：" prop="firmCode">
                        <el-input v-model="lawPlatForm.firmCode" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitFn(true)">提 交</el-button>
                <el-button @click="submitFn(false)">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 编辑弹窗 -->
        <el-dialog title="修改律师信息" :visible.sync="editFlag" center :modal-append-to-body="false" width="400px">
            <div class="dialog_content">
                <el-form :model="editForm" ref="editRef" label-width="140px">
                    <el-form-item label="律师姓名：" prop="lawPlatform">
                        <el-input v-model="editForm.lawyerName" placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="电话号码：" prop="lawPlatform">
                        <el-input v-model="editForm.phoneNumber" disabled placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="所属律所：" prop="lawPlatform">
                        <el-select v-model="editForm.lawFirmId" clearable placeholder="请选择">
                            <el-option v-for="item in firmOptions" :key="item.id" :label="item.firmName" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所属律师委托平台：" prop="lawPlatform">
                        <el-select v-model="editForm.lawPlatformId" clearable placeholder="请选择">
                            <el-option v-for="item in platOptions" :key="item.id" :label="item.firmName" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="修改密码：" prop="lawPlatform">
                        <el-input v-model="editForm.passWord" show-password placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="danger" v-if="lawyerMenuData && lawyerMenuData[300]" @click="delLawyer">删 除</el-button>
                <el-button type="primary" @click="editFn(true)">提 交</el-button>
                <el-button @click="editFn(false)">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog title="修改律所信息" :visible.sync="editFirmFlag" center :modal-append-to-body="false" width="400px">
            <div class="dialog_content">
                <el-form :model="editFirmForm" :rules="editFirmRules" ref="editFirmFormRef" label-width="140px">
                    <el-form-item label="律所全称：" prop="firmName">
                        <el-input v-model="editFirmForm.firmName" placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="社会统一信用代码：" prop="firmCode">
                        <el-input v-model="editFirmForm.firmCode" placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="律所联系人：" prop="contactPerson">
                        <el-input v-model="editFirmForm.contactPerson" placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人电话：" prop="contactPhone">
                        <el-input v-model="editFirmForm.contactPhone" maxlength="11" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="danger" v-if="lawyerMenuData && lawyerMenuData[300]" @click="delFirm">删 除</el-button>
                <el-button type="primary" @click="editFirmFn(null, true)">提 交</el-button>
                <el-button @click="editFirmFn(null, false)">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog title="修改委托平台" :visible.sync="editLawPlatFlag" center :modal-append-to-body="false" width="400px">
            <div class="dialog_content">
                <el-form :model="editLawPlatForm" :rules="editLawPlatFormRules" ref="editLawPlatFormRef" label-width="140px">
                    <el-form-item label="委托平台全称：" prop="firmName">
                        <el-input v-model="editLawPlatForm.firmName" placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="简称：" prop="abbreviation">
                        <el-input v-model="editLawPlatForm.abbreviation" placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="平台联系人：" prop="contactPerson">
                        <el-input v-model="editLawPlatForm.contactPerson" placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人电话：" prop="contactPhone">
                        <el-input v-model="editLawPlatForm.contactPhone" maxlength="11" placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="网址/APP名称：" prop="firmCode">
                        <el-input v-model="editLawPlatForm.firmCode" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="danger" v-if="lawyerMenuData && lawyerMenuData[300]" @click="delLawPlat">删 除</el-button>
                <el-button type="primary" @click="editLawplatFormFn(null, true)">提 交</el-button>
                <el-button @click="editLawplatFormFn(null, false)">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 停用弹窗 -->
        <el-dialog title="用户停用" :visible.sync="deactivateFlag" center :modal-append-to-body="false" width="400px">
            <div class="dialog_content">停用该律师账号后，律师将不能登录系统进行操作，是否确认停用 ！</div>
            <span slot="footer" class="dialog-footer">
                <el-button type="danger" @click="deactivateFn(true)">确 认</el-button>
                <el-button @click="deactivateFn(false)">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 修改记录弹窗 -->
        <el-dialog title="修改记录" :visible.sync="recordFlag" center :modal-append-to-body="false" width="550px">
            <div class="dialog_content">
                <el-table :data="recordTableData" v-loading="recordLoading">
                    <el-table-column prop="operateDesc" label="操作类型"></el-table-column>
                    <el-table-column prop="record" label="操作人"></el-table-column>
                    <el-table-column prop="createTime" label="操作时间" width="200"></el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination @current-change="handleRecordCurrentChange" @size-change="handleRecordSizeChange" :current-page="lawyerRecordForm.page" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="lawyerRecordForm.size" :total="lawyerRecordForm.total">
                </el-pagination>
            </div>
        </el-dialog>
        <!-- 律所/平台下律师弹窗 -->
        <el-dialog :title="userDialogTitle" :visible.sync="userFlag" center :modal-append-to-body="false" width="550px">
            <div class="dialog_content">
                <el-table :data="userTable">
                    <el-table-column prop="lawyerName" label="律师姓名"></el-table-column>
                    <el-table-column prop="phoneNumber" label="电话号码"></el-table-column>
                    <el-table-column prop="updateTime" label="创建时间" width="200"></el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination @current-change="handleUserCurrentChange" @size-change="handleUserSizeChange" :current-page="queryUserForm.page" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="queryUserForm.size" :total="queryUserForm.total"> </el-pagination>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'userLiveness',
    components: {},
    data() {
        return {
            activeName: '1',
            firmFlag: false,
            lawPlatFlag: false,
            loading: false,
            submitFlag: false,
            editFlag: false,
            deactivateFlag: false,
            recordLoading: false,
            recordFlag: false,
            editFirmFlag: false,
            editLawPlatFlag: false,
            querySubmitForm: {
                keyword: '',
                lawFirmId: '',
                lawPlatformId: '',
                enableFlag: '',
                page: 1,
                size: 10,
                total: 0
            },
            queryFirmForm: {
                keyword: '',
                page: 1,
                size: 10,
                total: 0
            },
            queryLawPlatForm: {
                keyword: '',
                page: 1,
                size: 10,
                total: 0
            },
            submitForm: {
                lawyerName: '',
                phoneNumber: '',
                lawFirmId: '',
                lawPlatformId: ''
            },
            firmForm: {
                firmName: '',
                firmCode: '',
                contactPerson: '',
                contactPhone: ''
            },
            editFirmForm: {
                firmName: '',
                firmCode: '',
                contactPerson: '',
                contactPhone: ''
            },
            lawPlatForm: {
                firmName: '',
                abbreviation: '',
                contactPerson: '',
                contactPhone: '',
                firmCode: ''
            },
            editLawPlatForm: {
                firmName: '',
                abbreviation: '',
                contactPerson: '',
                contactPhone: '',
                firmCode: ''
            },
            editForm: {
                id: '',
                lawyerName: '',
                phoneNumber: '',
                lawFirmId: '',
                lawPlatformId: '',
                passWord: ''
            },
            recordForm: {
                page: 1,
                size: 10,
                total: 0
            },
            options: [],
            enableFlagOptions: [
                {
                    id: 1,
                    firmName: '启用'
                },
                {
                    id: 0,
                    firmName: '停用'
                }
            ],
            firmOptions: [],
            platOptions: [],
            value: '',
            input: '',
            tableData: [],
            submitTableData: [],
            firmTableData: [],
            lawPlatTableData: [],
            submitRules: {
                lawyerName: [{ required: true, message: '请输入律师姓名', trigger: 'blur' }],
                phoneNumber: [{ required: true, message: '请输入电话号码', trigger: 'blur' }]
            },
            firmRules: {
                firmName: [{ required: true, message: '请输入律所全称', trigger: 'blur' }],
                contactPerson: [{ required: true, message: '请输入律所联系人', trigger: 'blur' }],
                contactPhone: [{ required: true, message: '请输入联系人电话', trigger: 'blur' }]
            },
            editFirmRules: {},
            lawPlatFormRules: {
                firmName: [{ required: true, message: '请输入委托平台全称', trigger: 'blur' }],
                contactPerson: [{ required: true, message: '请输入律所联系人', trigger: 'blur' }],
                contactPhone: [{ required: true, message: '请输入联系人电话', trigger: 'blur' }]
            },
            editLawPlatFormRules: {},
            isLawyer: false,
            lawyerMenuData: {},
            lawyerData: {},
            recordTableData: [],
            lawyerRecordForm: {
                operateId: '',
                page: 1,
                size: 10,
                total: 0
            },
            userDialogTitle: '律所旗下律师',
            userFlag: false,
            userTable: [],
            queryUserForm: {
                lawFirmId: '',
                lawPlatformId: '',
                enableFlag: 1,
                page: 1,
                size: 10,
                total: 0
            }
        };
    },
    mounted() {
        this.getHasLawyer();
        this.getLawyerMenu();
        this.tabClick();
    },
    methods: {
        getLawyerMenu() {
            this.instance.post('/lawyerInfo/getLawyerMenu', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    // FIND(100,"查询所有的权限"),
                    // FIND_CUSTOM(100,"查询自己的数据"),
                    // ADD(200,"添加数据权限"),
                    // ADD_CUSTOM(200,"添加自己部门数据权限"),
                    // DELETE(300,"删除数据的权限"),
                    // DELETE_CUSTOM(300,"删除自己数据的权限"),
                    // UPDATE(400,"修改数据权限"),
                    // UPDATE_CUSTOM(400,"修改自己数据权限"),
                    // DISTRIBUTION(500,"分配数据权限"),
                    // DISTRIBUTION_CUSTOM(500,"分配自己部门数据权限"),
                    // AUDIT(900,"审核");
                    data.data.forEach((item) => {
                        this.lawyerMenuData[item.code] = item.desc;
                    });
                }
            });
        },
        getFindFirm() {
            this.instance.post('/lawyerInfo/findFirm', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.firmOptions = data.data;
                }
            });
        },
        getFindPlatform() {
            this.instance.post('/lawyerInfo/findPlatform', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.platOptions = data.data;
                }
            });
        },
        getHasLawyer() {
            this.instance.post('/lawyerInfo/hasLawyer', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.isLawyer = data.data;
                }
            });
        },
        tabClick(queryFlag) {
            if (queryFlag) {
                this.querySubmitForm.page = 1;
            }
            switch (this.activeName) {
                case '1':
                    this.getFindFirm();
                    this.getFindPlatform();
                    this.getPageLawyer();

                    break;
                case '2':
                    this.getPageLawFirm();
                    break;

                default:
                    this.getPageLawPlatform();
                    break;
            }
        },
        handleCurrentChange(val) {
            this.querySubmitForm.page = val;
            this.tabClick();
        },
        handleSizeChange(val) {
            this.querySubmitForm.size = val;
            this.tabClick();
        },
        handleClick(rowItem, clickType) {
            switch (clickType) {
                case 'edit':
                    this.editForm = JSON.parse(JSON.stringify(rowItem));
                    this.editFlag = true;
                    break;
                case 'disabled':
                    this.deactivateFlag = true;
                    this.lawyerData = rowItem;
                    break;
                case 'enable':
                    this.enableLawyer(rowItem.id);
                    break;
                case 'record':
                    this.recordFlag = true;
                    this.lawyerRecordForm.operateId = rowItem.id;
                    this.getLawyerEditRecord();

                    break;

                default:
                    break;
            }
        },
        enableLawyer(lawyerId) {
            this.instance.post(`/lawyerInfo/enable/${lawyerId}`, {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('启用成功！');
                    this.tabClick();
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        submitFn(submitFlag) {
            switch (this.activeName) {
                case '1':
                    if (submitFlag) {
                        if (!this.submitForm.lawFirmId && !this.submitForm.lawPlatformId) {
                            this.$message.error('律所和律师委托平台至少选择一个');
                            return;
                        }
                        this.$refs.submitRef.validate((valid) => {
                            if (valid) {
                                this.addLawyer();
                            }
                        });
                    } else {
                        this.submitFlag = false;
                    }

                    break;
                case '2':
                    if (submitFlag) {
                        this.$refs.firmFormRef.validate((valid) => {
                            if (valid) {
                                this.addLawFirm();
                            }
                        });
                    } else {
                        this.firmFlag = false;
                    }

                    break;

                default:
                    if (submitFlag) {
                        this.$refs.lawPlatFormRef.validate((valid) => {
                            if (valid) {
                                this.addLawPlatform();
                            }
                        });
                    } else {
                        this.submitFlag = false;
                        this.lawPlatFlag = false;
                    }
                    break;
            }
        },
        editFn(editFlag) {
            if (editFlag) {
                if (!this.editForm.lawFirmId && !this.editForm.lawPlatformId) {
                    this.$message.error('律所和律师委托平台至少选择一个');
                    return;
                }
                this.instance.post('/lawyerInfo/editLawyer', this.editForm).then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        this.$message.success('编辑成功！');
                        this.editFlag = false;
                        this.tabClick();
                    } else {
                        this.$message.error(data.message);
                    }
                });
            } else {
                this.editFlag = false;
            }
        },
        deactivateFn(flag) {
            if (flag) {
                this.instance.post(`/lawyerInfo/forbidden/${this.lawyerData.id}`, {}).then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        this.$message.success('停用成功！');
                        this.deactivateFlag = false;
                        this.tabClick();
                    } else {
                        this.$message.error(data.message);
                    }
                });
            } else {
                this.deactivateFlag = false;
            }
        },
        getPageLawyer() {
            this.instance.post('/lawyerInfo/pageLawyer', this.querySubmitForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.submitTableData = data.data.list;
                    this.querySubmitForm.total = data.data.total;
                }
            });
        },
        addLawyer() {
            this.instance.post('/lawyerInfo/addLawyer', this.submitForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('律师创建成功！');
                    this.getPageLawyer();
                    this.$refs.submitRef.resetFields();
                    this.submitFlag = false;
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        getPageLawFirm() {
            this.instance.post('/lawyerInfo/pageLawFirm', this.queryFirmForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.firmTableData = data.data.list;
                    this.queryFirmForm.total = data.data.total;
                }
            });
        },
        addLawFirm() {
            this.instance.post('/lawyerInfo/addLawFirm', this.firmForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('律所创建成功！');
                    this.getPageLawFirm();
                    this.firmFlag = false;
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        getPageLawPlatform() {
            this.instance.post('/lawyerInfo/pageLawPlatform', this.queryLawPlatForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.lawPlatTableData = data.data.list;
                    this.queryLawPlatForm.total = data.data.total;
                }
            });
        },
        addLawPlatform() {
            this.instance.post('/lawyerInfo/addLawPlatform', this.lawPlatForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('委托平台创建成功！');
                    this.getPageLawPlatform();
                    this.lawPlatFlag = false;
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        getLawyerEditRecord() {
            this.instance.post('/lawyerInfo/lawyerRecord', this.lawyerRecordForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.recordTableData = data.data.list;
                    this.lawyerRecordForm.total = data.data.total;
                }
            });
        },
        handleRecordCurrentChange(val) {
            this.lawyerRecordForm.page = val;
            this.getLawyerEditRecord();
        },
        handleRecordSizeChange(val) {
            this.lawyerRecordForm.size = val;
            this.getLawyerEditRecord();
        },
        editFirmFn(firmItem, editType) {
            switch (String(editType)) {
                case 'true':
                    this.submitEditFirmFn();
                    break;
                case 'false':
                    this.cancelEditFirmFn();
                    break;

                default:
                    this.editFirmForm = JSON.parse(JSON.stringify(firmItem));
                    this.editFirmFlag = true;
                    break;
            }
        },
        submitEditFirmFn() {
            this.instance.post('/lawyerInfo/editLawFirm', this.editFirmForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('编辑成功！');
                    this.tabClick();
                    this.editFirmFn(null, false);
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        cancelEditFirmFn() {
            this.$refs.editFirmFormRef.resetFields();
            this.editFirmFlag = false;
        },
        editLawplatFormFn(lawplatItem, editType) {
            switch (String(editType)) {
                case 'true':
                    this.submitEditLawplatFn();
                    break;
                case 'false':
                    this.cancelLawplatFormFn();
                    break;

                default:
                    this.editLawPlatForm = JSON.parse(JSON.stringify(lawplatItem));
                    this.editLawPlatFlag = true;
                    break;
            }
        },
        submitEditLawplatFn() {
            this.instance.post('/lawyerInfo/editLawPlatform', this.editLawPlatForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('编辑成功！');
                    this.tabClick();
                    this.editLawplatFormFn(null, false);
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        cancelLawplatFormFn() {
            this.$refs.editLawPlatFormRef.resetFields();
            this.editLawPlatFlag = false;
        },
        delLawyer() {
            this.instance.post('/lawyerInfo/deleteLawyer', this.editForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('删除成功！');
                    this.editFlag = false;
                    this.tabClick();
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        delFirm() {
            this.instance.post(`/lawyerInfo/deleteLawFirm/${this.editFirmForm.id}`, {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('删除成功！');
                    this.editFirmFlag = false;
                    this.tabClick();
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        delLawPlat() {
            this.instance.post(`/lawyerInfo/deleteLawPlatform/${this.editLawPlatForm.id}`, {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('删除成功！');
                    this.editLawPlatFlag = false;
                    this.tabClick();
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        userDialog(rowItem, typeStr) {
            if (typeStr == 'firm') {
                this.userDialogTitle = '律所旗下律师';
                this.queryUserForm.lawFirmId = rowItem.id;
                this.queryUserForm.lawPlatformId = '';
            } else {
                this.userDialogTitle = '平台旗下律师';
                this.queryUserForm.lawPlatformId = rowItem.id;
                this.queryUserForm.lawFirmId = '';
            }
            this.queryUserForm.page = 1;
            this.queryUserForm.size = 10;
            this.queryUserForm.total = 0;
            this.queryUser();
        },
        queryUser() {
            this.instance.post('/lawyerInfo/pageLawyer', this.queryUserForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.userTable = data.data.list;
                    this.queryUserForm.total = data.data.total;
                    this.userFlag = true;
                }
            });
        },
        handleUserCurrentChange(val) {
            this.queryUserForm.page = val;
            this.queryUser();
        },
        handleUserSizeChange(val) {
            this.queryUserForm.size = val;
            this.queryUser();
        }
    }
};
</script>
<style lang="less">
.tooltip_text {
    cursor: pointer;
    color: #409eff;
}
</style>