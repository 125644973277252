<template>
    <div class="case_card caseInsurance">
        <div class="card_title">诉讼案件信息</div>
        <div class="card_content">
            <el-row>
                <el-col :span="8">
                    <el-row class="content_row">
                        <el-col class="col_title" :span="8">当前处理人：</el-col>
                        <el-col class="col_txt" :span="12">{{ caseDetailData && caseDetailData.currentHandler ? caseDetailData.currentHandler : '--' }}</el-col>
                    </el-row>
                </el-col>
                <el-col :span="7">
                    <el-row class="content_row">
                        <el-col class="col_title" :span="12">判决/调解：</el-col>
                        <el-col class="col_txt" :span="8">{{ caseDetailData && caseDetailData.judgmentMediation ? caseDetailData.judgmentMediation : '--' }}</el-col>
                    </el-row>
                </el-col>
                <el-col :span="7" :offset="1">
                    <el-row class="content_row">
                        <el-col class="col_title" :span="12">录入日期：</el-col>
                        <el-col class="col_txt" :span="12">{{ caseDetailData && caseDetailData.createTime ? caseDetailData.createTime : '--' }}</el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-row class="content_row">
                        <el-col class="col_title" :span="8">是否上诉：</el-col>
                        <el-col class="col_txt" :span="12">{{ caseDetailData && caseDetailData.appealFlag ? (String(caseDetailData.appealFlag) == 'true' ? '是' : '否') : '--' }}</el-col>
                    </el-row>
                </el-col>
                <el-col :span="7">
                    <el-row class="content_row">
                        <el-col class="col_title" :span="12">是否已履行支付：</el-col>
                        <el-col class="col_txt" :span="8">{{ caseDetailData && caseDetailData.performancePayment ? (String(caseDetailData.performancePayment) == 'true' ? '是' : '否') : '--' }}</el-col>
                    </el-row>
                </el-col>
                <el-col :span="7" :offset="1">
                    <el-row class="content_row">
                        <el-col class="col_title" :span="12">实际支付日期：</el-col>
                        <el-col class="col_txt" :span="12">{{ caseDetailData && caseDetailData.actualPaymentDate ? caseDetailData.actualPaymentDate : '--' }}</el-col>
                    </el-row>
                </el-col>
            </el-row>
            <div class="content_type">
                <el-tabs v-model="activeName" @tab-click="editContentFlag = false">
                    <el-tab-pane label="诉讼基本信息" name="8801"></el-tab-pane>
                    <template v-for="(instanceItem, index) in instanceDataArr">
                        <el-tab-pane :label="instanceMen[index].name" :name="String(index)" :key="index"></el-tab-pane>
                    </template>
                    <el-tab-pane label="证据材料" name="8802"></el-tab-pane>
                </el-tabs>
                <template v-if="activeName == '8801'">
                    <div class="tab_content">
                        <el-row class="content_row">
                            <el-col :span="7" :offset="1">原告：{{ caseDetailData && caseDetailData.plaintiff ? caseDetailData.plaintiff : '--' }}</el-col>
                            <el-col :span="7" :offset="1">诉讼金额：{{ caseDetailData && caseDetailData.lawsuitAmount ? caseDetailData.lawsuitAmount + ' 元' : '--' }}</el-col>
                            <el-col :span="7" :offset="1">诉讼原因：{{ caseDetailData && caseDetailData.lawsuitCause ? caseDetailData.lawsuitCause : '--' }}</el-col>
                        </el-row>
                        <el-row class="content_row">
                            <el-col :span="1" :offset="1">情况：</el-col>
                            <el-col class="situation_box" :span="22" v-if="caseDetailData && caseDetailData.situationJson && caseDetailData.situationJson.length > 0">
                                <div class="situation_item" v-for="(item, index) in caseDetailData.situationJson" :key="index">
                                    <p>
                                        {{ item.handlerTime }} <span style="margin-left: 15px">{{ item.handler }}</span>
                                    </p>
                                    <p>{{ item.situation }}</p>
                                </div>
                            </el-col>
                            <el-col :span="22" v-else> 暂无数据 </el-col>
                        </el-row>
                    </div>
                </template>
                <template v-for="(instanceItem, index) in instanceDataArr">
                    <template v-if="activeName == String(index)">
                        <div class="tab_content" :key="index">
                            <div class="btn_group" v-if="btnMenuData && btnMenuData[300]">
                                <el-button type="primary" style="visibility: hidden">批</el-button>
                                <div class="group_right" v-if="!editContentFlag">
                                    <el-button type="primary" v-if="index == 0 && caseDetailData && !caseDetailData.secondInstance" @click="editInstanceFn(false, instanceItem, index)">编 辑</el-button>
                                    <el-button type="primary" v-if="index == 1 && caseDetailData && !caseDetailData.reviewInstance" @click="editInstanceFn(false, instanceItem, index)">编 辑</el-button>
                                    <el-button type="primary" v-else-if="index == 2" @click="editInstanceFn(false, instanceItem, index)">编 辑</el-button>
                                </div>
                                <div class="group_right" v-else>
                                    <el-button type="primary" @click="editInstanceFn(true)">保 存</el-button>
                                    <el-button @click="cancelEdit">取 消</el-button>
                                </div>
                            </div>
                            <!-- 展示 -->
                            <template v-if="!editContentFlag">
                                <el-row class="content_row">
                                    <el-col :span="8">
                                        <div class="info_title txt_box">法院案号：</div>
                                        <div class="txt_box">{{ instanceItem && instanceItem.courtCaseNo ? instanceItem.courtCaseNo : '--' }}</div>
                                    </el-col>
                                    <el-col :span="7" :offset="1">
                                        <div class="info_title txt_box">法院：</div>
                                        <div class="txt_box">{{ instanceItem && instanceItem.court ? instanceItem.court : '--' }}</div>
                                    </el-col>
                                    <el-col :span="7" :offset="1">
                                        <div class="info_title txt_box">法官：</div>
                                        <div class="txt_box">{{ instanceItem && instanceItem.judge ? instanceItem.judge : '--' }}</div>
                                    </el-col>
                                </el-row>
                                <el-row class="content_row">
                                    <el-col :span="8">
                                        <div class="info_title txt_box">开庭时间：</div>
                                        <div class="txt_box">{{ instanceItem && instanceItem.courtTime ? instanceItem.courtTime : '--' }}</div>
                                    </el-col>
                                    <el-col :span="7" :offset="1">
                                        <div class="info_title txt_box">上诉截止日期：</div>
                                        <div class="txt_box">{{ instanceItem && instanceItem.appealClosingDate ? instanceItem.appealClosingDate : '--' }}</div>
                                    </el-col>
                                    <el-col :span="7" :offset="1">
                                        <div class="info_title txt_box">履行支付日期：</div>
                                        <div class="txt_box">{{ instanceItem && instanceItem.fulfillmentPaymentDate ? instanceItem.fulfillmentPaymentDate : '--' }}</div>
                                    </el-col>
                                </el-row>
                                <el-row class="content_row">
                                    <el-col :span="8">
                                        <div class="info_title txt_box">委托律所：</div>
                                        <div class="txt_box">{{ instanceItem && instanceItem.lawFirmName ? instanceItem.lawFirmName : '--' }}</div>
                                    </el-col>
                                    <el-col :span="7" :offset="1">
                                        <div class="info_title txt_box">委托律师平台：</div>
                                        <div class="txt_box">{{ instanceItem && instanceItem.lawPlatformName ? instanceItem.lawPlatformName : '--' }}</div>
                                    </el-col>
                                    <el-col :span="7" :offset="1">
                                        <div class="info_title txt_box">委托律师：</div>
                                        <div class="txt_box">{{ instanceItem && instanceItem.lawyer ? instanceItem.lawyer : '--' }}</div>
                                        <div class="txt_box click_txt" v-if="instanceItem.lawyerJson && instanceItem.lawyerJson.length > 0">
                                            <el-tooltip placement="top">
                                                <div slot="content">
                                                    <template v-for="(item, index) in instanceItem.lawyerJson">
                                                        <p :key="index">
                                                            <span style="margin-right: 10px">{{ item.lawyerName }}</span>
                                                            <span>{{ item.phoneNumber }}</span>
                                                        </p>
                                                    </template>
                                                </div>
                                                <span>共{{ instanceItem.lawyerJson.length }}个律师</span>
                                            </el-tooltip>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row class="content_row">
                                    <el-col :span="8">
                                        <div class="info_title txt_box">法院受理费：</div>
                                        <div class="txt_box">{{ instanceItem && instanceItem.courtAcceptanceFee ? instanceItem.courtAcceptanceFee + ' 元' : '--' }}</div>
                                    </el-col>
                                    <el-col :span="7" :offset="1">
                                        <div class="info_title txt_box">我司承担受理费：</div>
                                        <div class="txt_box">{{ instanceItem && instanceItem.bearAcceptanceFee ? instanceItem.bearAcceptanceFee + ' 元' : '--' }}</div>
                                    </el-col>
                                    <el-col :span="7" :offset="1">
                                        <div class="info_title txt_box">律师费：</div>
                                        <div class="txt_box">{{ instanceItem && instanceItem.counselFee ? instanceItem.counselFee + ' 元' : '--' }}</div>
                                    </el-col>
                                </el-row>
                                <el-row class="content_row">
                                    <el-col :span="8">
                                        <div class="info_title txt_box">判决时间：</div>
                                        <div class="txt_box">{{ instanceItem && instanceItem.judgmentTime ? instanceItem.judgmentTime : '--' }}</div>
                                    </el-col>
                                    <el-col :span="7" :offset="1">
                                        <div class="info_title txt_box">我司赔付金额：</div>
                                        <div class="txt_box">{{ instanceItem && instanceItem.compensationAmount ? instanceItem.compensationAmount + ' 元' : '--' }}</div>
                                    </el-col>
                                </el-row>
                                <el-row class="content_row">
                                    <el-col class="align_col" :span="24">
                                        <div class="info_title txt_box">判决情况：</div>
                                        <div class="info_input txt_box" v-if="instanceItem.situationJson && instanceItem.situationJson.length > 0">
                                            <div class="situation_item" v-for="(item, index) in instanceItem.situationJson" :key="index">
                                                <p>
                                                    {{ item.handlerTime }} <span style="margin-left: 15px">{{ item.handler }}</span>
                                                </p>
                                                <p>{{ item.situation }}</p>
                                            </div>
                                        </div>
                                        <el-col :span="22" v-else> 暂无数据 </el-col>
                                    </el-col>
                                </el-row>
                            </template>
                            <!-- 编辑-律师 -->
                            <template v-else>
                                <el-row class="content_row edit_content">
                                    <el-col :span="8">
                                        <el-row class="project_row">
                                            <el-col class="col_title" :span="8">法院案号：</el-col>
                                            <el-col class="col_txt" :span="16"><el-input v-model="editInstanceForm.courtCaseNo" placeholder="请输入内容"></el-input></el-col>
                                        </el-row>
                                    </el-col>
                                    <el-col :span="7" :offset="1">
                                        <el-row class="project_row">
                                            <el-col class="col_title" :span="9">法院：</el-col>
                                            <el-col class="col_txt" :span="15"><el-input v-model="editInstanceForm.court" placeholder="请输入内容"></el-input></el-col>
                                        </el-row>
                                    </el-col>
                                    <el-col :span="7" :offset="1">
                                        <el-row class="project_row">
                                            <el-col class="col_title" :span="9">法官：</el-col>
                                            <el-col class="col_txt" :span="15"><el-input v-model="editInstanceForm.judge" placeholder="请输入内容"></el-input></el-col>
                                        </el-row>
                                    </el-col>
                                </el-row>
                                <el-row class="content_row">
                                    <el-col :span="8">
                                        <el-row class="project_row">
                                            <el-col class="col_title" :span="8">开庭时间：</el-col>
                                            <el-col class="col_txt" :span="16">
                                                <el-date-picker v-model="editInstanceForm.courtTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期"> </el-date-picker>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                    <el-col :span="7" :offset="1">
                                        <el-row class="project_row">
                                            <el-col class="col_title" :span="9">上诉截止日期：</el-col>
                                            <el-col class="col_txt" :span="15">
                                                <el-date-picker v-model="editInstanceForm.appealClosingDate" :disabled="index == 2" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"> </el-date-picker>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                    <el-col :span="7" :offset="1">
                                        <el-row class="project_row">
                                            <el-col class="col_title" :span="9">履行支付日期：</el-col>
                                            <el-col class="col_txt" :span="15">
                                                <el-date-picker v-model="editInstanceForm.fulfillmentPaymentDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"> </el-date-picker>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                </el-row>
                                <el-row class="content_row">
                                    <el-col :span="8">
                                        <el-row class="project_row">
                                            <el-col class="col_title" :span="8">法院受理费：</el-col>
                                            <el-col class="col_txt" :span="16"
                                                ><el-input
                                                    v-model="editInstanceForm.courtAcceptanceFee"
                                                    maxlength="11"
                                                    oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '').replace(/^0+(\d)/, '$1')"
                                                    placeholder="请输入内容"
                                                ></el-input
                                            ></el-col>
                                        </el-row>
                                    </el-col>
                                    <el-col :span="7" :offset="1">
                                        <el-row class="project_row">
                                            <el-col class="col_title" :span="9">
                                                <div><span style="margin-left: -40px">我司承担受理费</span><span>：</span></div>
                                            </el-col>
                                            <el-col class="col_txt" :span="15"
                                                ><el-input
                                                    v-model="editInstanceForm.bearAcceptanceFee"
                                                    maxlength="11"
                                                    oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '').replace(/^0+(\d)/, '$1')"
                                                    placeholder="请输入内容"
                                                ></el-input
                                            ></el-col>
                                        </el-row>
                                    </el-col>
                                    <el-col :span="7" :offset="1">
                                        <el-row class="project_row">
                                            <el-col class="col_title" :span="9">律师费：</el-col>
                                            <el-col class="col_txt" :span="15"
                                                ><el-input
                                                    v-model="editInstanceForm.counselFee"
                                                    maxlength="11"
                                                    oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '').replace(/^0+(\d)/, '$1')"
                                                    placeholder="请输入内容"
                                                ></el-input
                                            ></el-col>
                                        </el-row>
                                    </el-col>
                                </el-row>
                                <el-row class="content_row">
                                    <el-col :span="8">
                                        <el-row class="project_row">
                                            <el-col class="col_title" :span="8">我司赔付金额：</el-col>
                                            <el-col class="col_txt" :span="16"
                                                ><el-input
                                                    v-model="editInstanceForm.compensationAmount"
                                                    maxlength="11"
                                                    oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '').replace(/^0+(\d)/, '$1')"
                                                    placeholder="请输入内容"
                                                ></el-input
                                            ></el-col>
                                        </el-row>
                                    </el-col>
                                    <el-col :span="7" :offset="1">
                                        <el-row class="project_row">
                                            <el-col class="col_title" :span="9">判决时间：</el-col>
                                            <el-col class="col_txt" :span="15">
                                                <el-date-picker v-model="editInstanceForm.judgmentTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"> </el-date-picker>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                    <el-col :span="7" :offset="1" v-if="btnMenuData && btnMenuData[301]">
                                        <el-row class="project_row">
                                            <el-col class="col_title" :span="9">委托律师平台：</el-col>
                                            <el-col class="col_txt" :span="15">
                                                <el-select v-model="editInstanceForm.lawPlatform" clearable filterable @change="(e) => selectChange(e, 'lawPlatform')" placeholder="请选择">
                                                    <el-option v-for="item in platOptions" :key="item.id" :label="item.firmName" :value="String(item.id)"> </el-option>
                                                </el-select>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                </el-row>
                                <el-row class="content_row" v-if="btnMenuData && btnMenuData[301]">
                                    <el-col :span="8">
                                        <el-row class="project_row">
                                            <el-col class="col_title" :span="8">委托律所：</el-col>
                                            <el-col class="col_txt" :span="16">
                                                <el-select v-model="editInstanceForm.lawFirm" clearable filterable @change="(e) => selectChange(e, 'lawFirm')" placeholder="请选择">
                                                    <el-option v-for="item in firmOptions" :key="item.id" :label="item.firmName" :value="String(item.id)"> </el-option>
                                                </el-select>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                    <el-col :span="7" :offset="1">
                                        <el-row class="project_row dy_row">
                                            <el-col class="col_title" :span="9">委托律师：</el-col>
                                            <el-col class="col_txt" :span="15" :offset="9">
                                                <el-select v-model="editInstanceForm.userNames" filterable clearable multiple placeholder="请选择">
                                                    <el-option v-for="item in lawyerInfoOptions" :key="item.id" :label="item.lawyerName" :value="item.userName"> </el-option>
                                                </el-select>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                    <el-col :span="7" :offset="1">
                                        <el-row>
                                            <el-col class="col_txt" :span="24" v-if="editInstanceForm.lawyerJson && editInstanceForm.lawyerJson.length > 0">
                                                <!-- 委托按钮委托律师不可删除 -->
                                                <template v-if="instanceItem.lawyer">
                                                    <el-tag class="select_user" v-for="(item, index) in [instanceItem.lawyer]" :key="index">
                                                        {{ item }}
                                                    </el-tag>
                                                </template>
                                                <template v-for="(lawyerItem, lawyerIndex) in editInstanceForm.lawyerJson">
                                                    <el-tag class="select_user" :key="lawyerItem.id" closable v-if="lawyerItem.lawyerName != editInstanceForm.lawyer" @close="selectUserClose(lawyerItem, lawyerIndex)">
                                                        {{ lawyerItem.lawyerName }}
                                                    </el-tag>
                                                </template>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                </el-row>
                                <el-row class="content_row">
                                    <el-col class="align_col" :span="24">
                                        <div class="info_title txt_box">判决情况：</div>
                                        <div class="info_input txt_box">
                                            <el-input v-model="editInstanceForm.situation" type="textarea" :autosize="{ minRows: 4 }" placeholder="请输入内容"> </el-input>
                                        </div>
                                    </el-col>
                                </el-row>
                            </template>
                        </div>
                    </template>
                </template>
                <template v-if="activeName == '8802'">
                    <div class="tab_content">
                        <div class="btn_group">
                            <div class="group_left">
                                <el-button type="primary" v-show="!editUpload" @click="allDownloadFile">批量下载</el-button>
                            </div>
                            <template v-if="btnMenuData && btnMenuData[300]">
                                <div class="group_right" v-if="!editUpload">
                                    <el-button type="primary" @click="editUploadFn(true)">编 辑</el-button>
                                    <el-button type="primary" @click="showUpload">点击上传</el-button>
                                </div>
                                <div class="group_right" v-else>
                                    <el-button type="primary" @click="submitUpload(true)">保 存</el-button>
                                    <el-button @click="editUploadFn(false)">取 消</el-button>
                                </div>
                            </template>
                        </div>
                        <el-table :class="{ noneTableData: materialTable.length < 1 }" :data="materialTable" style="width: 100%">
                            <el-table-column prop="fileName" label="文件名称" width="180">
                                <template slot-scope="scope">
                                    <div class="preview_file">
                                        <span :class="{ tooltip_text: scope.row.isPreview }" v-if="scope.row.fileExtension && scope.row.fileExtension == 'pdf'" @click="previewFile(scope.row)">{{ scope.row.fileName }}</span>
                                        <span :class="{ tooltip_text: scope.row.isPreview }" v-else>{{ scope.row.fileName }}</span>
                                        <el-image class="preview_image" v-if="scope.row.fileExtension && scope.row.fileExtension != 'pdf'" :src="scope.row.previewPath" :preview-src-list="[scope.row.previewPath]"> </el-image>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="typeName" label="文件类型" width="180">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.edit">
                                        <el-select v-model="scope.row.typeCode" placeholder="请选择">
                                            <el-option v-for="item in caseFileTypeOptions" :key="item.code" :label="item.name" :value="item.code"> </el-option>
                                        </el-select>
                                    </div>
                                    <div v-else>
                                        <span>{{ scope.row.typeName }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="createBy" label="上传人"> </el-table-column>
                            <el-table-column prop="createTime" label="上传时间"> </el-table-column>
                            <el-table-column label="操作" width="100">
                                <template slot-scope="scope">
                                    <!-- <span>{{ scope.$index }}</span> -->
                                    <el-button @click="handleFile(scope.row, scope.$index, 'del')" type="text" size="small" v-if="scope.row.edit">删除</el-button>
                                    <el-button @click="handleFile(scope.row, scope.$index)" type="text" size="small" v-else>下载</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <!--分页部分-->
                        <el-pagination
                            v-if="materialTable.length > 0"
                            @current-change="handleCurrentChange"
                            @size-change="handleSizeChange"
                            :current-page="materialForm.page"
                            layout="total, sizes, prev, pager, next, jumper"
                            :page-sizes="[10, 20]"
                            :page-size="materialForm.size"
                            :total="materialForm.total"
                        >
                        </el-pagination>
                    </div>
                </template>
            </div>
        </div>
        <!-- 上传弹窗 -->
        <el-dialog class="upload_dialog" title="上传材料" :visible.sync="uploadFlag" center :modal-append-to-body="false" :close-on-click-modal="false" width="30%">
            <div class="dialog_content">
                <el-form :model="uploadForm" :rules="uploadRules" ref="uploadRef" label-width="120px">
                    <el-form-item label="文件类型：" prop="lawPlatform">
                        <el-select v-model="uploadForm.lawPlatform" filterable placeholder="请选择">
                            <el-option v-for="item in caseFileTypeOptions" :key="item.code" :label="item.name" :value="item.code"> </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div class="upload_btn">
                    <el-upload ref="elUploadRef" v-if="!selectFileFlag" action="#" :http-request="(e) => ossUpload(e)" multiple :limit="10" :on-exceed="handleExceed" :file-list="fileList" :show-file-list="false">
                        <el-button size="mini" type="primary">选择文件</el-button>
                        <p class="errorTxt" v-if="filesLengthError">最少选择一个文件</p>
                    </el-upload>
                    <el-button size="mini" type="primary" disabled v-else>选择文件</el-button>
                </div>
                <div class="files_box" v-for="(item, index) in uploadCaseFileForm.files" :key="index">
                    <span>{{ item.fileName }}</span>
                    <i class="del_icon el-icon-close" @click="delUploadFile(item, index)"></i>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="entrustFn(true)">提 交</el-button>
                <el-button @click="entrustFn(false)">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import OSS from 'ali-oss';
import { v4 as uuidv4 } from 'uuid';
export default {
    props: {
        caseDetailData: {
            type: Object,
            default: null
        },
        btnMenuData: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            filesLengthError: false,
            loading: false,
            uploadFlag: false,
            editContentFlag: false,
            editUpload: false,
            firstInstanceData: null,
            secondInstanceData: null,
            selectFileFlag: false,
            instanceDataArr: [],
            instanceMen: {
                0: { name: '一审', index: 0 },
                1: { name: '二审', index: 1 },
                2: { name: '再审', index: 2 },
                3: { name: '', index: 3 },
                4: { name: '', index: 4 },
                5: { name: '', index: 5 }
            },
            editInstanceForm: {
                courtCaseNo: '',
                court: '',
                judge: '',
                courtTime: '',
                appealClosingDate: '',
                fulfillmentPaymentDate: '',
                courtAcceptanceFee: '',
                bearAcceptanceFee: '',
                counselFee: '',
                compensationAmount: '',
                judgmentTime: '',
                lawPlatform: '',
                lawFirm: '',
                lawyer: '',
                situation: '',
                userNames: [],
                lawyerJson: []
            },
            navForm: {
                caseNo: '',
                value1: [],
                value2: [],
                value3: '',
                value4: '',
                value5: '',
                page: 1,
                size: 10,
                total: 0
            },
            activeName: '8801',
            situationArr: [],
            textarea2: '',
            materialForm: {
                caseId: '',
                page: 1,
                size: 10,
                total: 0
            },
            materialTable: [],
            tmpTableData: [],
            caseFileTypeOptions: [],
            caseFileTypeObj: {},
            firmOptions: [],
            platOptions: [],
            lawyerInfoOptions: [],
            options: [],
            input: '',
            value: '',
            value1: '',
            uploadForm: {
                lawPlatform: ''
            },
            fileList: [],
            uploadCaseFileList: [],
            uploadRules: {
                lawPlatform: [{ required: true, message: '请选择文件类型', trigger: 'blur' }]
            },
            baseURL: '',
            unloaderHeader: { token: '' }, //上传的请求头
            uploadCaseFileForm: {
                caseId: '',
                files: []
            },
            editCaseFileForm: {
                caseId: '',
                deleteFiles: [],
                files: []
            },
            OSSClient: null,
            findLawyerData: {
                lawFirmId: '',
                lawPlatformId: ''
            },
            fileTypeList: ['tiff', 'jfif', 'bmp', 'webp', 'gif', 'svg', 'jpg', 'svgz', 'png', 'jpeg', 'ico', 'xbm', 'dib', 'pjp', 'apng', 'tif', 'pjpeg', 'avif']
        };
    },
    mounted() {
        this.baseURL = this.$store.getters.baseURL;
        this.initOSSClient();
        this.getCaseFileType();
        this.getFindFirm();
        this.getFindPlatform();
    },
    methods: {
        getCaseDetail() {
            this.$emit('getCaseDetail');
        },
        caseEditLock(lockId, rowItem, numIndex) {
            // CASE_EDIT(1, "案件信息编辑")
            // CHECK_EDIT(2, "审核编辑")
            // FILE_EDIT(3, "文件编辑")
            this.instance.post('/lawsuitCase/caseEditLock', { caseId: this.caseDetailData.id, lockId: lockId }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
                    if (!data.data.lock || userInfo.userName == data.data.lockUserName) {
                        if (lockId == 2) {
                            this.editBtnLogic(rowItem, numIndex);
                        } else {
                            this.editBtnUpload(rowItem);
                        }
                    } else {
                        this.$message.error(`${data.data.lockUser}-${data.data.phone}正在编辑，请等待编辑完成后再操作！`);
                    }
                }
            });
        },
        caseEditUnLock(lockId) {
            this.instance.post('/lawsuitCase/caseEditUnLock', { caseId: this.caseDetailData.id, lockId: lockId }).then((res) => {});
        },
        editBtnLogic(rowItem, numIndex) {
            this.editContentFlag = true;
            this.editInstanceForm = JSON.parse(JSON.stringify(rowItem));
            // 编辑时查询委托律师
            this.findLawyerData.lawPlatformId = this.editInstanceForm.lawPlatform;
            this.findLawyerData.lawFirmId = this.editInstanceForm.lawFirm;
            this.getFindLawyerInfo();
        },
        editBtnUpload(tmpData) {
            tmpData.forEach((item, index) => {
                item.edit = true;
                item.typeCode = item.typeCode;
            });

            this.materialTable = tmpData;
            this.editUpload = true;
        },
        selectUserClose(lawyerItem, lawyerIndex) {
            this.editInstanceForm.lawyerJson.splice(lawyerIndex, 1);
        },
        selectChange(val, selectType) {
            if (selectType == 'lawPlatform') {
                this.findLawyerData.lawPlatformId = val;
            } else {
                this.findLawyerData.lawFirmId = val;
            }
            this.editInstanceForm.userNames = [];
            this.getFindLawyerInfo();
        },
        getCaseFileType() {
            this.instance.get('/lawsuitCase/material/getCaseFileType', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.caseFileTypeObj = {};
                    this.caseFileTypeOptions = data.data;
                    data.data.forEach((item) => {
                        this.caseFileTypeObj[item.code] = item.name;
                    });
                }
            });
        },
        getFindFirm() {
            this.instance.post('/lawyerInfo/findFirm', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.firmOptions = data.data;
                }
            });
        },
        getFindPlatform() {
            this.instance.post('/lawyerInfo/findPlatform', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.platOptions = data.data;
                }
            });
        },
        getFindLawyerInfo() {
            this.instance.post('/lawyerInfo/findLawyerInfo', this.findLawyerData).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.lawyerInfoOptions = data.data;
                }
            });
        },
        getPageCaseMaterial() {
            this.materialForm.caseId = this.caseDetailData.id;
            this.instance.post('/lawsuitCase/material/pageCaseMaterial', this.materialForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.materialTable = data.data.list;
                    this.materialForm.total = data.data.total;

                    // 文件后缀是否可以预览
                    this.materialTable.forEach((fileItem, fileIndex) => {
                        let strArr = fileItem.fileName.split('.');
                        let fileExtension = strArr[strArr.length - 1];
                        if (fileExtension == 'pdf' || this.fileTypeList.indexOf(fileExtension) >= 0) {
                            fileItem.isPreview = true;
                            fileItem.fileExtension = fileExtension;
                            fileItem.previewPath = this.getOssFilePath(fileItem.ossPath);
                        }
                    });
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        handleCurrentChange(val) {
            this.materialForm.page = val;
            this.getPageCaseMaterial();
        },
        handleSizeChange(val) {
            this.materialForm.size = val;
            this.getPageCaseMaterial();
        },
        handleFile(fileItem, fileIndex, fileStr) {
            if (fileStr == 'del') {
                let delItem = this.materialTable.splice(fileIndex, 1);
                this.editCaseFileForm.deleteFiles.push(delItem[0]);
                this.$forceUpdate();
            } else {
                let fileUrl = this.getOssFilePath(fileItem.ossPath);
                this.downloadPdf(fileItem, fileUrl);
            }
        },
        getOssFilePath(filePath) {
            let fileUrl = this.OSSClient.signatureUrl(filePath);
            return fileUrl;
        },
        allDownloadFile() {
            this.materialTable.forEach((item, index) => {
                let fileUrl = this.getOssFilePath(item.ossPath);
                this.downloadPdf(item, fileUrl);
            });
        },
        downloadPdf(fileItem, fileUrl) {
            const link = document.createElement('a');
            fetch(fileUrl)
                .then((res) => res.blob())
                .then((blob) => {
                    link.href = URL.createObjectURL(blob);
                    // 下载文件的名称及文件类型后缀
                    link.download = fileItem.fileName;
                    document.body.appendChild(link);
                    link.click();
                    //在资源下载完成后 清除 占用的缓存资源
                    window.URL.revokeObjectURL(link.href);
                    document.body.removeChild(link);
                });
        },
        previewFile(fileRow) {
            this.openFileInNewTab(this.getOssFilePath(fileRow.ossPath));
        },
        openFileInNewTab(fileUrl) {
            var a = document.createElement('a');
            a.href = fileUrl;
            a.target = '_blank';
            a.click();
        },
        submitUpload(submitFlag) {
            if (submitFlag) {
                this.editCaseFileForm.files = this.materialTable;
                this.editCaseFile();
            } else {
            }
        },
        editUploadFn(editFlag) {
            let tmpData = JSON.parse(JSON.stringify(this.materialTable));
            this.tmpTableData = JSON.parse(JSON.stringify(this.materialTable));
            if (editFlag) {
                this.caseEditLock(3, tmpData);
            } else {
                this.getCaseDetail();
                this.editCaseFileForm = {
                    caseId: '',
                    deleteFiles: [],
                    files: []
                };
                tmpData.forEach((item, index) => {
                    item.edit = false;
                });

                this.materialTable = tmpData;
                this.editUpload = false;
                this.caseEditUnLock(3);
            }
        },
        editInstanceFn(submitFlag, rowItem, numIndex) {
            if (submitFlag) {
                this.editCaseCheck();
            } else {
                this.caseEditLock(2, rowItem, numIndex);
            }
        },
        cancelEdit() {
            this.editContentFlag = false;
            this.caseEditUnLock(2);
        },
        editCaseCheck() {
            let tmpEditInstanceData = JSON.parse(JSON.stringify(this.editInstanceForm));
            if (tmpEditInstanceData.lawyerJson && tmpEditInstanceData.lawyerJson.length > 0) {
                tmpEditInstanceData.lawyerJson.forEach((item, index) => {
                    tmpEditInstanceData.userNames && tmpEditInstanceData.userNames.unshift(item.userName);
                });
            }
            this.instance.post('/lawsuitCase/editCaseCheck', tmpEditInstanceData).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('诉讼案件信息编辑成功！');
                    this.editContentFlag = false;
                } else {
                    this.$message.error(data.message);
                }
                this.getCaseDetail();
            });
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        // 初始化OSS
        initOSSClient() {
            this.instance
                .get('/aliyunoss/access', { params: {} })
                .then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        this.OSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            bucket: data.data.bucket
                        });
                    } else {
                        console.log('oss初始化失败，正在重试！-0');
                    }
                })
                .catch((err) => {
                    console.log(err);
                    console.log('oss初始化失败-');
                    console.log('oss初始化失败，正在重试！-1');
                });
        },
        showUpload() {
            this.fileList = [];
            this.uploadForm = {
                lawPlatform: ''
            };
            this.uploadCaseFileForm = {
                caseId: '',
                files: []
            };
            this.uploadFlag = true;
        },
        //OSS直传
        ossUpload(e, fileType) {
            const OSS = require('ali-oss');
            let _this = this;
            let file = e.file;
            let storeAs = 'upload-file';
            let protocol = location.protocol;
            let tmpSuffix = file.name.split('.')[1];
            let fileTypeList = ['tiff', 'jfif', 'bmp', 'webp', 'gif', 'svg', 'jpg', 'svgz', 'png', 'jpeg', 'ico', 'xbm', 'dib', 'pjp', 'apng', 'tif', 'pjpeg', 'avif'];
            let tmpImageUrl = null;

            const reader = new FileReader();
            reader.readAsDataURL(file); // 将文件转换成 Base64 格式
            reader.onloadend = () => {
                tmpImageUrl = reader.result; // 保存 Base64 URL 地址
            };

            // if (fileType && fileType != tmpSuffix) {
            //     this.$message.error('请选择正确文件类型');
            //     return;
            // }

            // if (!fileType && fileTypeList.indexOf(tmpSuffix) == -1) {
            //     this.$message.error('请选择正确文件类型');
            //     return;
            // }
            // OSS.urllib是SDK内部封装的发送请求的逻辑，开发者可以使用任何发送请求的库向sts-server发送请求。
            OSS.urllib.request(protocol + _this.baseURL + '/aliyunoss/access', { method: 'GET' }, (err, response) => {
                try {
                    var result = JSON.parse(response).data;
                } catch (e) {
                    console.log('parse sts response info error: ' + e.message);
                }
                let client = new OSS({
                    accessKeyId: result.accessKeyId,
                    accessKeySecret: result.accessKeySecret,
                    stsToken: result.securityToken,
                    // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                    region: result.region,
                    bucket: result.bucket
                });
                // storeAs可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
                // file可以自定义为File对象、Blob数据以及OSS Buffer。
                storeAs = result.path + '/' + uuidv4() + '.' + file.name.split('.')[1];
                setTimeout(() => {
                    client
                        .multipartUpload(storeAs, file)
                        .then(function (result) {
                            // storeAs - 阿里返回图片路径
                            if (_this.uploadCaseFileForm.files.length < 10) {
                                _this.uploadCaseFileForm.files.push({
                                    caseId: _this.caseDetailData.id,
                                    fileName: file.name,
                                    ossPath: storeAs
                                });
                                _this.filesLengthError = false;
                                _this.checkUploadFileLength();
                            }
                        })
                        .catch(function (err) {
                            console.log(err);
                        });
                }, 500);
            });
        },
        checkUploadFileLength() {
            this.selectFileFlag = this.uploadCaseFileForm.files.length >= 10;
        },
        delUploadFile(fileItem, fileIndex) {
            this.uploadCaseFileForm.files.splice(fileIndex, 1);
            this.fileList.splice(fileIndex, 1);
            this.checkUploadFileLength();
        },
        entrustFn(submitFlag) {
            if (submitFlag) {
                if (this.uploadCaseFileForm.files.length < 1) {
                    this.filesLengthError = true;
                    return;
                }
                this.$refs.uploadRef.validate((valid) => {
                    if (valid) {
                        this.uploadCaseFileForm.files.forEach((item) => {
                            item.typeCode = this.uploadForm.lawPlatform;
                            item.typeName = this.caseFileTypeObj[this.uploadForm.lawPlatform];
                        });
                        this.addCaseFile();
                    }
                });
            } else {
                this.cancelUpload();
            }
        },
        cancelUpload() {
            this.uploadFlag = false;
            this.uploadForm.lawPlatform = '';
            this.fileList = [];
            this.uploadCaseFileForm.files = [];
            this.checkUploadFileLength();
            if (this.$refs.elUploadRef && this.$refs.elUploadRef[0]) {
                this.$refs.elUploadRef[0].clearFiles();
            } else {
                this.$refs.elUploadRef && this.$refs.elUploadRef.clearFiles();
            }
        },
        addCaseFile() {
            this.uploadCaseFileForm.caseId = this.caseDetailData.id;
            this.instance.post('/lawsuitCase/material/addCaseFile', this.uploadCaseFileForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('材料上传成功！');
                    this.getPageCaseMaterial();
                    this.cancelUpload();
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        editCaseFile() {
            this.editCaseFileForm.caseId = this.caseDetailData.id;
            this.instance.post('/lawsuitCase/material/editCaseFile', this.editCaseFileForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('修改材料成功！');
                    this.getPageCaseMaterial();
                    this.editUploadFn(false);
                } else {
                    this.$message.error(data.message);
                }
            });
        }
    },
    watch: {
        caseDetailData: {
            handler(val) {
                if (val) {
                    this.getPageCaseMaterial();
                    this.instanceDataArr = [];
                    val.firstInstance && this.instanceDataArr.push(val.firstInstance);
                    val.secondInstance && this.instanceDataArr.push(val.secondInstance);
                    val.reviewInstance && this.instanceDataArr.push(val.reviewInstance);
                }
            },
            immediate: true
        }
    }
};
</script>
<style lang="less" scoped>
@import '../../assets/css/caseCard.css';
.tooltip_text {
    cursor: pointer;
    color: #409eff;
}
.caseInsurance {
    .title_row {
        .btn_groups {
            text-align: right;
        }
    }
    .card_content {
        .content_row {
            .col_title {
                text-align: right;
            }
            .col_txt {
                line-height: 22px;
                .select_user {
                    margin-left: 10px;
                    margin-block: 5px;
                }
            }
        }
        .content_type {
            padding: 10px 20px;
            border: 1px solid #dcdcdc;
            .el-tabs {
                margin-bottom: 10px;
            }

            .tab_content {
                .noneTableData::before {
                    height: 0px !important;
                }
                .content_row {
                    margin-bottom: 25px;
                    .project_row {
                        display: flex;
                        align-items: center;
                    }
                    .dy_row {
                        // position: relative;
                        .col_title {
                            position: absolute;
                            top: 5px;
                        }
                        // .col_txt {
                        //     position: absolute;
                        //     right: 0;
                        //     top: 0;
                        // }
                    }
                    .situation_box {
                        padding: 20px;
                        height: 300px;
                        overflow-y: scroll;
                        border: 1px solid #dcdcdc;
                        .situation_item {
                            margin-bottom: 15px;
                        }
                        .situation_item:last-child {
                            margin-bottom: 0;
                        }
                    }
                    .txt_box {
                        display: inline-block;
                    }
                    .click_txt {
                        margin-left: 20px;
                        color: #409eff;
                        cursor: pointer;
                        border-bottom: 1px solid #409eff;
                    }
                    .info_title {
                        width: 100px;
                        text-align: right;
                        // border: 1px solid red;
                    }
                    .info_input {
                        width: 80%;
                    }
                    .align_col {
                        display: flex;
                        align-items: top;
                    }
                }
                .btn_group {
                    display: flex;
                    justify-content: space-between;
                    .group_right {
                        margin-bottom: 15px;
                    }
                }
                // 编辑
                .edit_content {
                    .content_row {
                        display: flex;
                        align-items: center;
                    }
                    .exception_row {
                        display: inline !important;
                    }
                }
                .preview_file {
                    position: relative;
                    .preview_image {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        /deep/ img {
                            opacity: 0 !important;
                        }
                    }
                }
            }
        }
    }
    .errorTxt {
        padding-left: 2px;
        font-size: 11px;
        color: red;
        position: relative;
        left: 2px;
    }
    .upload_dialog {
        .dialog_content {
            .upload_btn {
                text-align: center;
            }
            .files_box {
                display: flex;
                justify-content: space-between;
                margin-top: 5px;
                .del_icon {
                    cursor: pointer;
                    padding: 4px;
                }
            }
        }
    }
}
</style>
<style lang="less">
.el-image-viewer__wrapper {
    .el-image-viewer__canvas {
        .el-image-viewer__img {
            max-height: 80% !important;
            max-width: 50% !important;
        }
    }
}
</style>