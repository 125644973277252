<template>
    <div class="wrap">
        <div class="page_title"><b>用户活跃度统计</b></div>
        <div class="search_box chart_public elFormNoneBottomMargin">
            <!--检索部分-->
            <el-form :inline="true" :model="navForm">
                <el-form-item label="查询时间：" class="dateBox">
                    <el-row style="width: 330px">
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" v-model="navForm.startTime" :clearable="false" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                        <el-col class="line" style="text-align: center" :span="2">至</el-col>
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" v-model="navForm.endTime" :clearable="false" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="公估公司：">
                    <el-select v-model="navForm.lossCompany" filterable placeholder="请选择公估公司">
                        <el-option v-for="item in restaurants" :key="item.value" :label="item.value" :value="item.value"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="searchTable" style="margin-left: 0.2rem">查询</el-button>
                    <!-- <el-button type="primary" @click="downloadFile" style="margin-left: 0.2rem">下载</el-button> -->
                </el-form-item>
            </el-form>
        </div>
        <div class="user_liveness main">
            <div class="download_title flex">
                <div class="workorder_title">总览</div>
                <i class="download_icon el-icon-download" v-if="!loading" @click="downloadFile"></i>
                <i class="download_icon el-icon-loading" v-else></i>
            </div>
            <ul class="workorder_statistics">
                <li class="chart_public">
                    <div class="workorder_data">
                        <div class="workorder_num">
                            <p>活跃情况</p>
                        </div>
                        <div class="average">用户总数：{{ overviewSituationData.login && overviewSituationData.login.totalNum ? overviewSituationData.login.totalNum : 0 }} 个</div>
                        <div class="average">活跃账号个数：{{ overviewSituationData.login && overviewSituationData.login.targetNum ? overviewSituationData.login.targetNum : 0 }} 个</div>
                        <div class="average">今日活跃账号个数：{{ overviewSituationData.login && overviewSituationData.login.targetExtNum ? overviewSituationData.login.targetExtNum : 0 }} 个</div>
                    </div>
                </li>
                <li class="chart_public">
                    <div class="workorder_data">
                        <div class="workorder_num">
                            <p>风险提示使用情况</p>
                        </div>
                        <div class="average">用户总数：{{ overviewSituationData.risk && overviewSituationData.risk.totalNum ? overviewSituationData.risk.totalNum : 0 }} 个</div>
                        <div class="average">插件弹出账号数：{{ overviewSituationData.risk && overviewSituationData.risk.targetNum ? overviewSituationData.risk.targetNum : 0 }} 个</div>
                        <div class="average">查看风险提示案件数：{{ overviewSituationData.risk && overviewSituationData.risk.targetExtNum ? overviewSituationData.risk.targetExtNum : 0 }} 个</div>
                    </div>
                </li>
                <li class="chart_public">
                    <div class="workorder_data">
                        <div class="workorder_num">
                            <p>质检案件处理情况</p>
                        </div>
                        <div class="average">风险案件总数：{{ overviewSituationData.riskHandle && overviewSituationData.riskHandle.totalNum ? overviewSituationData.riskHandle.totalNum : 0 }} 件</div>
                        <div class="average">处理案件数：{{ overviewSituationData.riskHandle && overviewSituationData.riskHandle.targetNum ? overviewSituationData.riskHandle.targetNum : 0 }} 件</div>
                        <div class="average">处理案件账号数：{{ overviewSituationData.riskHandle && overviewSituationData.riskHandle.targetExtNum ? overviewSituationData.riskHandle.targetExtNum : 0 }} 个</div>
                    </div>
                </li>
                <li class="chart_public">
                    <div class="workorder_data">
                        <div class="workorder_num">
                            <p>中高风险案件处理情况</p>
                        </div>
                        <div class="average">中高风险总数：{{ overviewSituationData.modelRisk && overviewSituationData.modelRisk.totalNum ? overviewSituationData.modelRisk.totalNum : 0 }} 件</div>
                        <div class="average">标记案件数：{{ overviewSituationData.modelRisk && overviewSituationData.modelRisk.targetNum ? overviewSituationData.modelRisk.targetNum : 0 }} 件</div>
                        <div class="average">标记风险案件数：{{ overviewSituationData.modelRisk && overviewSituationData.modelRisk.targetExtNum ? overviewSituationData.modelRisk.targetExtNum : 0 }} 个</div>
                    </div>
                </li>
                <li class="chart_public">
                    <div class="workorder_data">
                        <div class="workorder_num">
                            <p>车损计算器使用情况</p>
                        </div>
                        <div class="average">车损案件报价总数：{{ overviewSituationData.vehicleDamage && overviewSituationData.vehicleDamage.targetNum ? overviewSituationData.vehicleDamage.targetNum : 0 }} 件</div>
                        <div class="average">车损案件总数：{{ overviewSituationData.vehicleDamage && overviewSituationData.vehicleDamage.totalNum ? overviewSituationData.vehicleDamage.totalNum : 0 }} 件</div>
                        <div class="average">车损案件报价占比：{{ overviewSituationData.vehicleDamage && overviewSituationData.vehicleDamage.targetRatio ? overviewSituationData.vehicleDamage.targetRatio : 0 }} %</div>
                    </div>
                </li>
                <li class="chart_public">
                    <div class="workorder_data">
                        <div class="workorder_num">
                            <p>人伤计算器使用情况</p>
                        </div>
                        <div class="average">人伤案件报价总数：{{ overviewSituationData.humanInjury && overviewSituationData.humanInjury.targetNum ? overviewSituationData.humanInjury.targetNum : 0 }} 件</div>
                        <div class="average">人伤案件总数：{{ overviewSituationData.humanInjury && overviewSituationData.humanInjury.totalNum ? overviewSituationData.humanInjury.totalNum : 0 }} 件</div>
                        <div class="average">人伤案件报价占比：{{ overviewSituationData.humanInjury && overviewSituationData.humanInjury.targetRatio ? overviewSituationData.humanInjury.targetRatio : 0 }} %</div>
                    </div>
                </li>
            </ul>
            <div class="workorder_title">公估公司活跃度排行榜</div>
            <div class="charts_box">
                <div>
                    <div class="charts_top">
                        <el-row :gutter="19">
                            <el-col :span="8">
                                <div class="charts_bar1 chart_public">
                                    <div class="flex">
                                        <div class="detail_title">账号活跃度排行榜</div>
                                        <el-radio-group v-model="chartsBar1Radio" @input="getCompanyActiveAccount" size="mini">
                                            <el-radio-button label="正序"></el-radio-button>
                                            <el-radio-button label="倒序"></el-radio-button>
                                        </el-radio-group>
                                    </div>
                                    <div id="charts_bar1" v-if="!isShowBar1" class="charts_bar"></div>
                                    <div class="charts_null" v-show="isShowBar1">暂无数据</div>
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div class="charts_bar1 chart_public">
                                    <div class="flex">
                                        <div class="detail_title" style="margin-bottom: 5px">风险提示使用情况</div>
                                    </div>
                                    <div id="charts_bar2" v-if="!isShowBar2" class="charts_bar"></div>
                                    <div class="charts_null" v-show="isShowBar2">暂无数据</div>
                                </div>
                            </el-col>
                            <el-col :span="8">
                                <div class="charts_bar1 chart_public">
                                    <div class="flex">
                                        <div class="detail_title" style="margin-bottom: 5px">质检案件处理情况</div>
                                    </div>
                                    <div id="charts_bar3" v-if="!isShowBar4" class="charts_bar"></div>
                                    <div class="charts_null" v-show="isShowBar4">暂无数据</div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <el-row :gutter="21">
                    <el-col :span="8">
                        <div class="charts_bar1 chart_public">
                            <div class="flex">
                                <div class="detail_title" style="margin-bottom: 5px">公估公司风险处理情况</div>
                            </div>
                            <div id="charts_bar6" v-if="!isShowBar5" class="charts_bar"></div>
                            <div class="charts_null" v-show="isShowBar5">暂无数据</div>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="charts_bar1 chart_public">
                            <div class="flex">
                                <div class="detail_title">人伤案件报价活跃度排行榜</div>
                                <el-radio-group v-model="chartsBar4Radio" @input="getCompanyActiveHumanInjury" size="mini">
                                    <el-radio-button label="正序"></el-radio-button>
                                    <el-radio-button label="倒序"></el-radio-button>
                                </el-radio-group>
                            </div>
                            <div id="charts_bar4" v-if="!isShowBar1" class="charts_bar"></div>
                            <div class="charts_null" v-show="isShowBar1">暂无数据</div>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="charts_bar1 chart_public">
                            <div class="flex">
                                <div class="detail_title">车损案件报价活跃度排行榜</div>
                                <el-radio-group v-model="chartsBar5Radio" @input="getCompanyActiveVehicleDamage" size="mini">
                                    <el-radio-button label="正序"></el-radio-button>
                                    <el-radio-button label="倒序"></el-radio-button>
                                </el-radio-group>
                            </div>
                            <div id="charts_bar5" v-if="!isShowBar1" class="charts_bar"></div>
                            <div class="charts_null" v-show="isShowBar1">暂无数据</div>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="workorder_title">账号活跃度排行榜</div>
            <el-row :gutter="21">
                <el-col :span="12">
                    <div class="charts_bottom chart_public">
                        <p class="chart_title">账户活跃情况</p>
                        <div id="charts_line1" v-if="!isShowBar1" class="charts_bar"></div>
                        <div class="charts_null" v-show="isShowBar1">暂无数据</div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="charts_bottom chart_public">
                        <p class="chart_title">插件风险提示情况</p>
                        <div id="charts_line2" v-if="!isShowBar1" class="charts_bar"></div>
                        <div class="charts_null" v-show="isShowBar1">暂无数据</div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="chart_row" :gutter="21">
                <el-col :span="12">
                    <div class="charts_bottom chart_public">
                        <p class="chart_title">质检案件处理</p>
                        <div id="charts_line3" v-if="!isShowBar1" class="charts_bar"></div>
                        <div class="charts_null" v-show="isShowBar1">暂无数据</div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="charts_bottom chart_public">
                        <p class="chart_title">个人风险案件处理情况</p>
                        <div id="charts_line7" v-if="!isShowBar6" class="charts_bar"></div>
                        <div class="charts_null" v-show="isShowBar6">暂无数据</div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="chart_row" :gutter="21">
                <el-col :span="12">
                    <div class="charts_bottom chart_public">
                        <p class="chart_title">车损计算器使用情况</p>
                        <div id="charts_line4" v-if="!isShowBar1" class="charts_bar"></div>
                        <div class="charts_null" v-show="isShowBar1">暂无数据</div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="charts_bottom chart_public">
                        <p class="chart_title">人伤计算器使用情况</p>
                        <div id="charts_line5" v-if="!isShowBar1" class="charts_bar"></div>
                        <div class="charts_null" v-show="isShowBar1">暂无数据</div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
let AXIS_LABEL = {
    show: true,
    interval: 0, // 使x轴文字显示全
    formatter: function (params) {
        var newParamsName = '';
        var paramsNameNumber = params.length;
        var provideNumber = 5; // 一行显示几个字
        var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 计算需要几行显示
        for (var i = 0; i < rowNumber; i++) {
            var tempStr = '';
            for (var j = 0; j < provideNumber; j++) {
                var index = i * provideNumber + j;
                if (index < paramsNameNumber) {
                    tempStr += params[index];
                }
            }
            newParamsName += tempStr + '\n'; // 换行
        }
        return newParamsName;
    }
};

import * as echarts from 'echarts';
export default {
    name: 'userLiveness',
    components: {},
    data() {
        return {
            chartsBar1: null,
            chartsBar2: null,
            chartsBar3: null,
            chartsBar4: null,
            chartsBar5: null,
            chartsLine1: null,
            chartsLine2: null,
            chartsLine3: null,
            chartsLine4: null,
            chartsLine5: null,
            chartsLine7: null,
            chartsLength: 5,
            isShowTime: false,
            isShowBar1: false,
            isShowBar2: false,
            isShowBar3: false,
            isShowBar4: false,
            isShowBar5: false,
            isShowBar6: false,
            chartsBar1Radio: '倒序',
            chartsBar3Radio: '倒序',
            chartsBar4Radio: '倒序',
            chartsBar5Radio: '倒序',
            countOrderNum: {}, //工单数量统计
            restaurants: [],
            userCount: {},
            riskCaseCount: {},
            vehicleDamageCount: {},
            humanInjuryCount: {},
            reduceLossAmount: {},
            navForm: {
                // 储存搜索框的检索条件信息
                startTime: '',
                endTime: '',
                lossCompany: ''
            },
            overviewSituationData: {},
            chartDomObj: {},
            loading: false,
            lineChartOption: {}
        };
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.chartReSize();
        });

        const date = new Date();
        let startDate = new Date(date.getTime() - 3600 * 1000 * 24 * 30);
        this.navForm.startTime = this.dateFormatting(startDate, 'yyyy-MM-dd');
        this.navForm.endTime = this.dateFormatting(date, 'yyyy-MM-dd');

        this.searchTable();
    },
    methods: {
        //点击查询
        searchTable() {
            if (!this.navForm.startTime) {
                this.$message.warning('抱歉，请选择查询开始时间！');
                return false;
            }
            if (!this.navForm.endTime) {
                this.$message.warning('抱歉，请选择查询结束时间！');
                return false;
            }

            // 公估公司
            this.searchLossCompany();

            // 公估公司维度
            // 总览情况
            this.getOverviewSituation();
            // 用户活跃度排行
            this.getCompanyActiveAccount();
            // 风险提示使用情况
            this.getCompanyActiveRiskUse();
            // 风险案件处理情况
            this.getCompanyActiveRiskHandle();
            // 公估公司风险案件处理情况
            this.getCompanyActiveModelRisk();
            // 人伤案件报价活跃度排行榜
            this.getCompanyActiveHumanInjury();
            // 用户车损案件报价活跃度排行榜
            this.getCompanyActiveVehicleDamage();

            // 用户维度
            // 账户活跃情况
            this.getUserActiveAccount();
            // 用户风险提示
            this.getUserActiveRiskUse();
            // 用户风险处理
            this.getUserActiveRiskHandle();
            // 个人风险案件处理情况
            this.getUserActiveModelRisk();
            // 用户车损
            this.getUserActiveVehicleDamage();
            // 用户人伤
            this.getUserActiveHumanInjury();
        },
        chartReSize() {
            this.$nextTick(() => {
                for (let i = 1; i < this.chartsLength + 1; i++) {
                    this['chartsBar' + i] && this['chartsBar' + i].resize();
                    this['chartsLine' + i] && this['chartsLine' + i].resize();
                }
            });
        },
        //获取所有公估公司
        searchLossCompany() {
            this.restaurants = [];
            this.instance
                .get('/user/usage/searchLossCompany', {
                    params: { search: '' }
                })
                .then((res) => {
                    if (res.data.code == 1) {
                        res.data.data.forEach((val, key) => {
                            this.restaurants.push({
                                id: val.id,
                                value: val.lossAdjuster
                            });
                        });
                    }
                });
        },
        // 总览情况
        getOverviewSituation() {
            let navForm = JSON.parse(JSON.stringify(this.navForm));
            this.instance.post('/user/operation/overviewSituation', navForm).then((res) => {
                if (res.data.code == 1) {
                    this.overviewSituationData = res.data.data;
                }
            });
        },
        // 用户活跃度排行
        getCompanyActiveAccount() {
            let navForm = JSON.parse(JSON.stringify(this.navForm));
            navForm.order = this.chartsBar1Radio == '倒序' ? '1' : '2';
            this.instance.post('/user/operation/companyActiveAccount', navForm).then((res) => {
                if (res.data.code == 1) {
                    this.loadChartsBar1(res.data.data);
                }
            });
        },
        // 风险提示使用情况
        getCompanyActiveRiskUse() {
            let navForm = JSON.parse(JSON.stringify(this.navForm));
            navForm.order = '1';
            this.instance.post('/user/operation/companyActiveRiskUse', navForm).then((res) => {
                if (res.data.code == 1) {
                    this.loadChartsBar2(res.data.data);
                }
            });
        },
        // 风险案件处理情况
        getCompanyActiveRiskHandle() {
            let navForm = JSON.parse(JSON.stringify(this.navForm));
            navForm.order = '1';
            this.instance.post('/user/operation/companyActiveRiskHandle', navForm).then((res) => {
                if (res.data.code == 1) {
                    this.loadChartsBar3(res.data.data);
                }
            });
        },
        // 公估公司风险处理情况
        getCompanyActiveModelRisk() {
            let navForm = JSON.parse(JSON.stringify(this.navForm));
            navForm.order = '1';
            this.instance.post('/user/operation/companyActiveModelRisk', navForm).then((res) => {
                if (res.data.code == 1) {
                    this.loadChartsBar6(res.data.data);
                }
            });
        },
        // 人伤案件报价活跃度排行榜
        getCompanyActiveHumanInjury() {
            let navForm = JSON.parse(JSON.stringify(this.navForm));
            navForm.order = this.chartsBar4Radio == '倒序' ? '1' : '2';
            this.instance.post('/user/operation/companyActiveHumanInjury', navForm).then((res) => {
                if (res.data.code == 1) {
                    this.loadChartsBar4(res.data.data);
                }
            });
        },
        // 用户车损案件报价活跃度排行榜
        getCompanyActiveVehicleDamage() {
            let navForm = JSON.parse(JSON.stringify(this.navForm));
            navForm.order = this.chartsBar5Radio == '倒序' ? '1' : '2';
            this.instance.post('/user/operation/companyActiveVehicleDamage', navForm).then((res) => {
                if (res.data.code == 1) {
                    this.loadChartsBar5(res.data.data);
                }
            });
        },
        // 账户活跃情况
        getUserActiveAccount() {
            let navForm = JSON.parse(JSON.stringify(this.navForm));
            this.instance.post('/user/operation/userActiveAccount', navForm).then((res) => {
                if (res.data.code == 1 && res.data.data) {
                    let response = res.data.data;
                    this.loadChartsline1(response);
                }
            });
        },
        // 用户风险提示
        getUserActiveRiskUse() {
            let navForm = JSON.parse(JSON.stringify(this.navForm));
            this.instance.post('/user/operation/userActiveRiskUse', navForm).then((res) => {
                if (res.data.code == 1) {
                    let response = res.data.data;
                    this.loadChartsline2(response);
                }
            });
        },
        // 用户风险处理
        getUserActiveRiskHandle() {
            let navForm = JSON.parse(JSON.stringify(this.navForm));
            this.instance.post('/user/operation/userActiveRiskHandle', navForm).then((res) => {
                if (res.data.code == 1) {
                    let response = res.data.data;
                    this.loadChartsline3(response);
                }
            });
        },
        // 个人风险处理
        getUserActiveModelRisk() {
            let navForm = JSON.parse(JSON.stringify(this.navForm));
            this.instance.post('/user/operation/userActiveModelRisk', navForm).then((res) => {
                if (res.data.code == 1) {
                    let response = res.data.data;
                    this.loadChartsBar7(response);
                }
            });
        },
        // 用户车损
        getUserActiveVehicleDamage() {
            let navForm = JSON.parse(JSON.stringify(this.navForm));
            this.instance.post('/user/operation/userActiveVehicleDamage', navForm).then((res) => {
                if (res.data.code == 1) {
                    let response = res.data.data;
                    this.loadChartsline4(response);
                }
            });
        },
        // 用户人伤
        getUserActiveHumanInjury() {
            let navForm = JSON.parse(JSON.stringify(this.navForm));
            this.instance.post('/user/operation/userActiveHumanInjury', navForm).then((res) => {
                if (res.data.code == 1) {
                    let response = res.data.data;
                    this.loadChartsline5(response);
                }
            });
        },
        querySearch(queryString, cb) {
            var restaurants = this.restaurants;
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (restaurant) => {
                return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) != -1;
            };
        },
        //加载异常报价品牌TOP5&异常次数统计图表
        loadChartsBar1(response) {
            let chartDom = document.getElementById('charts_bar1');
            if (!chartDom) {
                return;
            }
            this.chartsBar1 = echarts.init(chartDom);
            this.chartsBar1.clear();

            let xAxisData = [];
            let seriesData = [];
            let optionColor = ['#2C7BE4', '#CC66FF'];
            if (response) {
                response.forEach((val, key) => {
                    xAxisData.push(val.itemName);
                    seriesData.push(val.targetNum);
                });
            }

            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                // legend: {
                //     // data: legendData
                // },
                color: optionColor,
                xAxis: [
                    {
                        type: 'category',
                        axisLabel: AXIS_LABEL,
                        data: xAxisData,
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }
                ],
                grid: {
                    top: '10%',
                    right: '5%',
                    bottom: '20px'
                },
                series: [
                    {
                        name: '账号活跃度',
                        type: 'bar',
                        barMaxWidth: '28px',
                        data: seriesData
                    }
                ]
            };
            this.chartsBar1.setOption(option);
        },
        //异常报价报价人TOP5&报价金额统计
        loadChartsBar2(response) {
            let chartDom = document.getElementById('charts_bar2');
            if (!chartDom) {
                return;
            }
            this.chartsBar2 = echarts.init(chartDom);
            this.chartsBar2.clear();

            let xAxisData = [];
            let seriesData1 = [];
            let seriesData2 = [];
            let legendData = ['用户总数', '插件弹出账号数'];
            let optionColor = ['#2C7BE4', '#CC66FF'];
            if (response) {
                response.forEach((val, key) => {
                    xAxisData.push(val.itemName);
                    seriesData1.push(val.totalNum);
                    seriesData2.push(val.targetNum);
                });
            }

            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: legendData
                },
                color: optionColor,
                xAxis: [
                    {
                        type: 'category',
                        axisLabel: AXIS_LABEL,
                        data: xAxisData,
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }
                ],
                grid: {
                    top: '20%',
                    right: '5%',
                    bottom: '20px'
                },
                series: [
                    {
                        name: '用户总数',
                        type: 'bar',
                        barMaxWidth: '28px',
                        data: seriesData1
                    },
                    {
                        name: '插件弹出账号数',
                        type: 'bar',
                        barMaxWidth: '28px',
                        data: seriesData2
                    }
                ]
            };
            this.chartsBar2.setOption(option);
        },
        // 风险
        loadChartsBar3(response) {
            let chartDom = document.getElementById('charts_bar3');
            if (!chartDom) {
                return;
            }
            this.chartsBar3 = echarts.init(chartDom);
            this.chartsBar3.clear();

            let xAxisData = [];
            let seriesData1 = [];
            let seriesData2 = [];
            let legendData = ['风险案件总数', '处理案件数'];
            let optionColor = ['#2C7BE4', '#CC66FF'];
            if (response) {
                response.forEach((val, key) => {
                    xAxisData.push(val.itemName);
                    seriesData1.push(val.totalNum);
                    seriesData2.push(val.targetNum);
                });
            }
            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: legendData
                },
                color: optionColor,
                xAxis: [
                    {
                        type: 'category',
                        axisLabel: AXIS_LABEL,
                        data: xAxisData,
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }
                ],
                grid: {
                    top: '20%',
                    right: '5%',
                    bottom: '20px'
                },
                series: [
                    {
                        name: '风险案件总数',
                        type: 'bar',
                        barMaxWidth: '28px',
                        data: seriesData1
                    },
                    {
                        name: '处理案件数',
                        type: 'bar',
                        barMaxWidth: '28px',
                        data: seriesData2
                    }
                ]
            };
            this.chartsBar3.setOption(option);
        },
        // 公估公司风险
        loadChartsBar6(response) {
            let chartDom = document.getElementById('charts_bar6');
            if (!chartDom) {
                return;
            }
            this.chartsBar6 = echarts.init(chartDom);
            this.chartsBar6.clear();

            let xAxisData = [];
            let seriesData1 = [];
            let seriesData2 = [];
            let legendData = ['标记案件数量', '标记为风险案件数量'];
            let optionColor = ['#2C7BE4', '#CC66FF'];
            if (response) {
                response.forEach((val, key) => {
                    xAxisData.push(val.itemName);
                    seriesData1.push(val.totalNum);
                    seriesData2.push(val.targetNum);
                });
            }
            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: legendData
                },
                color: optionColor,
                xAxis: [
                    {
                        type: 'category',
                        axisLabel: AXIS_LABEL,
                        data: xAxisData,
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }
                ],
                grid: {
                    top: '20%',
                    right: '5%',
                    bottom: '20px'
                },
                series: [
                    {
                        name: '标记案件数量',
                        type: 'bar',
                        barMaxWidth: '28px',
                        data: seriesData1
                    },
                    {
                        name: '标记为风险案件数量',
                        type: 'bar',
                        barMaxWidth: '28px',
                        data: seriesData2
                    }
                ]
            };
            this.chartsBar6.setOption(option);
        },
        // 个人风险案件处理图表
        loadChartsBar7(response) {
            let chartDom = document.getElementById('charts_line7');
            if (!chartDom) {
                return;
            }
            this.chartsLine7 = echarts.init(chartDom);
            this.chartsLine7.clear();

            let xAxisData = [];
            let seriesData1 = [];
            let seriesData2 = [];
            let legendData = ['标记案件数量', '标记为风险案件数量'];
            let optionColor = ['#2C7BE4', '#CC66FF'];
            if (response) {
                response.forEach((val, key) => {
                    xAxisData.push(val.itemName);
                    seriesData1.push(val.totalNum);
                    seriesData2.push(val.targetNum);
                });
            }
            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: legendData
                },
                color: optionColor,
                xAxis: [
                    {
                        type: 'category',
                        axisLabel: AXIS_LABEL,
                        data: xAxisData,
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }
                ],
                grid: {
                    top: '20%',
                    right: '5%',
                    bottom: '20px'
                },
                series: [
                    {
                        name: '标记案件数量',
                        type: 'bar',
                        barMaxWidth: '28px',
                        data: seriesData1
                    },
                    {
                        name: '标记为风险案件数量',
                        type: 'bar',
                        barMaxWidth: '28px',
                        data: seriesData2
                    }
                ]
            };
            this.chartsLine7.setOption(option);
        },
        // 人伤
        loadChartsBar4(response) {
            let chartDom = document.getElementById('charts_bar4');
            if (!chartDom) {
                return;
            }
            this.chartsBar4 = echarts.init(chartDom);
            this.chartsBar4.clear();

            let xAxisData = [];
            let seriesData1 = [];
            let seriesData2 = [];
            let optionColor = ['#2C7BE4', '#CC66FF'];
            if (response) {
                response.forEach((val, key) => {
                    xAxisData.push(val.itemName);
                    seriesData1.push(val.targetNum);
                });
            }
            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                color: optionColor,
                xAxis: [
                    {
                        type: 'category',
                        axisLabel: AXIS_LABEL,
                        data: xAxisData,
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }
                ],
                grid: {
                    top: '20%',
                    right: '5%',
                    bottom: '20px'
                },
                series: [
                    {
                        name: '人伤案件报价活跃度',
                        type: 'bar',
                        barMaxWidth: '28px',
                        data: seriesData1
                    }
                ]
            };
            this.chartsBar4.setOption(option);
        },
        // 车损
        loadChartsBar5(response) {
            let chartDom = document.getElementById('charts_bar5');
            if (!chartDom) {
                return;
            }
            this.chartsBar5 = echarts.init(chartDom);
            this.chartsBar5.clear();

            let xAxisData = [];
            let seriesData1 = [];
            let seriesData2 = [];
            let optionColor = ['#2C7BE4', '#CC66FF'];
            if (response) {
                response.forEach((val, key) => {
                    xAxisData.push(val.itemName);
                    seriesData1.push(val.targetNum);
                });
            }
            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                color: optionColor,
                xAxis: [
                    {
                        type: 'category',
                        axisLabel: AXIS_LABEL,
                        data: xAxisData,
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }
                ],
                grid: {
                    top: '20%',
                    right: '5%',
                    bottom: '20px'
                },
                series: [
                    {
                        name: '车损案件报价活跃度',
                        type: 'bar',
                        barMaxWidth: '28px',
                        data: seriesData1
                    }
                ]
            };
            this.chartsBar5.setOption(option);
        },
        loadChartsline1(response) {
            let chartDom = document.getElementById('charts_line1');
            if (!chartDom) {
                return;
            }
            this.chartsLine1 = echarts.init(chartDom);
            this.chartsLine1.clear();

            let xAxisData = [];
            let seriesData1 = [];
            let seriesData2 = [];
            let legendData = ['活跃天数', '日均登录时长'];
            let optionColor = ['#0346F8', '#FF9A05'];
            if (response) {
                response.forEach((val, key) => {
                    xAxisData.push(val.itemName);
                    seriesData1.push(val.targetNum);
                    seriesData2.push(val.targetRatio);
                });
            }
            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: legendData
                },
                color: optionColor,
                xAxis: [
                    {
                        type: 'category',
                        axisLabel: AXIS_LABEL,
                        data: xAxisData
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    },
                    {
                        name: '单位：分钟',
                        type: 'value',
                        splitLine: {
                            show: false // 隐藏y轴网格线
                        }
                    }
                ],
                grid: {
                    top: '20%',
                    left: '4%',
                    right: '4%'
                    // bottom: '20px'
                },
                dataZoom: [
                    {
                        type: 'slider',
                        xAxisIndex: 0,
                        start: 0,
                        end: (12 / xAxisData.length) * 100,
                        filterMode: 'none'
                    }
                ],
                series: [
                    {
                        name: '活跃天数',
                        type: 'bar',
                        barMaxWidth: '28px',
                        yAxisIndex: 0,
                        data: seriesData1
                    },
                    {
                        name: '日均登录时长',
                        type: 'line',
                        yAxisIndex: 1,
                        data: seriesData2
                    }
                ]
            };
            this.chartsLine1.setOption(option);
        },
        loadChartsline2(response) {
            let chartDom = document.getElementById('charts_line2');
            if (!chartDom) {
                return;
            }
            this.chartsLine2 = echarts.init(chartDom);
            this.chartsLine2.clear();

            let xAxisData = [];
            let seriesData1 = [];
            let seriesData2 = [];
            let legendData = ['插件弹出天数', '查看插件提示案件数'];
            let optionColor = ['#0346F8', '#FF9A05'];
            if (response) {
                response.forEach((val, key) => {
                    xAxisData.push(val.itemName);
                    seriesData1.push(val.targetNum);
                    seriesData2.push(val.targetExtNum);
                });
            }
            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: legendData
                },
                color: optionColor,
                xAxis: [
                    {
                        type: 'category',
                        // axisLabel: AXIS_LABEL,
                        axisLabel: {
                            show: true,
                            margin: 15,
                            rotate: 20,
                            interval: 0, // 使x轴文字显示全
                            formatter: function (params) {
                                var newParamsName = '';
                                var paramsNameNumber = params.length;
                                var provideNumber = 5; // 一行显示几个字
                                var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 计算需要几行显示
                                for (var i = 0; i < rowNumber; i++) {
                                    var tempStr = '';
                                    for (var j = 0; j < provideNumber; j++) {
                                        var index = i * provideNumber + j;
                                        if (index < paramsNameNumber) {
                                            tempStr += params[index];
                                        }
                                    }
                                    newParamsName += tempStr + '\n'; // 换行
                                }
                                return newParamsName;
                            }
                        },
                        data: xAxisData
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    },
                    {
                        type: 'value',
                        splitLine: {
                            show: false // 隐藏y轴网格线
                        }
                    }
                ],
                grid: {
                    top: '20%',
                    left: '45px',
                    right: '45px',
                    bottom: '75px'
                },
                dataZoom: [
                    {
                        type: 'slider',
                        xAxisIndex: 0,
                        start: 0,
                        end: (12 / xAxisData.length) * 100,
                        filterMode: 'none'
                    }
                ],
                series: [
                    {
                        name: '插件弹出天数',
                        type: 'bar',
                        barMaxWidth: '28px',
                        yAxisIndex: 0,
                        data: seriesData1
                    },
                    {
                        name: '查看插件提示案件数',
                        type: 'line',
                        yAxisIndex: 1,
                        data: seriesData2
                    }
                ]
            };
            this.chartsLine2.setOption(option);
        },
        loadChartsline3(response) {
            let chartDom = document.getElementById('charts_line3');
            if (!chartDom) {
                return;
            }
            this.chartsLine3 = echarts.init(chartDom);
            this.chartsLine3.clear();

            let xAxisData = [];
            let seriesData1 = [];
            let seriesData2 = [];
            let legendData = ['点击减损建议处理按钮', '接受率'];
            let optionColor = ['#0346F8', '#FF9A05'];
            if (response) {
                response.forEach((val, key) => {
                    xAxisData.push(val.itemName);
                    seriesData1.push(val.targetNum);
                    seriesData2.push(val.targetRatio);
                });
            }
            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: legendData
                },
                color: optionColor,
                xAxis: [
                    {
                        type: 'category',
                        // axisLabel: AXIS_LABEL,
                        axisLabel: {
                            show: true,
                            margin: 15,
                            rotate: 20,
                            interval: 0, // 使x轴文字显示全
                            formatter: function (params) {
                                var newParamsName = '';
                                var paramsNameNumber = params.length;
                                var provideNumber = 5; // 一行显示几个字
                                var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 计算需要几行显示
                                for (var i = 0; i < rowNumber; i++) {
                                    var tempStr = '';
                                    for (var j = 0; j < provideNumber; j++) {
                                        var index = i * provideNumber + j;
                                        if (index < paramsNameNumber) {
                                            tempStr += params[index];
                                        }
                                    }
                                    newParamsName += tempStr + '\n'; // 换行
                                }
                                return newParamsName;
                            }
                        },
                        data: xAxisData
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    },
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: function (value) {
                                return value + '%';
                            }
                        },
                        splitLine: {
                            show: false // 隐藏y轴网格线
                        }
                    }
                ],
                grid: {
                    top: '20%',
                    left: '45px',
                    right: '45px',
                    bottom: '75px'
                },
                dataZoom: [
                    {
                        type: 'slider',
                        xAxisIndex: 0,
                        start: 0,
                        end: (12 / xAxisData.length) * 100,
                        filterMode: 'none'
                    }
                ],
                series: [
                    {
                        name: '点击减损建议处理按钮',
                        type: 'bar',
                        barMaxWidth: '28px',
                        yAxisIndex: 0,
                        data: seriesData1
                    },
                    {
                        name: '接受率',
                        type: 'line',
                        yAxisIndex: 1,
                        data: seriesData2
                    }
                ]
            };
            this.chartsLine3.setOption(option);
        },
        loadChartsline4(response) {
            let chartDom = document.getElementById('charts_line4');
            if (!chartDom) {
                return;
            }
            this.chartsLine4 = echarts.init(chartDom);
            this.chartsLine4.clear();

            let xAxisData = [];
            let seriesData1 = [];
            let seriesData2 = [];
            let legendData = ['使用车损计算器查询案件数', '查询报价次数'];
            let optionColor = ['#0346F8', '#FF9A05'];
            if (response) {
                response.forEach((val, key) => {
                    xAxisData.push(val.itemName);
                    seriesData1.push(val.targetNum);
                    seriesData2.push(val.totalNum);
                });
            }
            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: legendData
                },
                color: optionColor,
                xAxis: [
                    {
                        type: 'category',
                        // axisLabel: AXIS_LABEL,
                        axisLabel: {
                            show: true,
                            margin: 15,
                            rotate: 20,
                            interval: 0, // 使x轴文字显示全
                            formatter: function (params) {
                                var newParamsName = '';
                                var paramsNameNumber = params.length;
                                var provideNumber = 5; // 一行显示几个字
                                var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 计算需要几行显示
                                for (var i = 0; i < rowNumber; i++) {
                                    var tempStr = '';
                                    for (var j = 0; j < provideNumber; j++) {
                                        var index = i * provideNumber + j;
                                        if (index < paramsNameNumber) {
                                            tempStr += params[index];
                                        }
                                    }
                                    newParamsName += tempStr + '\n'; // 换行
                                }
                                return newParamsName;
                            }
                        },
                        data: xAxisData
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    },
                    {
                        type: 'value',
                        splitLine: {
                            show: false // 隐藏y轴网格线
                        }
                    }
                ],
                grid: {
                    top: '20%',
                    left: '45px',
                    right: '45px',
                    bottom: '75px'
                },
                dataZoom: [
                    {
                        type: 'slider',
                        xAxisIndex: 0,
                        start: 0,
                        end: (12 / xAxisData.length) * 100,
                        filterMode: 'none'
                    }
                ],
                series: [
                    {
                        name: '使用车损计算器查询案件数',
                        type: 'bar',
                        barMaxWidth: '28px',
                        yAxisIndex: 0,
                        data: seriesData1
                    },
                    {
                        name: '查询报价次数',
                        type: 'line',
                        yAxisIndex: 1,
                        data: seriesData2
                    }
                ]
            };

            this.chartsLine4.setOption(option);
        },
        loadChartsline5(response) {
            let chartDom = document.getElementById('charts_line5');
            if (!chartDom) {
                return;
            }
            this.chartsLine5 = echarts.init(chartDom);
            this.chartsLine5.clear();

            let xAxisData = [];
            let seriesData1 = [];
            let seriesData2 = [];
            let legendData = ['使用人伤计算器查询案件数', '提交报价次数'];
            let optionColor = ['#0346F8', '#FF9A05'];
            if (response) {
                response.forEach((val, key) => {
                    xAxisData.push(val.itemName);
                    seriesData1.push(val.targetNum);
                    seriesData2.push(val.targetExtNum);
                });
            }
            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: legendData
                },
                color: optionColor,
                xAxis: [
                    {
                        type: 'category',
                        // axisLabel: AXIS_LABEL,
                        axisLabel: {
                            show: true,
                            margin: 15,
                            rotate: 20,
                            interval: 0, // 使x轴文字显示全
                            formatter: function (params) {
                                var newParamsName = '';
                                var paramsNameNumber = params.length;
                                var provideNumber = 5; // 一行显示几个字
                                var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 计算需要几行显示
                                for (var i = 0; i < rowNumber; i++) {
                                    var tempStr = '';
                                    for (var j = 0; j < provideNumber; j++) {
                                        var index = i * provideNumber + j;
                                        if (index < paramsNameNumber) {
                                            tempStr += params[index];
                                        }
                                    }
                                    newParamsName += tempStr + '\n'; // 换行
                                }
                                return newParamsName;
                            }
                        },
                        data: xAxisData
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    },
                    {
                        type: 'value',
                        splitLine: {
                            show: false // 隐藏y轴网格线
                        }
                    }
                ],
                grid: {
                    top: '20%',
                    left: '45px',
                    right: '45px',
                    bottom: '75px'
                },
                dataZoom: [
                    {
                        type: 'slider',
                        xAxisIndex: 0,
                        start: 0,
                        end: (12 / xAxisData.length) * 100,
                        filterMode: 'none'
                    }
                ],
                series: [
                    {
                        name: '使用人伤计算器查询案件数',
                        type: 'bar',
                        barMaxWidth: '28px',
                        yAxisIndex: 0,
                        data: seriesData1
                    },
                    {
                        name: '提交报价次数',
                        type: 'line',
                        yAxisIndex: 1,
                        data: seriesData2
                    }
                ]
            };
            this.chartsLine5.setOption(option);
        },
        getHumanInjuryCount() {
            this.instance.post('/user/usage/humanInjuryCount', this.navForm).then((res) => {
                if (res.data.code == 1) {
                    this.humanInjuryCount = res.data.data || {};
                }
            });
        },
        downloadFile() {
            this.loading = true;
            this.instance({
                method: 'post',
                url: '/user/operation/exportUsageStatus',
                data: this.navForm,
                responseType: 'blob'
            })
                .then((res) => {
                    this.loading = false;
                    let url = window.URL.createObjectURL(new Blob([res.data]));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', '用户使用情况.xlsx'); //指定下载后的文件名，防跳转
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
};
</script>
<style lang="less" scoped>
@import '../../assets/css/table_public.css';

.charts_null {
    text-align: center;
    font-size: 0.2rem;
    color: #666;
    line-height: 3.3rem;
}
.null_data {
    width: 100%;
    text-align: center;
    font-size: 0.16rem;
    color: #333;
    padding-top: 0.44rem;
}
.null_data div {
    width: 1.93rem;
    margin: 0 auto 0.24rem;
}
.charts_bar {
    width: 100%;
    min-height: 180px;
    height: 90%;
    margin-top: 0.1rem;
}
.charts_top {
    width: 100%;
    margin: 0.3rem 0;
    /* display: flex;
    justify-content: space-between; */
}
.charts_bottom {
    width: 100%;
    height: 270px;
}
#charts_line {
    width: 100%;
    height: 3.5rem;
}
/* .charts_top .public_box {
    width: 30.6%;
    min-height: 4.3rem;
}
.charts_top .charts_bar3 {
    width: 35%;
} */
.wrap {
    width: 100%;
    /* height: 100%; */
    position: relative;
}
.workorder_statistics {
    width: 100%;
    display: flex;
    justify-content: space-between;
    // margin: 0.3rem 0;
}
.workorder_statistics .chart_public {
    margin-left: 1.33%;
    width: 24%;
    /* text-align: right; */
}
.chart_public {
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(223, 223, 223, 0.5);
    border: 1px solid #ececec;
}
.workorder_statistics .chart_public:first-child {
    margin-left: 0;
}
.workorder_icon {
    width: 0.7rem;
}
.workorder_data {
    /* width: auto; */
    /* width:1.95rem ; */
}
.workorder_num {
    margin-bottom: 10px;
    font-size: 0.32rem;
    font-weight: 600;
}
.workorder_num p {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    text-align: center;
}
.workorder_num b {
    color: #e4393c;
}
.workorder_data p {
    margin: 0.1rem 0 0.26rem;
    font-size: 14px;
    color: #333333;
}
.average {
    font-size: 14px;
    color: #1989fa;
    line-height: 1.6;
}
.detail_title {
    font-size: 0.22rem;
}
.chart_title {
    text-align: center;
    margin-bottom: 12px;
    font-size: 16px;
}
.download_icon {
    font-size: 24px;
    color: #409eff;
    cursor: pointer;
}
/deep/ .el-radio-button--mini .el-radio-button__inner {
    padding: 5px 8px;
    font-size: 10px;
}
.ranking_box {
    padding: 0.2rem;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgb(223 223 223 / 50%);
    /* border-radius: 0.1rem; */
    border: 1px solid #ececec;
}
.ranking_box /deep/ .el-radio-button--mini .el-radio-button__inner {
    padding: 7px 15px;
}
.grid-content {
    height: 100px;
    border: 1px solid red;
}

.user_liveness {
    .workorder_title {
        font-size: 18px;
        margin: 15px 0;
        padding-left: 15px;
        position: relative;
    }
    .workorder_title::before {
        content: '';
        display: inline-block;
        width: 5px;
        height: 100%;
        background: #1989fa;
        position: absolute;
        left: 0px;
        top: 0;
    }
}
.chart_row {
    margin-top: 20px;
}
</style>
