<template>
    <div class="wrap">
        <iframe class="iframe" :src="iframeSrc"></iframe>
    </div>
</template>

<script>
// @ is an alias to /src
// import MyHeader from '@/components/MyHeader.vue'
export default {
    name: 'carDamage',
    components: {},
    data() {
        return {
            iframeSrc: ''
        }
    },
    methods: {},
    mounted() {
        //
        var userInfo = localStorage.getItem('userInfo')
        var jobNo = '',
            role = '',
            token = ''
        if (userInfo) {
            userInfo = JSON.parse(userInfo)
            jobNo = userInfo.jobNo
            role = userInfo.role
            token = userInfo.token.token
        }

        var iframeSrc = location.protocol == 'http:' ? '//calculatorjia.sigreal.com.cn' : '//calculatorjia.sigreal.cn'
        iframeSrc = iframeSrc + '?jobNo=' + jobNo + '&role=' + role + '&token=' + token + '&userid=' + userInfo.id
        this.iframeSrc = iframeSrc
    }
}
</script>
<style scoped>
@import '../../../assets/css/table_public.css';
.wrap {
    height: 100%;
}
.iframe {
    height: 100%;
    width: 100%;
    border: none;
}
</style>
