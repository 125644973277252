<template>
    <div class="wrap">
        <div class="page_title"><b>工作台</b></div>
    </div>
</template>

<script>
export default {
    name: 'userLiveness',
    components: {},
    data() {
        return {};
    },
    mounted() {}
};
</script>