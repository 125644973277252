<template>
    <div class="case_card caseInsurance">
        <el-row class="title_row">
            <el-col :span="12">
                <div class="card_title">保险案件信息</div>
            </el-col>
            <el-col class="btn_groups" :span="12">
                <div class="default_btn" v-if="!editLawyer && !editTaibao">
                    <el-button type="primary" v-if="btnMenuData && btnMenuData[500]" @click="commitFlag = true">提 交</el-button>
                    <el-button type="primary" v-if="btnMenuData && btnMenuData[300]" @click="(e) => editContent(e)">编 辑</el-button>
                </div>
                <div class="default_btn" v-if="editLawyer || editTaibao">
                    <el-button type="primary" @click="(e) => editContent(e, true)">保 存</el-button>
                    <el-button @click="cancelEdit">取 消</el-button>
                </div>
            </el-col>
        </el-row>
        <!-- 展示 -->
        <div class="card_content" v-if="!editTaibao && !editLawyer">
            <el-row>
                <el-col :span="7">
                    <el-row class="content_row">
                        <el-col class="col_title" :span="8">案件号：</el-col>
                        <el-col class="col_txt" :span="16">{{ caseDetailData && caseDetailData.caseNo ? caseDetailData.caseNo : '--' }}</el-col>
                    </el-row>
                </el-col>
                <el-col :span="9">
                    <el-row class="content_row">
                        <el-col class="col_title" :span="8">保单号：</el-col>
                        <el-col class="col_txt" :span="16">{{ caseDetailData && caseDetailData.policyNo ? caseDetailData.policyNo : '--' }}</el-col>
                    </el-row>
                </el-col>
                <el-col :span="7" :offset="1">
                    <el-row class="content_row">
                        <el-col class="col_title" :span="8">出险时间：</el-col>
                        <el-col class="col_txt" :span="16">{{ caseDetailData && caseDetailData.accidentTime ? caseDetailData.accidentTime : '--' }}</el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="7">
                    <el-row class="content_row">
                        <el-col class="col_title" :span="8">
                            <div>
                                <span style="margin-left: -40px">保险产品类型</span>
                                <span>：</span>
                            </div>
                        </el-col>
                        <el-col class="col_txt" :span="16">{{ caseDetailData && caseDetailData.productType ? caseDetailData.productType : '--' }}</el-col>
                    </el-row>
                </el-col>
                <el-col :span="9">
                    <el-row class="content_row">
                        <el-col class="col_title" :span="8">险种：</el-col>
                        <el-col class="col_txt" :span="16">{{ caseDetailData && caseDetailData.insureTypeName ? caseDetailData.insureTypeName : '--' }}</el-col>
                    </el-row>
                </el-col>
                <el-col :span="7" :offset="1">
                    <el-row class="content_row">
                        <el-col class="col_title" :span="8">投保人名称：</el-col>
                        <el-col class="col_txt" :span="16">{{ caseDetailData && caseDetailData.insuranceApplicant ? caseDetailData.insuranceApplicant : '--' }}</el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="7">
                    <el-row class="content_row">
                        <el-col class="col_title" :span="8">骑手：</el-col>
                        <el-col class="col_txt" :span="16">{{ caseDetailData && caseDetailData.nameOfDeliveryMan ? caseDetailData.nameOfDeliveryMan : '--' }}</el-col>
                    </el-row>
                </el-col>
                <el-col :span="9">
                    <el-row class="content_row">
                        <el-col class="col_title" :span="8">保障时间：</el-col>
                        <el-col class="col_txt" :span="16">{{ caseDetailData && caseDetailData.startingTimeOfProtection ? caseDetailData.startingTimeOfProtection : '--' }}{{ caseDetailData && caseDetailData.stoppingTimeOfProtection ? ' 至 ' + caseDetailData.stoppingTimeOfProtection : '' }}</el-col>
                    </el-row>
                </el-col>
                <el-col :span="7" :offset="1">
                    <el-row class="content_row">
                        <el-col class="col_title" :span="8">出险省份：</el-col>
                        <el-col class="col_txt" :span="16">{{ caseDetailData && caseDetailData.accidentCityDistrict ? caseDetailData.accidentCityDistrict : '--' }}</el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
        <!-- 编辑 -太保 -->
        <div class="card_content edit_content" v-if="editTaibao">
            <el-form class="editCaseForm" :model="infuranceData" label-width="130px">
                <el-row>
                    <el-col :span="15" :offset="1">
                        <el-form-item class="special_item" label="案件名称：" prop="caseName">
                            <el-input v-model="infuranceData.caseName" placeholder="请输入内容"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-form class="editCaseForm" :model="queryCaseForm" :rules="queryCaseRules" ref="queryCaseRef" label-width="130px">
                <el-row>
                    <el-col :span="10">
                        <el-form-item :class="{ form_item: 1, form_case: 1, exist_case: isCaseExist }" label="报案号或美团案件号：" prop="caseNo">
                            <el-row class="content_row">
                                <el-col class="col_txt exist_col" :span="13">
                                    <el-input ref="formCase" :class="{ errorBorder: queryInputCase }" v-model.trim="queryCaseForm.caseNo" clearable @change="inputChange" @blur="checkLawsuitCase"></el-input>
                                    <div class="exist_message" v-if="isCaseExist">该报案号已录入过</div>
                                </el-col>
                                <template v-if="!manualEdit">
                                    <el-col class="col_txt" :span="2" :offset="1"><el-button type="primary" @click="searchMeiTuanCase">查询</el-button></el-col>
                                    <el-col class="col_txt" :span="1"><el-button class="manual_btn" type="primary" @click="manualEntry(true)">手动录入</el-button></el-col>
                                </template>
                                <el-col class="col_txt" :span="2" :offset="1" v-else><el-button type="primary" @click="manualEntry(false)">取消录入</el-button></el-col>
                            </el-row>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item class="special_item" label="原告：" prop="plaintiff">
                            <el-input v-model="infuranceData.plaintiff" placeholder="请输入内容"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="7" :offset="1">
                        <el-form-item label="判决/调解：" prop="judgmentMediation">
                            <el-select v-model="infuranceData.judgmentMediation" placeholder="请选择">
                                <el-option v-for="item in judgmentMediationOptions" :key="item.id" :label="item.dictName" :value="item.dictName"> </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-form class="infuranceForm" :model="infuranceData" :rules="infuranceRules" ref="infuranceRef" label-width="130px">
                <template v-if="manualEdit">
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="保单号：" prop="policyNo">
                                <el-input v-model.trim="infuranceData.policyNo"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7" :offset="1">
                            <el-form-item label="出险时间：" prop="accidentTime">
                                <el-date-picker class="width_inherit" v-model="infuranceData.accidentTime" type="date" placeholder="选择日期"> </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7" :offset="1">
                            <el-form-item label="保险产品类型：" prop="productType">
                                <el-select v-model="infuranceData.productType" placeholder="请选择">
                                    <el-option v-for="(item, index) in productTypeOptions" :key="index" :label="item" :value="item"> </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="险种：" prop="insureTypeName">
                                <el-select class="width_inherit" v-model="infuranceData.insureTypeName" placeholder="请选择">
                                    <el-option v-for="(item, index) in insureTypeOptions" :key="index" :label="item" :value="item"> </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7" :offset="1">
                            <el-form-item label="骑手：" prop="nameOfDeliveryMan">
                                <el-input v-model.trim="infuranceData.nameOfDeliveryMan"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7" :offset="1">
                            <el-form-item label="投保人名称：" prop="insuranceApplicant">
                                <el-input v-model.trim="infuranceData.insuranceApplicant"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="出险省份：" prop="accidentCityDistrict">
                                <el-input v-model.trim="infuranceData.accidentCityDistrict"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </template>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="诉讼金额：" prop="lawsuitAmount">
                            <el-input
                                v-model="infuranceData.lawsuitAmount"
                                maxlength="11"
                                oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '').replace(/^0+(\d)/, '$1')"
                                placeholder="请输入内容"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="7" :offset="1">
                        <el-form-item label="诉讼原因：" prop="lawsuitCause">
                            <el-select v-model="infuranceData.lawsuitCause" placeholder="请选择">
                                <el-option v-for="item in causeOptions" :key="item.id" :label="item.dictName" :value="item.dictName"> </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="是否上诉：" prop="appealFlag">
                            <el-checkbox v-model="infuranceData.appealFlag" :disabled="isDisabled" @change="(e) => checkboxChange(e, 0)">是</el-checkbox>
                        </el-form-item>
                    </el-col>
                    <el-col :span="7" :offset="1">
                        <el-form-item label="是否已履行支付：" prop="performancePayment">
                            <el-checkbox v-model="infuranceData.performancePayment">是</el-checkbox>
                        </el-form-item>
                    </el-col>
                    <el-col :span="7" :offset="1">
                        <el-form-item label="实际支付日期：" prop="actualPaymentDate">
                            <el-date-picker class="width_inherit" v-model="infuranceData.actualPaymentDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"> </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="caseDetailData.appealFlag">
                    <el-col :span="8">
                        <el-form-item label="是否再审：" prop="reviewFlag">
                            <el-checkbox v-model="infuranceData.reviewFlag" :disabled="isReviewDisabled" @change="(e) => checkboxChange(e, 1)">是</el-checkbox>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row class="content_row exception_row">
                    <el-col>
                        <el-form-item label="情况：" prop="situation">
                            <el-input v-model="infuranceData.situation" type="textarea" :rows="4" placeholder="请输入内容"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <!-- 编辑 -律师 -->
        <div class="card_content edit_content" v-if="editLawyer">
            <el-row>
                <el-col :span="8">
                    <el-row class="content_row">
                        <el-col class="col_title" :span="6">原告：</el-col>
                        <el-col class="col_txt" :span="18"><el-input v-model="editLawyerData.plaintiff" placeholder="请输入内容"></el-input></el-col>
                    </el-row>
                </el-col>
                <el-col :span="7" :offset="1">
                    <el-row class="content_row">
                        <el-col class="col_title" :span="8">诉讼金额：</el-col>
                        <el-col class="col_txt" :span="16"><el-input v-model="editLawyerData.lawsuitAmount" placeholder="请输入内容"></el-input></el-col>
                    </el-row>
                </el-col>
                <el-col :span="7" :offset="1">
                    <el-row class="content_row">
                        <el-col class="col_title" :span="8">诉讼原因：</el-col>
                        <el-col class="col_txt" :span="16">
                            <el-select v-model="editLawyerData.lawsuitCause" placeholder="请选择">
                                <el-option v-for="item in causeOptions" :key="item.id" :label="item.dictName" :value="item.dictName"> </el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-row class="content_row">
                        <el-col class="col_title" :span="6">判决/调解：</el-col>
                        <el-col class="col_txt" :span="18">
                            <el-select v-model="editLawyerData.judgmentMediation" placeholder="请选择">
                                <el-option v-for="item in judgmentMediationOptions" :key="item.id" :label="item.dictName" :value="item.dictName"> </el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-row class="content_row exception_row">
                <el-col class="col_title" :span="2">情况： </el-col>
                <el-col :span="22">
                    <el-input v-model="editLawyerData.situation" type="textarea" :rows="4" placeholder="请输入内容" style="margin-bottom: 20px"> </el-input>
                </el-col>
            </el-row>
            <el-row class="content_row exception_row" v-if="0">
                <el-col class="col_title" :span="2">备注： </el-col>
                <el-col :span="22">
                    <el-input v-model="editLawyerData.situation" type="textarea" :rows="4" placeholder="请输入内容"> </el-input>
                </el-col>
            </el-row>
        </div>
        <!-- 上诉弹窗 -->
        <el-dialog title="上诉" :visible.sync="appealFlag" :modal-append-to-body="false" :close-on-click-modal="false" width="30%" center>
            <p style="text-align: center">是否确定上诉，开启二审流程？</p>
            <span slot="footer" class="dialog-footer">
                <el-button type="danger" @click="appealFn(true, 0)">确 认</el-button>
                <el-button @click="appealFn(false, 0)">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 再审弹窗 -->
        <el-dialog title="上诉" :visible.sync="reviewFlag" :modal-append-to-body="false" :close-on-click-modal="false" width="30%" center>
            <p style="text-align: center">是否确定申请再审，开启再审流程？</p>
            <span slot="footer" class="dialog-footer">
                <el-button type="danger" @click="appealFn(true, 1)">确 认</el-button>
                <el-button @click="appealFn(false, 1)">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 提交弹窗 -->
        <el-dialog title="提交案件至太保" :visible.sync="commitFlag" :modal-append-to-body="false" :close-on-click-modal="false" width="450px" center>
            <div class="dialog_content">
                <p style="margin-bottom: 10px">确定案件信息已完整录入，提交至太保后，案件信息不可修改。</p>
                <el-form class="commit_form" :model="commitForm" ref="commitRef" :rules="commitRules" label-width="120px">
                    <el-form-item label="提交至诉管岗：" prop="distributeHandler">
                        <el-select class="width_inherit" v-model="commitForm.distributeHandler" filterable placeholder="请选择">
                            <el-option v-for="item in commitCaseUserOption" :key="item.userId" :label="item.nickName" :value="item.userId"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="情况说明：" prop="compensationAmount">
                        <el-input v-model="commitForm.situation" type="textarea" :rows="4" maxlength="200" show-word-limit></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="commitFn(true)">提 交</el-button>
                <el-button @click="commitFn(false)">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
let queryCaseRuleFlag = false;
const validatePass = (rule, value, callback) => {
    if (value.length > 0 && queryCaseRuleFlag) {
        callback(new Error('请输入正确的理赔报案号重新查询'));
    } else {
        callback();
    }
};
export default {
    props: {
        caseDetailData: {
            type: Object,
            default: null
        },
        btnMenuData: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            isCaseExist: false,
            manualEdit: false,
            loading: false,
            editLawyer: false,
            editTaibao: false,
            appealFlag: false,
            reviewFlag: false,
            commitFlag: false,
            queryCaseFlag: false,
            queryInputCase: false,
            inputChangeFlag: false,
            isDisabled: false,
            isReviewDisabled: false,
            navForm: {
                caseNo: '',
                value1: [],
                value2: [],
                value3: '',
                value4: '',
                value5: '',
                page: 1,
                size: 10,
                total: 0
            },
            commitForm: {
                distributeHandler: '',
                situation: ''
            },
            input: '',
            causeOptions: [],
            productTypeOptions: [],
            insureTypeOptions: [],
            judgmentMediationOptions: [],
            options: [],
            value: '',
            value1: '',
            textarea: '',
            infuranceData: {
                province: '',
                city: '',
                district: '',
                caseName: '',
                caseNo: '',
                caseId: '',
                plaintiff: '',
                judgmentMediation: '',
                lawsuitAmount: '',
                lawsuitCause: '',
                appealFlag: null,
                performancePayment: null,
                actualPaymentDate: '',
                situation: '',
                reviewFlag: ''
            },
            editLawyerData: {
                plaintiff: '',
                lawsuitAmount: '',
                lawsuitCause: '',
                judgmentMediation: '',
                situation: ''
            },
            queryCaseForm: {
                caseNo: ''
            },
            queryCaseRules: {
                caseNo: [
                    { required: true, message: '请输入案件号', trigger: ['blur'] },
                    { required: true, trigger: 'blur', validator: validatePass }
                ]
            },
            commitRules: {
                distributeHandler: [{ required: true, message: '请选择诉管岗', trigger: ['blur', 'change'] }]
            },
            infuranceRules: {
                province: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                city: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                district: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                policyNo: [{ required: true, message: '请输入保单号', trigger: ['blur', 'change'] }],
                accidentTime: [{ required: true, message: '请选择出险时间', trigger: ['blur', 'change'] }],
                productType: [{ required: true, message: '请选择保险产品类型', trigger: ['blur', 'change'] }],
                insureTypeName: [{ required: true, message: '请选择险种', trigger: ['blur', 'change'] }],
                nameOfDeliveryMan: [{ required: true, message: '请输入骑手', trigger: ['blur', 'change'] }],
                insuranceApplicant: [{ required: true, message: '请输入投保人名称', trigger: ['blur', 'change'] }],
                accidentCityDistrict: [{ required: true, message: '请输入出险省份', trigger: ['blur', 'change'] }]
            },
            commitCaseUserOption: [],
            commitUserObj: {},
            queryCaseData: {},
            queryFlag: false,
            rolesData: []
        };
    },
    mounted() {
        // 诉讼原因
        this.getCause();
        // 保险产品类型
        this.getProductType();
        // 险种类型
        this.getInsureTypeOptions();
        this.getCommitCaseUser();
        this.getJudgmentMediation();
    },
    methods: {
        getCaseDetail() {
            this.$emit('getCaseDetail');
        },
        caseEditLock(lockId) {
            // CASE_EDIT(1, "案件信息编辑")
            // CHECK_EDIT(2, "审核编辑")
            // FILE_EDIT(3, "文件编辑")
            this.instance.post('/lawsuitCase/caseEditLock', { caseId: this.caseDetailData.id, lockId: lockId }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
                    if (!data.data.lock || userInfo.userName == data.data.lockUserName) {
                        this.$emit('editInfo', false);
                        this.editBtnLogic();
                    } else {
                        this.$message.error(`${data.data.lockUser}-${data.data.phone}正在编辑，请等待编辑完成后再操作！`);
                    }
                }
            });
        },
        caseEditUnLock(lockId) {
            this.instance.post('/lawsuitCase/caseEditUnLock', { caseId: this.caseDetailData.id, lockId: lockId }).then((res) => {});
        },
        editBtnLogic() {
            this.cancelContent();
            this.infuranceData.caseId = this.caseDetailData.id;

            if (this.containsName(this.rolesData || [], '律师')) {
                this.editLawyer = true;
            } else {
                this.editTaibao = true;
                this.infuranceData.caseNo = this.caseDetailData.caseNo;
            }
            this.isDisabled = Boolean(this.caseDetailData.appealFlag);
            this.isReviewDisabled = Boolean(this.caseDetailData.reviewFlag);
        },
        containsName(arr, name) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].name === name) {
                    return true; // 如果找到匹配的对象，返回true
                }
            }
            return false; // 如果没有找到匹配的对象，返回false
        },
        searchMeiTuanCase() {
            this.queryFlag = true;
            this.queryInputCase = false;
            this.checkLawsuitCase();
            this.$refs.queryCaseRef.validate((valid) => {
                if (valid) {
                    this.queryCaseData = null;
                    this.instance
                        .get(`/lawsuitCase/getMeiTuanCase/${this.queryCaseForm.caseNo}`, {
                            params: {}
                        })
                        .then((res) => {
                            let data = res.data;
                            if (data.code == 1 && data.data) {
                                this.queryCaseFlag = true;
                                this.queryCaseData = data.data;
                                this.infuranceData.caseNo = data.data.caseNo;
                                this.$message.success('案件号查询成功！');
                            } else {
                                queryCaseRuleFlag = true;
                                this.$refs.queryCaseRef.validate();
                                queryCaseRuleFlag = false;
                                this.isCaseExist = false;
                            }
                        });
                } else {
                    this.queryInputCase = true;
                }
            });
        },
        manualEntry(manualFlag) {
            this.manualEdit = manualFlag;
            if (manualFlag) {
                queryCaseRuleFlag = false;
                this.$refs.queryCaseRef && this.$refs.queryCaseRef.resetFields();
                this.checkLawsuitCase();
            }
        },
        checkLawsuitCase() {
            if (!this.queryCaseForm.caseNo) {
                this.isCaseExist = false;
                return;
            }
            this.instance.get('/lawsuitCase/findLawsuitCase/' + this.queryCaseForm.caseNo, { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.isCaseExist = data.data;
                }
            });
        },
        getCause() {
            this.instance.get('/lawsuitCase/getCause', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.causeOptions = data.data;
                }
            });
        },
        getProductType() {
            this.instance.get('/lawsuitCase/getProductType', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.productTypeOptions = data.data;
                }
            });
        },
        getInsureTypeOptions() {
            this.instance.get('/lawsuitCase/getInsureTypeName', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.insureTypeOptions = data.data;
                }
            });
        },
        getCommitCaseUser() {
            this.instance.post('/lawsuitCase/findCommitCaseUser', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.commitUserObj = {};
                    this.commitCaseUserOption = data.data;
                    data.data.forEach((item) => {
                        this.commitUserObj[item.userId] = item;
                    });
                }
            });
        },
        getJudgmentMediation() {
            this.instance.get('/lawsuitCase/getJudgmentMediation', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.judgmentMediationOptions = data.data;
                }
            });
        },
        editInsuranceInfo(editForm) {
            if (this.manualEdit) {
                this.$refs.infuranceRef.validate((valid) => {
                    if (valid) {
                        this.editCaseFn(editForm);
                    }
                });
            } else {
                this.editCaseFn(editForm);
            }
        },
        editCaseFn(editForm) {
            let tmpFormData = JSON.parse(JSON.stringify(editForm));
            if (!this.manualEdit) {
                tmpFormData.policyNo = this.infuranceData.policyNo;
                tmpFormData.accidentTime = this.infuranceData.accidentTime;
                tmpFormData.productType = this.infuranceData.productType;
                tmpFormData.nameOfDeliveryMan = this.infuranceData.nameOfDeliveryMan;
                tmpFormData.insuranceApplicant = this.infuranceData.insuranceApplicant;
                tmpFormData.accidentCityDistrict = this.infuranceData.accidentCityDistrict;
                if (this.queryFlag) {
                    tmpFormData.policyNo = this.queryCaseData.policyNo;
                    tmpFormData.accidentTime = this.queryCaseData.accidentTime;
                    tmpFormData.productType = this.queryCaseData.productType;
                    tmpFormData.nameOfDeliveryMan = this.queryCaseData.nameOfDeliveryMan;
                    tmpFormData.insuranceApplicant = this.queryCaseData.insuranceApplicant;
                    tmpFormData.accidentCityDistrict = this.queryCaseData.accidentCityDistrict;
                }
            }
            this.instance.post('/lawsuitCase/editCase', tmpFormData).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.queryFlag = false;
                    this.isCaseExist = false;
                    this.manualEdit = false;
                    this.$emit('editInfo', true);
                    this.$message.success('保存成功！');
                    this.cancelContent();
                    this.$emit('getCaseDetail', true);
                    this.getCaseDetail();
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        cancelEdit() {
            this.queryFlag = false;
            this.$emit('editInfo', true);
            this.cancelContent();
            // 调用解锁接口
            this.caseEditUnLock(1);
        },
        editContent(e, submitFlag) {
            if (submitFlag) {
                // 500 提交按钮,律师才有提交按钮
                if (this.containsName(this.rolesData || [], '律师')) {
                    this.editLawyerData.caseId = this.caseDetailData.id;
                    this.editInsuranceInfo(this.editLawyerData);
                } else {
                    if (!this.queryCaseFlag && this.caseDetailData.caseNo != this.queryCaseForm.caseNo) {
                        this.$message.error('请先查询案件号！');
                        return;
                    }
                    this.editInsuranceInfo(this.infuranceData);
                }
            } else {
                this.caseEditLock(1);
            }
        },
        cancelContent() {
            let tmpCaseDetailData = JSON.parse(JSON.stringify(this.caseDetailData));
            this.queryCaseForm = {
                caseNo: tmpCaseDetailData.caseNo
            };
            // 500 提交按钮,律师才有提交按钮
            if (this.containsName(this.rolesData || [], '律师')) {
                this.editLawyer = false;
                // this.editLawyerData = {
                //     plaintiff: tmpCaseDetailData.plaintiff,
                //     lawsuitAmount: tmpCaseDetailData.lawsuitAmount,
                //     lawsuitCause: tmpCaseDetailData.lawsuitCause,
                //     judgmentMediation: tmpCaseDetailData.judgmentMediation,
                //     situation: tmpCaseDetailData.situation
                // };
                this.editLawyerData = tmpCaseDetailData;
            } else {
                this.editTaibao = false;
                this.queryCaseFlag = false;
                this.initInfuranceData();
            }
        },
        initInfuranceData() {
            let tmpCaseDetailData = JSON.parse(JSON.stringify(this.caseDetailData));
            this.infuranceData = tmpCaseDetailData;
            // this.infuranceData = {
            //     caseName: tmpCaseDetailData.caseName,
            //     caseNo: tmpCaseDetailData.caseNo,
            //     caseId: tmpCaseDetailData.caseId,
            //     plaintiff: tmpCaseDetailData.plaintiff,
            //     judgmentMediation: tmpCaseDetailData.judgmentMediation,
            //     lawsuitAmount: tmpCaseDetailData.lawsuitAmount,
            //     lawsuitCause: tmpCaseDetailData.lawsuitCause,
            //     appealFlag: tmpCaseDetailData.appealFlag,
            //     reviewFlag: tmpCaseDetailData.reviewFlag,
            //     performancePayment: tmpCaseDetailData.performancePayment,
            //     actualPaymentDate: tmpCaseDetailData.actualPaymentDate,
            //     situation: tmpCaseDetailData.situation
            // };
        },
        getRoles() {
            this.instance.post('/lawsuitCase/getRoles', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.rolesData = data.data;
                }
            });
        },
        commitFn(submitFlag) {
            if (submitFlag) {
                this.$refs.commitRef.validate((valid) => {
                    if (valid) {
                        this.commitForm.caseId = this.caseDetailData.id;
                        this.commitForm.distributeHandlerUser = this.commitUserObj[this.commitForm.distributeHandler].userName;
                        this.instance.post('/lawsuitCase/commitCase', this.commitForm).then((res) => {
                            let data = res.data;
                            if (data.code == 1) {
                                this.$message.success('提交成功！');
                                this.commitFlag = false;
                                this.$router.push({ path: '/litigationManage/myWorkbench' });
                            } else {
                                this.$message.error(data.message);
                            }
                            this.getCaseDetail();
                        });
                    }
                });
            } else {
                this.commitFlag = false;
            }
        },
        checkboxChange(val, currentIndex) {
            if (currentIndex == 0) {
                this.appealFlag = val;
            } else {
                this.reviewFlag = val;
            }
        },
        appealFn(submitFlag, currentIndex) {
            if (currentIndex == 0) {
                if (!submitFlag) {
                    this.infuranceData.appealFlag = false;
                }
                this.appealFlag = false;
            } else {
                if (!submitFlag) {
                    this.infuranceData.reviewFlag = false;
                }
                this.reviewFlag = false;
            }
        },
        inputChange() {
            this.inputChangeFlag = true;
        }
    },
    watch: {
        caseDetailData: {
            handler(val) {
                if (val) {
                    this.cancelContent();
                    this.getRoles();
                }
            },
            immediate: true
        }
    }
};
</script>
<style lang="less" scoped>
@import '../../assets/css/caseCard.css';
.caseInsurance {
    .title_row {
        .btn_groups {
            text-align: right;
        }
    }
    .card_content {
        padding-top: 20px;
        .content_row {
            margin-bottom: 20px;
            .col_title {
                text-align: right;
            }
            .col_txt {
                line-height: 22px;
            }
            .manual_btn {
                margin-left: 35px;
            }
        }
        .col_top {
            margin-top: 6px;
        }
    }
    // 编辑
    .edit_content {
        .content_row {
            display: flex;
            align-items: center;
        }
        .exception_row {
            align-items: flex-start !important;
        }
        .editCaseForm {
            // margin-top: 20px;
            .content_row {
                margin-bottom: 0px !important;
            }
            /deep/ .el-form-item__error {
                // left: 25% !important;
            }
            .form_case {
                position: relative;
                /deep/ .el-form-item__label {
                    position: absolute;
                    left: -45px;
                    width: 176px !important;
                }
            }
            .special_item {
                /deep/ .el-form-item__label {
                    width: 89px !important;
                }
                /deep/ .el-form-item__content {
                    margin-left: 89px !important;
                }
            }
            .exist_case {
                /deep/ .el-input__inner {
                    border: 1px solid #e6a23c;
                }
                .exist_col {
                    position: relative;
                    .exist_message {
                        color: #e6a23c;
                        font-size: 12px;
                        position: absolute;
                        bottom: -21px;
                    }
                }
            }
        }
    }
}
.province_box {
    display: flex;
    justify-content: space-between;
    .province_item {
        width: 45%;
    }
    .province_item:nth-child(2) {
        width: 25%;
        margin-left: 0px;
        /deep/ .el-form-item__content {
            margin-left: 0 !important;
        }
    }
    .province_item:nth-child(3) {
        width: 25%;
        margin-left: 0px;
        /deep/ .el-form-item__content {
            margin-left: 0 !important;
        }
    }
}

.dialog_content {
    .commit_form {
        margin-top: 20px;
        width: 87%;
    }
}
</style>