<template>
    <div class="wrap">
        <div class="page_title">
            <b>风险案件查询</b>
            <span>（案件列表显示满足筛选规则的案件）</span>
        </div>
        <div class="search_box public_box">
            <el-tabs v-model="activeName" @tab-click="handleClickTabs">
                <el-tab-pane label="我的规则" name="first"></el-tab-pane>
                <el-tab-pane label="自定义全局筛选" name="second"></el-tab-pane>
            </el-tabs>
            <!--检索部分-->
            <el-form :inline="true" :model="navForm" class="demo-form-inline">
                <div class="flex">
                    <div>
                        <el-form-item style="margin-right: 0.8rem">
                            <el-upload class="upload_box" accept=".xls,.xlsx" :action="baseURL + '/case/importCaseExcel'" :show-file-list="true" :headers="unloaderHeader" :limit="1" :on-remove="handleRemove" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                                <template>
                                    <el-button type="primary"><i class="el-icon-upload el-icon--left"></i>上传案件</el-button>
                                </template>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="结案时间：" class="dateBox">
                            <el-row style="width: 370px">
                                <el-col :span="11">
                                    <el-date-picker placeholder="请选择日期" clearable v-model="navForm.startTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                </el-col>
                                <el-col class="line" style="text-align: center" :span="2">至</el-col>
                                <el-col :span="11">
                                    <el-date-picker placeholder="请选择日期" clearable v-model="navForm.endTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item>
                            <span class="hint_text">（若无批量案件上传，可按结案时间查询）</span>
                        </el-form-item>
                    </div>
                    <div v-if="activeName == 'second'">
                        <el-form-item>
                            <el-button type="primary" plain icon="el-icon-plus" @click="addMyRule">添加为我的规则</el-button>
                        </el-form-item>
                    </div>
                </div>

                <template v-if="activeName == 'first'">
                    <br />
                    <el-form-item label="风险点组合名称：">
                        <el-select v-model="navForm.value" placeholder="请选择" style="width: 370px" clearable>
                            <el-option v-for="item in riskOptions" :key="item.id" :label="item.optionName" :value="item.id"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="searchTable" style="margin-left: 0.2rem">查询</el-button>
                    </el-form-item>
                </template>
                <template v-else>
                    <div class="custom_filtering">
                        <div class="condition_box">
                            <ul>
                                <li v-for="(items, index) in customFiltering">
                                    <div class="and_or" v-if="index != 0">
                                        <b :class="{ active: items.relation == 'and' }" @click="items.relation = 'and'">且</b>
                                        <b :class="{ active: items.relation == 'or' }" @click="items.relation = 'or'">或</b>
                                        <span></span>
                                    </div>
                                    <div class="condition_item">
                                        <div class="condition_lebal">
                                            <span>风险筛选项：</span>
                                            <b @click="handleClose(items, index)"></b>
                                        </div>
                                        <div class="condition_form">
                                            <el-select v-model="items.optionName" @change="optionnameChange(items)" style="font-size: 0.14rem" class="width_350" placeholder="请选择" clearable>
                                                <el-option style="font-size: 0.14rem" v-for="item in customFilterOptionList" :key="item.id" :label="item.optionName" :value="item.optionName"> </el-option>
                                            </el-select>
                                            <template v-for="(item, index) in customFilterOptionList">
                                                <template v-if="items.optionName == item.optionName">
                                                    <el-select v-if="items.optionType != 1" v-model="items.judgeOption" class="width_170" placeholder="请选择" clearable>
                                                        <el-option v-for="(option, key) in item.judgeOption" :label="option" :value="option" :key="key"> </el-option>
                                                    </el-select>
                                                    <el-select v-if="items.optionType == 3" v-model="items.optionValue" class="width_170" placeholder="请选择" clearable>
                                                        <el-option v-for="(option, key) in item.optionValue" :label="option" :value="option" :key="key"> </el-option>
                                                    </el-select>
                                                    <template v-if="items.optionType == 2 || items.optionType == 4">
                                                        <el-form-item>
                                                            <el-input v-model="items.optionsql1" type="number" class="width_170" placeholder="请输入，如1"></el-input>
                                                        </el-form-item>
                                                        <el-form-item v-if="items.judgeOption == '介于'">
                                                            <el-input v-model="items.optionsql2" type="number" class="width_170" placeholder="请输入，如1"></el-input>
                                                        </el-form-item>
                                                    </template>
                                                </template>
                                            </template>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="add_condition">
                            <div @click="addCondition"><img src="../../../public/images/plus_icon.png" alt="" /></div>
                            <span @click="addCondition">添加条件</span>
                        </div>
                    </div>
                    <div style="margin-top: 0.3rem; text-align: right"><el-button type="primary" @click="searchTable(2)" style="margin-left: 0.2rem">查询</el-button></div>
                </template>
            </el-form>
        </div>
        <div class="main">
            <div class="table_box public_box">
                <div class="table_title">
                    <b>案件详情</b>
                    <el-button @click="handleDownload" v-if="activeName == 'first' && tableData1.length > 0" class="download" type="text"><i class="el-icon-download el-icon--left"></i>下载表单</el-button>
                    <el-button @click="handleDownload" v-if="activeName == 'second' && tableData2.length > 0" class="download" type="text"><i class="el-icon-download el-icon--left"></i>下载表单</el-button>
                </div>
                <!--table表格部分-->
                <el-table ref="multipleTable" :data="activeName == 'first' ? tableData1 : tableData2" v-loading="loading" style="width: 100%" :empty-text="caseExcelData.length > 0 || navForm.endTime ? '暂无案件' : '暂无案件,请上传案件清单或选择查询日期'">
                    <!-- @selection-change="handleSelectionChange" -->
                    <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                    <el-table-column prop="meituanCaseNo" label="美团案件号"></el-table-column>
                    <el-table-column prop="accidentTime" label="出险时间"></el-table-column>
                    <el-table-column prop="reportTime" label="报案时间"></el-table-column>
                    <el-table-column prop="insureTypeName" label="保险险种"></el-table-column>
                    <el-table-column prop="currentStatusDesc" label="案件状态"></el-table-column>
                    <el-table-column prop="caseClosedTime" label="结案时间"></el-table-column>
                    <el-table-column prop="totalPayAmount" label="结案金额"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <span class="a_color" @click="lookDetails(scope.row)">查看详情</span>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination
                    style="margin-top: 0.2rem"
                    @current-change="handleCurrentChange"
                    @size-change="handleSizeChange"
                    :current-page="activeName == 'first' ? currentPage1 : currentPage2"
                    layout="total, sizes, prev, pager, next, jumper"
                    :page-sizes="[10, 20]"
                    :page-size="10"
                    :total="activeName == 'first' ? totalPage1 : totalPage2"
                >
                </el-pagination>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false" width="65%">
            <div class="dialog_box">
                <div class="dialog_header">
                    <div>案件号：{{ checkedRowDetail.caseNo }}</div>
                    <div>是否快赔：{{ checkedRowDetail.quickPayOrNot }}</div>
                </div>
                <div class="dialog_main">
                    <div class="dialog_row">
                        <div class="dialog_col">
                            <div class="detail_title">涉事方信息：</div>
                            <ul>
                                <li>出险人姓名：{{ checkedRowDetail.nameOfDeliveryMan }}</li>
                                <li>三者姓名：{{ checkedRowDetail.nameOfThirdParty }}</li>
                                <li>被保险人：{{ checkedRowDetail.nameOfInsured }}</li>
                                <li>投保人：{{ checkedRowDetail.insuranceApplicant }}</li>
                            </ul>
                        </div>
                        <div class="dialog_col">
                            <div class="detail_title">审核信息：</div>
                            <ul>
                                <li>公估公司：{{ checkedRowDetail.lossAdjusterCompany }}</li>
                                <li>初审人：{{ checkedRowDetail.firstCheckMan }}</li>
                                <li>复审人：{{ checkedRowDetail.reviewer }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="dialog_row">
                        <div class="dialog_col">
                            <div class="detail_title">事故省市：</div>
                            <ul>
                                <li>{{ checkedRowDetail.accidentProvincesAndCities }}</li>
                            </ul>
                        </div>
                        <!-- <div class="dialog_col">
							<div class="detail_title">责任比例：</div>
							<ul>
								<li>骑手20%{{checkedRowDetail.}}</li>
							</ul>
						</div> -->
                    </div>
                    <div class="dialog_row">
                        <div class="dialog_col" style="width: 100%">
                            <div class="detail_title">赔付信息：</div>
                            <ul>
                                <li>总赔款金额：{{ checkedRowDetail.totalPayAmount }}元</li>
                            </ul>
                            <div class="adjustment_detail">
                                <div>理算明细：</div>
                                <ul>
                                    <li>
                                        <span>车损：{{ checkedRowDetail.vehicleDamage }} 元</span>
                                        <span>门诊医疗费：{{ checkedRowDetail.outPatientMedicalExpenses }} 元</span>
                                    </li>
                                    <li>
                                        <span>伤残赔偿金：{{ checkedRowDetail.disabilityCompensation }} 元</span>
                                        <span>骑手住院津贴：{{ checkedRowDetail.hospitalizationAllowanceForDeliveryMan }} 元</span>
                                    </li>
                                    <li>
                                        <span>误工费：{{ checkedRowDetail.lostWages }} 元</span>
                                        <span>其他补助：{{ checkedRowDetail.otherAllowance }} 元</span>
                                    </li>
                                    <li>
                                        <span>物损金额：{{ checkedRowDetail.itemDamage }} 元</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src
// import MyHeader from '@/components/MyHeader.vue'
import { getToken } from '../../utils/auth.js';
export default {
    name: 'choreographer',
    components: {},
    data() {
        return {
            caseExcelData: [],
            dialogVisible: false,
            baseURL: '',
            unloaderHeader: { token: '' }, //上传的请求头
            fullscreenLoading: false,
            uploadFile: {}, //上传成功后的值
            fileName: '',
            activeName: 'first',
            loading: false,
            nowFilter: '',
            customFiltering: [
                // {
                // 	relation:'and',
                // 	caseField:'',
                // 	optionName:'',
                // 	judgeOption:'',
                // 	optionValue:'',
                // 	optionsql1:'',
                // 	optionsql2:''
                // }
            ], //自定义筛选条件
            navForm: {
                //储存搜索框的检索条件信息
                startTime: '',
                endTime: '',
                value: '',
                meituanCaseInfoList: []
            },
            checkedRowDetail: {}, //点击行的详情
            riskOptions: [], //我的规则 风险点组合名称
            customFilterOptionList: [], //自定义全局筛选  风险筛选项
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            totalPage: 1, //总页数tableData:[],
            tableData: [],

            currentPage1: 1, //当前页
            pageSize1: 10, //一页多少条数据
            totalPage1: 0, //总页数tableData:[],
            tableData1: [],
            currentPage2: 1, //当前页
            pageSize2: 10, //一页多少条数据
            totalPage2: 0, //总页数tableData:[],
            tableData2: [],

            tableDataName: 'tableData1',
            currentPageName: 'currentPage1',
            pageSizeName: 'pageSize1',
            totalPageName: 'totalPage1',
            multipleSelection: []
        };
    },
    methods: {
        //删除文件
        handleRemove(file, fileList) {
            this.caseExcelData = [];
        },
        //自定义筛选-风险筛选项change事件
        optionnameChange(items) {
            items.judgeOption = '';
            items.caseField = '';
            items.optionValue = '';
            items.optionsql1 = '';
            items.optionsql2 = '';
            this.customFilterOptionList.forEach((val, key) => {
                if (items.optionName == val.optionName) {
                    items.optionSql = val.optionSql;
                    items.optionType = val.optionType;
                    items.caseField = val.caseField;
                }
            });
        },
        //获取已定义筛选规则
        getCommonFilterOptionList() {
            this.instance.post('/case/getCommonFilterOptionList', {}).then((res) => {
                if (res.data.code == 1) {
                    this.riskOptions = res.data.data;
                }
            });
        },
        //获取自定义筛选规则选项
        getCustomFilterOptionList() {
            this.instance.post('/case/getCustomFilterOptionList', {}).then((res) => {
                if (res.data.code == 1) {
                    let customFilterOptionList = res.data.data;
                    customFilterOptionList.length > 0 &&
                        customFilterOptionList.forEach((val, key) => {
                            if (val.judgeOption) {
                                val.judgeOption = val.judgeOption.split(',');
                            } else {
                                val.judgeOption = [];
                            }
                            if (val.optionValue) {
                                val.optionValue = val.optionValue.split(',');
                            } else {
                                val.optionValue = [];
                            }
                        });
                    this.customFilterOptionList = customFilterOptionList;
                }
            });
        },
        //添加为我的规则
        addMyRule() {
            this.$confirm('是否添加为我的规则?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                center: true,
                type: 'warning'
            })
                .then(() => {
                    if (this.customFiltering.length == 0) {
                        this.$message.error('抱歉，请添加自定义筛选项！');
                        return false;
                    } else {
                        if (!this.detectCustomFilter()) {
                            return false;
                        } else {
                            let customFiltering = JSON.parse(JSON.stringify(this.customFiltering));
                            customFiltering.forEach((val, key) => {
                                if (key == 0) {
                                    val.relation = '';
                                }
                                if (val.optionType == 2 || val.optionType == 4) {
                                    val.optionValue = val.optionsql1;
                                    if (val.optionsql2 && val.judgeOption == '介于') {
                                        val.optionValue = val.optionValue + ',' + val.optionsql2;
                                    }
                                }
                            });
                            let json = {
                                customFilterOptionList: customFiltering
                            };
                            this.instance.post('/case/addCommonFilterOption', json).then((res) => {
                                this.$message(res.data.message);
                                if (res.data.code == 1) {
                                    this.getCommonFilterOptionList();
                                }
                            });
                        }
                    }
                })
                .catch(() => {});
        },
        //添加条件
        addCondition() {
            if (this.customFiltering.length >= 18) {
                this.$message({
                    type: 'wraning',
                    message: '抱歉，最多只能添加18条风险筛选项！'
                });
            } else {
                //判断上一条有没有填完
                if (!this.detectCustomFilter()) {
                    return false;
                }
                var obj = {
                    relation: 'and',
                    optionName: '',
                    caseField: '',
                    judgeOption: '',
                    optionValue: '',
                    optionsql1: '',
                    optionsql2: ''
                };
                this.customFiltering.push(obj);
            }
        },
        //点击自定义筛选的close图标
        handleClose(item, index) {
            this.customFiltering.splice(index, 1);
        },
        //点击查看详情
        lookDetails(row) {
            this.checkedRowDetail = row;
            this.dialogVisible = true;
        },
        handleClickTabs() {
            if (this.activeName == 'first') {
                this.currentPageName = 'currentPage1';
                this.pageSizeName = 'pageSize1';
                this.tableDataName = 'tableData1';
                this.totalPageName = 'totalPage1';
            } else {
                this.currentPageName = 'currentPage2';
                this.pageSizeName = 'pageSize2';
                this.tableDataName = 'tableData2';
                this.totalPageName = 'totalPage2';
            }
            // this.tableData=[];//  清空table的值
        },
        //文件上传成功时的钩子
        handleAvatarSuccess(res, file) {
            this.fullscreenLoading.close();
            this.fileName = file.name;
            this.uploadFile = res.data;
            if (res.code == 1) {
                this.$message.success(res.message);
                this.caseExcelData = res.data;
            } else {
                this.$message.error(res.message);
            }
        },
        //上传文件之前的钩子
        beforeAvatarUpload(file) {
            if (file.size / 1024 > 5000) {
                this.$message.error('抱歉，上传文件不得大于5M！');
                return false;
            }
            this.unloaderHeader.Authorization = 'Bearer ' + getToken();
            this.fullscreenLoading = this.$loading({
                lock: true,
                text: '上传中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        //切换table选中事件
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        //点击查询
        searchTable(sign) {
            if (this.caseExcelData.length > 0 || (this.navForm.startTime && this.navForm.endTime)) {
                if (sign == 2) {
                    if (!this.detectCustomFilter()) {
                        return false;
                    }
                }
                this[this.currentPageName] = 1;
                this.getTbodyData();
            } else {
                this.$message.error('请上传文件或者选择结案时间之后再查询！');
            }
        },
        //检测自定义筛选
        detectCustomFilter() {
            let customFiltering = this.customFiltering;
            let isSubmit = true,
                hintMessage = '';
            let len = customFiltering.length;
            if (len > 0) {
                let filterData = customFiltering[len - 1];
                if (!filterData.optionName) {
                    isSubmit = false;
                    hintMessage = '抱歉，请选择风险条件！';
                } else if (filterData.optionType == 2 || filterData.optionType == 4) {
                    if (!filterData.judgeOption) {
                        isSubmit = false;
                        hintMessage = '抱歉，请选择判断条件！';
                    } else {
                        if (!filterData.optionsql1) {
                            isSubmit = false;
                            hintMessage = '抱歉，请填写数值！';
                        } else if (filterData.judgeOption == '介于') {
                            if (!filterData.optionsql2) {
                                isSubmit = false;
                                hintMessage = '抱歉，请填写数值！';
                            }
                        }
                    }
                } else if (filterData.optionType == 3) {
                    if (!filterData.judgeOption) {
                        isSubmit = false;
                        hintMessage = '抱歉，请选择判断条件！';
                    } else if (!filterData.optionValue) {
                        isSubmit = false;
                        hintMessage = '抱歉，请选择判断值！';
                    }
                }
            }
            if (!isSubmit) {
                this.$message.error(hintMessage);
            }
            return isSubmit;
        },
        //获取tbody里的数据
        getTbodyData: function () {
            this.loading = true;
            this[this.tableDataName] = []; //  清空table的值
            var json = JSON.parse(JSON.stringify(this.navForm));
            json.meituanCaseInfoList = this.caseExcelData;
            if (this.activeName == 'first') {
                this.navForm.value &&
                    this.riskOptions.forEach((val, key) => {
                        if (val.id == this.navForm.value) {
                            json.commonFilterOption = val;
                        }
                    });
            } else {
                this.customFiltering.length > 0 &&
                    this.customFiltering.forEach((val, key) => {
                        if (key == 0) {
                            val.relation = '';
                        }
                        if (val.optionType == 2 || val.optionType == 4) {
                            val.optionValue = val.optionsql1;
                            if (val.optionsql2 && val.judgeOption == '介于') {
                                val.optionValue = val.optionValue + ',' + val.optionsql2;
                            }
                        }
                    });
                json.customFilterOptionList = this.customFiltering;
            }
            //储存当前的检索条件，下载用
            this.nowFilter = JSON.parse(JSON.stringify(json));
            json.pageNum = this[this.currentPageName];
            json.pageSize = this[this.pageSizeName];
            this.instance.post('/case/getCaseList', json).then((res) => {
                this.loading = false;
                if (res.data.code == 1) {
                    this[this.tableDataName] = res.data.data.list;
                    this[this.totalPageName] = parseInt(res.data.data.total);
                }
            });
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this[this.pageSizeName] = val;
            this.getTbodyData();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange: function (val) {
            this[this.currentPageName] = Number(val);
            this.getTbodyData();
        },
        //下载工单
        handleDownload() {
            var json = JSON.parse(JSON.stringify(this.nowFilter));
            this.fullscreenLoading = this.$loading({
                lock: true,
                text: '下载中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.instance({
                method: 'post',
                url: '/case/exportCaseExcel',
                data: json,
                responseType: 'blob',
                headers: {
                    // 'Content-Type':"application/x-www-form-urlencoded",
                }
            })
                .then((res) => {
                    // this.loading=false;
                    this.fullscreenLoading.close();
                    let url = window.URL.createObjectURL(new Blob([res.data]));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', '案件下载清单.csv'); //指定下载后的文件名，防跳转
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    mounted() {
        //
        this.getCommonFilterOptionList();
        this.getCustomFilterOptionList();
        this.baseURL = this.$store.getters.baseURL;
        // this.getTbodyData();
    }
};
</script>
<style scoped>
@import '../../assets/css/table_public.css';
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}
::v-deep input[type='‘number’'] {
    -moz-appearance: textfield !important;
}
.condition_form /deep/.el-input__inner,
.condition_form /deep/.el-select-dropdown__item {
    font-size: 0.14rem;
}
.condition_form {
    margin-top: 0.15rem;
}
.condition_form .el-form-item--mini.el-form-item,
.condition_form .el-form-item--small.el-form-item {
    margin-bottom: 0;
}
.condition_form .el-select,
.condition_form .el-input {
    margin-right: 0.2rem;
}
.condition_form /deep/.el-form-item__label {
    padding: 0;
}
.condition_lebal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.18rem;
}
.condition_lebal b {
    width: 0.2rem;
    height: 0.2rem;
    background: url(../../../public/images/close_icon.png) no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
}
.condition_item {
    width: 97%;
    min-height: 1.2rem;
    background: #f2f8fc;
    border-radius: 0.04rem;
    padding: 0.2rem 0.3rem;
}
.condition_box li {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    min-height: 1.2rem;
    margin-bottom: 0.3rem;
}
.condition_box li:nth-child(2) .condition_item {
    width: 96%;
}
.condition_box li:nth-child(3) .and_or {
    left: 1.5%;
}
.condition_box li:nth-child(3) .condition_item {
    width: 95%;
}
.condition_box li:nth-child(4) .and_or {
    left: 2.5%;
}
.condition_box li:nth-child(4) .condition_item {
    width: 94%;
}
.condition_box li:nth-child(5) .and_or {
    left: 3.5%;
}
.condition_box li:nth-child(5) .condition_item {
    width: 93%;
}
.condition_box li:nth-child(6) .and_or {
    left: 4.5%;
}
.condition_box li:nth-child(6) .condition_item {
    width: 92%;
}
.condition_box li:nth-child(7) .and_or {
    left: 5.5%;
}
.condition_box li:nth-child(7) .condition_item {
    width: 91%;
}
.condition_box li:nth-child(8) .and_or {
    left: 6.5%;
}
.condition_box li:nth-child(8) .condition_item {
    width: 90%;
}
.condition_box li:nth-child(9) .and_or {
    left: 7.5%;
}
.condition_box li:nth-child(9) .condition_item {
    width: 89%;
}
.condition_box li:nth-child(10) .and_or {
    left: 8.5%;
}
.condition_box li:nth-child(10) .condition_item {
    width: 88%;
}
.condition_box li:nth-child(11) .and_or {
    left: 9.5%;
}
.condition_box li:nth-child(11) .condition_item {
    width: 87%;
}
.condition_box li:nth-child(12) .and_or {
    left: 10.5%;
}
.condition_box li:nth-child(12) .condition_item {
    width: 86%;
}
.condition_box li:nth-child(13) .and_or {
    left: 11.5%;
}
.condition_box li:nth-child(13) .condition_item {
    width: 85%;
}
.condition_box li:nth-child(14) .and_or {
    left: 12.5%;
}
.condition_box li:nth-child(14) .condition_item {
    width: 84%;
}
.condition_box li:nth-child(15) .and_or {
    left: 13.5%;
}
.condition_box li:nth-child(15) .condition_item {
    width: 83%;
}
.condition_box li:nth-child(16) .and_or {
    left: 14.5%;
}
.condition_box li:nth-child(16) .condition_item {
    width: 82%;
}
.condition_box li:nth-child(17) .and_or {
    left: 15.5%;
}
.condition_box li:nth-child(17) .condition_item {
    width: 81%;
}
.condition_box li:nth-child(18) .and_or {
    left: 16.5%;
}
.condition_box li:nth-child(18) .condition_item {
    width: 80%;
}
.and_or {
    position: absolute;
    bottom: 0.4rem;
    left: 0;
    z-index: 20;
    width: 0.26rem;
    height: 1.7rem;
    padding-top: 0.5rem;
}
.and_or span {
    width: 1px;
    height: 1.7rem;
    background-color: #c1e0ff;
    position: absolute;
    top: 0;
    left: 0.12rem;
    z-index: -1;
}
.and_or b {
    display: inline-block;
    background: #ffffff;
    border: 1px solid #1989fa;
    font-size: 0.15rem;
    width: 0.26rem;
    height: 0.26rem;
    line-height: 0.24rem;
    text-align: center;
    color: #1989fa;
    margin-bottom: 0.1rem;
    cursor: pointer;
}
.and_or .active {
    color: #fff;
    background: #1989fa;
}
.custom_filtering {
    padding: 0 0 0.3rem;
    border-bottom: 1px solid #ebeef5;
}
.add_condition {
    display: flex;
    align-items: center;
    font-size: 0.18rem;
}
.add_condition div {
    width: 0.24rem;
    margin-right: 0.1rem;
    cursor: pointer;
}
.add_condition span {
    cursor: pointer;
}
.adjustment_detail {
    width: 100%;
    display: flex;
}
.dialog_col li,
.adjustment_detail > div {
    font-size: 0.18rem;
    color: #606266;
    margin-top: 0.2rem;
}
.adjustment_detail li span:first-child {
    display: inline-block;
    min-width: 3rem;
}
.dialog_row {
    margin-top: 0.4rem;
    display: flex;
}
.dialog_row > div {
    width: 50%;
}
.dialog_header {
    display: flex;
    height: 0.68rem;
    border-bottom: 1px solid #ebeef5;
    font-size: 0.24rem;
    font-weight: 600;
}
.dialog_header div:first-child {
    margin-right: 1.2rem;
}
/deep/.el-dialog__header {
    padding: 0;
}
/deep/.el-dialog__body {
    padding: 0.5rem;
    color: #333;
}
.table_title {
    display: flex;
    margin-bottom: 0.3rem;
    align-items: center;
}
.table_title b {
    font-size: 0.26rem;
    font-weight: 600;
}
.hint_text {
    color: #909399;
    font-size: 0.16rem;
}
/deep/.el-tabs__item {
    height: 0.4rem;
    font-size: 0.22rem;
    line-height: 1;
}
/deep/.el-tabs__header {
    margin-bottom: 0.38rem;
}
.warning_icon {
    width: 0.14rem;
    margin-right: 0.18rem;
}
.download {
    margin-left: 0.5rem;
    font-size: 0.18rem;
}
</style>
