<template>
    <div class="wrap">
        <div class="page_title">
            <b>人伤计算器</b>
        </div>
        <div class="search_box public_box">
            <!--检索部分-->
            <el-form :inline="true" :model="navForm" class="demo-form-inline">
                <el-form-item label="案件号：" prop="caseNo">
                    <el-input v-model="navForm.caseNo" @blur="caseNoBlur" placeholder="请填写案件号" clearable></el-input>
                </el-form-item>
                <el-form-item label="加盟雇主责任险版本选择：">
                    <el-select v-model="navForm.insuranceVersion" @change="insuranceVersionChange" class="width_250" placeholder="请选择">
                        <el-option v-for="item in insuranceVersionList" :key="item.id" :label="item.versionName" :value="item.versionName"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="案件类型：">
                    <el-select v-model="navForm.caseType" @change="caseTypeChange" placeholder="请选择">
                        <el-option v-for="item in caseTypeOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div v-show="navForm.caseType == 1 || (navForm.caseType == 3 && !threeVisible)">
                <div class="detail_title">骑手基本信息</div>
                <el-form :rules="riderRules" ref="formDataRider" :inline="true" :model="formDataRider" class="demo-form-inline">
                    <!-- <el-form-item label="案件号：" prop="caseNo">
						<el-input class="width_150" v-model="formDataRider.caseNo"  placeholder="请填写" clearable></el-input>
					</el-form-item> -->
                    <el-form-item label="保单号：" prop="policyNo">
                        <el-input class="width_150" v-model="formDataRider.policyNo" placeholder="请填写" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="出险日期：" prop="accidentTime" class="dateBox">
                        <el-date-picker placeholder="请选择日期" clearable v-model="formDataRider.accidentTime" type="date" value-format="yyyy-MM-dd" style="width: 150px"> </el-date-picker>
                    </el-form-item>
                    <el-form-item label="姓名：" prop="nameOfPerson">
                        <el-input class="width_150" v-model="formDataRider.nameOfPerson" placeholder="请填写" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="身份证号：" prop="personID">
                        <el-input class="width_150" maxlength="18" v-model="formDataRider.personID" @blur="personIDBlur(formDataRider)" placeholder="请填写" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="年龄：" prop="personAge">
                        <el-input class="width_150" v-model="formDataRider.personAge" disabled clearable></el-input>
                    </el-form-item>
                    <el-form-item label="性别：">
                        <el-select v-model="formDataRider.personSex" disabled class="width_150">
                            <el-option label="男" value="男"></el-option>
                            <el-option label="女" value="女"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="责任比例：">
                        <el-select v-model="formDataRider.dutyRatio" placeholder="请选择" class="width_150">
                            <el-option label="100%" value="100"></el-option>
                            <el-option label="80%" value="80"></el-option>
                            <el-option label="70%" value="70"></el-option>
                            <el-option label="50%" value="50"></el-option>
                            <el-option label="30%" value="30"></el-option>
                            <el-option label="20%" value="20"></el-option>
                            <el-option label="0%" value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否住院：">
                        <el-select v-model="formDataRider.hospitalizedOrNot" @change="hospitalizedOrNotChange(formDataRider, formDataRider.hospitalizedOrNot)" placeholder="请选择" class="width_150">
                            <el-option label="是" value="是"></el-option>
                            <el-option label="否" value="否"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否涉及诉讼：">
                        <el-select v-model="formDataRider.involvedInLitigationOrNot" @change="involvedInLitigationOrNotChange(formDataRider, formDataRider.involvedInLitigationOrNot)" placeholder="请选择" class="width_150">
                            <el-option label="是" value="是"></el-option>
                            <el-option label="否" value="否"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否伤残：">
                        <el-select v-model="formDataRider.disabilityOrNot" @change="disabilityOrNotChange(formDataRider, formDataRider.disabilityOrNot)" placeholder="请选择" class="width_150">
                            <el-option label="是" value="是"></el-option>
                            <el-option label="否" value="否"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否死亡：">
                        <el-select v-model="formDataRider.deathOrNot" @change="deathOrNotChange(formDataRider, formDataRider.deathOrNot)" placeholder="请选择" class="width_150">
                            <el-option label="是" value="是"></el-option>
                            <el-option label="否" value="否"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否有餐损：">
                        <el-select v-model="formDataRider.mealAllowanceOrNot" @change="mealAllowanceOrNotChange(formDataRider, formDataRider.mealAllowanceOrNot)" placeholder="请选择" class="width_150">
                            <el-option label="是" value="是"></el-option>
                            <el-option label="否" value="否"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否有误工信息：">
                        <el-select v-model="formDataRider.delayWorkOrNot" @change="delayWorkOrNotChange(formDataRider, formDataRider.delayWorkOrNot)" placeholder="请选择" class="width_150">
                            <el-option label="是" value="是"></el-option>
                            <el-option label="否" value="否"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否有营养信息：">
                        <el-select v-model="formDataRider.nutritionOrNot" @change="nutritionOrNotChange(formDataRider, formDataRider.nutritionOrNot)" placeholder="请选择" class="width_150">
                            <el-option label="是" value="是"></el-option>
                            <el-option label="否" value="否"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否有狂犬疫苗医疗保险金：">
                        <el-select v-model="formDataRider.rabiesVaccineOrNot" @change="rabiesVaccineOrNotChange(formDataRider, formDataRider.rabiesVaccineOrNot)" placeholder="请选择" class="width_150">
                            <el-option label="是" value="是"></el-option>
                            <el-option label="否" value="否"></el-option>
                        </el-select>
                    </el-form-item>
                    <div class="detail_title">骑手人伤</div>
                    <el-form-item label="就诊医院：" prop="hospital">
                        <el-input class="width_150" v-model="formDataRider.hospital" placeholder="请填写" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="就诊疾病：" prop="disease">
                        <el-input class="width_150" v-model="formDataRider.disease" placeholder="请填写" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="首次就诊日期：" prop="firstVisitTime" class="dateBox">
                        <el-date-picker placeholder="请选择日期" clearable v-model="formDataRider.firstVisitTime" type="date" value-format="yyyy-MM-dd" style="width: 150px"> </el-date-picker>
                    </el-form-item>
                    <el-form-item label="门诊实际花费：" prop="outPatientExpenses">
                        <el-input class="width_150" v-model="formDataRider.outPatientExpenses" type="number" placeholder="请填写" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="门诊保障外花费：" prop="outPatientExpensesExtra">
                        <el-input class="width_150" v-model="formDataRider.outPatientExpensesExtra" type="number" placeholder="请填写" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="免赔额：" prop="outPatientDeductible">
                        <el-input class="width_150" v-model="formDataRider.outPatientDeductible" type="number" placeholder="请填写" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="门诊总赔付额：" prop="totalOutPatientCompensation">
                        <el-input class="width_150" v-model="formDataRider.totalOutPatientCompensation" disabled clearable></el-input>
                    </el-form-item>
                    <template v-if="formDataRider.hospitalizedOrNot == '是'">
                        <div class="detail_title">骑手住院信息</div>
                        <el-form-item label="住院日期：" class="dateBox" prop="hospitalizationStartTime">
                            <el-row style="width: 300px">
                                <el-col :span="11">
                                    <el-date-picker placeholder="请选择日期" clearable v-model="formDataRider.hospitalizationStartTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                </el-col>
                                <el-col class="line" style="text-align: center" :span="2">至</el-col>
                                <el-col :span="11">
                                    <el-date-picker placeholder="请选择日期" clearable v-model="formDataRider.hospitalizationEndTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item label="住院天数（天）：" prop="hospitalizationDays">
                            <el-input class="width_150" v-model="formDataRider.hospitalizationDays" disabled clearable></el-input>
                        </el-form-item>
                        <el-form-item label="住院实际花费：" prop="hospitalizationExpenses">
                            <el-input class="width_150" v-model="formDataRider.hospitalizationExpenses" type="number" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="住院保障外花费：" prop="hospitalizationExpensesExtra">
                            <el-input class="width_150" v-model="formDataRider.hospitalizationExpensesExtra" type="number" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="免赔额：" prop="hospitalizationDeductible">
                            <el-input class="width_150" v-model="formDataRider.hospitalizationDeductible" type="number" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="住院总赔付额：" prop="totalHospitalizationCompensation">
                            <el-input class="width_150" v-model="formDataRider.totalHospitalizationCompensation" disabled clearable></el-input>
                        </el-form-item>
                    </template>
                    <template v-if="formDataRider.delayWorkOrNot == '是'">
                        <div class="detail_title">骑手误工费信息</div>
                        <el-form-item label="月均工资：" prop="avgMonthlySalary">
                            <el-input class="width_150" v-model="formDataRider.avgMonthlySalary" type="number" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="误工天数：" prop="delayWorkDays">
                            <el-input class="width_150" v-model="formDataRider.delayWorkDays" type="number" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="免赔额：" prop="delayWorkDeductible">
                            <el-input class="width_150" v-model="formDataRider.delayWorkDeductible" type="number" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="误工费总赔付金额：" prop="totalDelayWorkCompensation">
                            <el-input class="width_150" v-model="formDataRider.totalDelayWorkCompensation" disabled clearable></el-input>
                        </el-form-item>
                    </template>
                    <template v-if="formDataRider.nutritionOrNot == '是'">
                        <div class="detail_title">骑手营养费信息</div>
                        <el-form-item label="营养费基数：" prop="nutritionFeeBase">
                            <el-input class="width_150" v-model="formDataRider.nutritionFeeBase" type="number" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="营养天数：" prop="nutritionDays">
                            <el-input class="width_150" v-model="formDataRider.nutritionDays" type="number" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="免赔额：" prop="nutritionDeductible">
                            <el-input class="width_150" v-model="formDataRider.nutritionDeductible" type="number" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="营养费总赔付金额：" prop="totalNutritionCompensation">
                            <el-input class="width_150" v-model="formDataRider.totalNutritionCompensation" disabled clearable></el-input>
                        </el-form-item>
                    </template>
                    <template v-if="formDataRider.disabilityOrNot == '是'">
                        <div class="detail_title">骑手伤残</div>
                        <el-form-item label="评残项目：" prop="disabilityAssessmentItems">
                            <el-input class="width_150" v-model="formDataRider.disabilityAssessmentItems" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="伤残鉴定日期：" prop="disabilityAppraisalTime">
                            <el-date-picker placeholder="请选择日期" clearable v-model="formDataRider.disabilityAppraisalTime" type="date" value-format="yyyy-MM-dd" style="width: 150px"> </el-date-picker>
                        </el-form-item>
                        <el-form-item label="伤残鉴定机构：" prop="disabilityAppraisalInstitution">
                            <el-input class="width_150" v-model="formDataRider.disabilityAppraisalInstitution" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="伤残等级：">
                            <el-select v-model="formDataRider.disabilityLevel" placeholder="请选择" class="width_150">
                                <el-option v-for="(item, index) in disabilityLevelOptions" :key="index" :label="item.name" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="免赔额：" prop="disabilityDeductible">
                            <el-input class="width_150" v-model="formDataRider.disabilityDeductible" type="number" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="伤残赔付总额：" prop="totalDisabilityCompensation">
                            <el-input class="width_150" v-model="formDataRider.totalDisabilityCompensation" disabled clearable></el-input>
                        </el-form-item>
                    </template>
                    <div v-if="formDataRider.mealAllowanceOrNot == '是' || formDataRider.involvedInLitigationOrNot == '是' || formDataRider.rabiesVaccineOrNot == '是'" class="detail_title">骑手其他补助</div>
                    <template v-if="formDataRider.mealAllowanceOrNot == '是'">
                        <el-form-item label="餐损赔偿：" prop="mealAllowance">
                            <el-input class="width_150" v-model="formDataRider.mealAllowance" type="number" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                    </template>
                    <template v-if="formDataRider.involvedInLitigationOrNot == '是'">
                        <el-form-item label="诉讼费：" prop="legalCosts">
                            <el-input class="width_150" v-model="formDataRider.legalCosts" type="number" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                    </template>
                    <template v-if="formDataRider.rabiesVaccineOrNot == '是'">
                        <el-form-item label="狂犬疫苗医疗保险金：" prop="rabiesVaccineInsurance">
                            <el-input class="width_150" v-model="formDataRider.rabiesVaccineInsurance" type="number" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                    </template>
                    <template v-if="formDataRider.deathOrNot == '是'">
                        <div class="detail_title">骑手死亡信息</div>
                        <el-form-item label="死亡原因：" prop="deathReason">
                            <el-input class="width_150" v-model="formDataRider.deathReason" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="死亡日期：" prop="deathTime">
                            <el-date-picker placeholder="请选择日期" clearable v-model="formDataRider.deathTime" type="date" value-format="yyyy-MM-dd" style="width: 150px"> </el-date-picker>
                        </el-form-item>
                        <el-form-item label="死亡总赔付额：" prop="totalDeathCompensation">
                            <el-input class="width_150" v-model="formDataRider.totalDeathCompensation" type="number" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                    </template>
                </el-form>
                <div class="submit_btn">
                    <el-button type="primary" @click="submitFormRider">提交</el-button>
                </div>
            </div>
            <div v-show="navForm.caseType == 2 || (navForm.caseType == 3 && threeVisible)">
                <div v-for="(items, indexs) in formDataThreeArr" class="three_form">
                    <el-divider v-if="indexs != 0" style="background-color: #05a9c5"></el-divider>
                    <div class="detail_title">
                        <div>三者基本信息</div>
                        <i class="el-icon-delete" v-show="indexs != 0" @click="deleteForm(items, indexs)" style="color: #e4393c; margin-left: 0.2rem; cursor: pointer"></i>
                    </div>
                    <el-form :rules="riderRules" ref="formDataThreeArr" :inline="true" :model="items" class="demo-form-inline">
                        <template v-if="navForm.caseType == 2">
                            <!-- <el-form-item label="案件号：" prop="caseNo">
								<el-input class="width_150" v-model="items.caseNo"  placeholder="请填写" clearable></el-input>
							</el-form-item> -->
                            <el-form-item label="保单号：" prop="policyNo">
                                <el-input class="width_150" v-model="items.policyNo" placeholder="请填写" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="出险日期：" prop="accidentTime" class="dateBox">
                                <el-date-picker placeholder="请选择日期" clearable v-model="items.accidentTime" type="date" value-format="yyyy-MM-dd" style="width: 150px"> </el-date-picker>
                            </el-form-item>
                        </template>
                        <el-form-item label="姓名：" prop="nameOfPerson">
                            <el-input class="width_150" v-model="items.nameOfPerson" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="身份证号：" prop="personID">
                            <el-input class="width_150" maxlength="18" v-model="items.personID" @blur="personIDBlur(items)" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="年龄：" prop="personAge">
                            <el-input class="width_150" v-model="items.personAge" disabled clearable></el-input>
                        </el-form-item>
                        <el-form-item label="性别：">
                            <el-select v-model="items.personSex" disabled class="width_150">
                                <el-option label="男" value="男"></el-option>
                                <el-option label="女" value="女"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="责任比例：" prop="dutyRatio">
                            <el-input class="width_150" v-model="items.dutyRatio" type="number" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="是否住院：">
                            <el-select v-model="items.hospitalizedOrNot" @change="hospitalizedOrNotChange(items, items.hospitalizedOrNot)" placeholder="请选择" class="width_150">
                                <el-option label="是" value="是"></el-option>
                                <el-option label="否" value="否"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="是否涉及诉讼：" @change="involvedInLitigationOrNotChange(items, items.involvedInLitigationOrNot)">
                            <el-select v-model="items.involvedInLitigationOrNot" placeholder="请选择" class="width_150">
                                <el-option label="是" value="是"></el-option>
                                <el-option label="否" value="否"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="是否伤残：">
                            <el-select v-model="items.disabilityOrNot" @change="disabilityOrNotChange(items, items.disabilityOrNot)" placeholder="请选择" class="width_150">
                                <el-option label="是" value="是"></el-option>
                                <el-option label="否" value="否"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="是否死亡：">
                            <el-select v-model="items.deathOrNot" @change="deathOrNotChange(items, items.deathOrNot)" placeholder="请选择" class="width_150">
                                <el-option label="是" value="是"></el-option>
                                <el-option label="否" value="否"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="是否有误工信息：">
                            <el-select v-model="items.delayWorkOrNot" @change="delayWorkOrNotChange(items, items.delayWorkOrNot)" placeholder="请选择" class="width_150">
                                <el-option label="是" value="是"></el-option>
                                <el-option label="否" value="否"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="是否有营养信息：">
                            <el-select v-model="items.nutritionOrNot" @change="nutritionOrNotChange(items, items.nutritionOrNot)" placeholder="请选择" class="width_150">
                                <el-option label="是" value="是"></el-option>
                                <el-option label="否" value="否"></el-option>
                            </el-select>
                        </el-form-item>
                        <div class="detail_title">三者人伤</div>
                        <el-form-item label="就诊医院：" prop="hospital">
                            <el-input class="width_150" v-model="items.hospital" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="就诊疾病：" prop="disease">
                            <el-input class="width_150" v-model="items.disease" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="首次就诊日期：" prop="firstVisitTime" class="dateBox">
                            <el-date-picker placeholder="请选择日期" clearable v-model="items.firstVisitTime" type="date" value-format="yyyy-MM-dd" style="width: 150px"> </el-date-picker>
                        </el-form-item>
                        <el-form-item label="门诊实际花费：" prop="outPatientExpenses">
                            <el-input class="width_150" v-model="items.outPatientExpenses" type="number" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="门诊保障外花费：" prop="outPatientExpensesExtra">
                            <el-input class="width_150" v-model="items.outPatientExpensesExtra" type="number" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="免赔额：" prop="outPatientDeductible">
                            <el-input class="width_150" v-model="items.outPatientDeductible" type="number" placeholder="请填写" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="门诊总赔付额：" prop="totalOutPatientCompensation">
                            <el-input class="width_150" v-model="items.totalOutPatientCompensation" disabled clearable></el-input>
                        </el-form-item>
                        <template v-if="items.hospitalizedOrNot == '是'">
                            <div class="detail_title">三者住院信息</div>
                            <el-form-item label="住院日期：" class="dateBox" prop="hospitalizationStartTime">
                                <el-row style="width: 300px">
                                    <el-col :span="11">
                                        <el-date-picker placeholder="请选择日期" clearable v-model="items.hospitalizationStartTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                    </el-col>
                                    <el-col class="line" style="text-align: center" :span="2">至</el-col>
                                    <el-col :span="11">
                                        <el-date-picker placeholder="请选择日期" clearable v-model="items.hospitalizationEndTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                    </el-col>
                                </el-row>
                            </el-form-item>
                            <el-form-item label="住院天数（天）：" prop="hospitalizationDays">
                                <el-input class="width_150" v-model="items.hospitalizationDays" disabled></el-input>
                            </el-form-item>
                            <el-form-item label="住院实际花费：" prop="hospitalizationExpenses">
                                <el-input class="width_150" v-model="items.hospitalizationExpenses" type="number" placeholder="请填写" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="住院保障外花费：" prop="hospitalizationExpensesExtra">
                                <el-input class="width_150" v-model="items.hospitalizationExpensesExtra" type="number" placeholder="请填写" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="免赔额：" prop="hospitalizationDeductible">
                                <el-input class="width_150" v-model="items.hospitalizationDeductible" type="number" placeholder="请填写" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="住院总赔付额：" prop="totalHospitalizationCompensation">
                                <el-input class="width_150" v-model="items.totalHospitalizationCompensation" disabled clearable></el-input>
                            </el-form-item>
                        </template>
                        <template v-if="items.delayWorkOrNot == '是'">
                            <div class="detail_title">三者误工费信息</div>
                            <el-form-item label="月均工资：" prop="avgMonthlySalary">
                                <el-input class="width_150" v-model="items.avgMonthlySalary" type="number" placeholder="请填写" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="误工天数：" prop="delayWorkDays">
                                <el-input class="width_150" v-model="items.delayWorkDays" type="number" placeholder="请填写" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="免赔额：" prop="delayWorkDeductible">
                                <el-input class="width_150" v-model="items.delayWorkDeductible" type="number" placeholder="请填写" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="误工费总赔付金额：" prop="totalDelayWorkCompensation">
                                <el-input class="width_150" v-model="items.totalDelayWorkCompensation" disabled clearable></el-input>
                            </el-form-item>
                        </template>
                        <template v-if="items.nutritionOrNot == '是'">
                            <div class="detail_title">三者营养费信息</div>
                            <el-form-item label="营养费基数：" prop="nutritionFeeBase">
                                <el-input class="width_150" v-model="items.nutritionFeeBase" type="number" placeholder="请填写" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="营养天数：" prop="nutritionDays">
                                <el-input class="width_150" v-model="items.nutritionDays" type="number" placeholder="请填写" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="免赔额：" prop="nutritionDeductible">
                                <el-input class="width_150" v-model="items.nutritionDeductible" type="number" placeholder="请填写" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="营养费总赔付金额：" prop="totalNutritionCompensation">
                                <el-input class="width_150" v-model="items.totalNutritionCompensation" disabled clearable></el-input>
                            </el-form-item>
                        </template>
                        <template v-if="items.disabilityOrNot == '是'">
                            <div class="detail_title">三者伤残</div>
                            <el-form-item label="评残项目：" prop="disabilityAssessmentItems">
                                <el-input class="width_150" v-model="items.disabilityAssessmentItems" placeholder="请填写" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="伤残鉴定日期：" prop="disabilityAppraisalTime">
                                <el-date-picker placeholder="请选择日期" clearable v-model="items.disabilityAppraisalTime" type="date" value-format="yyyy-MM-dd" style="width: 150px"> </el-date-picker>
                            </el-form-item>
                            <el-form-item label="伤残鉴定机构：" prop="disabilityAppraisalInstitution">
                                <el-input class="width_150" v-model="items.disabilityAppraisalInstitution" placeholder="请填写" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="伤残等级：">
                                <el-select v-model="items.disabilityLevel" placeholder="请选择" class="width_150">
                                    <el-option v-for="(item, index) in disabilityLevelOptions" :key="index" :label="item.name" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="免赔额：" prop="disabilityDeductible">
                                <el-input class="width_150" v-model="items.disabilityDeductible" type="number" placeholder="请填写" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="上一年度城镇居民人均可支配收入：" prop="avgIncomeOfLastYear">
                                <el-input class="width_150" v-model="items.avgIncomeOfLastYear" type="number" placeholder="请填写" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="赔偿年限：" prop="compensationPeriod">
                                <el-input class="width_150" v-model="items.compensationPeriod" type="number" placeholder="请填写" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="伤残赔付总额：" prop="totalDisabilityCompensation">
                                <el-input class="width_150" v-model="items.totalDisabilityCompensation" disabled clearable></el-input>
                            </el-form-item>
                        </template>
                        <div v-if="items.involvedInLitigationOrNot == '是'" class="detail_title">三者其他补助</div>
                        <template v-if="items.involvedInLitigationOrNot == '是'">
                            <el-form-item label="诉讼费：" prop="legalCosts">
                                <el-input class="width_150" v-model="items.legalCosts" type="number" placeholder="请填写" clearable></el-input>
                            </el-form-item>
                        </template>
                        <template v-if="items.deathOrNot == '是'">
                            <div class="detail_title">三者死亡信息</div>
                            <el-form-item label="死亡原因：" prop="deathReason">
                                <el-input class="width_150" v-model="items.deathReason" placeholder="请填写" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="死亡日期：" prop="deathTime">
                                <el-date-picker placeholder="请选择日期" clearable v-model="items.deathTime" type="date" value-format="yyyy-MM-dd" style="width: 150px"> </el-date-picker>
                            </el-form-item>
                            <el-form-item label="死亡总赔付额：" prop="totalDeathCompensation">
                                <el-input class="width_150" v-model="items.totalDeathCompensation" type="number" placeholder="请填写" clearable></el-input>
                            </el-form-item>
                        </template>
                    </el-form>
                    <div v-if="indexs == formDataThreeArr.length - 1">
                        <el-button @click="addFormDataThree" size="mini" type="primary" icon="el-icon-plus">添加三者信息</el-button>
                    </div>
                </div>
                <div class="submit_btn">
                    <el-button type="primary" @click="submitFormThree">提交</el-button>
                </div>
            </div>
            <div>{{ totalOutPatientCompensation }}</div>
            <div>{{ totalHospitalizationCompensation }}</div>
            <div>{{ totalDelayWorkCompensation }}</div>
            <div>{{ totalNutritionCompensation }}</div>
            <div>{{ totalDisabilityCompensation }}</div>
            <div>{{ hospitalizationDays }}</div>
        </div>
        <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false" width="650px">
            <div class="dialog_main">
                <div class="dialog_title">
                    <div>{{ checkHumanInjuryResponse.humanInjuryWorkOrderTipsVo.personType == 1 ? '骑手' : '三者' }}人伤赔偿明细</div>
                    <div>异常项提示</div>
                </div>
                <ul class="dialog_ul">
                    <li v-for="(item, index) in checkHumanInjuryResponse.humanInjuryWorkOrderTipsVo.humanInjuryWorkOrderTipsList" :key="index">
                        <div>{{ index + 1 + '. ' + item.termName + ': ' + item.termAmount + ' 元' }}</div>
                        <b>{{ item.termExceptionTip }}</b>
                    </li>
                </ul>
                <el-divider></el-divider>
                <ul class="dialog_ul">
                    <li>
                        <div>{{ checkHumanInjuryResponse.humanInjuryWorkOrderTipsVo.lastTotalName + ': ' + checkHumanInjuryResponse.humanInjuryWorkOrderTipsVo.totalAmount + ' 元' }}</div>
                        <b v-if="checkHumanInjuryResponse.humanInjuryWorkOrderTipsVo.exceptionTipNumber > 0">{{ '共' + checkHumanInjuryResponse.humanInjuryWorkOrderTipsVo.exceptionTipNumber + '个异常项' }}</b>
                    </li>
                </ul>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">返回修改</el-button>
                <el-button type="primary" @click="addHumanInjuryWorkOrders">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src
// import MyHeader from '@/components/MyHeader.vue'
import { getToken } from '../../utils/auth.js';
export default {
    name: 'caseRisk',
    components: {},
    data() {
        return {
            dialogVisible: false,
            baseURL: '',
            fullscreenLoading: false,
            caseByCaseNo: {
                caseType: '',
                insuranceVersion: {},
                riderInfo: {},
                thirdPartyInfo: []
            },
            userInfo: {},
            caseTypeOptions: [
                {
                    name: '骑手人伤',
                    id: '1'
                },
                {
                    name: '三者人伤',
                    id: '2'
                },
                {
                    name: '骑手&三者人伤',
                    id: '3'
                }
            ],
            checkHumanInjuryResponse: {
                humanInjuryWorkOrderList: [],
                humanInjuryWorkOrderTipsVo: {}
            },
            insuranceVersionList: [],
            navForm: {
                //储存搜索框的检索条件信息
                caseNo: '',
                caseType: '1',
                insuranceVersion: ''
            },
            disabilityLevelOptions: [
                { name: '一级', value: '100' },
                { name: '二级', value: '80' },
                { name: '三级', value: '70' },
                { name: '四级', value: '60' },
                { name: '五级', value: '40' },
                { name: '六级', value: '30' },
                { name: '七级', value: '20' },
                { name: '八级', value: '15' },
                { name: '九级', value: '7' },
                { name: '十级', value: '5' }
            ],
            formDataRider: {
                //骑手表单信息
                personType: 1,
                caseNo: '',
                policyNo: '',
                accidentTime: '',
                nameOfPerson: '',
                personID: '',
                personAge: '',
                personSex: '男',
                dutyRatio: '50',
                hospitalizedOrNot: '是',
                involvedInLitigationOrNot: '是',
                disabilityOrNot: '是',
                deathOrNot: '是',
                mealAllowanceOrNot: '是',
                delayWorkOrNot: '是',
                nutritionOrNot: '是',
                rabiesVaccineOrNot: '是',
                hospital: '',
                disease: '',
                firstVisitTime: '',
                outPatientExpenses: '',
                outPatientExpensesExtra: '',
                outPatientDeductible: '',
                totalOutPatientCompensation: '',
                hospitalizationStartTime: '',
                hospitalizationEndTime: '',
                hospitalizationDays: '',
                hospitalizationExpenses: '',
                hospitalizationExpensesExtra: '',
                hospitalizationDeductible: '',
                totalHospitalizationCompensation: '',
                avgMonthlySalary: '',
                delayWorkDays: '',
                delayWorkDeductible: '',
                totalDelayWorkCompensation: '',
                nutritionFeeBase: '',
                nutritionDays: '',
                nutritionDeductible: '',
                totalNutritionCompensation: '',
                disabilityAssessmentItems: '',
                disabilityAppraisalTime: '',
                disabilityAppraisalInstitution: '',
                disabilityLevel: '60',
                disabilityDeductible: '',
                totalDisabilityCompensation: '',
                mealAllowance: '',
                legalCosts: '',
                rabiesVaccineInsurance: '',
                deathReason: '',
                deathTime: '',
                totalDeathCompensation: ''
            },
            threeVisible: false, //显示三者还是骑手
            checkedInsuranceVersion: {}, //选中的保险版本
            riderRules: {
                //骑手规则
                caseNo: [{ required: true, message: '请输入案件号', trigger: 'blur' }],
                policyNo: [{ required: true, message: '请输入保单号', trigger: 'blur' }],
                accidentTime: [{ required: true, message: '请选择出险日期', trigger: 'blur' }],
                nameOfPerson: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                personID: [
                    { required: true, message: '请输入身份证号', trigger: 'blur' },
                    { min: 18, max: 18, message: '长度为18个字符', trigger: 'blur' }
                ],
                personAge: [{ required: true, message: '请输入年龄', trigger: 'blur' }],
                dutyRatio: [{ required: true, message: '请输入责任比例', trigger: 'blur' }],
                hospital: [{ required: true, message: '请输入就诊医院', trigger: 'blur' }],
                disease: [{ required: true, message: '请输入就诊疾病', trigger: 'blur' }],
                firstVisitTime: [{ required: true, message: '请选择首次就诊日期', trigger: 'blur' }],
                outPatientExpenses: [{ required: true, message: '请输入门诊实际花费', trigger: 'blur' }],
                outPatientExpensesExtra: [{ required: true, message: '请输入门诊保障外花费', trigger: 'blur' }],
                outPatientDeductible: [{ required: true, message: '请输入门诊免赔额', trigger: 'blur' }],
                totalOutPatientCompensation: [{ required: true, message: '请输入门诊总赔付额', trigger: 'blur' }],
                hospitalizationStartTime: [{ required: true, message: '请选择住院日期', trigger: 'blur' }],
                hospitalizationEndTime: [{ required: true, message: '请选择住院日期', trigger: 'blur' }],
                hospitalizationDays: [{ required: true, message: '请输入住院天数', trigger: 'blur' }],
                hospitalizationExpenses: [{ required: true, message: '请输入住院实际花费', trigger: 'blur' }],
                hospitalizationExpensesExtra: [{ required: true, message: '请输入住院保障外花费', trigger: 'blur' }],
                hospitalizationDeductible: [{ required: true, message: '请输入住院免赔额', trigger: 'blur' }],
                totalHospitalizationCompensation: [{ required: true, message: '请输入住院总赔付额', trigger: 'blur' }],
                avgMonthlySalary: [{ required: true, message: '请输入月均工资', trigger: 'blur' }],
                delayWorkDays: [{ required: true, message: '请输入误工天数', trigger: 'blur' }],
                delayWorkDeductible: [{ required: true, message: '请输入误工费免赔额', trigger: 'blur' }],
                totalDelayWorkCompensation: [{ required: true, message: '请输入误工费总赔付金额', trigger: 'blur' }],
                nutritionFeeBase: [{ required: true, message: '请输入营养费基数', trigger: 'blur' }],
                nutritionDays: [{ required: true, message: '请输入营养天数', trigger: 'blur' }],
                nutritionDeductible: [{ required: true, message: '请输入营养费免赔额', trigger: 'blur' }],
                totalNutritionCompensation: [{ required: true, message: '请输入营养费总赔付金额', trigger: 'blur' }],
                disabilityAssessmentItems: [{ required: true, message: '请输入评残项目', trigger: 'blur' }],
                disabilityAppraisalTime: [{ required: true, message: '请选择伤残鉴定日期', trigger: 'blur' }],
                disabilityAppraisalInstitution: [{ required: true, message: '请输入伤残鉴定机构', trigger: 'blur' }],
                disabilityDeductible: [{ required: true, message: '请输入伤残免赔额', trigger: 'blur' }],
                avgIncomeOfLastYear: [{ required: true, message: '请输入上一年度居民可支配收入', trigger: 'blur' }],
                compensationPeriod: [{ required: true, message: '请输入赔偿年限', trigger: 'blur' }],
                totalDisabilityCompensation: [{ required: true, message: '请输入伤残赔付总额', trigger: 'blur' }],
                mealAllowance: [{ required: true, message: '请输入餐损赔偿', trigger: 'blur' }],
                legalCosts: [{ required: true, message: '请输入诉讼费', trigger: 'blur' }],
                rabiesVaccineInsurance: [{ required: true, message: '请输入狂犬疫苗医疗保险金', trigger: 'blur' }],
                deathReason: [{ required: true, message: '请输入死亡原因', trigger: 'blur' }],
                deathTime: [{ required: true, message: '请选择死亡日期', trigger: 'blur' }],
                totalDeathCompensation: [{ required: true, message: '请输入死亡总赔付额', trigger: 'blur' }]
            },
            formDataThree: {
                //三者表单信息
                personType: 2,
                caseNo: '',
                policyNo: '',
                accidentTime: '',
                nameOfPerson: '',
                personID: '',
                personAge: '',
                personSex: '男',
                dutyRatio: '',
                hospitalizedOrNot: '是',
                involvedInLitigationOrNot: '是',
                disabilityOrNot: '是',
                deathOrNot: '是',
                mealAllowanceOrNot: '是',
                delayWorkOrNot: '是',
                nutritionOrNot: '是',
                rabiesVaccineOrNot: '是',
                hospital: '',
                disease: '',
                firstVisitTime: '',
                outPatientExpenses: '',
                outPatientExpensesExtra: '',
                outPatientDeductible: '',
                totalOutPatientCompensation: '',
                hospitalizationStartTime: '',
                hospitalizationEndTime: '',
                hospitalizationDays: '',
                hospitalizationExpenses: '',
                hospitalizationExpensesExtra: '',
                hospitalizationDeductible: '',
                totalHospitalizationCompensation: '',
                avgMonthlySalary: '',
                delayWorkDays: '',
                delayWorkDeductible: '',
                totalDelayWorkCompensation: '',
                nutritionFeeBase: '',
                nutritionDays: '',
                nutritionDeductible: '',
                totalNutritionCompensation: '',
                disabilityAssessmentItems: '',
                disabilityAppraisalTime: '',
                disabilityAppraisalInstitution: '',
                disabilityLevel: '30',
                disabilityDeductible: '',
                avgIncomeOfLastYear: '',
                compensationPeriod: '',
                totalDisabilityCompensation: '',
                mealAllowance: '',
                legalCosts: '',
                rabiesVaccineInsurance: '',
                deathReason: '',
                deathTime: '',
                totalDeathCompensation: ''
            },
            formDataThreeArr: []
        };
    },
    computed: {
        //住院天数
        hospitalizationDays() {
            var startTime = this.formDataRider.hospitalizationStartTime;
            var endTime = this.formDataRider.hospitalizationEndTime;
            if (startTime && endTime) {
                let times = new Date(endTime).getTime() - new Date(startTime).getTime();
                this.formDataRider.hospitalizationDays = parseInt(times / 1000 / 24 / 60 / 60) + 1;
            }
            //三者住院天数
            this.formDataThreeArr.length > 0 &&
                this.formDataThreeArr.forEach((val, key) => {
                    var startTime = val.hospitalizationStartTime;
                    var endTime = val.hospitalizationEndTime;
                    if (startTime && endTime) {
                        let times = new Date(endTime).getTime() - new Date(startTime).getTime();
                        val.hospitalizationDays = parseInt(times / 1000 / 24 / 60 / 60) + 1;
                    }
                });
        },
        //骑手人伤门诊总赔付额
        totalOutPatientCompensation() {
            let total = this.formDataRider.outPatientExpenses - this.formDataRider.outPatientExpensesExtra - this.formDataRider.outPatientDeductible;
            this.formDataRider.totalOutPatientCompensation = ((total * this.formDataRider.dutyRatio) / 100).toFixed(2);
            //三者赔付总额
            this.formDataThreeArr.length > 0 &&
                this.formDataThreeArr.forEach((val, key) => {
                    let totalThree = val.outPatientExpenses - val.outPatientExpensesExtra - val.outPatientDeductible;
                    val.totalOutPatientCompensation = ((totalThree * val.dutyRatio) / 100).toFixed(2);
                });
        },
        //骑手住院总赔付额
        totalHospitalizationCompensation() {
            let total = this.formDataRider.hospitalizationExpenses - this.formDataRider.hospitalizationExpensesExtra - this.formDataRider.hospitalizationDeductible;
            this.formDataRider.totalHospitalizationCompensation = ((total * this.formDataRider.dutyRatio) / 100).toFixed(2);
            //三者赔付总额
            this.formDataThreeArr.length > 0 &&
                this.formDataThreeArr.forEach((val, key) => {
                    // debugger
                    let totalThree = val.hospitalizationExpenses - val.hospitalizationExpensesExtra - val.hospitalizationDeductible;
                    val.totalHospitalizationCompensation = ((totalThree * val.dutyRatio) / 100).toFixed(2);
                });
        },
        //骑手误工费总赔付金额
        totalDelayWorkCompensation() {
            let total = ((this.formDataRider.avgMonthlySalary / 30) * this.formDataRider.delayWorkDays * this.formDataRider.dutyRatio) / 100 - this.formDataRider.delayWorkDeductible;
            this.formDataRider.totalDelayWorkCompensation = total.toFixed(2);
            //三者赔付总额
            this.formDataThreeArr.length > 0 &&
                this.formDataThreeArr.forEach((val, key) => {
                    let totalThree = ((val.avgMonthlySalary / 30) * val.delayWorkDays * val.dutyRatio) / 100 - val.delayWorkDeductible;
                    val.totalDelayWorkCompensation = totalThree.toFixed(2);
                });
        },
        //骑手营养费总赔付金额
        totalNutritionCompensation() {
            let total = (this.formDataRider.nutritionFeeBase * this.formDataRider.nutritionDays * this.formDataRider.dutyRatio) / 100 - this.formDataRider.nutritionDeductible;
            this.formDataRider.totalNutritionCompensation = total.toFixed(2);
            //三者赔付总额
            this.formDataThreeArr.length > 0 &&
                this.formDataThreeArr.forEach((val, key) => {
                    let totalThree = (val.nutritionFeeBase * val.nutritionDays * val.dutyRatio) / 100 - val.nutritionDeductible;
                    val.totalNutritionCompensation = totalThree.toFixed(2);
                });
        },
        //骑手伤残赔付总额
        totalDisabilityCompensation() {
            let total = (((this.checkedInsuranceVersion.insuredAmount * this.formDataRider.disabilityLevel) / 100) * this.formDataRider.dutyRatio) / 100 - this.formDataRider.disabilityDeductible;
            this.formDataRider.totalDisabilityCompensation = total.toFixed(2);
            //三者赔付总额
            this.formDataThreeArr.length > 0 &&
                this.formDataThreeArr.forEach((val, key) => {
                    let totalThree = (val.avgIncomeOfLastYear * val.compensationPeriod * val.disabilityLevel) / 100 - val.disabilityDeductible;
                    val.totalDisabilityCompensation = totalThree.toFixed(2);
                });
        }
    },
    methods: {
        //案件号失去焦点事件
        caseNoBlur() {
            this.instance({
                method: 'post',
                url: '/case/getCaseByCaseNo',
                data: { caseNo: this.navForm.caseNo },
                headers: {
                    // 'Content-Type':"application/json;charset=UTF-8"
                }
            }).then((res) => {
                if (res.data.code == 1) {
                    if (res.data.data.caseType) {
                        this.caseByCaseNo = res.data.data;
                        let caseByCaseNo = res.data.data;
                        this.navForm.caseType = caseByCaseNo.caseType == '骑手人伤' ? '1' : caseByCaseNo.caseType == '三者人伤' ? '2' : '3';
                        this.navForm.insuranceVersion = caseByCaseNo.insuranceVersion.versionName;
                        if (this.navForm.caseType == 1 || this.navForm.caseType == 3) {
                            //骑手表单信息赋值
                            let riderInfo = caseByCaseNo.riderInfo;
                            this.formDataRider.policyNo = riderInfo.policyNo;
                            this.formDataRider.accidentTime = riderInfo.accidentTime;
                            this.formDataRider.dutyRatio = riderInfo.dutyRatio.replace(/[^\d]/g, '');
                            this.formDataRider.nameOfPerson = riderInfo.name;
                            this.formDataRider.hospital = riderInfo.hospital;
                            this.formDataRider.disease = riderInfo.diseaseName;
                            this.formDataRider.outPatientExpenses = riderInfo.outPatientExpenses;
                            this.formDataRider.hospitalizationExpenses = riderInfo.hospitalizationExpenses;
                            this.formDataRider.firstVisitTime = riderInfo.firstVisitTime;
                            this.formDataRider.hospitalizationStartTime = riderInfo.hospitalizationStartTime;
                            this.formDataRider.hospitalizationEndTime = riderInfo.hospitalizationEndTime;
                        }
                        if (this.navForm.caseType == 2 || this.navForm.caseType == 3) {
                            let thirdPartyInfo = caseByCaseNo.thirdPartyInfo;
                            if (thirdPartyInfo.length > 0) {
                                // this.formDataThreeArr.push(JSON.parse(JSON.stringify(this.formDataThree)));
                                let formDataThreeArr = [];
                                thirdPartyInfo.forEach((val, key) => {
                                    let formDataThree = JSON.parse(JSON.stringify(this.formDataThree));
                                    formDataThree.policyNo = val.policyNo;
                                    formDataThree.accidentTime = val.accidentTime;
                                    // formDataThree.dutyRatio=val.dutyRatio.replace(/[^\d]/g,'');
                                    formDataThree.nameOfPerson = val.name;
                                    formDataThree.hospital = val.hospital;
                                    formDataThree.disease = val.diseaseName;
                                    formDataThree.outPatientExpenses = val.outPatientExpenses;
                                    formDataThree.hospitalizationExpenses = val.hospitalizationExpenses;
                                    formDataThree.firstVisitTime = val.firstVisitTime;
                                    formDataThree.hospitalizationStartTime = val.hospitalizationStartTime;
                                    formDataThree.hospitalizationEndTime = val.hospitalizationEndTime;
                                    formDataThreeArr.push(formDataThree);
                                });
                                this.formDataThreeArr = formDataThreeArr;
                            }
                        }
                    } else {
                        this.$message.error('抱歉，案件信息缺失，请选择案件类型');
                    }
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        //案件类型change事件
        caseTypeChange(sign) {
            setTimeout(() => {
                if (this.$refs.formDataRider) {
                    this.$refs.formDataRider.resetFields();
                }
            }, 300);
            sign != 'not' && (this.threeVisible = false);
            this.formDataThreeArr = [];
            this.formDataThreeArr.push(JSON.parse(JSON.stringify(this.formDataThree)));
        },
        //加盟雇主责任险版本选择change事件
        insuranceVersionChange(val) {
            let obj = this.insuranceVersionList.find((item) => {
                return item.versionName === val;
            });
            this.checkedInsuranceVersion = obj;
            this.formDataRider.versionName = obj.versionName;
            this.formDataRider.salaryCap = obj.salaryCap;
        },
        //获取保险版本
        getInsuranceVersionList() {
            this.instance({
                method: 'post',
                url: '/human/injury/getInsuranceVersionList',
                data: {},
                headers: {
                    // 'Content-Type':"application/json;charset=UTF-8"
                }
            }).then((res) => {
                if (res.data.code == 1) {
                    this.insuranceVersionList = res.data.data;
                    this.navForm.insuranceVersion = this.insuranceVersionList[0].versionName;
                    this.insuranceVersionChange(this.insuranceVersionList[0].versionName);
                } else {
                    this.$message(res.data.message);
                }
            });
        },
        //是否住院change事件
        hospitalizedOrNotChange(item, val) {
            if (val == '否') {
                item.hospitalizationStartTime = '';
                item.hospitalizationEndTime = '';
                item.hospitalizationDays = '';
                item.hospitalizationExpenses = '';
                item.hospitalizationExpensesExtra = '';
                item.hospitalizationDeductible = '';
                item.totalHospitalizationCompensation = '';
            }
        },
        //是否涉及诉讼change事件
        involvedInLitigationOrNotChange(item, val) {
            if (val == '否') {
                item.legalCosts = '';
            }
        },
        //是否伤残change事件
        disabilityOrNotChange(item, val) {
            if (val == '否') {
                item.disabilityAssessmentItems = '';
                item.disabilityAppraisalTime = '';
                item.disabilityAppraisalInstitution = '';
                item.disabilityDeductible = '';
                item.totalDisabilityCompensation = 0;
                item.avgIncomeOfLastYear = '';
                item.compensationPeriod = '';
            }
        },
        //是否死亡change事件
        deathOrNotChange(item, val) {
            if (val == '否') {
                item.deathReason = '';
                item.deathTime = '';
                item.totalDeathCompensation = '';
            }
        },
        //是否有餐损change事件
        mealAllowanceOrNotChange(item, val) {
            if (val == '否') {
                item.mealAllowance = '';
            }
        },
        //是否有误工信息change事件
        delayWorkOrNotChange(item, val) {
            if (val == '否') {
                item.avgMonthlySalary = '';
                item.delayWorkDays = '';
                item.delayWorkDeductible = '';
                item.totalDelayWorkCompensation = 0;
            }
        },
        //是否有营养信息change事件
        nutritionOrNotChange(item, val) {
            if (val == '否') {
                item.nutritionFeeBase = '';
                item.nutritionDays = '';
                item.nutritionDeductible = '';
                item.totalNutritionCompensation = 0;
            }
        },
        //是否有狂犬疫苗医疗保险金change事件
        rabiesVaccineOrNotChange(item, val) {
            if (val == '否') {
                item.rabiesVaccineInsurance = '';
            }
        },
        //删除三者的form
        deleteForm(item, index) {
            this.formDataThreeArr.splice(index, 1);
        },
        //点击添加三者信息
        addFormDataThree() {
            this.formDataThreeArr.push(JSON.parse(JSON.stringify(this.formDataThree)));
        },
        //骑手身份证号blur事件
        personIDBlur(item) {
            let personID = item.personID;
            if (personID.length == 18) {
                item.personAge = this.getAgeByIdNo(personID);
                var sexSign = personID.slice(-2, -1);
                item.personSex = sexSign % 2 == 1 ? '男' : '女';
            }
        },
        //返回身份证年龄
        getAgeByIdNo(identityCard) {
            var len = (identityCard + '').length;
            if (len == 0) {
                return 0;
            } else {
                if (len != 15 && len != 18) {
                    //身份证号码只能为15位或18位其它不合法
                    return 0;
                }
            }
            var strBirthday = '';
            if (len == 18) {
                //处理18位的身份证号码从号码中得到生日和性别代码
                strBirthday = identityCard.substr(6, 4) + '/' + identityCard.substr(10, 2) + '/' + identityCard.substr(12, 2);
            }
            if (len == 15) {
                strBirthday = '19' + identityCard.substr(6, 2) + '/' + identityCard.substr(8, 2) + '/' + identityCard.substr(10, 2);
            }
            //时间字符串里，必须是“/”
            var birthDate = new Date(strBirthday);
            var nowDateTime = new Date();
            var age = nowDateTime.getFullYear() - birthDate.getFullYear();
            //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
            if (nowDateTime.getMonth() < birthDate.getMonth() || (nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        },
        //提交三者form
        submitFormThree() {
            var len = this.formDataThreeArr.length;
            for (var i = 0; i < len; i++) {
                this.$refs.formDataThreeArr[i].validate((valid) => {
                    if (valid) {
                        if (i + 1 == len) {
                            var dutyRatioTotal = parseFloat(this.formDataRider.dutyRatio);
                            this.formDataThreeArr.forEach((val, key) => {
                                val.versionName = this.checkedInsuranceVersion.versionName;
                                val.salaryCap = this.checkedInsuranceVersion.salaryCap;
                                val.caseType = this.navForm.caseType;
                                val.caseNo = this.navForm.caseNo;
                                val.jobNo = this.userInfo.jobNo;
                                val.insuranceVersion = this.navForm.insuranceVersion;
                                if (this.navForm.caseType == 3) {
                                    dutyRatioTotal += parseFloat(val.dutyRatio);
                                    val.caseNo = this.formDataRider.caseNo;
                                    val.policyNo = this.formDataRider.policyNo;
                                    val.accidentTime = this.formDataRider.accidentTime;
                                }
                            });
                            if (this.navForm.caseType == 3) {
                                dutyRatioTotal = dutyRatioTotal.toFixed(0);
                                if (dutyRatioTotal != 100) {
                                    this.$message.error('抱歉，骑手责任比例加上三者责任比例不等于100%');
                                    return false;
                                }
                            }
                            this.checkHumanInjuryWorkOrders(this.formDataThreeArr);
                        }
                    }
                });
            }
        },
        //提交骑手form
        submitFormRider() {
            this.$refs.formDataRider.validate((valid) => {
                if (valid) {
                    var json = JSON.parse(JSON.stringify(this.formDataRider));
                    json.caseType = this.navForm.caseType;
                    json.caseNo = this.navForm.caseNo;
                    json.jobNo = this.userInfo.jobNo;
                    json.insuranceVersion = this.navForm.insuranceVersion;
                    // json.personType=1;
                    this.checkHumanInjuryWorkOrders([json]);
                }
            });
        },
        //校验人伤工单
        checkHumanInjuryWorkOrders(json) {
            this.instance({
                method: 'post',
                url: '/human/injury/checkHumanInjuryWorkOrders',
                data: json,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                if (res.data.code == 1) {
                    this.dialogVisible = true;
                    this.checkHumanInjuryResponse = res.data.data;
                    // this.$message.success(res.data.message);
                    // this.humanInjuryWorkOrderTipsVo=res.data.data.humanInjuryWorkOrderTipsVo;
                } else {
                    this.$message(res.data.message);
                }
            });
        },
        //新增人伤工单
        addHumanInjuryWorkOrders(json) {
            this.instance({
                method: 'post',
                url: '/human/injury/addHumanInjuryWorkOrders',
                data: this.checkHumanInjuryResponse.humanInjuryWorkOrderList,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                if (res.data.code == 1) {
                    this.dialogVisible = false;
                    if (this.navForm.caseType != 3 || this.threeVisible) {
                        this.$message.success(res.data.message);
                        this.caseTypeChange('not');
                    }
                    this.threeVisible = !this.threeVisible;
                } else {
                    this.$message(res.data.message);
                }
            });
        }
    },
    mounted() {
        //
        window.vue = this;
        this.baseURL = this.$store.getters.baseURL;
        let userInfo = localStorage.getItem('userInfo');
        userInfo && (this.userInfo = JSON.parse(userInfo));
        this.getInsuranceVersionList();
    },
    created() {
        this.formDataThreeArr.push(JSON.parse(JSON.stringify(this.formDataThree)));
    }
};
</script>
<style scoped>
@import '../../assets/css/table_public.css';
.dialog_main {
    width: 90%;
    margin: 0 auto;
}
.dialog_title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #000000;
}
.dialog_title div {
    width: 50%;
    text-align: center;
}
.dialog_ul li {
    margin-top: 0.2rem;
    display: flex;
    justify-content: space-between;
}
.dialog_ul li > div,
.dialog_ul li > b {
    width: 46%;
    /* text-align: center; */
}
.dialog_ul li > b {
    color: #e4393c;
}
/deep/.el-dialog__body {
    padding-top: 15px;
}
.three_form {
    margin-bottom: 0.3rem;
}
.detail_title {
    font-size: 16px;
    padding-bottom: 0.15rem;
    border-bottom: 1px dashed #999;
    margin: 0.05rem 0 0.2rem;
}
.submit_btn {
    text-align: center;
    /* margin-top: 0.2rem; */
}
</style>
