<template>
    <div class="home">
        <div class="main">
            <div class="search_box">
                <div class="search_input">
                    <el-input v-model="search" placeholder="请输入案件号" clearable />
                    <el-button type="primary" v-if="isValid" @click="getVehicleData">搜索</el-button>
                </div>
                <div class="demo_num">
                    <div><img src="../../../public/images/warning_icon.png" /></div>
                    <span>示例案件号：812020080703202208051000193</span>
                </div>
                <div v-if="!isValid" style="margin-top: 0.3rem">
                    <div class="search_input">
                        <el-input v-model="vin" placeholder="请输入VIN码" clearable />
                        <el-button type="primary" @click="getVehicleData(2)">搜索</el-button>
                    </div>
                    <div class="demo_num">
                        <div><img src="../../../public/images/warning_icon.png" /></div>
                        <span>示例VIN码：LGBH12E20HY420076</span>
                    </div>
                </div>
            </div>
            <div class="result_box" v-if="tableData.length > 0">
                <p class="small_title">查询结果</p>
                <div class="brand_box">
                    <!-- <div class="auto_logos"><img src="../../public/images/auto_logos.png" ></div> -->
                    <div class="brand_detail">
                        <p>{{ tableData[0].amBrandName + '-' + tableData[0].amSeriesName }}</p>
                        <ul>
                            <li>VIN年份：{{ tableData[0].amVinYear }}</li>
                            <li>一级分类：{{ tableData[0].vehCateOneNames }}</li>
                            <li>二级分类：{{ tableData[0].vehCateTwoNames }}</li>
                            <li>厂商类型：{{ tableData[0].importFlag }}</li>
                            <li>国别：{{ tableData[0].countriesName }}</li>
                            <li>变速箱：{{ tableData[0].gearboxType }}</li>
                            <li>排量：{{ tableData[0].engineDesc }}</li>
                            <li>驱动形式：{{ tableData[0].drivenType }}</li>
                        </ul>
                    </div>
                </div>
                <el-table :data="tableData" style="width: 100%" size="small" empty-text="暂无数据" @row-click="clickRow">
                    <el-table-column align="center" prop="amVehicleName" label="销售车型名称" />
                    <el-table-column align="center" prop="amBrandName" label="品牌" />
                    <el-table-column align="center" prop="amSeriesName" label="车系" />
                    <el-table-column align="center" prop="drivenType" label="驱动形式" />
                    <el-table-column align="center" label="厂家指导价">
                        <template slot-scope="scope">
                            <div>{{ scope.row.price }}元</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div v-else class="empty_box">
                <div class="empty_img"><img src="../../../public/images/null.png" /></div>
                <p>请输入案件号</p>
            </div>
        </div>
        <el-dialog :visible.sync="modalVisible" :modal-append-to-body="false" :close-on-click-modal="false" :before-close="beforeClose" title="请选择维修渠道" width="5.4rem" center top="25vh">
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleDialogBtn(1)">修理厂维修</el-button>
                <el-button type="primary" @click="handleDialogBtn(2)">4S店维修</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src
export default {
    name: 'carDamage',
    components: {},
    data() {
        return {
            caseNo: '',
            iframeSrc: '',
            isValid: true,
            modalVisible: false,
            checkedRow: {},
            tableData: [],
            jobNo: '',
            role: '',
            search: '',
            vin: ''
        };
    },
    methods: {
        //获取销售车型列表的接口
        getVehicleData(sign) {
            if (!this.search) {
                this.$message.error('抱歉，请输入案件号！');
                return false;
            }
            if (sign == 2 && !this.vin) {
                this.$message.error('抱歉，请输入VIN码！');
                return false;
            }
            this.tableData = [];
            this.instance({
                method: 'post',
                url: '/easyepc/analysisVinByCaseNo',
                data: { searchWord: this.search, jobNo: this.jobNo, role: this.role, vin: this.vin },
                headers: {
                    // 'Content-Type':"application/x-www-form-urlencoded",
                }
            }).then((res) => {
                if (res.data.code == 1) {
                    this.dataCollection({ eventId: 301, remark: '通过案件号查询案件进入到零件选择页面', caseNo: this.search });
                    this.tableData = res.data.data;
                } else if (res.data.code == 0) {
                    this.$message.error(res.data.message);
                } else if (res.data.code == 2) {
                    this.$message.error(res.data.message);
                    this.isValid = false;
                }
            });
        },
        //点击table行
        clickRow(row) {
            if (row.reportedOrNot == 1) {
                this.$notify({
                    title: '警告',
                    message: '当前车辆已被报价',
                    duration: 0,
                    type: 'warning'
                });
            }
            this.modalVisible = true;
            this.checkedRow = row;
            // localStorage.setItem('vehicleDetail',JSON.stringify(row))
            // router.push({path:'/VehicleInfo',query:{vid:row.amVehicleId}})
        },
        //dialog关闭前的回调
        beforeClose() {
            this.modalVisible = false;
            this.$notify.closeAll();
        },
        handleDialogBtn(sign) {
            this.modalVisible = false;
            this.$notify.closeAll();
            localStorage.setItem('vehicleDetail', JSON.stringify(this.checkedRow));
            this.$router.push({ path: '/calculatorTool/carDamageDetail', query: { vid: this.checkedRow.amVehicleId, caseNo: this.search, type: sign, jobNo: this.jobNo } });
        }
    },
    mounted() {
        //
        this.caseNo = this.$route.query.caseNo;
        if (this.caseNo) {
            this.search = this.caseNo;
            this.getVehicleData();
        }
        var userInfo = localStorage.getItem('userInfo');
        if (userInfo) {
            userInfo = JSON.parse(userInfo);
            this.jobNo = userInfo.jobNo;
            this.role = userInfo.role;
        }
        let vid = this.$route.query.vid;
        if (vid) {
            this.search = vid;
            this.getVehicleData();
        }
    }
};
</script>
<style scoped>
/deep/.el-dialog__title {
    display: block;
    margin-top: 0.5rem;
}
/deep/.el-dialog--center .el-dialog__body {
    display: none;
}
/deep/.el-dialog__footer {
    padding: 0.6rem 0 0.7rem;
}
.empty_box {
    text-align: center;
    padding-top: 1.1rem;
}
.empty_img {
    width: 183px;
    margin: 0 auto 0.1rem;
}
/deep/.el-table--small .el-table__cell {
    padding: 0.1rem 0;
}
/deep/.el-table th.el-table__cell {
    background-color: #f6f7f7;
}
/deep/.el-table__row:hover {
    color: #1989fa;
    cursor: pointer;
}
.brand_box {
    display: flex;
    padding: 0 0.4rem;
    margin-bottom: 0.3rem;
}
.auto_logos {
    width: 1.4rem;
    margin-right: 0.15rem;
}
.brand_detail {
    /* width: 10.1rem; */
}
.brand_detail p {
    font-size: 0.24rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
}
.brand_detail ul {
    margin-top: 0.25rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 13px;
}
.brand_detail li {
    min-width: 1.3rem;
    margin-right: 1.3rem;
    margin-bottom: 0.1rem;
}
.small_title {
    font-size: 15px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 0.64rem;
    padding-left: 0.4rem;
    margin-bottom: 0.04rem;
}
.search_input .el-input {
    width: 390px;
}
.search_input .el-button {
    min-width: 1rem;
}
.search_input {
    width: 7.24rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.19rem;
}
.demo_num {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #ffffff;
    margin-top: 0.06rem;
}
.demo_num div {
    width: 0.14rem;
    margin-right: 0.12rem;
}
.demo_num2 {
    padding-left: 0.26rem;
}
.home {
    width: 100%;
    background-color: #f2f8fc;
    padding-bottom: 0.5rem;
}
.main {
    width: 100%;
    position: relative;
}
.search_box {
    width: 100%;
    height: 277px;
    background: url(../../../public/images/main_bg.png) no-repeat;
    background-size: 100% 100%;
    padding: 1.98rem 3.6rem 0;
}
.result_box {
    width: 18rem;
    min-height: 4rem;
    background: #ffffff;
    margin: -0.52rem auto 0;
    padding-bottom: 0.5rem;
}
</style>
