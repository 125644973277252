<template>
    <div class="case_card card_bottom-padding caseInfo">
        <el-row class="title_row">
            <el-col :span="12">
                <div class="card_title">
                    诉讼案件详情
                    <el-tag type="warning" style="margin-left: 5px" v-if="caseDetailData && caseDetailData.suspendedFlag">挂起</el-tag>
                </div>
            </el-col>
        </el-row>
        <div class="card_content">
            <el-row>
                <el-col :span="24">
                    <el-row class="content_row">
                        <el-col class="col_title" :span="5">案件名称：</el-col>
                        <el-col class="col_txt" :span="7">{{ caseDetailData && caseDetailData.caseName ? caseDetailData.caseName : '--' }}</el-col>
                        <el-col class="col_txt" :span="10">
                            归档编号：
                            {{ caseDetailData && caseDetailData.filingNumber ? caseDetailData.filingNumber : '--' }}
                        </el-col>
                    </el-row>
                    <el-row class="content_row">
                        <el-col class="col_title" :span="5">法院案号：</el-col>
                        <el-col class="col_txt" :span="7">{{ caseDetailData && caseDetailData.courtCaseNo ? caseDetailData.courtCaseNo : '--' }}</el-col>
                        <el-col class="col_txt" :span="4">
                            <span
                                v-if="caseDetailData && caseDetailData.successRate"
                                :class="{ winRate: 1, redColor: caseDetailData && caseDetailData.successRate && caseDetailData.successRate > 50, greenColor: caseDetailData && caseDetailData.successRate && caseDetailData.successRate <= 50 }"
                            ></span>
                            胜诉率：
                            {{ caseDetailData && caseDetailData.successRate ? caseDetailData.successRate + '%' : '--' }}
                        </el-col>
                        <el-col class="col_txt" :span="6" :offset="4">案件状态：{{ caseDetailData && caseDetailData.checkStatusDesc ? caseDetailData.checkStatusDesc : '--' }}</el-col>
                    </el-row>
                    <el-row class="content_row">
                        <el-col class="col_txt" :span="7">结案我司承担受理费：{{ caseDetailData && caseDetailData.bearAcceptanceFee && caseDetailData.currentCaseStep == 999 ? caseDetailData.bearAcceptanceFee + ' 元' : '--' }}</el-col>
                        <el-col class="col_title" :span="5"><div style="width: 1px; height: 1px"></div></el-col>
                        <el-col class="col_txt" :span="4">结案法院受理费：{{ caseDetailData && caseDetailData.courtAcceptanceFee && caseDetailData.currentCaseStep == 999 ? caseDetailData.courtAcceptanceFee + ' 元' : '--' }}</el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        caseDetailData: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            navForm: {
                caseNo: '',
                value1: [],
                value2: [],
                value3: '',
                value4: '',
                value5: '',
                page: 1,
                size: 10,
                total: 0
            }
        };
    },
    mounted() {},
    methods: {},
    watch: {
        caseDetailData: {
            handler(val) {
                if (val) {
                }
            },
            immediate: true
        }
    }
};
</script>
<style lang="less" scoped>
@import '../../assets/css/caseCard.css';
.caseInfo {
    .title_row {
        .btn_groups {
            text-align: right;
        }
    }
    .card_content {
        .content_row {
            .title {
                font-weight: 600;
            }
            .col_title {
                // font-weight: 600;
                max-width: 80px;
            }
            .col_txt {
                line-height: 22px;
                // border: 1px solid red;
            }
            .winRate {
                display: inline-block;
                width: 11px;
                height: 11px;
                border-radius: 50%;
                margin-right: 5px;
                position: relative;
                top: 1px;
            }
            .greenColor {
                background: #67c23a;
            }
            .redColor {
                background: red;
            }
        }
    }
}
</style>