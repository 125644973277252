<template>
    <div class="module_wrap box_border">
        <div class="box_title box_title_radio">
            <span>事故省风险案件{{ echartsType == '1' ? '' : '占比' }}排行榜</span>
            <!-- <div class="radio_group" v-if="echartsType == '1'">
                <el-row>
                    <el-radio-group v-model="radio" @change="radioChange" size="mini">
                        <el-radio-button label="数量"></el-radio-button>
                        <el-radio-button label="金额"></el-radio-button>
                    </el-radio-group>
                </el-row>
                <el-row style="margin-top: 10px">
                    <el-radio-group v-model="radio1" @change="radioChange1" size="mini">
                        <el-radio-button label="全国"></el-radio-button>
                        <el-radio-button label="前15"></el-radio-button>
                    </el-radio-group>
                </el-row>
            </div> -->

            <div class="radio_group" v-if="echartsType == '1'">
                <span style="margin-right: 10px">
                    <el-radio-group v-model="radio" @change="radioChange" size="mini">
                        <el-radio-button label="数量"></el-radio-button>
                        <el-radio-button label="金额"></el-radio-button>
                    </el-radio-group>
                </span>
                <span>
                    <el-radio-group v-model="radio1" @change="radioChange1" size="mini">
                        <el-radio-button label="全国"></el-radio-button>
                        <el-radio-button label="前15"></el-radio-button>
                    </el-radio-group>
                </span>
            </div>
        </div>
        <div id="case_bar" v-if="echartsType == '1'"></div>
        <div id="case_bar2" v-else></div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'ProvinceRisk',
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        },
        navForm: {
            type: Object,
            default: {}
        },
        echartsType: {
            type: String,
            default: ''
        }
    },
    watch: {
        isCollapse() {
            this.myChart && this.myChart.resize();
        },
        navForm: {
            deep: true,
            handler(val) {
                this.getData();
            }
        }
    },
    data() {
        return {
            topDays: [],
            newNumber: 0,
            myChart: null,
            yAxisData1: [],
            yAxisData2: [],
            sumSeriesData1: [],
            sumSeriesData2: [],
            numSeriesData1: [],
            numSeriesData2: [],
            option: '',
            radio: '数量',
            radio1: '前15',
            tmpCasesNumTopTen: null
        };
    },
    mounted() {
        this.role = localStorage.getItem('role');
        window.addEventListener('resize', () => {
            this.myChart && this.myChart.resize();
        });
    },
    methods: {
        getData() {
            let instancePath = null;
            switch (this.echartsType) {
                case '1':
                    instancePath = '/large/data/screen/getProvinceCasesNumTopTen';
                    break;
                case '2':
                    instancePath = '/large/data/screen/getProvinceCasesRatioTopTen';
                    break;

                default:
                    break;
            }
            instancePath &&
                this.instance({
                    method: 'post',
                    url: instancePath,
                    data: this.navForm,
                    headers: {
                        'Content-Type': 'application/json;charset=UTF-8'
                    }
                }).then((res) => {
                    if (res.data.code == 1) {
                        let response = res.data.data;
                        this.tmpCasesNumTopTen = response;
                        if (this.echartsType == 1) {
                            this.loadCharts(response);
                        } else {
                            this.loadCharts2(response);
                        }
                    }
                });
        },
        //数量&金额change事件
        radioChange() {
            if (this.option) {
                if (this.radio == '数量') {
                    this.option.yAxis.data = this.yAxisData1;
                    this.option.series[0].data = this.numSeriesData1;
                    this.option.series[0].name = '风险案件数量';
                } else {
                    this.option.yAxis.data = this.yAxisData2;
                    this.option.series[0].data = this.sumSeriesData1;
                    this.option.series[0].name = '风险案件金额';
                }
                setTimeout(() => {
                    this.myChart.setOption(this.option);
                }, 100);
            }
        },
        radioChange1() {
            this.loadCharts(this.tmpCasesNumTopTen);
        },
        loadCharts(response) {
            let _this = this;
            var chartDom = document.getElementById('case_bar');
            if (!chartDom) {
                return;
            }
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' });
            this.yAxisData1 = [];
            this.sumSeriesData1 = [];
            this.sumSeriesData2 = [];
            this.yAxisData2 = [];
            this.numSeriesData1 = [];
            this.numSeriesData2 = [];
            response.caseRiskNum.forEach((val, key) => {
                this.yAxisData1.push(val.name);
                this.numSeriesData1.push(val.caseNum);
                this.numSeriesData2.push(val.totalCaseNum);
            });
            response.caseRiskMoney.forEach((val, key) => {
                this.yAxisData2.push(val.name);
                this.sumSeriesData1.push(val.amountCaseFiled);
                this.sumSeriesData2.push(val.totalPayAmount);
            });

            if (this.radio1 == '前15') {
                this.sumSeriesData1 = this.sumSeriesData1.slice(0, 15);
                this.sumSeriesData2 = this.sumSeriesData2.slice(0, 15);
                this.yAxisData1 = this.yAxisData1.slice(0, 15);
                this.yAxisData2 = this.yAxisData2.slice(0, 15);
            }

            this.option = {
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    // formatter: '{b}: {c}件',
                    formatter(value) {
                        let name = '',
                            caseNum = '',
                            totalCaseNum = '';
                        if (_this.radio == '数量') {
                            response.caseRiskNum.forEach((val, key) => {
                                if (value[0].name == val.name) {
                                    name = val.name;
                                    caseNum = val.caseNum;
                                    totalCaseNum = val.totalCaseNum;
                                }
                            });
                            return name + '</br>风险案件数量：' + caseNum + '</br>总案件数量：' + totalCaseNum;
                        } else {
                            response.caseRiskMoney.forEach((val, key) => {
                                if (value[0].name == val.name) {
                                    name = val.name;
                                    caseNum = val.amountCaseFiled;
                                    totalCaseNum = val.totalPayAmount;
                                }
                            });
                            return name + '</br>风险案件金额：' + caseNum + '</br>总案件金额：' + totalCaseNum;
                        }
                    },
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    // data: ['风险案件数量', '总案件量'],
                    // data: ['风险案件数量'],
                    itemWidth: 12,
                    itemHeight: 12,
                    itemGap: 20,
                    textStyle: {
                        color: '#fff'
                        // fontSize: '0.12rem'
                    },
                    left: 'center'
                },
                grid: {
                    top: 25,
                    left: '0',
                    right: '4%',
                    bottom: '10',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#fff',
                        // fontSize: '0.12rem',
                        // rotate: 20,
                        width: 60,
                        overflow: 'truncate',
                        ellipsis: '...'
                    },
                    data: this.yAxisData1
                },
                series: [
                    {
                        name: '风险案件数量',
                        type: 'bar',
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                                { offset: 0, color: '#f295ff' },
                                { offset: 0.5, color: '#cecbff' },
                                { offset: 1, color: '#32dadd' }
                            ])
                        },
                        stack: 'total',
                        emphasis: {
                            focus: 'series'
                        },
                        barMaxWidth: '40%',
                        data: this.numSeriesData1
                    }
                    // {
                    // 	name:'总案件量',
                    // 	type: 'bar',
                    // 	itemStyle: {
                    // 		color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    // 			{ offset: 0, color: '#a254a8' },
                    // 			{ offset: 0.5, color: '#8f72bf' },
                    // 			{ offset: 1, color: '#669fdf' }
                    // 		])
                    // 	},
                    // 	emphasis: {
                    // 	    focus: 'series'
                    // 	},
                    // 	stack: 'total',
                    // 	barMaxWidth:'35%',
                    // 	data: this.numSeriesData2
                    // }
                ]
            };
            this.myChart.setOption(this.option);
        },
        loadCharts2(response) {
            let _this = this;
            var chartDom = document.getElementById('case_bar2');
            if (!chartDom) {
                return;
            }
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' });
            this.yAxisData1 = [];
            this.sumSeriesData1 = [];
            this.sumSeriesData2 = [];
            this.yAxisData2 = [];
            this.numSeriesData1 = [];
            this.numSeriesData2 = [];
            response.caseRiskNum.forEach((val, key) => {
                this.yAxisData1.push(val.name);
                this.numSeriesData1.push(val.caseNum);
                this.numSeriesData2.push(val.totalCaseNum);
            });

            this.option = {
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    // formatter: '{b}: {c}件',
                    formatter(value) {
                        let name = '',
                            caseNum = '',
                            totalCaseNum = '';
                        if (_this.radio == '数量') {
                            response.caseRiskNum.forEach((val, key) => {
                                if (value[0].name == val.name) {
                                    name = val.name;
                                    caseNum = val.caseNum;
                                    totalCaseNum = val.totalCaseNum;
                                }
                            });
                            return name + '</br>风险案件数量：' + caseNum + '</br>总案件数量：' + totalCaseNum;
                        } else {
                            response.caseRiskMoney.forEach((val, key) => {
                                if (value[0].name == val.name) {
                                    name = val.name;
                                    caseNum = val.amountCaseFiled;
                                    totalCaseNum = val.totalPayAmount;
                                }
                            });
                            return name + '</br>风险案件金额：' + caseNum + '</br>总案件金额：' + totalCaseNum;
                        }
                    },
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    // data: ['风险案件数量', '总案件量'],
                    data: ['风险案件数量占比'],
                    itemWidth: 12,
                    itemHeight: 12,
                    itemGap: 20,
                    textStyle: {
                        color: '#fff'
                        // fontSize: '0.12rem'
                    },
                    left: 'center'
                },
                grid: {
                    top: 25,
                    left: '0',
                    right: '4%',
                    bottom: '10',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#fff',
                        // fontSize: '0.12rem',
                        // rotate: 20,
                        width: 60,
                        overflow: 'truncate',
                        ellipsis: '...'
                    },
                    data: this.yAxisData1
                },
                series: [
                    {
                        name: '风险案件数量占比',
                        type: 'bar',
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                                { offset: 0, color: '#f295ff' },
                                { offset: 0.5, color: '#cecbff' },
                                { offset: 1, color: '#32dadd' }
                            ])
                        },
                        stack: 'total',
                        emphasis: {
                            focus: 'series'
                        },
                        barMaxWidth: '40%',
                        data: this.numSeriesData1
                    }
                ]
            };
            this.myChart.setOption(this.option);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.module_wrap {
    width: 100%;
    height: 6.2rem;
    padding: 0.15rem 0.3rem 0;
    margin-top: 0.25rem;
}
#case_bar,
#case_bar2 {
    width: 100%;
    height: 5.4rem;
    margin-top: 0.15rem;
}
</style>
