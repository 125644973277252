<template>
    <div class="wrap">
        <div class="page_title"><b>车损报价列表</b></div>
        <div class="search_box public_box">
            <!--检索部分  -->
            <el-form :inline="true" :model="navForm" class="demo-form-inline">
                <el-form-item label="案件号：">
                    <el-input v-model="navForm.caseNo" type="text" placeholder="请输入案件号" clearable></el-input>
                </el-form-item>
                <el-form-item label="报价提交时间：" class="dateBox">
                    <el-row style="width: 330px">
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" v-model="navForm.startTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                        <el-col class="line" style="text-align: center" :span="2">至</el-col>
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" v-model="navForm.endTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="searchTable" style="margin-left: 0.2rem">查询</el-button>
                    <!-- <el-button type="primary"  size="medium" @click="linkHeat" style="margin-left: 0.2rem;">热力图</el-button> -->
                </el-form-item>
            </el-form>
        </div>
        <div class="main">
            <div class="table_box public_box">
                <div class="download_box">
                    <div class="warning_icon">
                        <img src="../../../public/images/warning_icon.png" />
                    </div>
                    <div>
                        <span>累计报价</span>
                        <b class="a_color">{{ multipleSelection.length }}</b>
                        <span>次</span>
                    </div>
                    <div style="margin-left: 0.3rem">
                        <span>总报价</span>
                        <b class="a_color">{{ totalAmount }}</b>
                        <span>元</span>
                    </div>
                    <div class="download a_color" @click="handleDownload">下载定损单</div>
                </div>
                <!--table表格部分-->
                <el-table ref="multipleTable" :data="tableData" v-loading="loading" style="width: 100%" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column prop="caseNo" label="案件号"></el-table-column>
                    <el-table-column prop="amVehicleName" label="厂牌车型"></el-table-column>
                    <el-table-column prop="vin" label="VIN码"></el-table-column>
                    <el-table-column prop="partsNum" label="装配件总量（个）"></el-table-column>
                    <el-table-column prop="totalAmount" label="车损总报价（元）"></el-table-column>
                    <el-table-column prop="exceptionTips" label="报价异常项提示"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <span class="a_color" @click="lookDetails(scope.row)">查看详情</span>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="10" :total="totalPage"> </el-pagination>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false" width="70%">
            <div class="dialog_box">
                <div class="dialog_main">
                    <table class="table_base" border="1" cellspacing="0" cellpadding="0">
                        <tr class="table_title">
                            <td colspan="12">机动车辆报价定损单</td>
                        </tr>
                        <tr>
                            <td colspan="12">被保险人：{{ workOrderDetail.nameOfInsured }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">案件号：</td>
                            <td colspan="4">{{ workOrderDetail.caseNo }}</td>
                            <td colspan="2">保单号：</td>
                            <td colspan="4">{{ workOrderDetail.policyNo }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">VIN码：</td>
                            <td colspan="4">{{ workOrderDetail.vin }}</td>
                            <td colspan="2">车牌号：</td>
                            <td colspan="4">{{ workOrderDetail.plateNo }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">厂牌车型：</td>
                            <td colspan="4">{{ workOrderDetail.amVehicleName }}</td>
                            <td colspan="2">出险时间：</td>
                            <td colspan="4">{{ workOrderDetail.accidentTime }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">维修渠道名称：</td>
                            <td colspan="4">{{ workOrderDetail.repairChannel }}</td>
                            <td colspan="2">报价时间：</td>
                            <td colspan="4">{{ workOrderDetail.reportTime }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">序号</td>
                            <td colspan="1">更换零件名称</td>
                            <td colspan="1">原厂零件名称</td>
                            <td colspan="1">零件号</td>
                            <td colspan="1">修理项目</td>
                            <td colspan="2">装配数量(个)</td>
                            <td colspan="1">零件报价(元)</td>
                            <td colspan="1">修补费(元)</td>
                            <td colspan="1">拆装报价(元)</td>
                            <td colspan="1">喷漆费报价(元)</td>
                        </tr>
                        <tr v-for="(item, index) in workOrderDetail.damageWorkOrderList" :key="index">
                            <td colspan="2">{{ index + 1 }}</td>
                            <td colspan="1">{{ item.stdPartName }}</td>
                            <td colspan="1">{{ item.oeName }}</td>
                            <td colspan="1">{{ item.oe }}</td>
                            <td colspan="1">{{ item.operationType }}</td>
                            <td colspan="2">{{ item.partNum }}</td>
                            <td colspan="1">{{ item.partsOffer }}</td>
                            <td colspan="1">{{ item.repairOffer }}</td>
                            <td colspan="1">{{ item.installOffer }}</td>
                            <td colspan="1">{{ item.paintingOffer }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">材料费：</td>
                            <td colspan="4">{{ workOrderDetail.materialCost }}元</td>
                            <td colspan="2">工时费：</td>
                            <td colspan="4">{{ workOrderDetail.laborCost }}元</td>
                        </tr>
                        <tr>
                            <td colspan="2">总报价：</td>
                            <td colspan="10">{{ workOrderDetail.totalAmount }}元</td>
                        </tr>
                    </table>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src
// import MyHeader from '@/components/MyHeader.vue'
export default {
    name: 'carDamageList',
    components: {},
    data() {
        return {
            loading: false,
            dialogVisible: false,
            navForm: {
                //储存搜索框的检索条件信息
                startTime: '',
                endTime: '',
                caseNo: ''
            },
            workOrderDetail: {
                damageWorkOrderList: []
            },
            totalAmount: 0,
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            totalPage: 1, //总页数tableData:[],
            tableData: [],
            multipleSelection: []
        };
    },
    methods: {
        //切换table选中事件
        handleSelectionChange(val) {
            this.multipleSelection = val;
            if (val.length > 0) {
                var total = 0;
                val.forEach((value, index) => {
                    total += value.totalAmount;
                });
                this.totalAmount = total;
            }
        },
        //点击查询
        searchTable() {
            this.currentPage = 1;
            this.getTbodyData();
        },
        //点击查看详情
        lookDetails(row) {
            this.checkedRowDetail = row;
            this.dialogVisible = true;
            var json = {
                caseNo: row.caseNo,
                vin: row.vin,
                addTime: row.addTime
            };
            this.instance.post('/easyepc/findWorkOrderDetail', json).then((res) => {
                if (res.data.code == 1) {
                    var response = res.data.data;
                    this.workOrderDetail = response;
                }
            });
        },
        //获取tbody里的数据
        getTbodyData: function () {
            this.loading = true;
            this.tableData = []; //  清空table的值
            var json = JSON.parse(JSON.stringify(this.navForm));
            json.pageNum = this.currentPage;
            json.pageSize = this.pageSize;
            this.instance.post('/easyepc/findWorkOrders', json).then((res) => {
                this.loading = false;
                this.tableData = res.data.data.list;
                this.totalPage = parseInt(res.data.data.total);
            });
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTbodyData();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange: function (val) {
            this.currentPage = Number(val);
            this.getTbodyData();
        },
        //下载工单
        handleDownload() {
            if (this.multipleSelection.length == 0) {
                this.$message.error('抱歉，请选择您要下载的定损单！');
                return false;
            }
            // var json=JSON.parse(JSON.stringify(this.navForm));
            // json.workOrderList=this.multipleSelection;
            this.loading = true;
            this.instance({
                method: 'post',
                url: '/easyepc/exportWorkOrderDetail',
                data: this.multipleSelection,
                responseType: 'blob',
                headers: {
                    // 'Content-Type':"text/csv;charset=UTF-8",
                }
            })
                .then((res) => {
                    this.loading = false;
                    let url = window.URL.createObjectURL(new Blob([res.data]));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    let unit = this.multipleSelection.length > 1 ? 'zip' : 'xlsx';
                    link.setAttribute('download', '定损单下载表单.zip'); //指定下载后的文件名，防跳转
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    mounted() {
        //
        // const date = new Date();
        // let startDate=new Date(date.getTime() - 3600 * 1000 * 24 * 7);
        // this.navForm.startTime=this.dateFormatting(startDate,'yyyy-MM-dd');
        // this.navForm.endTime=this.dateFormatting(date,'yyyy-MM-dd');
        this.getTbodyData();
    }
};
</script>
<style scoped>
@import '../../assets/css/table_public.css';

.table_base {
    width: 100%;
    border-color: #eee;
    /* border-collapse: collapse; */
    border-collapse: separate !important;
    border-spacing: 0px !important;
}
.table_base td {
    padding: 5px 8px;
    font-size: 12px;
}
.table_title td {
    font-size: 16px;
    text-align: center;
    color: #000000;
    font-weight: 500;
    line-height: 1.6;
}
.download_box {
    display: flex;
    width: 100%;
    padding: 0.09rem 0.22rem;
    background: #e9f4ff;
    border: 1px solid #1989fa;
    font-size: 0.16rem;
    margin-bottom: 0.2rem;
    align-items: center;
}
.download_box b {
    display: inline-block;
    margin: 0 0.1rem;
}
.warning_icon {
    width: 0.14rem;
    margin-right: 0.18rem;
}
.download {
    margin-left: 0.5rem;
    cursor: pointer;
}
</style>
