<template>
    <div class="home">
        <div class="main">
            <div class="search_box">
                <div class="search_input">
                    <el-input v-model="searchBase" placeholder="请输入案件号" clearable />
                    <el-button type="primary" @click="handleSearch">搜索</el-button>
                </div>
                <div class="brand_box">
                    <!-- <div class="auto_logos"><img src="../../../../public/images/car-damage/auto_logos.png" ></div> -->
                    <div>
                        <p>{{ vehicleDetail.amBrandName + '-' + vehicleDetail.amSeriesName }}</p>
                        <span>{{ vehicleDetail.amVehicleName }}</span>
                    </div>
                </div>
            </div>
            <div class="result_box">
                <div class="search_input search_input2">
                    <div>
                        <el-input v-model="searchParts" placeholder="请输入零配件名称/OE码" clearable />
                        <el-button type="primary" @click="handleSearchParts">搜索</el-button>
                    </div>
                    <div class="city_box">
                        <span class="location_icon"></span>
                        <b class="city_hint">查询城市：</b>
                        <el-dropdown class="city_dropdown" trigger="click">
                            <span class="el-dropdown-link"> {{ defaultProvince.province }} | {{ defaultProvince.region }} </span>
                            <el-dropdown-menu style="max-height: 4rem; overflow: auto">
                                <el-dropdown-item v-for="(item, index) in provinceData" :key="index" @click.native="clickDropdown(item)" :command="item.province">{{ item.province }}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
                <div class="vehicle_main">
                    <el-menu default-active="2" class="el-menu-vertical-demo vehicle_menu">
                        <template v-for="(item1, index1) in menusData">
                            <el-menu-item :index="index1.toString()" v-if="item1.isLeaf == 1" @click="menusSelect(item1)">
                                <span>{{ item1.partGroupName }}</span>
                            </el-menu-item>
                            <el-submenu :index="index1.toString()" v-else>
                                <template slot="title">
                                    <span>{{ item1.partGroupName }}</span>
                                </template>
                                <template v-for="(item2, index2) in item1.child">
                                    <el-menu-item :index="index1 + '-' + index2" v-if="item2.isLeaf == 1" @click="menusSelect(item2)">
                                        <span style="padding-left: 0.1rem !important">{{ item2.partGroupName }}</span>
                                    </el-menu-item>
                                    <el-submenu :index="index1 + '-' + index2" v-else>
                                        <template slot="title">
                                            <span style="padding-left: 0.1rem !important">
                                                {{ item2.partGroupName }}
                                            </span>
                                        </template>
                                        <el-menu-item v-for="(item3, index3) in item2.child" :key="index3" :index="index1 + '-' + index2 + '-' + index3" @click="menusSelect(item3)" style="padding-left: 0.3rem !important">
                                            {{ item3.partGroupName }}
                                        </el-menu-item>
                                    </el-submenu>
                                </template>
                            </el-submenu>
                        </template>
                    </el-menu>
                    <el-table :data="tableData" class="vehicle_table" size="small" height="4.6rem" empty-text="暂无数据">
                        <el-table-column prop="name" label="零件名称">
                            <template slot-scope="scope">
                                <div class="plus_cell">
                                    <div v-show="!checkedPartsId.includes(scope.row.oeId + scope.row.oeName)" @click="clickRow(scope.row, 'plus')"><img src="../../../public/images/plus_icon.png" /></div>
                                    <div v-show="checkedPartsId.includes(scope.row.oeId + scope.row.oeName)" @click="clickRow(scope.row, 'minus')"><img src="../../../public/images/minus_icon.png" /></div>
                                    <span>{{ scope.row.stdPartName }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="oeName" label="原厂零件名称" />
                        <el-table-column prop="oe" label="零件号" />
                        <!-- <el-table-column label="图片">
                            <template slot-scope="scope">
                                <div class="part_img" @click="loadPartImg(scope.row)">
                                    <img src="../../../../public/images/default_icon.png" />
                                </div>
                            </template> -->
                        </el-table-column>
                        <!-- <el-table-column prop="type" label="适配车型" /> -->
                    </el-table>
                </div>
            </div>
            <div class="parts_box">
                <div class="parts_top">
                    <p>我的配件工单</p>
                    <div class="parts_totle">
                        <div>您已添加配件：{{ myPartsData.length }}个；价格合计：{{ totalPrice }}元</div>
                        <el-button type="primary" size="mini" class="download_btn" @click="handleDownload"> 工单下载<i class="el-icon-download el-icon--right"></i> </el-button>
                        <el-popover placement="top" width="400" v-model="baseParameter.visible">
                            <p>请输入您的宝贵建议：</p>
                            <el-input type="textarea" style="margin: 10px 0" :rows="5" placeholder="请输入内容" v-model="baseParameter.textarea"> </el-input>
                            <div style="text-align: right; margin: 0">
                                <el-button size="mini" type="text" @click="baseParameter.visible = false">取消</el-button>
                                <el-button type="primary" size="mini" @click="submitMessage">确定</el-button>
                            </div>
                            <el-button size="mini" type="success" slot="reference">留言板</el-button>
                        </el-popover>
                    </div>
                </div>
                <!-- <div class="parts_middle" :class="{disabled_null_border:submitDisabled}"> -->
                <div class="parts_middle disabled_null_border">
                    <el-table :data="myPartsData" size="small" table-layout="auto" empty-text="暂无数据" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="45"></el-table-column>
                        <el-table-column label="零件名称">
                            <template slot-scope="scope">
                                <div v-if="scope.row.oe">{{ scope.row.stdPartName }}</div>
                                <div v-else class="table_input">
                                    <el-input v-model="scope.row.stdPartName" :disabled="submitDisabled" size="small" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="原厂零件名称">
                            <template slot-scope="scope">
                                <div v-if="scope.row.oe">{{ scope.row.oeName }}</div>
                                <div v-else class="table_input">
                                    <el-input v-model="scope.row.oeName" disabled size="small" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="零件号">
                            <template slot-scope="scope">
                                <div v-if="scope.row.oe">{{ scope.row.oe }}</div>
                                <div v-else class="table_input">
                                    <el-input v-model="scope.row.oe" disabled size="small" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="零件维修/更换" width="100">
                            <template slot-scope="scope">
                                <div>
                                    <el-select v-model="scope.row.operationType" @change="partsTypeChange(scope.row)" :disabled="submitDisabled" class="m-2" placeholder="Select" size="default">
                                        <el-option label="维修" value="1"></el-option>
                                        <el-option label="更换" value="2"></el-option>
                                    </el-select>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="装配数量(个)" width="90">
                            <template slot-scope="scope">
                                <div v-if="scope.row.oe">{{ scope.row.partNum }}</div>
                                <div v-else class="table_input">
                                    <el-input v-model="scope.row.partNum" :disabled="submitDisabled" type="number" size="small" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="零件报价(元)" width="90">
                            <template slot-scope="scope">
                                <div class="table_input">
                                    <el-input :disabled="!scope.row.disabled || submitDisabled" type="number" v-model="scope.row.partsOffer" size="small" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="拆装报价(元)" width="150">
                            <template slot-scope="scope">
                                <div class="table_input">
                                    <el-input v-model="scope.row.installOffer" :disabled="submitDisabled" type="number" size="small" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="修补费报价(元)" width="150">
                            <template slot-scope="scope">
                                <div class="table_input">
                                    <el-input :disabled="scope.row.disabled || submitDisabled" v-model="scope.row.repairOffer" type="number" size="small" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="喷漆报价(元)" width="150">
                            <template slot-scope="scope">
                                <div class="table_input">
                                    <el-input v-model="scope.row.paintingOffer" :disabled="submitDisabled" type="number" size="small" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="报价异常提示">
                            <template slot-scope="scope">
                                <div>
                                    <span style="color: #cc4f4f">{{ scope.row.tips }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <div class="delete_icon" @click="handleDelete('single', scope.row)">
                                    <img src="../../../public/images/delete_icon.png" />
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="parts_bottom">
                    <div>
                        <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="handleAdd">自定义添加</el-button>
                        <el-button size="mini" type="danger" plain icon="el-icon-delete" @click="handleDelete('batch')" style="margin-left: 0.3rem">批量删除</el-button>
                    </div>
                    <div>
                        <el-button :disabled="submitDisabled" size="mini" type="primary" icon="el-icon-top" @click="checkWorkOrders">提交报价</el-button>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="centerDialogVisible" :modal-append-to-body="false" :title="checkedPartName" width="56%">
            <div><img :src="checkedPartImg.picUrl" /></div>
        </el-dialog>
        <result-modal @handleReturn="handleReturn" @addWorkOrders="addWorkOrders" :tipsNum="baseParameter.tipsNum" :pageType="submitType" :modalVisible="modalVisible"></result-modal>
    </div>
</template>

<script>
// @ is an alias to /src
import ResultModal from './carDamageDetail/components/ResultModal.vue';
import { v4 as uuidv4 } from 'uuid';
export default {
    name: 'VehicleInfo',
    components: {
        ResultModal
    },
    data() {
        return {
            vehicleDetail: '',
            modalVisible: false,
            partForm: {},
            submitType: '',
            checkedPartsId: [],
            myPartsData: [],
            multipleSelection: [],
            menusData: [],
            tableData: [],
            searchBase: '',
            searchParts: '', //搜索零件号值
            defaultProvince: {},
            submitDisabled: false,
            basePartsPrice: [],
            checkedPartImg: {}, //选中的零件图片
            checkedPartName: '选中的零件名称', //选中的零件名称
            centerDialogVisible: false,

            baseParameter: {
                vin: '',
                textarea: '',
                tipsNum: 0,
                visible: false,
                channelType: '', //维修渠道类型  1==修理厂 2==4s店
                partGroupId: '', //车辆结构id
                amVehicleId: '' // 销售车型ID
            },
            provinceData: [
                { code: '110000', province: '北京市', region: '华北区', oeRegion: '华北区' },
                { code: '120000', province: '天津市', region: '华北区', oeRegion: '华北区' },
                { code: '130000', province: '河北省', region: '华北区', oeRegion: '华北区' },
                { code: '140000', province: '山西省', region: '华北区', oeRegion: '华北区' },
                { code: '150000', province: '内蒙古', region: '华北区', oeRegion: '华北区' },
                { code: '210000', province: '辽宁省', region: '东北区', oeRegion: '东北区' },
                { code: '220000', province: '吉林省', region: '东北区', oeRegion: '东北区' },
                { code: '230000', province: '黑龙江省', region: '东北区', oeRegion: '东北区' },
                { code: '310000', province: '上海市', region: '华东区', oeRegion: '华东区' },
                { code: '320000', province: '江苏省', region: '华东区', oeRegion: '华东区' },
                { code: '330000', province: '浙江省', region: '华东区', oeRegion: '华东区' },
                { code: '340000', province: '安徽省', region: '华东区', oeRegion: '华东区' },
                { code: '350000', province: '福建省', region: '华东区', oeRegion: '华南区' },
                { code: '360000', province: '江西省', region: '华东区', oeRegion: '华南区' },
                { code: '370000', province: '山东省', region: '华东区', oeRegion: '华北区' },
                { code: '410000', province: '河南省', region: '华中区', oeRegion: '华北区' },
                { code: '420000', province: '湖北省', region: '华中区', oeRegion: '华南区' },
                { code: '430000', province: '湖南省', region: '华中区', oeRegion: '华南区' },
                { code: '440000', province: '广东省', region: '华南区', oeRegion: '华南区' },
                { code: '450000', province: '广西省', region: '华南区', oeRegion: '华南区' },
                { code: '460000', province: '海南省', region: '华南区', oeRegion: '华南区' },
                { code: '500000', province: '重庆市', region: '西南区', oeRegion: '西部区' },
                { code: '510000', province: '四川省', region: '西南区', oeRegion: '西部区' },
                { code: '520000', province: '贵州省', region: '西南区', oeRegion: '华南区' },
                { code: '530000', province: '云南省', region: '西南区', oeRegion: '西部区' },
                { code: '540000', province: '西藏省', region: '西南区', oeRegion: '西部区' },
                { code: '610000', province: '陕西省', region: '西北区', oeRegion: '西部区' },
                { code: '620000', province: '甘肃省', region: '西北区', oeRegion: '西部区' },
                { code: '630000', province: '青海省', region: '西北区', oeRegion: '西部区' },
                { code: '640000', province: '宁夏省', region: '西北区', oeRegion: '西部区' },
                { code: '650000', province: '新疆省', region: '西北区', oeRegion: '西部区' },
                { code: '710000', province: '台湾省', region: '华东区', oeRegion: '港澳台' },
                { code: '810000', province: '香港', region: '华南区', oeRegion: '港澳台' },
                { code: '820000', province: '澳门', region: '华南区', oeRegion: '港澳台' }
            ]
        };
    },
    computed: {
        totalPrice() {
            let total = 0;
            if (this.myPartsData.length > 0) {
                this.myPartsData.forEach((val, key) => {
                    total += Number(val.partNum) * (Number(val.partsOffer) + Number(val.installOffer) + Number(val.repairOffer) + Number(val.paintingOffer));
                });
            }
            return total;
        }
    },
    methods: {
        //获取车辆结构
        getVehicleStructure() {
            this.instance({
                method: 'post',
                url: '/easyepc/getVehicleStructure',
                data: { amVehicleId: this.baseParameter.amVehicleId },
                headers: {
                    // 'Content-Type':"application/x-www-form-urlencoded",
                }
            }).then((res) => {
                var data = res.data;
                if (data.code == 1) {
                    this.baseParameter.amVehicleId = data.data.amVehicleId;
                    let menusData = [];
                    if (data.data.vehicleStructures.length > 0) {
                        data.data.vehicleStructures.forEach((val, key) => {
                            val.child = [];
                            if (val.partGroupLevel == 1) {
                                menusData.push(val);
                            }
                        });
                        data.data.vehicleStructures.forEach((val, key) => {
                            if (val.partGroupLevel == 2) {
                                menusData.forEach((value, index) => {
                                    if (value.partGroupId == val.parentId) {
                                        value.child.push(val);
                                    }
                                });
                            }
                        });
                        data.data.vehicleStructures.forEach((val, key) => {
                            if (val.partGroupLevel == 3) {
                                menusData.forEach((value, index) => {
                                    for (let i = 0, len = value.child.length; i < len; i++) {
                                        if (value.child[i].partGroupId == val.parentId) {
                                            value.child[i].child.push(val);
                                        }
                                    }
                                });
                            }
                        });
                        this.menusData = menusData;
                    }
                } else {
                }
            });
        },
        //选择菜单的回调
        menusSelect(item) {
            this.getPartsByPartGroup(item.partGroupId);
        },
        //通过零件分组查配件
        getPartsByPartGroup(partGroupId) {
            this.baseParameter.partGroupId = partGroupId;
            this.instance({
                method: 'post',
                url: '/easyepc/getPartsByPartGroup',
                data: {
                    amVehicleId: this.baseParameter.amVehicleId,
                    partGroupId,
                    vin: this.vehicleDetail.vin
                },
                headers: {
                    // 'Content-Type':"application/x-www-form-urlencoded",
                }
            }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    if (data.data.partsGroup.length > 0) {
                        data.data.partsGroup.forEach((val, key) => {
                            val.operationType = '1';
                            val.partsOffer = 0;
                            val.installOffer = 0;
                            val.repairOffer = 0;
                            val.paintingOffer = 0;
                            val.tips = '';
                            val.vin = data.data.vin;
                        });
                    }
                    this.tableData = data.data.partsGroup;
                } else {
                    // this.$message.error(data.message)
                }
            });
        },
        //查询零件图片
        loadPartImg(row) {
            this.checkedPartImg = [];
            this.checkedPartName = row.oeName;
            this.instance({
                method: 'post',
                url: '/easyepc/getPicByOeId',
                data: {
                    amVehicleId: this.baseParameter.amVehicleId,
                    partGroupId: this.baseParameter.partGroupId,
                    oeId: row.oeId
                },
                headers: {
                    // 'Content-Type':"application/x-www-form-urlencoded",
                }
            }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.checkedPartImg = data.data[0];
                    this.centerDialogVisible = true;
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        //零件价格查询
        getPartsPrice(row) {
            this.instance({
                method: 'post',
                url: '/easyepc/getPartsPrice',
                data: {
                    oeId: row.oeId
                },
                headers: {
                    // 'Content-Type':"application/x-www-form-urlencoded",
                }
            }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.basePartsPrice.push(data.data);
                    this.myPartsData.forEach((val, key) => {
                        if (val.oeId == data.data.oeId) {
                            val.guidePrice = data.data.guidePrice;
                        }
                    });
                } else {
                    // this.$message.error(data.message)
                }
            });
        },
        //通过OE查询适配销售车型
        getTypeByOE() {
            this.instance({
                method: 'post',
                url: '/easyepc/getTypeByOE',
                data: {
                    oe: '',
                    amBrandId: ''
                },
                headers: {
                    // 'Content-Type':"application/x-www-form-urlencoded",
                }
            }).then((res) => {
                if (res.data.code == 1) {
                } else {
                    // this.$message.error(res.data.message)
                }
            });
        },
        //分省市场参考价查询
        getProvincePrice(row) {
            this.instance({
                method: 'post',
                url: '/easyepc/getProvincePrice',
                data: {
                    oe: row.oe,
                    brandId: row.priceBrandId,
                    provinceCode: this.defaultProvince.code
                },
                headers: {
                    // 'Content-Type':"application/x-www-form-urlencoded",
                }
            }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.myPartsData.forEach((val, key) => {
                        if (val.oe == data.data.oe) {
                            val.marketPrice = data.data.marketPrice;
                        }
                    });
                } else {
                    // this.$message.error(data.message)
                }
            });
        },
        //点击搜索调用的函数
        handleSearch() {
            this.$router.push({ path: '/calculatorTool/carDamage', query: { vid: this.searchBase } });
        },
        //点击搜索零件调用的函数
        handleSearchParts() {
            this.instance({
                method: 'post',
                url: '/easyepc/getPartsByNameOrOE',
                data: {
                    searchWord: this.searchParts,
                    amVehicleId: this.baseParameter.amVehicleId,
                    vin: this.vehicleDetail.vin
                },
                headers: {
                    // 'Content-Type':"application/x-www-form-urlencoded",
                }
            }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    if (data.data.length > 0) {
                        data.data.forEach((val, key) => {
                            val.operationType = '1';
                            val.partsOffer = 0;
                            val.installOffer = 0;
                            val.repairOffer = 0;
                            val.paintingOffer = 0;
                            val.tips = '';
                            val.vin = this.vehicleDetail.vin;
                        });
                    }
                    this.tableData = data.data;
                } else {
                    // this.$message.error(data.message)
                }
            });
        },
        //点击加减号
        clickRow(row, sign) {
            this.submitDisabled = false;
            if (sign == 'plus') {
                row.repairChannel = this.baseParameter.channelType;
                row.amVehicleId = this.baseParameter.amVehicleId;
                row.operationType = '1';
                row.partsOffer = 0;
                row.installOffer = 0;
                row.repairOffer = 0;
                row.paintingOffer = 0;
                this.myPartsData.push(row);
                this.checkedPartsId.push(row.oeId + row.oeName);
                this.getPartsPrice(row);
            } else {
                let _index = this.myPartsData.findIndex((value, index) => {
                    return value.oeId + value.oeName == row.oeId + row.oeName;
                });
                this.myPartsData.splice(_index, 1);
                this.checkedPartsId.remove(row.oeId + row.oeName);
            }
        },
        //点击切换城市
        clickDropdown(item) {
            this.$confirm('更换城市后，将为您重新查询当前配件价格，是否继续？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.defaultProvince = item;
                })
                .catch(() => {});
        },
        //选中配件的复选框change事件
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        //零件维修更换的change事件
        partsTypeChange(item) {
            if (item.operationType == '2') {
                item.disabled = true;
                item.repairOffer = 0;
            } else {
                item.disabled = false;
                item.partsOffer = 0;
            }
        },
        //操作删除选中的配件
        handleDelete(sign, row) {
            if (sign == 'batch') {
                //批量
                if (this.multipleSelection.length > 0) {
                    //过滤未选中的配件
                    let arr = this.myPartsData.filter((item) => {
                        let selection = this.multipleSelection.map((v) => v.oeId);
                        return !selection.includes(item.oeId);
                    });
                    //删除选中零件id中勾选的
                    this.multipleSelection.forEach((val, key) => {
                        this.checkedPartsId.remove(val.oeId + val.oeName);
                    });
                    this.multipleSelection = [];
                    this.myPartsData = arr;
                } else {
                    this.$message.error('抱歉，请先选择要删除的配件！');
                    return false;
                }
            } else {
                let _index = this.myPartsData.findIndex((value, index) => {
                    return value.oeId == row.oeId;
                });
                this.myPartsData.splice(_index, 1);
                this.checkedPartsId.remove(row.oeId + row.oeName);
            }
        },
        //点击工单下载
        handleDownload() {
            if (this.myPartsData.length > 0) {
                var isSubmit = true;
                this.myPartsData.forEach((val, key) => {
                    if (!val.addTime) {
                        isSubmit = false;
                    }
                });
                if (isSubmit == false) {
                    this.$message.error('抱歉，请先提交报价，再下载工单！');
                    return false;
                }
            } else {
                this.$message.error('抱歉，请先添加配件！');
                return false;
            }
            this.instance({
                method: 'post',
                url: '/easyepc/exportWorkOrderDetail',
                data: [this.myPartsData[0]],
                responseType: 'blob',
                headers: {
                    // 'Content-Type':"text/csv;charset=UTF-8",
                }
            })
                .then((res) => {
                    let url = window.URL.createObjectURL(new Blob([res.data]));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', '车损计算器下载工单.zip'); //指定下载后的文件名，防跳转
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //点击自定义添加
        handleAdd() {
            this.submitDisabled = false;
            this.myPartsData.push({
                vin: this.vehicleDetail.vin,
                oeId: uuidv4(),
                oeName: '',
                partNum: 1,
                oe: '',
                stdPartName: '',
                tips: '',
                partsOffer: 0,
                installOffer: 0,
                repairOffer: 0,
                paintingOffer: 0,
                operationType: '1'
            });
        },
        //关闭模态框
        handleReturn() {
            this.modalVisible = false;
        },
        //点击提交报价
        checkWorkOrders() {
            if (this.myPartsData.length > 0) {
                let isSubmit = true;
                let hintMessage = '';
                this.myPartsData.forEach((val, key) => {
                    if (!val.stdPartName) {
                        hintMessage = '抱歉，请填写自定义零件名称！';
                        isSubmit = false;
                    }
                    if (!val.partNum > 0) {
                        hintMessage = '抱歉，请填写装配数量且大于0！';
                        isSubmit = false;
                    }
                    if (val.operationType == 2 && !val.partsOffer > 0) {
                        hintMessage = '抱歉，选择零件更换时，请填写零件报价且大于0！';
                        isSubmit = false;
                    }
                    val.caseNo = this.baseParameter.caseNo;
                    val.jobNo = this.baseParameter.jobNo;
                    val.vehiclePrice = this.vehicleDetail.price;
                    val.amVehicleName = this.vehicleDetail.amVehicleName;
                    val.vehicleBrand = this.vehicleDetail.amBrandName + '-' + this.vehicleDetail.amSeriesName;
                    if (!val.marketPrice > 0) {
                        let oeRegion = this.defaultProvince.oeRegion;
                        this.basePartsPrice.length > 0 &&
                            this.basePartsPrice.forEach((value, index) => {
                                if (value.oeId == val.oeId) {
                                    if (oeRegion == '华东区') {
                                        val.marketPrice = value.marketPriceHd;
                                    } else if (oeRegion == '东北区') {
                                        val.marketPrice = value.marketPriceDb;
                                    } else if (oeRegion == '华北区') {
                                        val.marketPrice = value.marketPriceHb;
                                    } else if (oeRegion == '西部区') {
                                        val.marketPrice = value.marketPriceXb;
                                    } else if (oeRegion == '华南区') {
                                        val.marketPrice = value.marketPriceHn;
                                    }
                                }
                            });
                    }
                });
                if (!isSubmit) {
                    this.$message.error(hintMessage);
                    return false;
                }
                this.instance({
                    method: 'post',
                    url: '/easyepc/checkWorkOrders',
                    data: this.myPartsData,
                    headers: {
                        // 'Content-Type':"application/x-www-form-urlencoded",
                    }
                }).then((res) => {
                    let data = res.data;
                    this.modalVisible = true;
                    if (data.code == 1) {
                        this.dataCollection({ eventId: 302, remark: '修改逻辑，提交报价', caseNo: this.baseParameter.caseNo });
                        this.dataCollection({ eventId: 303, remark: '车损保存工单', caseNo: this.baseParameter.caseNo });
                        this.submitType = 'success';
                        this.submitDisabled = true;
                        this.linkNewPage(data.data);
                    } else if (data.code == 0) {
                        this.dataCollection({ eventId: 302, remark: '修改逻辑，提交报价', caseNo: this.baseParameter.caseNo });
                        this.submitType = 'wraning';
                        this.submitDisabled = false;
                        // ElMessage.error(data.message)
                        if (!data.data) {
                            this.$message.error(data.message);
                        }
                    } else if (data.code == 2) {
                        this.dataCollection({ eventId: 302, remark: '修改逻辑，提交报价', caseNo: this.baseParameter.caseNo });
                        this.$message.error(data.message);
                    }

                    if (data.data.damageWorkOrderList.length > 0) {
                        let num = 0;
                        data.data.damageWorkOrderList.forEach((val, key) => {
                            if (val.tips.trim()) {
                                num++;
                            }
                            this.partsTypeChange(val);
                        });
                        this.baseParameter.tipsNum = num;
                    }
                    this.myPartsData = data.data.damageWorkOrderList;
                });
            } else {
                this.$message.error('抱歉，请先添加配件！');
            }
        },
        //报异常后点击仍要提交
        addWorkOrders() {
            this.instance({
                method: 'post',
                url: '/easyepc/addWorkOrders',
                data: this.myPartsData,
                headers: {
                    // 'Content-Type':"application/x-www-form-urlencoded",
                }
            }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.dataCollection({ eventId: 303, remark: '车损保存工单', caseNo: this.baseParameter.caseNo });
                    this.submitDisabled = true;
                    // modalVisible.value=false;
                    this.myPartsData = data.data.damageWorkOrderList;
                    this.linkNewPage(data.data);
                    this.submitType = 'success';
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        //提交报价后的跳转
        linkNewPage(data) {
            // setTimeout(()=>{
            // 	this.$router.push({path:'/calculatorTool/carDamage',query:{vid:data.caseNo}})
            // },2000)
        },
        //点击留言板的确定按钮
        submitMessage(data) {
            this.instance({
                method: 'post',
                url: '/message/addTMessageBoard',
                data: {
                    messageBoard: this.baseParameter.textarea,
                    sysUserId: this.baseParameter.userid
                },
                headers: {
                    // 'Content-Type':"application/x-www-form-urlencoded",
                }
            }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.baseParameter.visible = false;
                    this.baseParameter.textarea = '';
                }
                this.$message(data.message);
            });
        }
    },
    mounted() {
        //
        this.defaultProvince = this.provinceData[0];
        let userInfo = localStorage.getItem('userInfo');
        if (userInfo) {
            userInfo = JSON.parse(userInfo);
            this.baseParameter.jobNo = userInfo.jobNo;
        }
        let vehicleDetail = localStorage.getItem('vehicleDetail');
        vehicleDetail && (this.vehicleDetail = JSON.parse(vehicleDetail) || {});
        this.baseParameter.amVehicleId = this.$route.query.vid;
        this.baseParameter.caseNo = this.$route.query.caseNo;
        this.baseParameter.channelType = this.$route.query.type;
        //获取车辆结构
        this.getVehicleStructure();
    }
};
</script>
<style scoped>
.part_img {
    width: 0.36rem;
    cursor: pointer;
    position: relative;
    left: 44%;
}
.parts_bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.parts_bottom .el-button {
    font-size: 13px;
}
/* .parts_bottom /deep/.el-icon {
    font-size: 0.2rem;
} */
.parts_middle {
    margin: 0.2rem 0;
}
.parts_middle /deep/.el-table .cell {
    line-height: 1.3;
    text-align: center;
}
/* .parts_middle .el-select /deep/.el-input__inner {
    font-size: 0.12rem;
    padding: 0 0.12rem;
} */
.parts_middle /deep/.el-input__suffix {
    right: 0.06rem;
}
.delete_icon {
    width: 15px;
    margin: 0 auto;
    cursor: pointer;
}
.parts_box {
    width: 100%;
    margin: 0.3rem auto 0;
}
.parts_top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.7rem 0 0.2rem;
}
.parts_top p {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
}
.parts_totle {
    font-size: 13px;
    display: flex;
    align-items: center;
    color: #666;
}
.download_btn {
    font-size: 13px;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}
.vehicle_table.el-table--small {
    font-size: 13px;
}
.plus_cell {
    display: flex;
    align-items: center;
}
.plus_cell div {
    width: 0.14rem;
    min-width: 12px;
    cursor: pointer;
    margin-right: 0.1rem;
}
.vehicle_menu .el-menu-item,
.vehicle_menu /deep/.el-submenu__title {
    padding: 0 0.1rem !important;
    font-size: 13px;
    height: 0.6rem;
    line-height: 0.6rem;
    min-width: auto;
}
.vehicle_menu /deep/.el-menu-item-group__title {
    padding: 0;
}
.vehicle_menu {
    height: 4.6rem;
    overflow-y: auto;
}
.vehicle_main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 0.16rem;
}
.vehicle_menu {
    width: 2.3rem;
    min-width: 160px;
    margin-right: 0.15rem;
}
.vehicle_table {
    width: 14.8rem;
}
/deep/.el-table--small .el-table__cell {
    padding: 0.1rem 0;
}
/deep/.el-table th.el-table__cell {
    background-color: #f6f7f7;
}
.el-dropdown-menu--large /deep/.el-dropdown-menu__item {
    font-size: 13px;
    line-height: 0.22rem;
}
.brand_box {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #fff;
    position: absolute;
    top: 5%;
}
.brand_box p {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    margin-bottom: 0.1rem;
}
.auto_logos {
    width: 1.2rem;
    margin-right: 0.2rem;
    border-radius: 50%;
    overflow: hidden;
}
.small_title {
    font-size: 0.24rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 0.64rem;
    padding-left: 0.2rem;
    margin-bottom: 0.04rem;
}
.search_input .el-input {
    width: 4.5rem;
    margin-right: 0.2rem;
    line-height: 0.48rem;
}
.search_input .el-input--large /deep/.el-input__inner {
    height: 0.48rem;
    line-height: 0.48rem;
    font-size: 13px;
}
.search_input .el-button {
    min-width: 1rem;
    min-height: 0.48rem;
    padding: 0.13rem 0.18rem;
    font-size: 13px;
    --el-button-size: auto;
}
.search_input {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1.3rem;
}
.search_input2 {
    margin-bottom: 0;
    justify-content: space-between;
}
.city_dropdown .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
    display: flex;
    align-items: center;
    font-size: 13px;
}
.city_box {
    display: flex;
    align-items: center;
}
.location_icon {
    width: 0.15rem;
    height: 0.2rem;
    background: url(../../../public/images/location_icon.png) no-repeat;
    background-size: 100% 100%;
}
.city_hint {
    font-size: 13px;
    margin-left: 0.1rem;
}
.home {
    width: 100%;
    background-color: #f2f8fc;
    padding-bottom: 0.5rem;
}
.home img {
    user-select: none;
}
.main {
    width: 100%;
    position: relative;
}
.search_box {
    width: 100%;
    height: 135px;
    background: url(../../../public/images/main_bg.png) no-repeat;
    background-size: 100% 100%;
    padding: 0.7rem 1.8rem 0 0.9rem;
}
.result_box {
    width: 100%;
    height: 5.6rem;
    background: #ffffff;
    margin: -0.52rem auto 0;
    padding: 0.24rem 0.3rem 0.26rem;
    position: relative;
}
/deep/.el-sub-menu .el-sub-menu__icon-arrow {
    right: 0.2rem;
}
/* 设置滚动条的样式 */
::-webkit-scrollbar {
    width: 5px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
    border-radius: 5px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
    /* background:rgba(255,0,0,0.4); */
}
</style>
