<template>
    <div class="wrap">
        <div class="page_title"><b>案件风险统计</b></div>
        <div class="search_box public_box">
            <!--检索部分-->
            <el-form :inline="true" :model="navForm" class="demo-form-inline">
                <el-form-item label="报案时间：" class="dateBox">
                    <el-row style="width: 350px">
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" clearable v-model="navForm.reportStartTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                        <el-col class="line" style="text-align: center" :span="2">至</el-col>
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" clearable v-model="navForm.reportEndTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="结案时间：" class="dateBox">
                    <el-row style="width: 350px">
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" clearable v-model="navForm.startTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                        <el-col class="line" style="text-align: center" :span="2">至</el-col>
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" clearable v-model="navForm.endTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="事故省：">
                    <el-select v-model="navForm.accidentProvince" clearable class="width_150" placeholder="请选择">
                        <el-option v-for="item in provinceOptions" :key="item" :label="item" :value="item"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="分包商(被保险人)：">
                    <el-input class="width_150" v-model="navForm.nameOfInsured" placeholder="请填写分包商" clearable></el-input>
                </el-form-item>
                <el-form-item label="公估公司：">
                    <el-input class="width_150" v-model="navForm.lossAdjusterCompany" placeholder="请填写公估公司" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="searchTable" style="margin-left: 0.2rem">查询</el-button>
                    <el-button type="success" @click="handleDownload" style="margin-left: 0.2rem">下载风险报告</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main">
            <ul class="case_statistics">
                <li class="public_box">
                    <div class="detail_title">伤残案件信息</div>
                    <div class="case_middle">
                        <div class="workorder_icon">
                            <img src="../../../public/images/case_icon1.png" />
                        </div>
                        <ul>
                            <li>
                                <span>总赔款金额：</span>
                                <b>{{ caseTypeData.disabilityData.totalPayAmount || 0 }}元</b>
                            </li>
                            <li>
                                <span>案件数量：</span>
                                <b>{{ caseTypeData.disabilityData.casesCount || 0 }}件</b>
                            </li>
                            <li>
                                <span>案均赔付金额：</span>
                                <b>{{ caseTypeData.disabilityData.avgPayAmount || 0 }}元</b>
                            </li>
                        </ul>
                    </div>
                    <ul class="case_bottom">
                        <li>
                            <span>伤残赔款金额占比：</span>
                            <b>{{ caseTypeData.disabilityData.suchCaseAmountRatio || 0 }}%</b>
                        </li>
                        <li>
                            <span>伤残案件数量占比：</span>
                            <b>{{ caseTypeData.disabilityData.suchCaseCountRatio || 0 }}%</b>
                        </li>
                        <li>
                            <span>伤残案件案均风险点个数：</span>
                            <b>{{ caseTypeData.disabilityData.avgRiskNo || 0 }}个</b>
                        </li>
                    </ul>
                </li>
                <li class="public_box">
                    <div class="detail_title">门诊案件信息</div>
                    <div class="case_middle">
                        <div class="workorder_icon">
                            <img src="../../../public/images/case_icon2.png" />
                        </div>
                        <ul>
                            <li>
                                <span>总赔款金额：</span>
                                <b>{{ caseTypeData.outPatientData.totalPayAmount || 0 }}元</b>
                            </li>
                            <li>
                                <span>案件数量：</span>
                                <b>{{ caseTypeData.outPatientData.casesCount || 0 }}件</b>
                            </li>
                            <li>
                                <span>案均赔付金额：</span>
                                <b>{{ caseTypeData.outPatientData.avgPayAmount || 0 }}元</b>
                            </li>
                        </ul>
                    </div>
                    <ul class="case_bottom">
                        <li>
                            <span>门诊赔款金额占比：</span>
                            <b>{{ caseTypeData.outPatientData.suchCaseAmountRatio || 0 }}%</b>
                        </li>
                        <li>
                            <span>门诊案件数量占比：</span>
                            <b>{{ caseTypeData.outPatientData.suchCaseCountRatio || 0 }}%</b>
                        </li>
                        <li>
                            <span>门诊案件案均风险点个数：</span>
                            <b>{{ caseTypeData.outPatientData.avgRiskNo || 0 }}个</b>
                        </li>
                    </ul>
                </li>
                <li class="public_box">
                    <div class="detail_title">住院案件信息</div>
                    <div class="case_middle">
                        <div class="workorder_icon">
                            <img src="../../../public/images/case_icon3.png" />
                        </div>
                        <ul>
                            <li>
                                <span>总赔款金额：</span>
                                <b>{{ caseTypeData.hospitalizationData.totalPayAmount || 0 }}元</b>
                            </li>
                            <li>
                                <span>案件数量：</span>
                                <b>{{ caseTypeData.hospitalizationData.casesCount || 0 }}件</b>
                            </li>
                            <li>
                                <span>案均赔付金额：</span>
                                <b>{{ caseTypeData.hospitalizationData.avgPayAmount || 0 }}元</b>
                            </li>
                        </ul>
                    </div>
                    <ul class="case_bottom">
                        <li>
                            <span>住院赔款金额占比：</span>
                            <b>{{ caseTypeData.hospitalizationData.suchCaseAmountRatio || 0 }}%</b>
                        </li>
                        <li>
                            <span>住院案件数量占比：</span>
                            <b>{{ caseTypeData.hospitalizationData.suchCaseCountRatio || 0 }}%</b>
                        </li>
                        <li>
                            <span>住院案件案均风险点个数：</span>
                            <b>{{ caseTypeData.hospitalizationData.avgRiskNo || 0 }}个</b>
                        </li>
                    </ul>
                </li>
                <li class="public_box">
                    <div class="detail_title">车物损案件信息</div>
                    <div class="case_middle">
                        <div class="workorder_icon">
                            <img src="../../../public/images/case_icon4.png" />
                        </div>
                        <ul>
                            <li>
                                <span>总赔款金额：</span>
                                <b>{{ caseTypeData.damageData.totalPayAmount || 0 }}元</b>
                            </li>
                            <li>
                                <span>案件数量：</span>
                                <b>{{ caseTypeData.damageData.casesCount || 0 }}件</b>
                            </li>
                            <li>
                                <span>案均赔付金额：</span>
                                <b>{{ caseTypeData.damageData.avgPayAmount || 0 }}元</b>
                            </li>
                        </ul>
                    </div>
                    <ul class="case_bottom">
                        <li>
                            <span>车物损赔款金额占比：</span>
                            <b>{{ caseTypeData.damageData.suchCaseAmountRatio || 0 }}%</b>
                        </li>
                        <li>
                            <span>车物损案件数量占比：</span>
                            <b>{{ caseTypeData.damageData.suchCaseCountRatio || 0 }}%</b>
                        </li>
                        <li>
                            <span>车物损案件案均风险点个数：</span>
                            <b>{{ caseTypeData.damageData.avgRiskNo || 0 }}个</b>
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="table_box public_box">
                <el-tabs v-model="tabsActiveName" type="card" @tab-click="handleClickTabs">
                    <el-tab-pane label="分包商案件排名" name="first"></el-tab-pane>
                    <el-tab-pane label="公估人案件排名" name="second"></el-tab-pane>
                </el-tabs>
                <!--table表格部分-->
                <el-table ref="multipleTable" :data="tabsActiveName == 'first' ? tableData1 : tableData2" v-loading="loading" style="width: 100%" @sort-change="sortChange">
                    <!-- @selection-change="handleSelectionChange"  -->
                    <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                    <el-table-column v-if="tabsActiveName == 'first'" prop="nameOfInsured" label="分包商"></el-table-column>
                    <el-table-column v-else prop="assessor" label="公估人ID"></el-table-column>
                    <el-table-column v-if="tabsActiveName == 'second'" label="公估作业评分">
                        <template slot-scope="scope">
                            <span class="a_color" @click="lookScoringDetail(scope.row)">{{ scope.row.scoring }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="casesCount" label="案件数量(件)" sortable="custom"></el-table-column>
                    <template v-if="tabsActiveName == 'second'">
                        <el-table-column prop="handledRiskNum" label="已处理的风险点(个)" sortable="custom"></el-table-column>
                        <el-table-column prop="handledRiskRatio" label="已处理的风险点个数占比" sortable="custom">
                            <template slot-scope="scope">
                                <span>{{ scope.row.handledRiskRatio }}%</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="impairmentAmount" label="减损金额" sortable="custom"></el-table-column>
                        <el-table-column prop="handledStrongRiskOrNot" label="强风险是否处理"></el-table-column>
                    </template>
                    <el-table-column prop="avgDisabilityAmount" label="伤残案均金额" sortable="custom"></el-table-column>
                    <el-table-column prop="avgOutPatientAmount" label="门诊案均金额" sortable="custom"></el-table-column>
                    <el-table-column prop="avgHospitalizationAmount" label="住院案均金额" sortable="custom"></el-table-column>
                    <el-table-column prop="avgDamageAmount" label="车物损案均金额" sortable="custom"></el-table-column>
                    <el-table-column prop="avgRiskNo" label="案均风险点个数" sortable="custom"></el-table-column>
                    <template v-if="tabsActiveName == 'first'">
                        <el-table-column prop="reportRate24Hour" label="24小时报案率" sortable="custom">
                            <template slot-scope="scope">
                                <span>{{ scope.row.reportRate24Hour }}%</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="highRiskRate" label="高风险案件占比" sortable="custom">
                            <template slot-scope="scope">
                                <span>{{ scope.row.highRiskRate }}%</span>
                            </template>
                        </el-table-column>
                    </template>
                    <!-- <el-table-column prop="totalPayAmount" label="风险等级" sortable='custom'></el-table-column> -->
                </el-table>
                <!--分页部分-->
                <el-pagination
                    style="margin-top: 0.2rem"
                    @current-change="handleCurrentChange"
                    @size-change="handleSizeChange"
                    :current-page="tabsActiveName == 'first' ? currentPage1 : currentPage2"
                    layout="total, sizes, prev, pager, next, jumper"
                    :page-sizes="[10, 20]"
                    :page-size="10"
                    :total="tabsActiveName == 'first' ? totalPage1 : totalPage2"
                >
                </el-pagination>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false" width="60%">
            <div class="dialog_box">
                <div class="dialog_main">
                    <div id="radar_chart"></div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src
// import MyHeader from '@/components/MyHeader.vue'
import * as echarts from 'echarts';
export default {
    name: 'caseStatistics',
    components: {},
    data() {
        return {
            tabsActiveName: 'first',
            myChart: '',
            dialogVisible: false,
            caseTypeData: {
                disabilityData: {},
                outPatientData: {},
                hospitalizationData: {},
                damageData: {}
            }, //案件风险统计
            navForm: {
                //储存搜索框的检索条件信息
                startTime: '',
                endTime: '',
                reportStartTime: '',
                reportEndTime: '',
                value: '',
                nameOfInsured: '',
                lossAdjusterCompany: '',
                accidentProvince: ''
            },
            loading: false,
            provinceOptions: [
                '辽宁省',
                '江苏省',
                '广西壮族自治区',
                '福建省',
                '四川省',
                '黑龙江省',
                '山东省',
                '江西省',
                '陕西省',
                '吉林省',
                '北京市',
                '安徽省',
                '河北省',
                '河南省',
                '重庆市',
                '天津市',
                '湖北省',
                '甘肃省',
                '上海市',
                '云南省',
                '贵州省',
                '内蒙古自治区',
                '山西省',
                '海南省',
                '浙江省',
                '宁夏回族自治区',
                '青海省',
                '广东省',
                '新疆维吾尔自治区',
                '湖南省',
                '西藏自治区',
                '香港特别行政区',
                '澳门特别行政区',
                '台湾省'
            ],
            currentPage1: 1, //当前页
            pageSize1: 10, //一页多少条数据
            totalPage1: 0, //总页数tableData:[],
            tableData1: [],
            currentPage2: 1, //当前页
            pageSize2: 10, //一页多少条数据
            totalPage2: 0, //总页数tableData:[],
            tableData2: [],
            tableDataName: 'tableData1',
            currentPageName: 'currentPage1',
            pageSizeName: 'pageSize1',
            totalPageName: 'totalPage1',
            multipleSelection: []
        };
    },
    methods: {
        lookScoringDetail(row) {
            this.dialogVisible = true;
            this.checkedRow = row;
            setTimeout(() => {
                this.loadCharts();
            }, 500);
        },
        //加载评分雷达图
        loadCharts() {
            var chartDom = document.getElementById('radar_chart');
            var row = this.checkedRow;
            var seriesData = [row.dayEndCaseNumScoring, row.medicalEndCaseTimeScoring, row.medicalEndCaseAmountScoring, row.vehicleItemEndCaseTimeScoring, row.vehicleItemEndCaseAmountScoring, row.handledRiskScoring];
            if (!chartDom) {
                return;
            }
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' });
            var option = {
                legend: {
                    show: false
                },
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        return (
                            `日均结案量：` +
                            row.dayEndCaseNum +
                            `件 (` +
                            row.dayEndCaseNumScoring +
                            `分)<br>人伤结案时长：` +
                            row.medicalEndCaseTime +
                            `天 (` +
                            row.medicalEndCaseTimeScoring +
                            `分)<br>人伤案均赔款：` +
                            row.medicalEndCaseAmount +
                            `元 (` +
                            row.medicalEndCaseAmountScoring +
                            `分)<br>车物损结案时长：` +
                            row.vehicleItemEndCaseTime +
                            `天 (` +
                            row.vehicleItemEndCaseTimeScoring +
                            `分)<br>车物损案均赔款：` +
                            row.vehicleItemEndCaseAmount +
                            `元 (` +
                            row.vehicleItemEndCaseAmountScoring +
                            `分)<br>处理风险评分：` +
                            row.handledRiskRatio +
                            `% (` +
                            row.handledRiskScoring +
                            `分)`
                        );
                    }
                },
                radar: {
                    // shape: 'circle',
                    indicator: [{ name: '日均结案量' }, { name: '人伤结案时长' }, { name: '人伤案均赔款' }, { name: '车物损结案时长' }, { name: '车物损案均赔款' }, { name: '处理风险评分' }],
                    name: {
                        textStyle: {
                            color: '#333'
                        }
                    }
                },
                series: [
                    {
                        type: 'radar',
                        data: [
                            {
                                value: seriesData,
                                name: '公估作业评分'
                            }
                        ]
                    }
                ]
            };
            this.myChart.setOption(option);
        },
        handleClickTabs() {
            if (this.tabsActiveName == 'first') {
                this.currentPageName = 'currentPage1';
                this.pageSizeName = 'pageSize1';
                this.tableDataName = 'tableData1';
                this.totalPageName = 'totalPage1';
            } else {
                this.currentPageName = 'currentPage2';
                this.pageSizeName = 'pageSize2';
                this.tableDataName = 'tableData2';
                this.totalPageName = 'totalPage2';
            }
            this[this.currentPageName] = 1;
            this.getTbodyData();
        },
        //排序
        sortChange(column) {
            // column.ascending==上升  column.descending==下降  column.prop
            this.navForm.sortField = column.prop;
            this.navForm.sortOrder = column.order == 'ascending' ? 'asc' : 'desc';
            this.getTbodyData();
        },
        //点击查询
        searchTable() {
            this[this.currentPageName] = 1;
            this.getCaseTypeData();
            this.getTbodyData();
        },
        //案件风险统计看板
        getCaseTypeData: function () {
            this.instance({
                method: 'post',
                url: '/case/getCaseTypeData',
                data: this.navForm
                //  headers:{
                // 'Content-Type':"application/x-www-form-urlencoded",
                //  },
            }).then((res) => {
                // this.loading=false;
                if (res.data.code == 1) {
                    if (res.data.data) {
                        this.caseTypeData = res.data.data;
                    } else {
                        this.caseTypeData = {
                            disabilityData: {},
                            outPatientData: {},
                            hospitalizationData: {},
                            damageData: {}
                        };
                    }
                } else {
                    this.caseTypeData = {
                        disabilityData: {},
                        outPatientData: {},
                        hospitalizationData: {},
                        damageData: {}
                    };
                }
            });
        },
        //获取tbody里的数据
        getTbodyData: function () {
            this.loading = true;
            this[this.tableDataName] = []; //  清空table的值
            var json = JSON.parse(JSON.stringify(this.navForm));
            //储存当前的检索条件，下载用
            this.nowFilter = JSON.parse(JSON.stringify(json));
            json.pageNum = this[this.currentPageName];
            json.pageSize = this[this.pageSizeName];
            let path = this.tabsActiveName == 'first' ? '/case/getNameOfInsuredCaseData' : '/case/getAssessorCaseData';
            this.instance.post(path, json).then((res) => {
                this.loading = false;
                if (res.data.code == 1) {
                    this[this.tableDataName] = res.data.data.list;
                    this[this.totalPageName] = parseInt(res.data.data.total);
                } else {
                    this.totalPage = 0;
                }
            });
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this[this.pageSizeName] = val;
            this.getTbodyData();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange: function (val) {
            this[this.currentPageName] = Number(val);
            this.getTbodyData();
        },
        //下载工单
        handleDownload() {
            var json = JSON.parse(JSON.stringify(this.nowFilter));
            json.tabNum = this.tabsActiveName == 'first' ? 3 : 5;
            this.fullscreenLoading = this.$loading({
                lock: true,
                text: '下载中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.instance({
                method: 'post',
                url: '/case/exportCaseRiskReport',
                data: json,
                responseType: 'blob',
                headers: {
                    // 'Content-Type':"application/x-www-form-urlencoded",
                }
            })
                .then((res) => {
                    // this.loading=false;
                    this.fullscreenLoading.close();
                    if (res.data.size < 200) {
                        this.$message.error('单次下载案件不能超过10000条,请分段下载!');
                        return false;
                    }
                    let url = window.URL.createObjectURL(new Blob([res.data]));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', '风险报告.xls'); //指定下载后的文件名，防跳转
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    mounted() {
        //
        const date = new Date();
        let startDate = new Date(date.getTime() - 3600 * 1000 * 24 * 7);
        this.navForm.reportStartTime = this.dateFormatting(startDate, 'yyyy-MM-dd');
        this.navForm.reportEndTime = this.dateFormatting(date, 'yyyy-MM-dd');
        this.getCaseTypeData();
        this.getTbodyData();
        window.addEventListener('resize', () => {
            if (this.myChart) {
                setTimeout(() => {
                    this.myChart.dispose();
                    this.loadCharts();
                }, 500);
            }
        });
    }
};
</script>
<style scoped>
@import '../../assets/css/table_public.css';
#radar_chart {
    width: 7rem;
    height: 5rem;
    margin: 0 auto;
}
.table_box {
    margin-top: 0;
}
.case_middle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 0.18rem;
    margin: 0.2rem 0;
}
.case_middle li {
    line-height: 2;
}
.case_middle li b {
    color: #e4393c;
}
.case_bottom {
    font-size: 0.16rem;
    display: flex;
    justify-content: space-between;
}
.case_bottom b {
    color: #409eff;
}
.workorder_icon {
    width: 1.3rem;
}
.wrap {
    width: 100%;
    position: relative;
}
.case_statistics {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0.3rem 0 0;
    flex-wrap: wrap;
}
.case_statistics .public_box {
    width: 49%;
    margin-bottom: 2%;
}
.case_statistics .public_box:nth-child(1),
.case_statistics .public_box:nth-child(3) {
    margin-right: 2%;
}
</style>
