<template>
    <div class="wrap">
        <div class="page_title"><b>人伤计算列表</b></div>
        <!-- <div class="search_box public_box">
			<el-form :inline="true"  :model="navForm" class="demo-form-inline">
				<el-form-item label="案件号：">
					<el-input class="width_150" v-model="navForm.phoneNum" placeholder="请输入案件号" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary"  @click="searchTable" style="margin-left: 0.2rem;">查询</el-button>
				</el-form-item>
			</el-form>
		</div> -->
        <div class="main">
            <div class="table_box public_box">
                <div class="download_box">
                    <div class="warning_icon">
                        <img src="../../../public/images/warning_icon.png" />
                    </div>
                    <div>
                        <span>累计报价</span>
                        <b class="a_color">{{ multipleSelection.length }}</b>
                        <span>次</span>
                    </div>
                    <div style="margin-left: 0.5rem">
                        <span>总报价</span>
                        <b class="a_color">{{ totalAmount }}</b>
                        <span>万</span>
                    </div>
                </div>
                <!--table表格部分-->
                <el-table ref="multipleTable" :data="tableData" v-loading="loading" style="width: 100%" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column prop="caseNo" label="案件号"></el-table-column>
                    <el-table-column prop="policyNo" label="保单号"></el-table-column>
                    <el-table-column prop="insuranceVersion" label="险种名称"></el-table-column>
                    <el-table-column prop="caseType" label="案件类型">
                        <template slot-scope="scope">
                            <span>{{ scope.row.caseType == 1 ? '骑手人伤' : scope.row.caseType == 2 ? '三者人伤' : '骑手&三者人伤' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="骑手人伤总赔付金额(元)">
                        <template slot-scope="scope">
                            <span>{{ scope.row.personType == 1 ? scope.row.totalAmount : '--' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="三者人伤总赔付金额(元)">
                        <template slot-scope="scope">
                            <span>{{ scope.row.personType == 2 ? scope.row.totalAmount : '--' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="exceptionTipNumber" label="报价异常项提示次数"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <span class="a_color" @click="lookDetails(scope.row)">查看详情</span>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="10" :total="totalPage"> </el-pagination>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false" width="65%">
            <div class="dialog_box">
                <div class="dialog_header">
                    <ul class="form_ul">
                        <li>
                            <b>案件号：</b>
                            <span>{{ checkedRowDetail.caseNo }}</span>
                        </li>
                        <li>
                            <b>保单号：</b>
                            <span>{{ checkedRowDetail.policyNo }}</span>
                        </li>
                        <li>
                            <b>险种名称：</b>
                            <span>{{ checkedRowDetail.insuranceVersion }}</span>
                        </li>
                        <li>
                            <b>案件类型：</b>
                            <span>{{ checkedRowDetail.caseType == 1 ? '骑手人伤' : checkedRowDetail.caseType == 2 ? '三者人伤' : '骑手&三者人伤' }}</span>
                        </li>
                        <li>
                            <b>出险日期：</b>
                            <span>{{ checkedRowDetail.accidentTime }}</span>
                        </li>
                    </ul>
                </div>
                <div class="dialog_main">
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="案件报价信息" name="first">
                            <div class="pane_box" v-for="(items, indexs) in checkHumanInjuryResponse.humanInjuryWorkOrderTipsVos" :key="indexs">
                                <div class="public_box">
                                    <div class="dialog_title">
                                        <div>{{ items.personType == 1 ? '骑手' : '三者' }}人伤赔偿明细</div>
                                        <div>异常项提示</div>
                                    </div>
                                    <ul class="dialog_ul">
                                        <li v-for="(item, index) in items.humanInjuryWorkOrderTipsList" :key="index">
                                            <div>{{ index + 1 + '. ' + item.termName + ': ' + item.termAmount + ' 元' }}</div>
                                            <b>{{ item.termExceptionTip }}</b>
                                        </li>
                                    </ul>
                                    <el-divider></el-divider>
                                    <ul class="dialog_ul">
                                        <li>
                                            <div>{{ items.lastTotalName + ': ' + items.totalAmount + ' 元' }}</div>
                                            <b v-if="items.exceptionTipNumber > 0">{{ '共' + items.exceptionTipNumber + '个异常项' }}</b>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <el-empty v-if="checkHumanInjuryResponse.humanInjuryWorkOrderTipsVos.length == 0" description="暂无数据"></el-empty>
                        </el-tab-pane>
                        <el-tab-pane label="骑手人伤详情" name="second">
                            <div class="pane_box" v-for="(item, index) in checkHumanInjuryResponse.riderWorkOrderList" :key="index">
                                <div class="detail_title">骑手信息</div>
                                <ul class="form_ul">
                                    <li>
                                        <b>姓名：</b><span>{{ item.nameOfPerson }}</span>
                                    </li>
                                    <li>
                                        <b>身份证号：</b><span>{{ item.personID }}</span>
                                    </li>
                                    <li>
                                        <b>年龄：</b><span>{{ item.personAge }}</span>
                                    </li>
                                    <li>
                                        <b>性别：</b><span>{{ item.personSex }}</span>
                                    </li>
                                    <li>
                                        <b>责任比例：</b><span>{{ item.dutyRatio }}%</span>
                                    </li>
                                </ul>
                                <div class="detail_title">骑手人伤</div>
                                <ul class="form_ul">
                                    <li>
                                        <b>就诊医院：</b><span>{{ item.hospital }}</span>
                                    </li>
                                    <li>
                                        <b>就诊疾病：</b><span>{{ item.disease }}</span>
                                    </li>
                                    <li>
                                        <b>首次就诊日期：</b><span>{{ item.firstVisitTime }}</span>
                                    </li>
                                    <li>
                                        <b>门诊实际花费：</b><span>{{ item.outPatientExpenses }}元</span>
                                    </li>
                                    <li>
                                        <b>门诊保障外花费：</b><span>{{ item.outPatientExpensesExtra }}元</span>
                                    </li>
                                    <li>
                                        <b>免赔额：</b><span>{{ item.outPatientDeductible }}元</span>
                                    </li>
                                    <li>
                                        <b>门诊总赔付额：</b><span>{{ item.totalOutPatientCompensation }}元</span>
                                    </li>
                                </ul>
                                <div class="detail_title">骑手住院信息</div>
                                <ul class="form_ul">
                                    <li>
                                        <b>住院日期：</b><span>{{ item.hospitalizationStartTime + '至' + item.hospitalizationEndTime }}</span>
                                    </li>
                                    <li>
                                        <b>住院天数（天）：</b><span>{{ item.hospitalizationDays }}天</span>
                                    </li>
                                    <li>
                                        <b>住院实际花费：</b><span>{{ item.hospitalizationExpenses }}元</span>
                                    </li>
                                    <li>
                                        <b>住院保障外花费：</b><span>{{ item.hospitalizationExpensesExtra }}元</span>
                                    </li>
                                    <li>
                                        <b>免赔额：</b><span>{{ item.hospitalizationDeductible }}元</span>
                                    </li>
                                    <li>
                                        <b>住院总赔付额：</b><span>{{ item.totalHospitalizationCompensation }}元</span>
                                    </li>
                                </ul>
                                <div class="detail_title">骑手误工费信息</div>
                                <ul class="form_ul">
                                    <li>
                                        <b>骑手月均工资：</b><span>{{ item.avgMonthlySalary }}元</span>
                                    </li>
                                    <li>
                                        <b>误工天数：</b><span>{{ item.delayWorkDays }}天</span>
                                    </li>
                                    <li>
                                        <b>免赔额：</b><span>{{ item.delayWorkDeductible }}元</span>
                                    </li>
                                    <li>
                                        <b>骑手误工费总赔付金额：</b><span>{{ item.totalDelayWorkCompensation }}元</span>
                                    </li>
                                </ul>
                                <div class="detail_title">骑手营养费信息</div>
                                <ul class="form_ul">
                                    <li>
                                        <b>营养费基数：</b><span>{{ item.nutritionFeeBase }}元</span>
                                    </li>
                                    <li>
                                        <b>营养天数：</b><span>{{ item.nutritionDays }}天</span>
                                    </li>
                                    <li>
                                        <b>免赔额：</b><span>{{ item.nutritionDeductible }}元</span>
                                    </li>
                                    <li>
                                        <b>骑手营养费总赔付金额：</b><span>{{ item.totalNutritionCompensation }}元</span>
                                    </li>
                                </ul>
                                <div class="detail_title">骑手伤残</div>
                                <ul class="form_ul">
                                    <li>
                                        <b>评残项目：</b><span>{{ item.disabilityAssessmentItems || '--' }}</span>
                                    </li>
                                    <li>
                                        <b>伤残鉴定日期：</b><span>{{ item.disabilityAppraisalTime || '--' }}</span>
                                    </li>
                                    <li>
                                        <b>伤残鉴定机构：</b><span>{{ item.disabilityAppraisalInstitution || '--' }}</span>
                                    </li>
                                    <li>
                                        <b>伤残等级：</b><span>{{ item.disabilityLevel }}</span>
                                    </li>
                                    <li>
                                        <b>免赔额：</b><span>{{ item.disabilityDeductible }}元</span>
                                    </li>
                                    <li>
                                        <b>伤残赔付总额：</b><span>{{ item.totalDisabilityCompensation }}元</span>
                                    </li>
                                </ul>
                                <div class="detail_title">骑手其他补助</div>
                                <ul class="form_ul">
                                    <li>
                                        <b>餐损赔偿：</b><span>{{ item.mealAllowance }}元</span>
                                    </li>
                                    <li>
                                        <b>诉讼费：</b><span>{{ item.legalCosts }}元</span>
                                    </li>
                                    <li>
                                        <b>狂犬疫苗医疗保险金：</b><span>{{ item.rabiesVaccineInsurance }}元</span>
                                    </li>
                                </ul>
                                <div class="detail_title">骑手死亡信息</div>
                                <ul class="form_ul">
                                    <li>
                                        <b>死亡原因：</b><span>{{ item.deathReason || '--' }}</span>
                                    </li>
                                    <li>
                                        <b>死亡日期：</b><span>{{ item.deathTime || '--' }}</span>
                                    </li>
                                    <li>
                                        <b>死亡总赔付额：</b><span>{{ item.totalDeathCompensation }}万元</span>
                                    </li>
                                </ul>
                            </div>
                            <el-empty v-if="checkHumanInjuryResponse.riderWorkOrderList.length == 0" description="暂无数据"></el-empty>
                        </el-tab-pane>
                        <el-tab-pane label="三者人伤详情" name="third">
                            <div class="pane_box" v-for="(item, index) in checkHumanInjuryResponse.thirdWorkOrderList" :key="index">
                                <div class="detail_title">三者信息</div>
                                <ul class="form_ul">
                                    <li>
                                        <b>姓名：</b><span>{{ item.nameOfPerson }}</span>
                                    </li>
                                    <li>
                                        <b>身份证号：</b><span>{{ item.personID }}</span>
                                    </li>
                                    <li>
                                        <b>年龄：</b><span>{{ item.personAge }}</span>
                                    </li>
                                    <li>
                                        <b>性别：</b><span>{{ item.personSex }}</span>
                                    </li>
                                    <li>
                                        <b>责任比例：</b><span>{{ item.dutyRatio }}%</span>
                                    </li>
                                </ul>
                                <div class="detail_title">三者人伤</div>
                                <ul class="form_ul">
                                    <li>
                                        <b>就诊医院：</b><span>{{ item.hospital }}</span>
                                    </li>
                                    <li>
                                        <b>就诊疾病：</b><span>{{ item.disease }}</span>
                                    </li>
                                    <li>
                                        <b>首次就诊日期：</b><span>{{ item.firstVisitTime }}</span>
                                    </li>
                                    <li>
                                        <b>门诊实际花费：</b><span>{{ item.outPatientExpenses }}元</span>
                                    </li>
                                    <li>
                                        <b>门诊保障外花费：</b><span>{{ item.outPatientExpensesExtra }}元</span>
                                    </li>
                                    <li>
                                        <b>免赔额：</b><span>{{ item.outPatientDeductible }}元</span>
                                    </li>
                                    <li>
                                        <b>门诊总赔付额：</b><span>{{ item.totalOutPatientCompensation }}元</span>
                                    </li>
                                </ul>
                                <div class="detail_title">三者住院信息</div>
                                <ul class="form_ul">
                                    <li>
                                        <b>住院日期：</b><span>{{ item.hospitalizationStartTime + '至' + item.hospitalizationEndTime }}</span>
                                    </li>
                                    <li>
                                        <b>住院天数（天）：</b><span>{{ item.hospitalizationDays }}天</span>
                                    </li>
                                    <li>
                                        <b>住院实际花费：</b><span>{{ item.hospitalizationExpenses }}元</span>
                                    </li>
                                    <li>
                                        <b>住院保障外花费：</b><span>{{ item.hospitalizationExpensesExtra }}元</span>
                                    </li>
                                    <li>
                                        <b>免赔额：</b><span>{{ item.hospitalizationDeductible }}元</span>
                                    </li>
                                    <li>
                                        <b>住院总赔付额：</b><span>{{ item.totalHospitalizationCompensation }}元</span>
                                    </li>
                                </ul>
                                <div class="detail_title">三者误工费信息</div>
                                <ul class="form_ul">
                                    <li>
                                        <b>骑手月均工资：</b><span>{{ item.avgMonthlySalary }}元</span>
                                    </li>
                                    <li>
                                        <b>误工天数：</b><span>{{ item.delayWorkDays }}天</span>
                                    </li>
                                    <li>
                                        <b>免赔额：</b><span>{{ item.delayWorkDeductible }}元</span>
                                    </li>
                                    <li>
                                        <b>骑手误工费总赔付金额：</b><span>{{ item.totalDelayWorkCompensation }}元</span>
                                    </li>
                                </ul>
                                <div class="detail_title">三者营养费信息</div>
                                <ul class="form_ul">
                                    <li>
                                        <b>营养费基数：</b><span>{{ item.nutritionFeeBase }}元</span>
                                    </li>
                                    <li>
                                        <b>营养天数：</b><span>{{ item.nutritionDays }}天</span>
                                    </li>
                                    <li>
                                        <b>免赔额：</b><span>{{ item.nutritionDeductible }}元</span>
                                    </li>
                                    <li>
                                        <b>骑手营养费总赔付金额：</b><span>{{ item.totalNutritionCompensation }}元</span>
                                    </li>
                                </ul>
                                <div class="detail_title">三者伤残</div>
                                <ul class="form_ul">
                                    <li>
                                        <b>评残项目：</b><span>{{ item.disabilityAssessmentItems || '--' }}</span>
                                    </li>
                                    <li>
                                        <b>伤残鉴定日期：</b><span>{{ item.disabilityAppraisalTime || '--' }}</span>
                                    </li>
                                    <li>
                                        <b>伤残鉴定机构：</b><span>{{ item.disabilityAppraisalInstitution || '--' }}</span>
                                    </li>
                                    <li>
                                        <b>伤残等级：</b><span>{{ item.disabilityLevel }}</span>
                                    </li>
                                    <li>
                                        <b>免赔额：</b><span>{{ item.disabilityDeductible }}元</span>
                                    </li>
                                    <li>
                                        <b>上一年度城镇居民人均可支配收入：</b><span>{{ item.avgIncomeOfLastYear }}元</span>
                                    </li>
                                    <li>
                                        <b>赔偿年限：</b><span>{{ item.compensationPeriod }}年</span>
                                    </li>
                                    <li>
                                        <b>伤残赔付总额：</b><span>{{ item.totalDisabilityCompensation }}元</span>
                                    </li>
                                </ul>
                                <div class="detail_title">三者其他补助</div>
                                <ul class="form_ul">
                                    <li>
                                        <b>诉讼费：</b><span>{{ item.legalCosts }}元</span>
                                    </li>
                                </ul>
                                <div class="detail_title">三者死亡信息</div>
                                <ul class="form_ul">
                                    <li>
                                        <b>死亡原因：</b><span>{{ item.deathReason || '--' }}</span>
                                    </li>
                                    <li>
                                        <b>死亡日期：</b><span>{{ item.deathTime || '--' }}</span>
                                    </li>
                                    <li>
                                        <b>死亡总赔付额：</b><span>{{ item.totalDeathCompensation }}万元</span>
                                    </li>
                                </ul>
                            </div>
                            <el-empty v-if="checkHumanInjuryResponse.thirdWorkOrderList.length == 0" description="暂无数据"></el-empty>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src
// import MyHeader from '@/components/MyHeader.vue'
export default {
    name: 'humanInjuryList',
    components: {},
    data() {
        return {
            activeName: 'first',
            dialogVisible: false,
            loading: false,
            navForm: {
                //储存搜索框的检索条件信息
            },
            checkHumanInjuryResponse: {
                humanInjuryWorkOrderTipsVos: [],
                riderWorkOrderList: [],
                thirdWorkOrderList: []
            },
            disabilityLevelOptions: [
                { name: '一级', value: '100' },
                { name: '二级', value: '80' },
                { name: '三级', value: '70' },
                { name: '四级', value: '60' },
                { name: '五级', value: '40' },
                { name: '六级', value: '30' },
                { name: '七级', value: '20' },
                { name: '八级', value: '15' },
                { name: '九级', value: '7' },
                { name: '十级', value: '5' }
            ],
            checkedRowDetail: {}, //点击行的详情
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            totalPage: 1, //总页数tableData:[],
            tableData: [],
            multipleSelection: [],
            totalAmount: 0
        };
    },
    methods: {
        //切换table选中事件
        handleSelectionChange(val) {
            this.multipleSelection = val;
            if (val.length > 0) {
                var total = 0;
                val.forEach((value, index) => {
                    total += value.totalAmount;
                });
                this.totalAmount = total;
            }
        },
        //点击查询
        searchTable() {
            this.currentPage = 1;
            this.getTbodyData();
        },
        //点击查看详情
        lookDetails(row) {
            this.checkedRowDetail = row;
            this.dialogVisible = true;
            var json = {
                caseNo: row.caseNo,
                policyNo: row.policyNo,
                addTime: row.addTime
            };
            this.instance.post('/human/injury/getHumanInjuryWorkOrderDetail', json).then((res) => {
                var response = res.data.data;
                response.humanInjuryWorkOrderTipsVos = response.humanInjuryWorkOrderTipsVos || [];
                response.riderWorkOrderList = response.riderWorkOrderList || [];
                response.thirdWorkOrderList = response.thirdWorkOrderList || [];
                if (response.riderWorkOrderList.length > 0) {
                    response.riderWorkOrderList.forEach((val, key) => {
                        if (!val.disabilityAssessmentItems) {
                            val.disabilityLevel = '--';
                        } else {
                            this.disabilityLevelOptions.forEach((value, index) => {
                                if (val.disabilityLevel == value.value) {
                                    val.disabilityLevel = value.name;
                                }
                            });
                        }
                    });
                }
                if (response.thirdWorkOrderList.length > 0) {
                    response.thirdWorkOrderList.forEach((val, key) => {
                        if (!val.disabilityAssessmentItems) {
                            val.disabilityLevel = '--';
                        } else {
                            this.disabilityLevelOptions.forEach((value, index) => {
                                if (val.disabilityLevel == value.value) {
                                    val.disabilityLevel = value.name;
                                }
                            });
                        }
                    });
                }
                this.checkHumanInjuryResponse = response;
            });
        },
        //获取tbody里的数据
        getTbodyData: function () {
            this.loading = true;
            this.tableData = []; //  清空table的值
            var json = JSON.parse(JSON.stringify(this.navForm));
            json.pageNum = this.currentPage;
            json.pageSize = this.pageSize;
            this.instance.post('/human/injury/getHumanInjuryWorkOrders', json).then((res) => {
                this.loading = false;
                let data = res.data;
                if (data.code == 1) {
                    this.tableData = res.data.data.list;
                    this.totalPage = parseInt(res.data.data.total);
                }
            });
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTbodyData();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange: function (val) {
            this.currentPage = Number(val);
            this.getTbodyData();
        }
    },
    mounted() {
        //
        this.getTbodyData();
    }
};
</script>
<style scoped>
@import '../../assets/css/table_public.css';
.public_box {
    margin-top: 0.3rem;
    border-radius: 0.1rem;
}
.dialog_title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #000000;
}
.dialog_title div {
    width: 50%;
    text-align: center;
}
.dialog_ul {
    width: 90%;
    margin: 0 auto;
}
.dialog_ul li {
    margin-top: 0.2rem;
    display: flex;
    justify-content: space-between;
}
.dialog_ul li > div,
.dialog_ul li > b {
    width: 46%;
    text-align: center;
}
.dialog_ul li > b {
    color: #e4393c;
}
/deep/.el-tabs__header {
    margin: 0 0 0.05rem;
}
.detail_title {
    margin: 0.25rem 0 0.15rem;
    font-size: 14px;
}
.detail_title::before {
    height: 14px;
}
.pane_box {
    padding: 0 0.2rem;
}
.dialog_header {
    padding-bottom: 0.3rem;
    border-bottom: 1px solid #999;
    margin-bottom: 0.2rem;
}
/deep/.el-dialog__body {
    padding-top: 10px;
}
.download_box {
    display: flex;
    width: 100%;
    padding: 0.09rem 0.22rem;
    background: #e9f4ff;
    border: 1px solid #1989fa;
    font-size: 0.16rem;
    margin-bottom: 0.2rem;
    align-items: center;
}
.download_box b {
    display: inline-block;
    margin: 0 0.1rem;
}
.warning_icon {
    width: 0.14rem;
    margin-right: 0.18rem;
}
.download {
    margin-left: 0.3rem;
    cursor: pointer;
}
</style>
