<template>
    <div class="wrap">
        <div class="page_title"><b>报价详情</b></div>
        <div class="search_box public_box">
            <!--检索部分-->
            <el-form :inline="true" :model="navForm" class="demo-form-inline">
                <el-form-item label="查询时间：" class="dateBox">
                    <el-row style="width: 330px">
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" v-model="navForm.startTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                        <el-col class="line" style="text-align: center" :span="2">至</el-col>
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" v-model="navForm.endTime" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="报价人手机号：">
                    <el-input class="width_150" v-model="navForm.phoneNum" type="tel" maxlength="11" placeholder="请输入手机号" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="searchTable" style="margin-left: 0.2rem">查询</el-button>
                    <!-- <el-button type="primary"  size="medium" @click="linkHeat" style="margin-left: 0.2rem;">热力图</el-button> -->
                </el-form-item>
            </el-form>
        </div>
        <div class="main">
            <div class="table_box public_box">
                <div class="download_box">
                    <div class="warning_icon">
                        <img src="../../../public/images/warning_icon.png" />
                    </div>
                    <div>
                        <span>已选择</span>
                        <b class="a_color">{{ multipleSelection.length }}</b>
                        <span>项</span>
                    </div>
                    <div class="download a_color" @click="handleDownload">下载表单</div>
                </div>
                <!--table表格部分-->
                <el-table ref="multipleTable" :data="tableData" v-loading="loading" style="width: 100%" @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column prop="addTime" label="报价提交时间"></el-table-column>
                    <el-table-column prop="userName" label="报价人"></el-table-column>
                    <el-table-column prop="repairChannel" label="维修渠道"></el-table-column>
                    <el-table-column prop="vehicleBrand" label="车型"></el-table-column>
                    <el-table-column prop="vin" label="VIN"></el-table-column>
                    <el-table-column prop="operationType" label="报价类型"></el-table-column>
                    <el-table-column prop="stdPartName" label="零件名称"></el-table-column>
                    <el-table-column prop="totalPrice" label="总报价（元）"></el-table-column>
                    <el-table-column prop="tips" label="是否有异常报价"></el-table-column>
                    <el-table-column prop="abnormalPrice" label="异常总差价（元）"></el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="10" :total="totalPage"> </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
// import MyHeader from '@/components/MyHeader.vue'
export default {
    name: 'offerDetail',
    components: {},
    data() {
        return {
            loading: false,
            navForm: {
                //储存搜索框的检索条件信息
                startTime: '',
                endTime: '',
                phoneNum: ''
            },
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            totalPage: 1, //总页数tableData:[],
            tableData: [],
            multipleSelection: []
        };
    },
    methods: {
        //切换table选中事件
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        //点击查询
        searchTable() {
            this.currentPage = 1;
            this.getTbodyData();
        },
        //获取tbody里的数据
        getTbodyData: function () {
            this.loading = true;
            this.tableData = []; //  清空table的值
            var json = JSON.parse(JSON.stringify(this.navForm));
            json.pageNum = this.currentPage;
            json.pageSize = this.pageSize;
            this.instance.post('/backstage/getWorkOrders', json).then((res) => {
                this.loading = false;
                let data = res.data;
                if (data.code == 1) {
                    this.tableData = res.data.data.list;
                    this.totalPage = parseInt(res.data.data.total);
                }
            });
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTbodyData();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange: function (val) {
            this.currentPage = Number(val);
            this.getTbodyData();
        },
        //下载工单
        handleDownload() {
            var json = JSON.parse(JSON.stringify(this.navForm));
            json.workOrderList = this.multipleSelection;
            this.loading = true;
            this.instance({
                method: 'post',
                url: '/backstage/exportWorkOrders',
                data: json,
                responseType: 'blob',
                headers: {
                    // 'Content-Type':"application/x-www-form-urlencoded",
                }
            })
                .then((res) => {
                    this.loading = false;
                    if (res.data.size < 200) {
                        this.$message.error('单次下载案件不能超过10000条,请分段下载!');
                        return false;
                    }
                    let url = window.URL.createObjectURL(new Blob([res.data]));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', '车损后台统计数据下载表单.csv'); //指定下载后的文件名，防跳转
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    mounted() {
        //
        const date = new Date();
        let startDate = new Date(date.getTime() - 3600 * 1000 * 24 * 7);
        this.navForm.startTime = this.dateFormatting(startDate, 'yyyy-MM-dd');
        this.navForm.endTime = this.dateFormatting(date, 'yyyy-MM-dd');
        this.getTbodyData();
    }
};
</script>
<style scoped>
@import '../../assets/css/table_public.css';
.download_box {
    display: flex;
    width: 100%;
    padding: 0.09rem 0.22rem;
    background: #e9f4ff;
    border: 1px solid #1989fa;
    font-size: 0.16rem;
    margin-bottom: 0.2rem;
    align-items: center;
}
.download_box b {
    display: inline-block;
    margin: 0 0.1rem;
}
.warning_icon {
    width: 0.14rem;
    margin-right: 0.18rem;
}
.download {
    margin-left: 0.3rem;
    cursor: pointer;
}
</style>
