<template>
    <div class="wrap">
        <div class="page_title">
            <b>{{ pageTitle }}</b>
        </div>
        <div class="search_box public_box">
            <el-form ref="searchForm" :inline="true" :model="searchForm">
                <el-form-item label="标签名称：" prop="labelName">
                    <el-input class="width_200" v-model="searchForm.labelName" maxlength="20" placeholder="请输入标签名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="标签类型：" prop="type">
                    <el-select class="width_150" v-model="searchForm.type" placeholder="请选择标签类型" clearable>
                        <el-option v-for="item in tagTypeOption" :key="item.metaData" :label="item.dictName" :value="item.metaData"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="来源：" prop="labelSource">
                    <el-select class="width_150" v-model="searchForm.labelSource" placeholder="请选择来源" clearable>
                        <el-option v-for="item in sourceOption" :key="item.metaData" :label="item.dictName" :value="item.metaData"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="stage_item" label="关联案件数：">
                    <el-row class="stage_row">
                        <el-col :span="11">
                            <el-form-item prop="relatedCaseLe">
                                <el-input v-model="searchForm.relatedCaseLe" placeholder="最小值" maxlength="9"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col class="connect_col" :span="2">-</el-col>
                        <el-col :span="11">
                            <el-form-item prop="relatedCaseGe">
                                <el-input v-model="searchForm.relatedCaseGe" placeholder="最大值" maxlength="9"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item class="stage_item" label="质检人伤风险案件数：">
                    <el-row class="stage_row">
                        <el-col :span="11">
                            <el-form-item prop="injuryRiskCaseLe">
                                <el-input v-model="searchForm.injuryRiskCaseLe" placeholder="最小值" maxlength="9"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col class="connect_col" :span="2">-</el-col>
                        <el-col :span="11">
                            <el-form-item prop="injuryRiskCaseGe">
                                <el-input v-model="searchForm.injuryRiskCaseGe" placeholder="最大值" maxlength="9"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item class="stage_item" label="质检车损风险案件数：">
                    <el-row class="stage_row">
                        <el-col :span="11">
                            <el-form-item prop="vehicleRiskCaseLe">
                                <el-input v-model="searchForm.vehicleRiskCaseLe" placeholder="最小值" maxlength="9"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col class="connect_col" :span="2">-</el-col>
                        <el-col :span="11">
                            <el-form-item prop="vehicleRiskCaseGe">
                                <el-input v-model="searchForm.vehicleRiskCaseGe" placeholder="最大值" maxlength="9"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="searchTable" style="margin-left: 0.2rem">查询</el-button>
                    <el-button type="warning" @click="resetForm" style="margin-left: 0.2rem">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="table_box public_box">
            <p class="clearfix"><el-button class="fr" type="primary" style="margin-bottom: 10px" @click="addTagDialog">添加</el-button></p>
            <el-table stripe ref="filterTable" :data="tableData" v-loading="myPagingLoading">
                <el-table-column prop="labelName" label="标签名称" width="200"></el-table-column>
                <el-table-column prop="typeName" label="标签类型" width="200"></el-table-column>
                <el-table-column prop="labelSourceName" label="来源" width="200"></el-table-column>
                <el-table-column prop="relatedCase" label="关联案件" width="200"></el-table-column>
                <el-table-column prop="injuryRiskCase" label="质检人伤风险案件数" width="200"></el-table-column>
                <el-table-column prop="vehicleRiskCase" label="质检车损风险案件数" width="200"></el-table-column>
                <el-table-column prop="createBy" label="创建人" width="200"></el-table-column>
                <el-table-column prop="packageNo" label="是否启用" fixed="right">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.enable" active-color="#13ce66" inactive-color="#ff4949" @change="switchStatus(scope.row, scope.$index)"> </el-switch>
                    </template>
                </el-table-column>
                <el-table-column key="010" label="操作" fixed="right" width="100">
                    <template slot-scope="scope">
                        <div class="operate_box">
                            <span class="a_color" @click="editTagData(scope.row)" v-if="scope.row.labelSource == 2">修改</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!--分页部分-->
            <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="searchForm.page" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="searchForm.size" :total="searchForm.total"> </el-pagination>
        </div>
        <!-- 添加标签窗口 -->
        <el-dialog class="tag_dialog" :title="tagDataTitle" center :visible.sync="dialogVisible" :close-on-click-modal="false" width="60%" append-to-body>
            <el-form ref="tagDataForm" :rules="tagDataRules" :inline="true" :model="tagDataForm">
                <el-form-item label="标签名称：" prop="labelName">
                    <el-input class="width_310" v-model="tagDataForm.labelName" maxlength="20" placeholder="请输入标签名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="标签类型：" prop="type">
                    <el-select class="width_310" v-model="tagDataForm.type" placeholder="请选择标签类型" clearable>
                        <el-option v-for="item in tagTypeOption" :key="item.metaData" :label="item.dictName" :value="item.metaData"> </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="tag_content">
                <div class="tag_parent">
                    <div class="tag_header">
                        <div class="header_item" v-for="(item, index) in tagDataForm.labelDetails" :key="'item:' + index">
                            <div class="header_txt">
                                <div class="decoration">且</div>
                                <el-select class="width_200" v-model="item.dicItemObj" :disabled="Boolean(tagDataForm.id)" size="mini" placeholder="请选择标签类型" @change="$forceUpdate()" clearable>
                                    <el-option v-for="(dicItem, dicIndex) in itemOption" :key="dicIndex" :label="dicItem.itemName" :value="dicItem.filed + ',' + dicItem.type + ',' + dicItem.itemName"> </el-option>
                                </el-select>
                            </div>
                            <i class="btn_icon header_btn el-icon-circle-plus" @click="addTagHeaderItem" v-if="index == 0 && !tagDataForm.id"></i>
                            <i class="btn_icon body_btn el-icon-circle-plus" @click="addTagBodyItem" v-if="index == 0"></i>
                        </div>
                    </div>
                    <div class="tag_table">
                        <div class="header_item" v-for="(item, index) in tagDataForm.labelDetails" :key="'item:' + index">
                            <div class="tag_body">
                                <div class="body_item" v-for="(items, indexs) in item.labelItems" :key="'items:' + indexs">
                                    <i class="btn_icon remove_btn el-icon-remove" @click="removeTagBodyItem(indexs)" v-if="index == 0 && item.labelItems.length > 1"></i>
                                    <el-input v-model="items.value" size="mini" clearable placeholder="请输入内容" @input="$forceUpdate()"></el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--分页部分-->
            <el-pagination
                ref="pagination"
                @current-change="(val) => handleCurrentChange(val, 'dialog')"
                @size-change="(val) => handleSizeChange(val, 'dialog')"
                :current-page="detailPageForm.page"
                layout="total, sizes, prev, pager, next, jumper"
                :page-sizes="[10, 20]"
                :page-size="detailPageForm.size"
                :total="detailPageForm.total"
                v-if="tagDataForm.id"
            >
            </el-pagination>
            <span slot="footer" class="dialog-footer">
                <el-button @click="tagDataSubmit(false)">取 消</el-button>
                <el-button type="primary" @click="tagDataSubmit(true)">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pageTitle: '',
            searchForm: {
                labelName: '',
                // labelSource: '2',
                labelSource: '',
                injuryRiskCaseLe: '',
                injuryRiskCaseGe: '',
                relatedCaseLe: '',
                relatedCaseGe: '',
                vehicleRiskCaseLe: '',
                vehicleRiskCaseGe: '',
                page: 1,
                size: 20,
                total: 0
            },
            detailPageForm: {
                labelId: null,
                page: 1,
                size: 10,
                total: 0
            },
            tagDataForm: {
                id: '',
                delDetails: [],
                labelName: '',
                typeStr: '',
                labelDetails: [
                    {
                        id: null,
                        labelId: null,
                        filed: '',
                        labelItems: [{ value: '' }]
                    }
                ]
            },
            tagDataRules: {
                labelName: [{ required: true, message: '请输入标签名称', trigger: 'blur' }],
                type: [{ required: true, message: '请选择标签类型', trigger: 'blur' }]
            },
            tagTypeOption: [],
            sourceOption: [],
            itemOption: [],
            tableData: [],
            value: '',
            input: '',
            tagDataTitle: '',
            tableData: [],
            tmpTagDataForm: null,
            myPagingLoading: false,
            dialogVisible: false,
            editTagDialogForm: false
        };
    },
    mounted() {
        this.pageTitle = this.$route.meta.title;
        this.getTypeDic();
        this.getSourceDic();
        this.getTableData();
        this.getItemDic();
    },
    methods: {
        getItemDic() {
            this.instance.get('/caseRiskLabel/itemDic', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.itemOption = data.data;
                }
            });
        },
        getTypeDic() {
            this.instance.get('/caseRiskLabel/typeDic', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.tagTypeOption = data.data;
                }
            });
        },
        getSourceDic() {
            this.instance.get('/caseRiskLabel/sourceDic', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.sourceOption = data.data;
                }
            });
        },
        getTableData() {
            this.instance.post('/caseRiskLabel/searchPage', this.searchForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.tableData = data.data.list;
                    this.searchForm.total = data.data.total;
                }
            });
        },
        searchTable() {
            this.searchForm.page = 1;
            this.getTableData();
        },
        resetForm() {
            this.$refs.searchForm && this.$refs.searchForm.resetFields();
        },
        handleCurrentChange(val, switchStr) {
            let tmpPage = JSON.parse(JSON.stringify(this.detailPageForm.page));
            if (switchStr == 'dialog') {
                if (this.isEditTagDataForm()) {
                    this.detailPageForm.page = val;
                    this.editTagData(this.tagDataForm);
                } else {
                    this.$refs.pagination.internalCurrentPage = tmpPage;
                    this.$message.warning('有修改数据暂未提交，请先进行提交');
                }
            } else {
                this.searchForm.page = val;
                this.getTableData();
            }
        },
        handleSizeChange(val, switchStr) {
            let tmpSize = JSON.parse(JSON.stringify(this.detailPageForm.size));
            if (switchStr == 'dialog') {
                if (this.isEditTagDataForm()) {
                    this.detailPageForm.size = val;
                    this.detailPageForm.page = 1;
                    this.editTagData(this.tagDataForm);
                } else {
                    this.$refs.pagination.internalPageSize = tmpSize;
                    this.$message.warning('有修改数据暂未提交，请先进行提交');
                }
            } else {
                this.searchForm.size = val;
                this.searchForm.page = 1;
                this.getTableData();
            }
        },
        addTagDialog() {
            this.tagDataTitle = this.tagDataForm.id ? '修改标签' : '添加标签';
            this.tagDataForm = {
                id: '',
                delDetails: [],
                labelName: '',
                typeStr: '',
                labelDetails: [
                    {
                        id: null,
                        labelId: null,
                        filed: '',
                        labelItems: [{ value: '' }]
                    }
                ]
            };
            this.resetTagDataForm();
            this.dialogVisible = true;
        },
        tagDataSubmit(submitFlag) {
            if (submitFlag) {
                this.$refs.tagDataForm.validate((valid) => {
                    if (valid) {
                        if (this.tagDataForm.labelDetails && this.tagDataForm.labelDetails.length > 0) {
                            this.tagDataForm.labelDetails.forEach((item, index) => {
                                if (item.dicItemObj) {
                                    let dicItemObjArr = item.dicItemObj.split(',');
                                    item.labelItems.forEach((items) => {
                                        items.filed = dicItemObjArr[0];
                                        items.itemName = dicItemObjArr[2];
                                        items.type = dicItemObjArr[1];
                                    });
                                }

                                if (item.labelItems.length > 0) {
                                    item.delArr = [];
                                    item.labelItems.forEach((items, indexs) => {
                                        if (!Boolean(items.value)) {
                                            item.delArr.push(false);
                                        }
                                    });
                                }
                            });
                            this.tagDataForm.labelDetails.forEach((item, index) => {
                                if (item.delArr.length == item.labelItems.length && index != 0) {
                                    this.tagDataForm.labelDetails.splice(index, 1);
                                }
                            });
                        }
                        // 去重，删除标签id
                        this.tagDataForm.delDetails = Array.from(new Set(this.tagDataForm.delDetails));
                        this.instance.post(`/caseRiskLabel/${this.tagDataForm.id ? 'updateLabel' : 'saveLabel'}`, this.tagDataForm).then((res) => {
                            let data = res.data;
                            if (data.code == 1) {
                                // if (this.tagDataForm.id) {
                                //     this.dialogVisible = !this.isEditTagDataForm();
                                //     this.isEditTagDataForm() && this.resetTagDataForm();
                                //     this.editTagData();
                                // } else {
                                //     this.dialogVisible = false;
                                //     this.resetTagDataForm();
                                //     this.getTableData();
                                // }
                                this.dialogVisible = false;
                                this.resetTagDataForm();
                                this.getTableData();
                                this.$message.success(`${this.tagDataForm.id ? '修改成功' : '添加成功'}`);
                            } else {
                                this.$message.error(data.message);
                            }
                        });
                    }
                });
            } else {
                this.dialogVisible = false;
                this.resetTagDataForm();
            }
        },
        resetTagDataForm() {
            this.detailPageForm = {
                labelId: null,
                page: 1,
                size: 10,
                total: 0
            };
        },
        addTagHeaderItem() {
            this.tagDataForm.labelDetails.push({ id: 32, labelId: 29, labelItems: [], filed: null });
            let addArrLength = this.tagDataForm.labelDetails[0].labelItems.length;
            for (let i = 0; i < addArrLength; i++) {
                this.tagDataForm.labelDetails[this.tagDataForm.labelDetails.length - 1].labelItems.push({ value: '' });
            }
            this.$forceUpdate();
        },
        addTagBodyItem() {
            this.tagDataForm.labelDetails.forEach((item, index) => {
                item.labelItems.push({ value: '' });
            });
            this.$forceUpdate();
        },
        removeTagBodyItem(indexs) {
            this.tagDataForm.labelDetails.forEach((item, index) => {
                let tmpItem = item.labelItems.splice(indexs, 1);
                this.tagDataForm.delDetails.push(tmpItem[0].id);
            });
            this.$forceUpdate();
        },
        editTagData(itemRow) {
            this.tagDataTitle = this.tagDataForm.id ? '修改标签' : '添加标签';
            this.detailPageForm.labelId = itemRow.id || itemRow.labelId;

            this.instance.post('/caseRiskLabel/searchDetailPage', this.detailPageForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    // 回显标签编辑数据
                    this.tagDataForm = JSON.parse(JSON.stringify(itemRow));
                    this.tagDataForm.type = String(this.tagDataForm.type);
                    this.tagDataForm.labelDetails = data.data.columnData;
                    // 绑定标签表头
                    if (this.tagDataForm.labelDetails && this.tagDataForm.labelDetails.length > 0) {
                        this.tagDataForm.labelDetails.forEach((item, index) => {
                            item.filed = item.labelItems[0].filed;
                            item.dicItemObj = item.filed + ',' + item.labelItems[0].type + ',' + item.labelItems[0].itemName;
                        });
                    }
                    this.tagDataForm.delDetails = [];
                    this.detailPageForm.labelId = itemRow.id;
                    this.detailPageForm.total = data.data.data.total;
                    this.tmpTagDataForm = JSON.parse(JSON.stringify(this.tagDataForm));
                    this.dialogVisible = true;
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        switchStatus(rowItem, rowIndex) {
            this.instance.post(`/caseRiskLabel/enableDisable/${rowItem.id}`, { status: rowItem.enable }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('更新成功！');
                } else {
                    this.$message.error('更新失败！');
                    setTimeout(() => {
                        rowItem.enable = !rowItem.enable;
                        this.$set(this.tableData, rowIndex, rowItem);
                    }, 500);
                }
            });
        },
        isEditTagDataForm() {
            return JSON.stringify(this.tmpTagDataForm) == JSON.stringify(this.tagDataForm);
        }
    }
};
</script>
<style lang="less" scoped>
.search_box {
    padding-bottom: 0;
    .stage_item {
        width: 300px;
        // border: 1px solid red;
        .stage_row {
            width: 200px;
            .el-col {
                /deep/.el-form-item {
                    margin-bottom: 0;
                }
                /deep/.el-input__inner {
                    padding: 0 9px;
                }
            }
        }
    }
    .stage_item:nth-child(5),
    .stage_item:nth-child(6) {
        width: 355px;
    }
    /deep/ .el-input__inner {
        line-height: normal;
    }
}
.tag_dialog {
    .width_310 {
        width: 310px;
    }
    .tag_content {
        min-height: 100px;
        margin: 10px 0;
        width: 100%;
        margin-top: -10px;
        // border: 1px solid red;
        .tag_parent {
            width: 100%;
            overflow-x: scroll;
            .tag_table,
            .tag_header {
                padding: 40px 10px 10px 40px;
                max-height: 322px;
                // overflow: scroll;
                // overflow-y: scroll;
                white-space: nowrap;
                .header_item {
                    display: inline-block;
                    min-width: 100px;
                    border: 1px solid #aaa;
                    border-right: 0;
                    border-bottom: 0;
                    position: relative;
                    /deep/ .el-input__inner {
                        border: none;
                        border-radius: 0;
                        border-bottom: 1px solid #aaa;
                    }
                    .header_txt {
                        position: relative;
                        border-bottom: 1px solid #aaa;
                        .decoration {
                            position: absolute;
                            top: -38px;
                            right: -8px;
                            color: #333;
                            z-index: 2;
                        }
                        .decoration::before {
                            content: '';
                            display: inline-block;
                            width: 40px;
                            height: 20px;
                            border: 1px solid #aaa;
                            border-bottom: 0;
                            position: absolute;
                            left: -13px;
                            top: 16px;
                            z-index: -1;
                        }
                    }
                    .header_btn {
                        left: 20px;
                        top: -37px;
                    }
                    .body_btn {
                        left: -38px;
                        top: 2px;
                    }
                    .header_txt {
                        height: 30px;
                        line-height: 30px;
                        border-bottom: 1px solid #aaa;
                    }
                    .tag_body {
                        .body_item {
                            height: 30px;
                            line-height: 30px;
                            position: relative;
                            text-align: center;
                            .remove_btn {
                                color: #f56c6c;
                                left: -38px;
                                top: 2px;
                            }
                        }
                        .body_item:last-child {
                            border-bottom: 0;
                        }
                    }
                }
                .header_item:last-child {
                    border-right: 1px solid #aaa;
                    .decoration {
                        display: none;
                    }
                }
            }
            .tag_table {
                padding-top: 0;
                margin-top: -1px;
            }
            .tag_header {
                padding-bottom: 0;
            }
        }
    }
    .btn_icon {
        font-size: 24px;
        color: #409eff;
        cursor: pointer;
        position: absolute;
    }
}
</style>