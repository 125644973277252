<template>
    <div class="module_wrap box_border">
        <div class="box_title">{{ navForm.accidentProvince == '全国' ? '全国伤残案均金额排行榜' : '伤残损案均金额统计' }}</div>
        <div id="assessment_deviation_bar" v-show="province == '全国'"></div>
        <div id="assessment_deviation_bar2" v-show="province != '全国'"></div>
        <div class="tables_box" v-if="tableData.length > 0">
            <el-table :data="[tableData[0]]" max-height="200">
                <el-table-column v-for="(item, index) in tableData" :label="item.accItem" :key="index" :width="province == '全国' ? '' : '100'">
                    <template slot-scope="scope">
                        <span v-if="0">{{ scope.row }}</span>
                        {{ item.accAmount ? item.accAmount : '--' }} 元
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'AssessmentDeviation',
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        },
        navForm: {
            type: Object,
            default: {}
        }
    },
    watch: {
        isCollapse() {
            setTimeout(() => {
                this.myChart && this.myChart.resize();
            }, 600);
        },
        navForm: {
            // immediate: true,
            deep: true,
            handler(val) {
                this.province = val.accidentProvince;
                this.getData();
            }
        }
    },
    data() {
        return {
            topDays: [],
            newNumber: 0,
            myChart: '',
            myChart2: '',
            option: '',
            province: '',
            tableData: []
        };
    },
    mounted() {
        this.role = localStorage.getItem('role');
        window.addEventListener('resize', () => {
            if (this.myChart) {
                setTimeout(() => {
                    this.myChart.resize();
                }, 500);
            }
        });
    },
    methods: {
        getData() {
            this.instance({
                method: 'post',
                url: '/large/data/screen/disabledRanking',
                data: this.navForm,
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            }).then((res) => {
                if (res.data.code == 1) {
                    let response = res.data.data;
                    if (response.length > 0) {
                        if (this.province == '全国') {
                            response = response.splice(0, 20);
                            this.loadCharts(response);
                        } else {
                            this.loadCharts2(response);
                        }
                    }
                }
            });
        },
        //加载省份的图表
        loadCharts2(response) {
            var chartDom = document.getElementById('assessment_deviation_bar2');
            this.myChart2 = echarts.init(chartDom, null, { renderer: 'svg' });
            let xAxisData = [],
                seriesData1 = [],
                seriesData2 = [];
            response.forEach((val, key) => {
                xAxisData.push(val.accItem);
                seriesData1.push(val.prvAmount);
                seriesData2.push(val.accAmount);
            });
            this.tableData = response;
            let option = {
                tooltip: {
                    // trigger: 'axis',
                    confine: true,
                    formatter: '{a}<br/>{b}: {c}元',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    data: [this.province, '全国'],
                    itemWidth: 12,
                    itemHeight: 12,
                    itemGap: 20,
                    textStyle: {
                        color: '#fff'
                        // fontSize: '0.12rem'
                    },
                    left: 'center'
                },
                grid: {
                    top: 25,
                    left: '10',
                    right: '10',
                    bottom: '10',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: true,
                        alignWithLabel: true
                    },
                    axisLabel: {
                        showMaxLabel: true,
                        rotate: 30,
                        // fontSize: '0.12rem',
                        color: '#fff', //坐标值得具体的颜色
                        width: 70,
                        overflow: 'truncate',
                        ellipsis: '...'
                    },
                    data: xAxisData
                    // boundaryGap: [0, 0.01]
                },
                yAxis: {
                    // type: 'category',
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#fff',
                        formatter: '{value} 元'
                        // fontSize: '0.12rem'
                    }
                },
                series: [
                    {
                        name: this.province,
                        type: 'bar',
                        itemStyle: {
                            color: '#A55576'
                        },
                        stack: 'total',
                        emphasis: {
                            focus: 'series'
                        },
                        barMaxWidth: '40%',
                        data: seriesData1
                    },
                    {
                        name: '全国',
                        type: 'bar',
                        itemStyle: {
                            color: '#9B7986'
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        stack: 'total',
                        barMaxWidth: '40%',
                        data: seriesData2
                    }
                ]
            };
            this.myChart2.setOption(option);
        },
        //加载全国的图表
        loadCharts(response) {
            var chartDom = document.getElementById('assessment_deviation_bar');
            if (!chartDom) {
                return;
            }
            this.myChart = echarts.init(chartDom, null, { renderer: 'svg' });
            let xAxisData = [],
                seriesData = [];
            response.forEach((val, key) => {
                xAxisData.push(val.accItem);
                seriesData.push(val.accAmount);
            });
            this.tableData = response;
            var barWidth = 16;
            var option = {
                tooltip: {
                    // trigger: 'axis',
                    confine: true,
                    formatter: '{b} : {c}元',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    top: 30,
                    left: '10',
                    right: '0',
                    bottom: '5',
                    containLabel: true
                },
                xAxis: {
                    data: xAxisData,
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    axisLabel: {
                        showMaxLabel: true,
                        rotate: 40,
                        // fontSize: '0.12rem',
                        color: '#fff', //坐标值得具体的颜色
                        width: 60,
                        overflow: 'truncate',
                        ellipsis: '...'
                    }
                },
                yAxis: {
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#fff',
                        formatter: '{value} 元'
                        // fontSize: '0.12rem'
                    }
                },
                series: [
                    {
                        //下半截柱子
                        name: '2020',
                        type: 'bar',
                        barWidth: barWidth,
                        barGap: '-100%',
                        itemStyle: {
                            //lenged文本
                            opacity: 0.8,
                            color: function (params) {
                                return new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: '#FF9A22' // 0% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: '#FAC773' // 100% 处的颜色
                                        }
                                    ],
                                    false
                                );
                            }
                        },
                        data: seriesData
                    },
                    {
                        // 替代柱状图 默认不显示颜色，是最下方柱图（邮件营销）的value值 - 20
                        type: 'bar',
                        barWidth: barWidth,
                        barGap: '-100%',
                        stack: '广告',
                        itemStyle: {
                            color: 'transparent'
                        },
                        data: seriesData
                    },
                    {
                        //下半截柱子顶部圆片
                        name: '',
                        type: 'pictorialBar',
                        symbolSize: [barWidth, 10],
                        symbolOffset: [0, -6],
                        z: 12,
                        itemStyle: {
                            opacity: 1,
                            color: function (params) {
                                return new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: '#FF9A22' // 0% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: '#FAC773' // 100% 处的颜色
                                        }
                                    ],
                                    false
                                );
                            }
                        },
                        label: {
                            show: false,
                            position: 'top',
                            // fontSize: '0.12rem',
                            color: '#fff',
                            formatter: '{c}元'
                        },
                        symbolPosition: 'end',
                        data: seriesData
                    },
                    {
                        //下半截柱子底部圆片
                        name: '',
                        type: 'pictorialBar',
                        symbolSize: [barWidth, 11],
                        symbolOffset: [0, 5],
                        z: 12,
                        itemStyle: {
                            opacity: 1,
                            color: function (params) {
                                return new echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        {
                                            offset: 0,
                                            color: '#FF9A22' // 0% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: '#FFD56E' // 100% 处的颜色
                                        }
                                    ],
                                    false
                                );
                            }
                        },
                        data: seriesData
                    }
                ]
            };
            this.myChart.setOption(option, true);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.module_wrap {
    width: 100%;
    min-height: 3.7rem;
    padding: 0.15rem 0.3rem 0;
    padding-bottom: 0.3rem;
    margin-top: 0.25rem;
    .tables_box {
        width: 100%;
        height: 100px;
        margin-top: 17px;
        padding: 0.3rem;
        padding-bottom: 0;
        padding-top: 0.3rem;
        div {
            background: none;
        }
        /deep/ tr,
        /deep/.el-table__cell {
            background: none;
            color: #fff;
            // border-color: #006ec4;
        }
        /deep/.el-table__row::hover {
            background: none;
        }
        /deep/ tr:hover > td {
            background: none;
        }
        /deep/ td.el-table__cell {
            border: 0px;
        }
        /deep/ .el-table--border {
            border: none;
            border: 0px;
        }
    }
}
#assessment_deviation_bar,
#assessment_deviation_bar2 {
    width: 100%;
    height: 3rem;
    margin-top: 0.1rem;
}
</style>
