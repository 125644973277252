<template>
	<div class="module_wrap box_border">
		<div class="box_title">分包商（被保险人）已决案件量TOP6统计</div>
		<div id="case_bar"></div>
	</div>
</template>

<script>
import * as echarts from 'echarts';
export default {
	name: 'CaseQuantity',
	props: {
		isCollapse:{
			type: Boolean,
			default: true
		}
	},
	watch: {
		isCollapse(){
			setTimeout(()=>{
				this.myChart.resize();
			},600)
		},
	},
	data(){
		return{
			topDays:[],
			newNumber:0,
			myChart:''
		}
	},
	mounted() {
		this.role=localStorage.getItem("role");
		this.getData();
		window.addEventListener('resize', ()=> {
		    if(this.myChart){
		        setTimeout(()=>{
					this.myChart.resize();
		        },500)
		    }
		})
	},
	methods:{
		getData(){
			this.instance({
				  method:'post',
				  url:'/large/screen/insuredSettledCaseTopSix',
				  data:{},
				  headers:{
					'Content-Type':"application/json;charset=UTF-8",
				  }
			}).then(res=>{
				if(res.data.code==1){
					let response=res.data.data;
					if(response.length>0){
						this.loadCharts(response);
					}
				}
			});
		},
		loadCharts(response){
			var chartDom = document.getElementById('case_bar');
			this.myChart =echarts.init(chartDom, null, { renderer: 'svg' });
			let yAxisData=[],seriesData=[];
			response.forEach((val,key)=>{
				yAxisData.push(val.insured);
				seriesData.push(val.settledCaseNo);
			})
			var option = {
				tooltip: {
					// trigger: 'axis',
					confine:true,
					formatter: '{b}: {c}件',
					axisPointer: {
						type: 'shadow'
					}
				},
				grid: {
					top:20,
					left: '0',
					right: '4%',
					bottom: '10',
					containLabel: true
				},
				xAxis: {
					type: 'value',
					splitLine:{
						show:false
					},
					axisLabel:{
						show:false
					},
					boundaryGap: [0, 0.01]
				},
				yAxis: {
					type: 'category',
					axisLine:{
						show:false
					},
					axisTick:{
						show:false
					},
					axisLabel:{
						color:'#fff',
						fontSize:'0.12rem',
						// rotate: 20,
						width:80,
						overflow:'truncate',
						ellipsis:'...'
					},
					data: yAxisData
				},
				series: [
					{
						type: 'bar',
						itemStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
								{ offset: 0, color: '#669fdf' },
								{ offset: 0.5, color: '#8f72bf' },
								{ offset: 1, color: '#a254a8' }
							])
						},
						barMaxWidth:'40%',
						data: seriesData
					}
				]
			};
			this.myChart.setOption(option);
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.module_wrap{
		width:100% ;
		height: 3.3rem;
		padding: 0.15rem 0.3rem 0;
		margin-top: 0.25rem;
	}
	#case_bar{
		width: 100%;
		height: 2.7rem;
	}
</style>
